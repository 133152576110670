.admindailog_title{
    padding: 16px 0px !important;
}
.admin_textbox{
    height: 46px;
}
.admindailog_title_modal{
    padding: 16px 26px !important;
}

.pageHead {
    margin: 10px 0px;
}

.leftGrid {
    border: 1px solid green;
    padding: 10px 5px;
}

.searchBtnCntnr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.filters {
    padding: 10px 10px 20px 10px;
}

.heading{
    font-family: 'Segoe UI';
    font-size: 16px;
    color: #04093F;
}

.subHead {
    font-size: 14px;
    font-family: "Segoe UI";
    opacity: 0.7;
    color: #43425D;
}

.kendo_candidateSearch_admin {
    height: 67vh;
}

@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}