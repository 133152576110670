.skill_popup {
    width: 750px;
    height: 939px;
    margin: 30px auto;

}


.select {
     display: inline-block;
}
 .select .select-field input, .select .select-field label, .select .select-field input:checked ~ .select-placeholder {
     display: none;
}
 .select .select-field input:checked + label, .select .select-field .select-placeholder {
     display: block;
     font-size: 22px;
     font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
 .select .select-field label {
     border: 1px solid gray;
     padding: 5px;
     background: white;
}
 .select .select-options {
     animation: disable-options 0.5s;
     animation-fill-mode: both;
     opacity: 0;
     position: absolute;
     border: 1px solid gray;
     background: white;
}
 .select .select-options label {
     display: block;
}


 .select .select-options-focus {
     position: absolute;
     opacity: 0;
     pointer-events: none;
}
 .select .select-options-focus:focus + * {
     animation-name: enable-options;
     opacity: 1;
     

}
 @keyframes disable-options {
     0% {
         pointer-events: initial;
    }
     100% {
         pointer-events: none;
    }
}
 @keyframes enable-options {
     0% {
         pointer-events: none;
    }
     100% {
         pointer-events: initial;
    }
}
 
 form {
     width: 100%;
    
     
     align-items: center;
     justify-content: center;
     flex-direction: column;
     padding: 0px;
     box-sizing: border-box;
}
 legend {
     font-size:22px;
     margin-bottom: 17px;
      font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}

 .customized-select {
     font-family: sans-serif;
     font-size: 0.9em;
     position: relative;
     width: 100%;
}
 .customized-select label {
     cursor: pointer;
}
 .customized-select * {
     box-sizing: border-box;
}
 .customized-select .select-field label {
     background-color: #fff;
     border: 2px solid #A3BCD5;
     border-radius: 4px;
     overflow: hidden;
     padding: 15px 30px 15px 10px;
     background-image: url("https://image.flaticon.com/icons/svg/60/60995.svg");
     background-size: 10px;
     background-repeat: no-repeat;
     background-position: calc(100% - 10px) 50%;
     animation: bounce 300ms cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

 
 .customized-select .select-options {
     transition: all 200ms ease-out;
     top: 100%;
     left: 0;
     min-width: 100%;
     margin-top: 0px;
     background: #fff;
     height: 240px;
     overflow-y: scroll;
     border: 2px solid #2896FF;
     border-radius: 3px;
     z-index: 999
}
 .customized-select .select-options label {
     padding: 13px 10px;
     font-size: 22px;
      font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
 .customized-select .select-options label:hover {
     background: #DDEAF6;
     padding: 13px 10px;

}
.dropdown_width_outer {
    display: flex;
    justify-content: space-between;

}
.dropdown_width {
    width: 48.5%;
}

.text_box_cleartalent 
{
    width: 94%;
    height: 45px;
    padding: 0px 0px;
    font-size: 14px;
   /* line-height: 1.42857143;*/
  padding: 0px 20px;
    font-size: 16px;
  
  font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
}
.text_box_cleartalent:focus {
        outline-color:#2d2d2d
}
.text_box_cleartalent::placeholder {
  color: #01313C;
  opacity: 1; /* Firefox */

 padding: 15px 30px 15px 10px;
  margin-top: 1px;
  font-size: 16px;
  
  font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    white-space: normal;
word-wrap: break-word;

}


.rating_outer {
    height: 30px;
    
    width: 100%;
    display: flex;
}
.rating_skill {
    border: none;

}

.rating_skill>input {
    display: none;
}

.rating_skill>label:before {
    margin-right: 7px;
    font-size: 1.55em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating_skill>label:first-child:before {
    margin-right: 0px;
    font-size: 1.55em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating_skill>.half:before {
    content: "\f089";
    position: absolute;
}

.rating_skill>label {
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D1E8FF;
    float: right;
}


/***** CSS Magic to Highlight Stars on Hover *****/

.rating_skill>input:checked~label
{
    color: #1A8FFF;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D1E8FF;
}
.rating_skill_labelactive
{
    color: #1A8FFF !important;
    -webkit-text-stroke-width: 1px !important;
    -webkit-text-stroke-color: #D1E8FF !important;
}

/* show gold star when clicked */

.rating_skill:not(:checked)>label:hover,

/* hover current star */

.rating_skill:not(:checked)>label:hover~label {
    color: #1A8FFF;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D1E8FF;
}


/* hover previous stars in list */

.rating_skill>input:checked+label:hover,

/* hover current star when changing rating */

.rating_skill>input:checked~label:hover,
.rating_skill>label:hover~input:checked~label,

/* lighten current selection */

.rating_skill>input:checked~label:hover~label {
    color: #1A8FFF;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D1E8FF;
}

.ReactModal__Overlay {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    overflow-y: scroll !important;
}

.adddaytime_modal {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 750px !important;
    margin: 30px auto !important;
    position: absolute !important;
}

.modal_popup_close {
    position: absolute;
    top: 24px;
    right: 24px;
}
.dropdown_style
{
    width: 100%;
    padding: 20px;
    border: solid 2px #A3BCD5!important;
    font-size: 16px;
    font-family: 'Segoe-Regular';
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    padding: 15px 30px 15px 10px!important;
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
.btn-trash
{
    background-color: #ff3333;
    color:#fff;
    border:1px solid #ff3333;
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
.adddaytime_modal {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 100% !important;
    margin: 30px auto !important;
    position: absolute !important;
    /* margin-top: 200px!important; */
    
    
}
.addDay_time {
    width: 100%;
}
}

@media (min-width: 768px) and (max-width: 1435px){
    .dropdown_width{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

.subskill-badge--list {
    display: flex;
    flex-wrap: wrap;
}
.individual-additional-skill {
    border: 1px solid #A3BCD5;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
  }