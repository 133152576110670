/* @media (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .Poweresume .text_area_cleartalent_support {
    width: 77%;
    padding: 0px 30px;
    font-size: 16px;
    font-family: "Segoe-Regular";
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
  }
  .Poweresume .container_rec {
    flex-direction: column;
    align-items: flex-start;
}
.Poweresume .responsive_Support {
    padding: 0px 18px !important ;
  }
  .Poweresume .support_textbackgroundone {
    background-color: #d1e8ff;
    width: 94%;
    padding: 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .Poweresume .support_textbackgroundtwo {
    background-color: #d1e8ff;
    width: 94%;
    padding: 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .Poweresume .support_section {
    display: block;
    justify-content: unset;
  }

  .Poweresume .MuiTypography-h6 {
    font-family: "Segoe-Light" !important;
    font-size: 30px !important;
    color: #01313c !important;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  .Poweresume .hamberger_icon {
    float: right;
    display: contents;
  }
  .Poweresume .nav_logo {
    margin-top: 22px;
    margin-bottom: 16px;

    width: 80%;
  }

  .Poweresume .nav_bg_righ {
      display: flex;
  }
  .Poweresume .nav_bg_rig{
    display: flex;
     justify-content: center;
  }
  .Poweresume .hamberger_nav {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 350px;
    z-index: 99;
    background-color: #04093f;
    top: 61px;
  }
  .Poweresume .fadeInHmbMenu {
    opacity: 1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    position: absolute;
    width: 100%;
    left: 0px;
    height: 350px;
    z-index: 99;
    background-color: #04093f;
    top: 61px;
  }
  .Poweresume .fadeOutHmbMenu {
    opacity: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    width: 0;
    height: 0;
  }
  .Poweresume .navbar_mobile {
    width: 100%;
    overflow: auto;
    padding-inline-start: 0px;
  }
  .Poweresume .mobilemenuleft_adjust {
    margin-left: 24px;
  }

  .Poweresume .navbar_mobile li {
    list-style-type: none;
    width: 100%;
    border-bottom: 1px solid #192288;
    padding: 20px 0px !important;
  }
  .Poweresume .navbar_mobile li:last-child {
    list-style-type: none;
    width: 100%;
    border-bottom: 0px solid #192288;
    padding: 20px 0px !important;
  }

  .Poweresume .navbar_mobile li img {
    vertical-align: middle;
  }
  .Poweresume .nav_bg_home_sectio {
    justify-content: space-between;
  }
  .Poweresume .fle>.MuiSvgIcon-root{
    display: none;
  }
  .profileName_DesktopElement{
    display: none;
   }
   .profileName_PhoneElement{ 
     display: block !important;
    }
}
.profileName_PhoneElement{
  display: none;
}
/*--------------- Header CSS -------------*/
.profile_dropdown{
  position: relative;
}
.profile_headshadow{
  box-shadow: 0 0 10px -1px rgb(0 0 0 / 34%);
}
.profile_name{
  width: 80%;
}
.profile_drop{
  width: 1%;
}

.profile_drop_men{
position: absolute;
top: 73px;
right: 0px;
}
.profile_support
{
  margin-top: 5px;
}



.Poweresume .nav_b {
  width: 100%;
  background-color:  #FFFFFF;
}
.Poweresume .header_fixed {
  position: fixed;
  top: 0px;
  z-index: 1;
}
.Poweresume .nav_b_left {
  /* width: 42%; */
  background-color: #FFFFFF;
}
.Poweresume .nav_b_right {
  /* width: 58%; */
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Poweresume .hamberger_icon {
  display: none;
}
.Poweresume .nav_logo {
  margin-top: 22px;
  margin-bottom: 16px;
  width: 100%;
}
.Poweresume .navbar {
  width: 100%;
  overflow: auto;
  padding-inline-start: 0px;
}
.Poweresume .navbar li {
  list-style-type: none;
}
.Poweresume .navbar li img {
  vertical-align: middle;
}
 .Poweresume .cardAction{
  width:100%;
  padding:0 !important;
  margin: 0 !important;
  background-color: #1885e6;
  color: white;
  height: 160px;
  margin-top: -40px !important;
}
.Poweresume .card_text{
  font-size: 12px !important;
  font-family: 'Segoe UI'!important;
  width: 100%;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.Poweresume .menulist{
  height: 100%;
  width: 100%;
}
.Poweresume .dialog{
  position: absolute !important;
  left: 50px !important;
  top:50px !important;
  border: 5px solid black;
}
.Poweresume .all_profil{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #D1E8FF !important;
  padding: 0px 30px 50px 30px !important;
  height: 180px;
  width: 150px;

}
.Poweresume .LinkForHeader {
  float: left;
  padding: 7px 0px 0px 38px;
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-family: "Segoe-Regular";
}
.Poweresume .nav_menu_icon {
  padding-right: 5px;
}
.Poweresume .notify-badge {
  position: absolute;
  top: 5px;
  margin-left: 9px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  background: #2ad156;
  text-align: center;
  border-radius: 50%;
  color: black;
  font-size: 8px;
}
.Poweresume .MuiTypography-h6 {
  font-family: "Segoe-Light" !important;
  font-size: 35px !important;
  color: #01313c !important;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.Poweresume .MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 6px 24px;
}
.Poweresume .contact_details_heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Segoe-Semibold" !important;
  color: #01313c !important;
}
.Poweresume .nav_menu_adjust {
  display: flex;
  justify-content: space-between;
}
.Poweresume .mobilemenuleft_adjust {
  margin-left: 0px;
}
.Poweresume .fadeInHmbMenu {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
  position: absolute;
  width: 100%;
  left: 0px;
  height: 350px;
  z-index: 99;
  background-color: #04093f;
  top: 61px;
}
.Poweresume .fadeOutHmbMenu {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  width: 0;
  height: 0;
}
.Poweresume .support_textbackgroundone {
  background-color: #d1e8ff;
  width: 44.5%;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
}
.Poweresume .support_textbackgroundtwo {
  background-color: #d1e8ff;
  width: 42%;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
}
.Poweresume .support_section {
  display: flex;
  justify-content: space-between;
}
.Poweresume .text_area_cleartalent_support {
  width: 94%;
  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}
.Poweresume .add_email_box {
  width: 100%;
  position: relative;
  display: flex;
}
.Poweresume .add_email_box .search-input {
  width: 100%;
  padding: 10px;
  height: 45px;
  border: 1px solid #a5a4a4;
  border-radius: 4px 0 0 4px;
  border-right: none;
  outline: none;
  font-size: 16px;
  color: #000;
  background: none;
  font-family: "Segoe-Regular";
}

.Poweresume .add_email_box .search-input:focus {
  box-shadow: none;
  border: 1px solid #a5a4a4;
}

.Poweresume .add_email_box .search-input::placeholder {
  font-size: 16px;
  color: #01313c;

  font-family: "Segoe-Regular";
}

.Poweresume .text_area_cleartalent_support {
  width: 94%;

  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid #a5a4a4;
  border-radius: 4px;
}

.Poweresume .text_area_cleartalent_support::placeholder {
  font-size: 16px;
  color: #01313c;

  font-family: "Segoe-Regular";
}

.Poweresume .add_email_box .search-button {
  text-align: center;
  height: 45px;
  padding: 0px 20px;
  outline: none;
  cursor: pointer;
  border: 1px solid #2ad156;
  border-radius: 0 4px 4px 0;
  border-left: none;
  background: none;
  font-size: 16px;
  border-left: 1px solid #a5a4a4;
  font-family: "Segoe-Semibold";
  color: #fff;
  background-color: #2ad156;
}
.mail_bg {
  background-color: #d1e8ff;
  padding: 5px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline-block;
  margin-top: 7px;
  margin-bottom: 8px;
}
.mail_b {
  background-color: #F5F5F5;
  margin-left: 5px;
  border-radius: 50px;
  font-family: "Segoe-Regular";
  font-size: 17px;
  padding: 5px;
  display: inline-block;
  margin-top: 7px;
  margin-bottom: 8px;
}





.Poweresume .mail_text {
  font-size: 18px;
  margin-left: 8px;
  font-family: "Segoe-Regular";
  color: #01313c;
}

.mail_txt{
  font-family: "Segoe-Regular";
}

.mail_close_icon {
  margin-left: 8px;
  margin-right: 5px;
  cursor: pointer;
}
.Poweresume .support-mailbody-textarea {
  width: 100%;
  height: 100px !important;
}
.Poweresume .mail-validation-error {
  color: red;
}
.Poweresume .remove-snackbar-alert .MuiAlert-message {
  font-family: "Segoe-Regular";
}
.Poweresume .text_area_send_mail {
  width: 100%;
  padding-right: 0px;
}
.Poweresume .profile_Image{
  width: 38px;;
  height:38px;
  border-radius: 50%;
  margin-top: 8px;
}
.Poweresume .profileIma{
  width: 82.25px;
  height: 82.25px;
  border-radius: 50%;
}
.Poweresume .sdudent_name {
  font-family: "Arial" !important;
    font-size: 14px bold !important;
    text-align: center;
    /* color: #1885e6; */
    padding-top: 19px !important;

}

.Poweresume .teso{
  font-family: "Arial" !important;
  position: absolute;
}
.Poweresume .MuiSvgIcon-root {
  fill: currentColor;
  flex-shrink: 0;
  user-select: none;
}
.Poweresume #support_Icon >.fle>.MuiSvgIcon-root {
  margin-top: 0px;
  padding-top: 0px;
}
.Poweresume .user_men{
  display: flex;
}
.Poweresume .container_rec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:50px 0px;
  
}

.Poweresume .fl{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
 .Poweresume .w-100
 {
  max-width: 100%;
 }
 .Poweresume .fles{
  display: flex;
  align-items: center;
 }
 .Poweresume .nav_bg_home_sectio {
  display: flex;
  justify-content: space-between;
}
.Poweresume .support_card{
  height: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Poweresume .support_content{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.Poweresume .support_img{
  margin-top: 65px;
}

.Poweresume .support_write{
  border-bottom: 1px solid #6398F1;
}


.Poweresume .support_action{
  height: 85px !important;
  border-top: 1px solid #6A91E9 ;
  opacity: 0.5;
  margin-right: 74px;
  margin-left: 74px;
  
}

.Poweresume .support_dialog_title{
  position: relative;
}
.Poweresume #para{
  font-size: 23px;
  font-family: 'Manrope-Regular';
  color: #666666;
}
.Poweresume .support_line{
  border-bottom: 1px solid #6398F1;
  width: 268px;
  font-family: 'Manrope-Medium';
  font-size: 36px;
  color: #1885E6;
  height: 58px;
}
.Poweresume .support_form_leb{
  font-family: 'Manrope-Medium';
  font-size: 16px;
  color:#666666;

}
.support_dialog_title .support_lin{
  border-bottom: 1px solid #6398F1;
  width: 368px;
  font-family: 'Manrope-Medium';
  font-size: 36px;
  color: #1885E6;
  height: 52px;
}
.Poweresume .support_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
}
.Poweresume .blue{
  position: absolute;
  top: 0;
  right: 4px;
}
.Poweresume .support_lebel{
  width: 330px;
}

.Poweresume .Support_textbox{
  height: 40px;
  width: 100%;
  padding-left: 0px !important;
}
.Poweresume #standard-basic{
  border-bottom: 2px solid #707070;
  border-radius: 0px;
  padding-left: 0px !important;
  font-family: 'Manrope-Regular';
}
.Poweresume #mes_lable{
  font-size: 16px;
  font-family: 'Manrope-Medium';
  width: 100%;
 height: 15px;
 color:#666666;
}




.Poweresume .support_message{
  height: 140px;
  width: 100%;
  border: 1.4px solid #707070;
  opacity: 0.6;
  border-radius: 3px;
}
.Poweresume .support_message_lebel{
  margin-top: 30px;
  margin-left: 20px;
  padding-bottom: 10px;
  font-family: 'Manrope';
  font-size: 20px;
}

.Poweresume .btn_send{
  width: 149px;
  height: 37px;
  background-color: #1885E6;
  color: #FFFFFF;
  border: none;
}

.Poweresume .btn_cancel{
  width: 149px;
  height: 37px;
  background-color: #FFFFFF;
  color: #1885E6;
  border: 1px solid #1885E6 !important;
}

.Poweresume .support_con{
  padding-top: 0px !important;
  padding-right: 28px !important;
}

.Poweresume .support_grid{
  padding-right: 20px;
}

.Poweresume .support_grid_message{
  margin-top: 20px !important;
  padding-bottom: 0px;
}




.MuiAutocomplete-option li{
  margin-left: -12px !important;
}


.support_con .MuiAutocomplete-input{
  margin-left: -12px !important;
}


.Poweresume element.style {
  margin-left: 0px !important;
}

.Poweresume .resume_head
{
  display: flex;
  align-items: center;
}
.Poweresume .user_menu{
  width: max-content;
}
.pr_support{
  height: 24px;
  width: 24px;
}