.spocGrid {
    grid-template-columns: 80% 20%;
    grid-gap: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;

}

.kendoGrid_manageSpoc{
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.kendoGrid_manageSpoc .k-grid{
    min-height: 70vh !important;
}

