.kHmnKJ {
  min-height: 484px;
}
.btn-blue {
  color: #01313C;
    background-color: #D1E8FF
}
.dloCfB {
  display: none;
}
#video-intro-element {
  height: 454px;
  width: 98%;
  transform: rotateY(180deg);
  object-fit: cover;
}
#video-intro-playback-element {
  height: 454px;
  width: 98%;
  object-fit: cover;
}
.skill_video_sec_left.skill-test-left {
  width: 100% !important;
}
.skill_video_sec_right.skill-test-right {
  width: 50% !important;
}
.preview-video-intro {
  width: 100%;
  object-fit: cover;
  height: 90px;
}
.tipsRecord_btn{
  margin-left: 10px;
  background-color:rgba(209,232,255,0.5);
  border-color: rgba(163,188,213,0.5);
}
@media only screen and (max-width: 768px) {
  #video-intro-element {
    height: 454px;
    width: 100%;
    transform: rotateY(180deg);
    object-fit: cover;
  }
  .tipsRecord_btn{
    margin-left: 0px;
    background-color:rgba(209,232,255,0.5);
  border-color: rgba(163,188,213,0.5);
  }
button:hover {
  cursor: pointer;
}
}

.record-intro-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
}
.record-intro-title span{
  padding-right: 4em; 
  font-family: 'Segoe-Semibold';
}
.record-video-timer-wrapper {
  display: flex;
}
.record-intro-title .react-timer {
  display: flex !important;
  margin-left: 1rem !important;
}

.skill_video_sec_right.available-video-intro-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 2rem;
  margin-top: 20px;
}
.available-video-tipsRecord_btn {
  background-color:rgba(209,232,255,0.5);
  border-color: rgba(163,188,213,0.5);
}

video.preview-video-intro::-webkit-media-controls{
  display: none;
  -webkit-appearance: none;
}
.recorded-video-intro {
  width: 100%;
}
/* .MediaStreamView-video-FilePicker {
  width: 100%;
} */

.circular-progress-record-video {
  position: absolute;
  z-index: 99999999;
  top: 50%;
  left: 45%;
}
.react-file-picker {
  display: flex;
}

@media (max-width: 700px) {
  .record-intro-title {
    flex-wrap: wrap;
  }
  .record-video-timer-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .record-intro-title span{
    padding-right: 0;
  }
  .record-video-intro-responsive button {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 768px) {
  .skill_video_sec_right.available-video-intro-right {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.video-intro-heading.MuiDialogTitle-root {
  padding: 16px 27px !important;
}
@media (max-width: 767px) {
  .record-video-intro-responsive .MuiDialog-paperFullWidth {
    width: 100% !important;
    margin: 15px !important;
  }
  .record-video-intro-responsive .crossFormodal {
    right: 22px !important;
  }
}


@media(min-width: 1367px) {
  .record-video-intro-responsive .video-intro-btn-container {
    padding: 24px 42px 24px 42px
  }
  .MediaStreamView-video-FilePicker{
    width:98%
  }
}
@media(max-width: 1366px) {
  .record-video-intro-responsive .video-intro-btn-container {
   padding: 24px 55px 24px 42px;
  }
  .MediaStreamView-video-FilePicker{
    width: 98%;
  }
}


.seperate_border {
  border: 1px solid #e4e4e4;
  padding: 10px;
  border-radius: 4px;
}




.text-center {
text-align: center;
}

.text-underline {
text-decoration: underline;
}
.video_section_arrow {
color: #007bff;
margin-right: 10px;
justify-content: center;
flex-direction: row;
}

.video_section_box {
color: #007bff;
margin-right: 8px;
font-size: 10px;
display: flex;
justify-content: center;
flex-direction: row;
align-items: center;
}
.ml_30 {
margin-left: 30px;
}

.video_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.videotimer_count_text {
  color: #ffffff;
  font-size: 110px;
  font-family: 'Segoe-Regular';   
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #001727a1;
  padding: 0px 50px;
  border-radius: 50%;  
  box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 33%);
}
.md400{
  max-width: 400px;
}
.mb2vh
{
  margin-bottom: 2vh;
}
.delbutton{
  background-color:red ;
  color: white;
}

/* Rectangle 7 */
.retake_button
{
  width: 100%;
  /* margin-left: 40px; */
  /* justify-content: center; */
/* position: absolute; */
width: 199px;
height: 35px;

border-radius: 4px;


background: #d1e8ff;
border: none;
}

