.btn-container {
    margin-top: 20px;
  }
  
  .view-btn {
    width: 199px;
    height: 39px;
    top: 667px;
    left: 584px;
    border-radius: 8px;
    background-color: #31B3F8;
    color: white;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border-style: none;
  }
  
  .middle-card-gap {
    padding: 0px 20px;
    width: 38%;
    margin-bottom: 20px;
  }
  
  
  .table-header-recruitment-leader {
    display: flex;
    background: rgb(49,179,248);
  background: linear-gradient(90deg, rgba(49,179,248,1) 0%, rgba(29,105,146,1) 100%);
    color: white;
    align-items: center;
    padding: 15px 15px;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .recruitment-leader-left {
    display: flex;
    align-items: center;
  }
  
  .datePickerContainer_recruitment-leader-left {
    display: flex;
    align-items: center;
  
  }
  
  @media only screen and (max-width: 480px) {
    .datePickerContainer_recruitment-leader-left {
      flex-direction: column;
      align-items: flex-start;
    }
  
  }
  
  .recruitment-leader-left p,
  .datePickerContainer_recruitment-leader-left p {
    font-family: "Segoe-semibold";
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    padding-left: 10px;
  }
  
  .recruitment-leader-left .date-inner-text,
  .datePickerContainer_recruitment-leader-left .date-inner-text {
    font-family: "Segoe-semibold";
    font-weight: bold;
    color: #fff;
    font-size: 14px;
    padding: 0px 8px;
  }
  
  
  .datePickerContainer_recruitment-leader-left input[type="text"],
  select {
  
    background: white;
    border: none;
    height: 30px;
    /* padding: 0px 10px; */
    padding-left: 10px !important;
    padding-right: 10px;
    width: 120px;
  }
  
  .table-header-recruitment-leader .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0;
    opacity: 0.7;
  }
  
  .requision-table .MuiTableCell-root {
    padding: 8px 18px;
    font-family: "Segoe-semibold";
  }
  
  .box-shadow {
    box-shadow: 0px 3px 8px #0000003d;
  }
  
  .border-none {
    border-bottom: 1px solid transparent !important;
    /* width: 200px; */
  }
  
  .left {
    width: 25%;
  }
  
  .left img {
    height: 56px;
  }
  
  .left .talentCommunityLeftSideMainContainer {
    width: unset;
    border-radius: 8px;
    box-shadow: 0px 3px 8px #0000003d;
  }
  
  .right {
    width: 38%
  }
  
  .table-header-recruitment-leader img {
    width: 32px;
  }
  
  .view-btn-requisitions {
    width: 199px;
    height: 39px;
    top: 667px;
    left: 584px;
    border-radius: 8px;
    background-color: #31B3F8;
    color: white;
    font-family: "Segoe-semibold";
    font-weight: bold;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border-style: none;
  }
  
  .recruitment-leader-top .talentCommunitySizeTile {
    font-family: "Segoe-semibold";
    color: #4D4F5C;
    font-size: 13px;
  }
  
  
  .recruitment-leader-top .talentCommunitySizecontainer {
    width: 300px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 8px #0000003d;
    align-items: center;
    height: 40px;
    border-radius: 8px;
  }
  
  .recruitment-leader-top .talentCommunitySizeleftside {
    display: flex;
    align-items: center;
  }
  
  /* .recruitment-leader-top .talentCommunitySizeCount {
      padding-bottom: 5px;
      margin-top: -6px;
      color: #03A2F9;
      font-family: "Segoe-semibold";
      font-size: 24px;
    } */
  .requision-row-blue {
    width: 140px !important;
    border-bottom: 1px solid transparent !important;
    font-size: 20px !important;
    background-color: rgba(0, 158, 253, 0.17);
    font-size: 18px !important;
    font-family: "Segoe-semibold" !important;
  }
  
  .requision-number-blue {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 18px !important;
    background-color: rgba(0, 158, 253, 0.17);
    font-family: "Segoe-semibold" !important;
  }
  
  
  .requision-row-white {
    width: 140px !important;
    border-bottom: 1px solid transparent !important;
    font-size: 20px !important;
    background-color: #fff;
    font-size: 18px !important;
    font-family: "Segoe-semibold" !important;
  }
  
  .requision-number-white {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 18px !important;
    background-color: #fff;
    font-family: "Segoe-semibold" !important;
  }
  
  .requision-row {
    width: 140px !important;
    border-bottom: 1px solid transparent !important;
    font-size: 20px !important;
    background-color: rgba(255, 4, 4, 0.26);
    font-size: 18px !important;
    font-family: "Segoe-semibold" !important;
  }
  
  .requision-number {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 18px !important;
    background-color: rgba(255, 4, 4, 0.26);
    font-family: "Segoe-semibold" !important;
  }
  
  
  .requision-number-inner-1-blue {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: rgba(0, 158, 253, 0.17);
    padding: 0px !important;
  }
  
  .requision-number-inner-2-blue {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: rgba(0, 158, 253, 0.17);
    padding: 0px !important;
  }
  
  .requision-number-inner-2-blue_covered {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: aliceblue;
    padding: 0px !important;
  }
  
  .requision-number-inner-1-white {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: #fff;
    padding: 0px !important;
  }
  
  .requision-number-inner-2-white {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: #fff;
    padding: 0px !important;
  }
  
  .requision-number-inner-1 {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: rgba(255, 4, 4, 0.16);
    padding: 0px !important;
  }
  
  .requision-number-inner-2 {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: rgba(255, 4, 4, 0.16);
    padding: 0px !important;
  }
  
  
  /* @media screen and (max-width: 1270px){
  .talenCommunityActivityContainer {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
  }
  .middle-card-gap {
    padding: 0px 20px;
    width: 32%;
  }
  .right {
  width: 33%;
  }
    } */
  
  /* @media screen and (max-width: 1370px){
      .middle-card-gap {
        padding: 0px 20px;
        width: 32%;
      }
      .right {
      width: 33%;
      }
    } */
  @media only screen and (min-width: 1260px) and (max-width: 1799px) {
  
    .middle-card-gap {
      padding: 0px 20px;
      width: 36%;
    }
  
    .right {
      width: 36%;
    }
  }
  
  @media only screen and (min-width: 1260px) and (max-width: 1400px) {
  
    .table-header-recruitment-leader input[type="text"],
    select {
  
      font-size: 11px;
    }
  
    .middle-card-gap {
      padding: 0px 20px;
      width: 33%;
    }
  
    .right {
      width: 33%;
    }
  }
  
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  
    .middle-card-gap {
      padding: 0px 20px;
      width: 33%;
    }
  
    .right {
      width: 33%;
    }
  }
  
  @media only screen and (min-width: 1501px) and (max-width: 1600px) {
  
    .table-header-recruitment-leader input[type="text"],
    select {
  
      font-size: 16px;
    }
  
    .middle-card-gap {
      padding: 0px 20px;
      width: 35%;
    }
  
    .right {
      width: 35%;
    }
  }
  
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  
  /* .uncoveredTableData .MuiTableCell-stickyHeader {
    background-color: rgba(255, 4, 4, 0.13) !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 4px 10px -4px !important;
  }
  
  .coveredTableData .MuiTableCell-stickyHeader {
    background-color: rgba(0, 158, 253, 0.10) !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 4px 10px -4px !important;
  }
  
  .openTableData .MuiTableCell-stickyHeader {
    background-color: #fff !important;
    border: 1px solid rgba(128, 128, 128, 0.4) !important;
  } */
  
  .circularProgress_requisitionCount {
    width: 19px !important;
    height: 19px !important;
  }
  
  .collapseMainContainer {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }
  
  .collapseMainContainer_header {
    display: flex;
  }
  
  .collapse_headerItems {
    padding: 10px;
    font-family: 'Segoe-semibold';
    width: 100%;
    border: 1px solid #000;
    align-items: flex-end;
  }
  
  .requsitionStatusDataContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .requsitionStatusInfoContainer_header {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  }
  .requsitionStatusInfoContainer_header_dialog {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  }
  
  .collapseDataContainer {
    display: flex;
  }
  
  .collapse_dataItems {
    padding: 10px;
    font-family: 'Segoe-semibold';
    font-size: 14px;
    width: 100%;
  }
  
  .requsitionStatusInfo_title {
    width: 120px;
  }
  
  .requsitionStatusInfoContainer_header_covered {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    background-color: #d4efff;
    border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  }
  .requsitionStatusInfoContainer_header_covered_dialog {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    background-color: #d4efff;
    border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  }
  
  .requsitionStatusInfoContainer_header_uncovered {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    background: rgb(242,235,189);
    background: linear-gradient(90deg, rgba(242,235,189,1) 0%, rgba(254,191,189,1) 100%);
      border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  
  }
  .requsitionStatusInfoContainer_header_uncovered_dialog {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    background: rgb(242,235,189);
    background: linear-gradient(90deg, rgba(242,235,189,1) 0%, rgba(254,191,189,1) 100%);
      border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  
  }
  
  .coveredTableData .MuiTableCell-stickyHeader {
    background-color: #d4efff !important;
  }
  
  .uncoveredTableData .MuiTableCell-stickyHeader {
    background: rgb(242,235,189) !important;
  }
  
  
  
  /* For 150% Screen Resolution */
  @media only screen and (max-width: 1380px){
  
    .recruitment-leader-left p{
        font-size: 16px;
    }
  }
  
  .requsitionDialog .MuiTableContainer-root {
    margin: 0px 0px !important;
  }
  
  .requsitionDialog .datePickerContainer_recruitment-leader-left input[type="text"], select{
    width: 200px;
  }
  
  .ButtonSave_requisitionCount{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    width: 85px;
  }
  
  .ButtonCancel_requisitionCount{
    background-color: #ffffff !important;
    color: #009EFD !important;
    width: 85px;
  }
  


  .gridCollapseContainer  .MuiGrid-container {
    overflow-y: auto;
  }

  .gridCollapseContainer  .MuiGrid-container::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
    z-index: 999;
}

.gridCollapseContainer  .MuiGrid-container::-webkit-scrollbar-thumb {
    background-color: #1F729F;
    /* Set the color of the scrollbar thumb */
    z-index: 999;
}

.gridCollapseContainer  .MuiGrid-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the color of the scrollbar track */
    z-index: 999;
}
