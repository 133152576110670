
.container11 {
  /* position: relative; */
  width: 100%;
  max-width: 1095px;
  /* height: 647px; */

}

.navStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.threeCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.middleContainer {
  width: 36vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card1Styles {
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 32%;
}

.replaceFileBtn {
  font-size: 12px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
  border: 0px;
  width: 10vw;
  height: 4vh;
  background-color: #d1e8ff !important;
  color: #01313C !important;
}
.leftSide_poweResumeInfoCard{
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 5px;
}
.topContainerone_leftSide_poweResumeInfoCard .MuiButton-root.Mui-disabled{
  color: #01313C !important;
}

.testimonialBtn {
  font-size: 12px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
  border: 0px;
  width: 10vw;
  height: 4vh;
  background-color: white;
  border: 1px solid #A3BCD5;
}

.primarySkillContainer {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 25px;
  border-radius: 5px;
}

.primarySkillCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */

}

.pSkillHeading {
  margin-bottom: 25px;
}

.calender {
  margin-left: 20px;
}

.primarySkill_rightside {
  text-align: end;
}

.power-resume-text {
  float: right;
  top: 80px;
  margin-top: -22px;
  width: 100px;

}

.small-rectangle {
  /* position: relative;  */
  top: 5px;
  /* left: 9px; */
  /* width: 230px; */
  /* height: 87px; */
  background-color: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none;
  height: auto;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.4);
  /* shadow without bottom */
  padding: 1px 20px;
  /* box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2); */

}

.left_small_div_wd {
  width: 23%;
  /* height: 68px; */
}

.right_div_txt {
  /* text-align: right; */
  /* padding-top: 16px */
}

.right_small_div_wd {
  /* width: 77% */
}

.large-rectangle {
  /* margin-top: -3px; */
  /* position: relative;  */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* width: 50vw; */
  justify-content: space-between;
  top: -19px;
  left: 0;
  /* width: 100%;
    max-width: 1095px; */
  /* height: 647px; */
  background-color: #fff;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  /* padding-top: 18px; */
  padding: 20px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
}

.large-rectangle-padding-left {
  padding-left: 25px
}

.mg_lft_25 {
  margin-left: 25px;
}

.large-rectangle::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2); */
  ;
  z-index: -1;
}

.profileImage_powerResume {
  /* width: 58px; */
  height: 54px;
  border-radius: 50%;
}

.camera_icon_profile_powerResume {
  position: absolute;
  width: 13px;
  height: 13px;
  bottom: 0;
  right: 0px;
  top: 0;
  padding-left: 10px;
}

.powerresume_first_container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.powerresume_first_container .poweresume_profile_image {
  margin-right: 10px;
  padding-top: 6px;
  /* Adjust spacing between image and text */
}

.powerResume_specific_content {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  margin-left: 12px;
  /* padding-top: 15px; */

}

.font_12_bold_segoe {
  font-size: 12px;
  font-weight: 700;
  font-family: SegoeUI-Bold, "Segoe UI";
  color: #04093F;
  line-height: 2.0;
}

.font_12_semibold_segoe {
  font-size: 12px;
  font-weight: 600;
  font-family: "Segoe-Semibold";

}

.color_04093F {
  color: #04093F
}

.wrd_brk {
  word-break: break-word;
}

.threeDivContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.profile_boxes {
  padding-left: 13px;
  /* width: 238px;
    height: 138px; */
  /* background-color: #f1f1f1; */
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text_style_div {
  font-size: 14px;
  /* display: flex;
    text-align: center; */

  /* Adjust spacing between lines if needed */

}

.seekingText {
  font-size: 16px;
  font-weight: 600;
  font-family: SegoeUI-Semibold, "Segoe UI";
  margin-top: 20px;
}

.videoStyle {
  width: 85%;
  height: 80px;
}

.mt_bt_5 {
  margin-bottom: 5px;
}

.line_height_2 {
  line-height: 2.0;
}

.line_height_1 {
  line-height: 1.0;
}

.font_400 {
  font-size: 14px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 400;
  color: #01313c;
}

.font_600_12 {
  font-size: 12px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
}

.font_600_17 {
  font-size: 17px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
}

.font_600_10 {
  font-size: 10px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
}

.fs_regular_normal {
  font-weight: normal;
  font-family: "Segoe-Regular";
  font-size: 12px;
}

.responsive_Skill_Test_Info {
  display: none;
}

.font_600_14 {
  font-size: 14px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
}

.font_12_400 {
  font-size: 12px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 400;
}

.color_1a8fff {
  color: #1a8fff;
}

.color_01313C {
  color: #01313C;
}

.text-with-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-with-image img {
  margin-right: 5px;
  /* Adjust the spacing between the image and text */
}

.underline_text {
  text-decoration: underline;
}

.rectangle_199_35 {
  width: 199px;
  height: 35px;
  border-radius: 1px;

  transform: translate(-0.26px, -0.265px);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_color_d1e8ff {
  background-color: #d1e8ff;
}

.border_A3BCD5 {
  border: 1px solid #A3BCD5
}

.primary_skill_powerresume_wd {
  width: 100%;
  max-width: 780px;
  height: 202px;
  /* margin-bottom: 35px */
}

.primary_skill_powerresume_sub_card {
  display: flex;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease-in-out;
  /* padding: 14px 20px 20px 20px; */
  border-radius: 4px;
  margin-top: 30px
    /* background-color: #FFFFFF; */
}

.skill_videotest_powerresume_wd {
  width: 100%;
  max-width: 773px;
  /* height: 200px */
}

.skill_videotest_powerresume_sub_card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease-in-out;
  padding: 10px 25px;
  /* padding: 14px 20px 20px 20px; */
  border-radius: 4px;
  /* margin-top: 44px */
  /* background-color: #FFFFFF; */
}

.left-section,
.right-section {
  width: 50%;
  height: 100%;
  display: flex;



  flex-direction: column;
  /* align-items: center;
    justify-content: center; */
}

.font_25_300 {
  font-size: 25px;
  font-weight: 300;
  font-family: SegoeUI-Light, "Segoe UI";

}

.font_18_600 {
  font-size: 18px;
  font-weight: 600;
  font-family: SegoeUI-Light, "Segoe UI";

}

.color_01313c {
  color: #01313c;
}

.primary_skill_rectangle_container {
  margin-top: 16px;
  display: flex;
}

.btnpostion {
  display: "flex";
  padding: "0px!important";
  margin-top: "20px";
  background: "none!important";
  align-items: "center";
  flex-direction: "row";
  justify-content: "flex-end!important";
}

.btnstyle {
  padding: "0px!important";
  background: "none!important";
  max-width: "unset !important";
}

.left-section {
  width: 60%;
  padding-top: 6px;
  padding-left: 20px;
}

.font_14_rating {
  color: #1885e6;
  font-size: 14px;
  font-family: "SegoeUI, Segoe UI";
}

.font_37_300 {
  color: #1885e6;
  font-size: 37px;
  font-family: SegoeUI, "Segoe UI";
  font-weight: 300;
}

.font_15_600 {
  font-size: 15px;
  font-weight: 600;
  font-family: SegoeUI-Semibold, "Segoe UI";
}

.font_16_600 {
  font-size: 16px;
  font-weight: 600;
  font-family: SegoeUI-Semibold, "Segoe UI";
}

.right-section {
  width: 40%;
  text-align: right;
  padding-right: 14px;

}

.right-section-padding-top {
  padding-top: 59px;
}

.dltBtnContainer {
  margin-top: 4vh;
}

.buttonDeleteFont {
  color: #01313c;
  font-size: 18px;
  font-weight: 600;
  font-family: SegoeUI-Semibold, "Segoe UI";
  background-color: #ffff;
  width: 110px;
  height: 45px;
  border: 1px solid #A3BCD5;
}

.skill_publish_class {
  /* flex-wrap: wrap; */
  display: flex;
  /* padding-top: 10px;
    padding-left: 10px; */
}

.skill_class {
  display: flex;
  flex-grow: 1;
  /* width: 75% */
}

.publish_class {
  display: flex;
  text-align: right;
}

.video-interview-chkbox-publish {
  display: block;
  /* margin-bottom: 23px;
    margin-top: 10px; */
}

.testDesc {
  /* padding-left: 10px; */
  margin-top: 3px;
}

.marginTop7px {
  margin-top: 7px;
}

.newLine {
  border-top: 1px solid #a3bcd5;
  width: 73%;
  margin-left: 0px;
}

.skills_test_sore {
  /* padding-left: 10px; */
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.score_class {
  display: flex;
  /* width: 75%; */
  flex-direction: column;
}

.skillScoreClass {
  font-size: "12px";
  font-family: "SegoeUI, Segoe UI"
}

.view_skil_test_class {
  display: flex;
  text-align: right;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 12vw;
  height: 64vh;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);

  /* margin-right: 35px; */
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding-left: 10px; */
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  /* margin-right: 25px; */
}

.modifyDateBtn {
  margin-bottom: 20px;
}

.candidateDates {
  margin-top: 20px;
}

.rightDivMargin {
  margin-left: 35px;
  /* margin-top: 14px; */
}

.font_18_400 {
  font-size: 18px;
  font-weight: 400;
  font-family: SegoeUI-Bold, "Segoe UI";
}

.margin_top_left {
  margin-top: 21px;
  margin-left: 21px;
}

.font_14_400 {
  font-size: 14px;
  font-weight: 400;
  font-family: SegoeUI-Bold, "Segoe UI";
}

.avl_for_next_div {
  margin-top: 16px;
}

.retangle_175_24 {
  width: 175px;
  height: 24px;
  border-radius: 1px;
  transform: translate(-0.26px, -0.265px);
  /* margin-right: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modify_day_time {
  margin-top: 111%;
  margin-left: 11px;
}

.icon_button_dt_time {
  width: 10vw;
  height: 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #A3BCD5;
}

@media (max-width: 768px) {
  .mg_lft_25 {
    margin-left: 0px;
  }

  .rightDivMargin {
    margin-left: 0px;
  }

  .left_small_div_wd {
    width: 50%
  }

  .right_small_div_wd {
    width: 50%
  }
}

@media (max-width: 768px) {
  .threeDivContainer {

    justify-content: center;
  }

  .rightDivMargin {
    margin-left: 0px;
  }

  .left_small_div_wd {
    width: 50%
  }

  .right_small_div_wd {
    width: 50%
  }
}

@media screen and (max-width: 600px) {
  .large-rectangle-padding-left {
    padding-left: 0px
  }

  .right-section-padding-top {
    padding-top: 28px;
  }

  .rightDivMargin {
    margin-left: 0px;
  }

  .left_small_div_wd {
    width: 50%
  }

  .right_small_div_wd {
    width: 50%
  }

  .font_37_300 {
    color: #1885e6;
    font-size: 20px;
    font-family: SegoeUI, "Segoe UI";
    font-weight: 300;
  }

}

@media screen and (max-width: 1024px) {
  .font_37_300 {
    color: #1885e6;
    font-size: 20px;
    font-family: SegoeUI, "Segoe UI";
    font-weight: 300;
  }

  .left_small_div_wd {
    width: 50%
  }

  .right_small_div_wd {
    width: 50%
  }
}

@media screen and (max-width: 820px) {
  .right-section-padding-top {
    padding-top: 31px;
  }
}

/* .large-rectangle:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 5px;
      width: 80%;
      box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
    } */
/* @media (min-width: 768px) {
      .container11 {
        max-width: 768px;
      }
    }
    
    @media (min-width: 992px) {
      .container11 {
        max-width: 992px;
      }
    }
    
    @media (min-width: 1200px) {
      .container11 {
        max-width: 1200px;
      }
    } */

.headeings_poweResumeRight {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap-reverse;
}

.profileHeaderDiv {
  min-width: 220px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) -1px -5px 5px 0px;
  /* border-bottom: 4px solid #1885E6; */
  display: flex;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #FFFFFF;
}

.nameEmailDiv_poweResumeRight {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 15px;
  font-family: 'Segoe-semibold';
  font-size: 14px;
  color: #04093F;
  margin-top: 5px;

}

.powerResume_email{
  font-family: 'Segoe-Regular';
  font-size: 14px;
  color: #04093F;
}

.camera_icon_profile_poweResumeRight {
  /* position: absolute; */
  width: 18px;
  height: 18px;
  margin-left: -16px;
}

.poweResumeInfoCard_poweResumeRight {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 5px;
  margin-left: 10px;
  border-top-left-radius: 0px;
  margin-top: -3px;
  margin-right: 3px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 20px;
}

.rightCorner_poweResumeRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
}

.poweResumeHeading_poweResumeRight {
  font-family: 'Segoe UI';
  font-size: 30px;
  color: #1885E6;
  /* margin-bottom: 10px; */
}

.poweResumeLastUpdated_poweResumeRight {
  font-family: 'Segoe-semibold';
  font-size: 15px;
  color: #04093F;
}

.rightSide_poweResumeInfoCard {
  width: 220px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  padding: 15px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-left: 15px;
}

.manrope-18_poweResumeRight {
  font-family: 'Manrope';
  font-size: 20px;
  color: #04093F;
  margin-bottom: 10px;
}

.availabilty_poweResumeRight {
  font-family: 'Manrope';
  font-size: 15px;
  color: #04093F;
}

.availabiltyTime_poweResumeRight {
  font-family: 'Manrope-semibold';
  font-size: 15px;
  color: #04093F;
  margin-left: 15px;
}

.weektitle_poweResumeRight {
  margin-top: 15px;
  font-family: 'Manrope-semibold';
  font-size: 16px;
  color: #04093F;
}

.weekTiming_poweResumeRight {
  font-family: 'Manrope';
  font-size: 13px;
  background-color: #D1E8FF;
  padding: 3px 7px;
  border-radius: 5px;
  width: 200px;
  margin-top: 7px;
}

.dateTimeButton_poweResumeRight .MuiButton-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modifyDateTimeBtnText {
  font-family: 'Segoe-semibold';
  font-size: 13px;
  color: #04093F;
  margin-top: 1px;
}

.dateTimeButton_poweResumeRight {
  border: 1px solid #A3BCD5 !important;
  background-color: transparent !important;
  width: 215px;
  opacity: 0.7;
}

.dateTimeButton_poweResumeRight .MuiButton-root {
  margin-top: 10px;
}

.UpperSection_rightSide_poweResumeInfoCard {
  display: flex;
  flex-direction: column;
}

.dateTimeButtonContainer_poweResumeRight {
  padding-top: 20px !important;
  display: flex;
  flex-direction: column;
}

.trackHistory_button_poweResumeRight{
  margin-top: 20px !important;
  background-color: #05A6F4 !important;
  color: #ffffff !important;
}

.topContainerone_leftSide_poweResumeInfoCard {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.resumeCard_leftSide_poweResumeInfoCard {
  width: 200px;
  min-height: 125px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  justify-content: space-between;
  align-items: center;
}

.resumeHeading_resumeBox {
  font-family: 'Manrope';
  font-size: 15px;
  color: #04093F;
}

.resumeCard_leftSide_poweResumeInfoCard .ReplaceFileButton {
  background-color: #D1E8FF !important;
  width: 170px;
  border: 1px solid #A3BCD5 !important;
}

.videoIntroThumbnail_poweResumeRight {
  height: 80%;
  width: 196px;
}

.videoIndtroCard_leftSide_poweResumeInfoCard {
  width: 200px;
  min-height: 125px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
}

.primarySkillContainer_poweResumeRight {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
}
.primarySkillContainer_poweResumeRight_shared {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  width: 50vw;
}

.primarySkillTitle_poweResumeRight {
  font-family: 'Manrope-light';
  font-size: 25px;
  color: #04093F;
}

.primarySKillMainContainer_poweResumeRight {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.primarySKillMainContainer_poweResumeRight_shared{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 50vw;
}
.additionalsKillMainContainer_poweResumeRight{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
}
.additionalSkillRatingDiv{

}

.primarySKillItemOne_poweResumeRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.primarySKillItemTwo_poweResumeRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.PrimarySkillName_poweResumeRight {
  font-family: 'Segoe-semibold';
  font-size: 18px;
  color: #04093F;
}
.noOtherSkill_poweResumeRight{
  font-family: 'Segoe UI';
  font-size: 18px;
  color: #04093F;
  margin-top: 10px;
}

.primarySkillExp_poweResumeRight {
  font-family: 'Segoe-semibold';
  font-size: 13px;
  color: #04093F;
}

.SelfAssesmentTitle_poweResumeRight {
  font-family: 'Manrope-semibold';
  font-size: 15px;
  color: #04093F
}
.SelfAssement_additionalSkill{
  font-family: 'Manrope-semibold';
  font-size: 15px;
  color: #04093F
}

.additionalSKillScroll_poweResumeRight {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.arraowIconContainer_powerResumeRight {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.arraowIcon_powerResumeRight {
  height: 15px !important;
  width: 15px !important;
}

.skillItemCountContainer_poweResumeRight {
  font-family: 'Segoe-semibold';
  font-size: 12px;
  color: #04093F;
}

.skillItemCountContainer_poweResumeRight_opacity {
  opacity: 0.6;
}

.skillTestContainer_poweResumeRight {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
}
.skillTestContainer_poweResumeRight_shared {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  width: 50vw;
}


.skillTestName_poweResumeRight {
  font-family: 'Segoe-semibold';
  margin-top: 10px;
  font-size: 17px;
  color: #04093F;
}

.skillTestInfoContainer_poweResumeRight {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.skillTestTimeInfoContainer_poweResumeRight {
  display: flex;
  font-family: 'Segoe UI';
  font-size: 12px;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #A3BCD5;
}

.testScoreContainer_poweResumeRight {
  display: flex;
  font-family: 'Segoe UI';
  font-size: 12px;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-bottom: 10px;
}

.viewSkillTestBtn_poweResumeRight {
  width: 125px;
  background-color: #1885E6 !important;
  color: #FFFFFF !important;
}
.viewSkillTestBtn_poweResumeRight_copyLink{
  width: 125px;
  background-color: #1885E6 !important;
  color: #FFFFFF !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.viewSkillTestBtn_poweResumeRight:hover , .viewSkillTestBtn_poweResumeRight_copyLink:hover{
  opacity: 0.8;
}

.personalInfocardItems_poweResumeCard {
  display: flex;
  gap:10px;
  justify-content: flex-start;
  font-family: 'Manrope';
  font-size: 12px;
  color: #04093F;
}

.personalInfoCard_title_poweResumeInfoCard{
  font-family: 'Manrope';
  font-size: 15px;
  color: #04093F;
}

.personalInfoCard_leftSide_poweResumeInfoCard {
  width: 200px;
  min-height: 125px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap:10px;
  padding-right: 0px;
}
.icons_personalInfo_poweResumeRight {
  height: 20px !important;
  width: 14px !important;
}
.locationicons_personalInfo_poweResumeRight{
  height: 20px;
  width: 18px;
}
.linkedinicons_personalInfo_poweResumeRight{
  height: 15px;
  width: 15px;
}
.linkedinText_poweResumeRight{
  text-wrap: wrap;
  word-wrap: break-word;
  margin-top: 1px;
  width: 175px;
}
.primarySkillContainer_poweResumeRight .MuiRating-root{
  color: #1885E6 !important;
}
.starRatingContainer_poweResumeRight{
  display: flex;
  gap: 2px;
}
.starRatingHeight_poweResumeRight{
  height: 23px;
  width: 23px;
}

.skillVideoTestContentContainer_poweResumeRight{
  display: flex;
  flex-direction: column;
}
.SkillVideoTestTitleStatusContainer_poweResumeRight{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.videoSKillTestStatus_poweResumeRight_completed{
  font-family: 'Segoe-Regular';
  font-size: 18px;
  color: #FFFFFF;
  background-color: #1885E6 ;
  border-radius: 25px;
  padding: 5px 15px;
}
.videoSKillTestStatus_poweResumeRight_missing{
  font-family: 'Segoe-Regular';
  font-size: 18px;
  color: #FFFFFF;
  background-color: red ;
  border-radius: 25px;
  padding: 5px 15px;
}
.skillVideoTestestbtnContainer_poweResumeRight{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 5px;
}
.skillTestmainDiv_poweResumeRight{
  width: 100%;
}
.noVideoText_poweResume{
  font-family: 'Manrope';
  font-size: 12px;
  height: 50%;
}
.level_Skill_poweResumeRight{
  font-family: 'Segoe-semibold';
  font-size: 14px;
  color: #04093F;
}
.AdditionalSkillRatingContainer{
  display: flex;
  flex-direction: column;
}
.radioOptions_poweResumeRight{
  display: flex;
  align-items: center;
}
.radioOptionsContainer_poweResumeRight{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: -6px;
}
.radioOptionsContainer_poweResumeRight_skeleton{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: -6px;
  margin-top: 10px;
}
.radioLabel_poweResumeRight{
  font-family: 'Segoe-semibold';
  font-size: 12px;
  color: #04093F;
  margin-left: -3px;
}
.interViewTimeSkeleton{
  margin-top: 5px;
}
.additionalSkillContainerOne{
  display: flex;
  justify-content: space-between;
}
.noAdditionalSkill{
  margin-top: 15px;
}
@media only screen and (max-width: 1028px){
  .skillTestContainer_poweResumeRight_shared{
    width: 65vw;
  }
  .primarySkillContainer_poweResumeRight_shared{
    width: 65vw;
  }
  .rightSide_poweResumeInfoCard{
    margin-left: 5px;
    margin-top: 10px;
  }
}
.view_test{
  margin-left: 20px;
  margin-top: 24px ;
}
.ml-3px{
  margin-left:3px ;
}
