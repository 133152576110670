/* DeleteActivity.css */

.deleteActivityDialog .deleteActivitydialogTitle {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    font-family: '"Segoe-Semibold"', sans-serif; 
    background: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    text-align: start;
    padding: 15px;
}

.deleteActivityDialog .deleteActivitydialogContent {
    font-size: 16px;
    color: #555;
    text-align: center;
    margin: 10px 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.deleteActivityDialog .deleteActivitydialogActions {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    gap: 10px;
}

.deleteActivityDialog .deleteActivityButtonCancel {
    background-color: #f0f0f0;
    color: #333;
    border-radius: 8px;
    font-weight: bold;
}

.deleteActivityDialog .deleteActivityButtonConfirm {
    background-color: #e53935;
    color: white;
    border-radius: 8px;
    font-weight: bold;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.deleteActivityDialog .deleteActivityButtonConfirm:hover {
    background-color: #d32f2f;
}
