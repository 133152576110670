.edit_activity_cancel{
 /* background-color: yellow; */
 float:right;
 margin: 2%;
}
.actionFieldheightWidth{
    height: 40px;
    width: 100%!important;
    border:0.5px solid rgba(0, 0, 0, 0.342);
}
.loaderForEdit{
  display: grid;
  place-items: center;
  padding: 120px 0 120px 0; 
  color: rgb(45, 143, 230);
  width: 100%;
  height: 100%;
}
.updateActivityformWrapper{
    padding: 5px;
}
.spaceLeft{
  margin-left: 8%;
}