.footer_recruiter_signup {
  width: 100%;
  background-color: #C2C2C2;
}

.container_rec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:50px 0px;
  
}

.footer-left img {
  height: 40px;
  width: 350px;
}

.footer-links {
  display: flex;
  color: #000;
  font-size: 1em;
  font-family: 'Manrope-Medium';
}

.footerTerms {
  font-size: 1em;
  font-family: 'Manrope-Medium';
  color: #2896ff;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}
.footerTerms:hover{
  opacity: 0.7;
}
@media (max-width: 768px) {
  .container_rec {
      flex-direction: column;
      align-items: flex-start;
  }

  .footer-links {
      flex-direction: column;
      align-items: flex-start;
  }
}

@media (min-width:769px) and (max-width: 880px) {
  .footer-left img {
      height: 25px;
      width: 250px;
  }
  .footer-links .footerTerms{
      font-size: 0.5em;
  }
}
