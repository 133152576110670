.division-dialog-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}
.division-dialog-content{
    padding-top: 20px !important;
}
.input-field-division-dialog input[type="text"]{
    height: 45px;
}
.field-title-division-dialog{
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}
.updateButton-division{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}
.updateButton-division:hover{
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}
.updateButton-division_disabled{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.cancel-button-division{
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.cancel-button-division:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.dialog-actionbutton-division{
    padding: 15px 20px !important;
}
.cancelIcon_leadersArr{
    cursor: pointer;
    margin-top: 45px;
}
.cancelIcon_leadersArr:hover{
    opacity: 0.7;
}

.addIcon_leadersArr{
    float: right;
    cursor: pointer;
    color: #1885E6 !important;
    font-size: 35px !important;
}
.addIcon_leadersArr:hover{
    opacity: 0.7;
}
.dialog-actionbutton-division .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
}
.kendoGrid_manageDivision .k-grid{
    min-height: 70vh !important;
}
.kendoGrid_manageDivision{
    padding: 15px !important;
}
.editBtn_manageDivision{
    background-color: #31B3F8 !important;
    color: #FFFFFF !important;
}
.editBtn_manageDivision:hover{
    background-color: #31B3F8 !important;
    opacity: 0.7;
}