.mainContainer_createRequisition {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #ffffff;
}


/* .topContainer_createRequisition {} */

.bottomContainer_createRequisition {
    margin-top: auto;
}

.mainDiv_createRequisition {
    margin: 0 5%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.titlesofCommunity {
    font-size: 30px;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin: 10px 0px 20px;
    display: flex;
    align-items: center;
    width: max-content;
    color: #31B3F8;
    border-bottom: 1px solid #31B3F8;
    padding-bottom: 5px;
}
.titlesofCommunity_blank{
    min-height: 57px;
}

.goBackToRecruiterDashboard_createRequisition{
    font-size: 20px;
    color: #7F7F7F;
    font-family: "Segoe-Semibold";
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 34vw;
    margin-bottom: 15px;
}
.goBackToRecruiterDashboard_createRequisition:hover{
    color:#04093f;
}

.mainContainer_div_createRequisition{
    box-shadow: 0px 3px 8px #0000003d;
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.rightside_maincontainer_div_createRequisition{
    width: 350px;
    padding: 10px;
}

.leftside_maincontainer_div_createRequisition{
    width: calc(100% - 360px);
    padding-top: 20px;
    justify-content: space-between;
}

.middleDiv_mainContainer{
    display: flex;
    flex-direction: column;
    background-color: #B0DAF130;
    /* flex-wrap: wrap; */
    /* height: 540px; */
    border-radius: 10px;
    padding: 20px;
}

.feildvalue_container {
    display: flex;
    flex-direction: column;
}

.valueFeild_Text {
    font-family: "Segoe-regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

}

.valueFeild_createRequisition{
    margin-top: 10px;
}

.createRequisition_textbox_assignto{
    height: 47px;
    background-color: #ffffff;
}

.feildvalue_container_jobDescription{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.valueFeild_Text {
    font-family: "Segoe-regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

}

.support_message_createRequisition{
    width: 100%;
    border: 1.4px solid #707070;
    opacity: 0.6;
    border-radius: 3px;
}

.container_button_fileattachment{
    margin-top: 30px;
    height: 90px;
    display: flex;
    align-items: flex-end;
}

.attachfileIcon{
    height: 20px !important;
    width: 20px !important;
    color: #ffffff !important;
}

.uploadFuctionContainer_conditional_createRequition{
    text-align: center;
    margin-top: 10px;
}

.uploadcontainer_profile_createRequition{
    display: flex;
    flex-direction: column;
}

.uploadFuctionCon_createRequition{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.close_icon_createRequisition{
    position: relative;
    top: -10px;
    right: -77px;
    cursor: pointer;
}

.candidateFileExtention_createRequisition{
    position: relative;
    top: 4px;
    right: -24px;
}

.form_container_createRequisition{
    width: 230px;
}
.form_container_createRequisition_location{
    width: 480px;
}

/* .createRequisition_textbox{

} */

.createRequisition_textbox_outline{
    height: 47px;
    background-color: #ffffff !important;
}

.createRequisition_textbox_datapicker{
    height: 47px;
    border: 1px solid rgb(192, 192, 192);
}

.form_container_createRequisition .react-datepicker__calendar-icon{
    right: 8px !important;
    margin-top: 7px;
}

.form_container_createRequisition .react-datepicker-wrapper {
    width: 100%;
}

.leftside_topContainer_createRequisition{
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
    padding-left: 20px;
    align-content: baseline;
}

.ActionContainer_createRequisition{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-left: 20px;
    gap:10px;
}

.ButtonSave_createRequisition{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    width: 85px;
}

.ButtonSave_createRequisition_dialog{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    margin-right: 10px !important;
    width: 85px;
}

.ButtonSave_createRequisition_dialog_opacity{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    margin-right: 10px !important;
    width: 85px;
    opacity: 0.5;
}


.ButtonCancel_createRequisition{
    background-color: #ffffff !important;
    color: #009EFD !important;
    width: 85px;
}

.ButtonCancel_cloneRequisition{
    background-color: #ffffff !important;
    color: #009EFD !important;
    width: 100%;
}

.container_button_fileattachment .MuiButton-label{
    flex-direction: row;
}

.new_candidate_icon_createRequisition{
    height: 35px;
    width: 35px;
}

.candidate_filepicker_button_createRequition{
    /* width: 150px !important; */
    width: 174px !important;
    background-color: #31B3F8 !important;
    color: white !important;
}

.bottomcontainer_container_attachment {
    /* margin-top: 15px; */
    width: 174px !important;
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    /* width: 150px !important; */
}

.createRequisition_error_outline{
    height: 46px;
    border: 2px solid red;
}

.createRequisition_error{
    height: 45px;
    border: 2px solid red;
}

.button_Container_CreateRequisition{
    margin-right: 5px !important;
    margin-top: 15px !important;
}