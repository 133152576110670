@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
.editCenter{
    display: flex;
    justify-content: center;
}
.logoButton_rp{
    display: flex;
    background-color: #3f51b5;
    color: white;
    font-size: 16px;
    font-family: "Segoe-Semibold";
    border: none;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    align-items: center !important;
    transition: box-shadow 1s;
}

.logoButton_rp:hover{

    /* opacity: 0.8; */
    background-color: #303f9f;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

.logoButton_rp_text{
    margin-left: 7px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.containerMain{
    position: relative;
    width: 100%;
}
.buttonAdd{
    position: absolute;
}
/* .style_grid{
    margin-bottom: 20px;
    height: 65vh;
} */

.dialogContainer input[type="text"] {
    padding: 14px 0px !important;
}