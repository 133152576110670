.dialogueForTestimonial
{
    background: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 350px;
    position: relative;
}
.type_a
{
    cursor: pointer;
}
.testimonial_chkbox {
    display: block;
    margin:10px 0px 0px 0px;
    /*margin-top: 23px;*/
    width: 100%;
}

.testimonial_chkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.testimonial_chkbox label {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Segoe-Semibold';
    font-size: 22px;
    color: #01313C;
    display: flex;
    align-items: center;
}

.testimonial_chkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #01313C;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px !important;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    
    /* float: left; */
    margin-right: 11px;
    /* margin-top: 5px; */
}


/* .testimonial_chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 14px;
    width: 7px;
    height: 17px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */
.testimonial_chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 16px;
    /* left: -28px; */
    left: 6px;
    width: 4px;
    height: 11px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    }

.testimonial_chkbox :checked+label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #1A8FFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 12px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    
}