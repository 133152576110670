.table_wrapper {
  width: 100%;
  height: 95vh;
  display: grid;
  place-items: center;
}

.table_container {
  width: 80%;
  height: 60vh;
  margin: 0 auto;
}

.button_container {
  display: flex;
  justify-content: space-between;
}

.actionContainer {
  display: flex;
  justify-content: center;
}

.alltest_table_bg {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  height: unset;
  width: unset;
    background: white;
    padding: 20px 20px;
    margin-bottom: 10px;
}

.alltest_width {
  width: 100%;
  height: unset;
}

.creatTest_title .MuiDialogTitle-root {
  padding: 16px 24px !important;
}