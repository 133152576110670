.Pcom_pic{
    position: relative;
}
.proile-fwd-icon {
    vertical-align: super;
}

.profile_icon {
    vertical-align: middle;
}

.profile-text {
    display: inline-block;
    width: 82px;
    margin-left: 5px;
}

.profile_card {
    position: relative;
}

.profile_tops {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    align-items: center;
    display: flex;
}

.radialProgressBar {
    border-radius: 50%;
    /*-webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);*/
    width: 34px;
    height: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #ddd;
    margin-left: 14px;
    float: left;
}

.radialProgressBar .overlay {
    border-radius: 50%;
    width: 28px;
    height: 23px;
    margin: auto;
    background: #fff;
    text-align: center;
    padding-top: 12%;
    font-family: 'Segoe-Regular';
    color: #01313C;
    font-size: 12px;
    vertical-align: middle;
}

.progress-80 {
    background-image: -webkit-linear-gradient(left, #028cd5 50%, transparent 50%), -webkit-linear-gradient(108deg, #028cd5 50%, #ddd 50%);
    background-image: linear-gradient(4deg, #2896FF 50%, transparent 50%), linear-gradient(-112deg, #2896FF 50%, #ddd 50%);
}

.profileleft_popup {
    width: 700px;
    margin: 30px auto;
}

.dropdown_width_profile {
    width: 31% !important;
}
.extra_padding {
    padding: 14px 8px 20px 8px;
    }
    
    .profile_completion_bottom_padding {
    padding: 14px 12px 0px 12px;
    }

.editprofilemodal {
    background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;	
	width: 500px ;
    position: relative;
    padding: 0px 20px 0px 20px;
}
.editprofilemodal_availabilty {
    background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;	
	width: 500px ;
    position: relative;
    padding: 0px 20px 0px 20px;
}
.editprofilemodal_completion {
    background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;	
	width: 700px ;
    position: relative;
    padding: 0px 20px 0px 20px;
    overflow-y: scroll;
    height: 600px;
}
.modal_popup_close {
	position: absolute;
	top: 24px;
	right: 24px;
	
}
.myskill-right {
	text-align: right;
}

.Dropdown-control{
    border:solid 2px #A3BCD5!important;
    font-size: 22px;
    font-family: 'Segoe-Regular';
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    padding: 15px 30px 15px 10px!important;
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
.Dropdown-arrow{
    top: 28px!important;
    border-color: #444 transparent transparent!important;
    
}
/* .input_dropdown{
    padding: 13px 10px;
    font-size: 22px;
    font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
} */

.Dropdown-menu {
    transition: all 200ms ease-out !important;
    top: 100% !important;
    left: 0 !important;
    min-width: 100% !important;
    margin-top: 0px !important;
    background: #fff !important;
    height: 240px !important;
    overflow-y: scroll !important;
    border: 2px solid #2896FF !important;
    border-radius: 3px !important;
    z-index: 999 !important;


}
.Dropdown-option {
    padding: 13px 10px!important;
    font-size: 22px !important;
    font-family: 'Segoe-Semibold'!important;
    font-style: normal!important;
    font-weight: 600!important;
    color: #01313C!important;

}
.profile_pop
{
    height: auto;
    overflow-y: scroll;
}
.br-word
{
    word-break: break-all;
}
.upload_button{
    margin-right: 20px;
    margin-top: -42px;
}
/* .MuiInput-underline:before{
    transition: none!important;
    border-bottom: none!important;
}

.MuiInput-underline:after {
    border-bottom: none!important;

}
.MuiInput-underline:after{
    border-bottom: none!important; 
}
.MuiGrid-container {
    border: 2px solid #A3BCD5;
    border-radius: 4px!important;
    padding: 6px 12px!important;
}
.MuiFormControl-marginNormal{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
} */
.progressBar
{
    width: 40px!important;
    height: 40px!important;
    margin-left: 7px!important;
}
.CircularProgressbar .CircularProgressbar-text
{
    font-size: 30px!important;
    font-weight: bold;
}
.powerProfileImage
{
    width:55px;
    height: 55px;
    border-radius: 50%;
}
.camera_icon
{
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 54px;
    top:0;
}

.all_padding {
    padding-left: 20px;
}
.editprofilemodal_image {
    background: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 600px;
    position: relative;
    padding: 0px 20px 0px 20px;
    
}
.position_unset {
    position: unset;
}
/* for croper */
.crop-container {
    position: relative;
    width: 100%;
    height: 250px;
    background: #333;
  }
  
  .controls {
    padding: 16px;
    display: flex;
    
    justify-content: space-between;
   
  }
  .sliderContainer {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .slider {
    padding: 22px 0px;
    margin-left: 16px;
  }


.reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    /* border-radius: 50%;
    height: 250px !important;
    width: 250px !important; */
}
/* ------- Adding Circle Image Upload with id circle_profilePic ------*/
#circle_ProfilePic .reactEasyCrop_CropArea {
    border-radius: 50%;
    height: 250px !important;
    width: 250px !important;
  }
.mb_20 {
    margin-bottom: 20px;
}
.w-100 {
    width: 94%;
}
.MuiSlider-root {
    color: #3f51b5;
    width: 94% !important;
    cursor: pointer;
    height: 2px;
    display: inline-block;
    padding: 13px 0;
    position: relative;
    box-sizing: content-box;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    /* float: left; */
}
/* for croper */
.df_profile{
    display: flex;
}
.profiletopedit_icon{
    margin-left: 12px;
}
.phonenumber_align{
    margin-left: 6px;
}
.position_align{
    margin-left: 4px;
}
.exp_align{
    margin-left: 5px;
}
.zipgap{
    margin-top: 0px;
}
.icon_align{
    display: flex;
flex-direction: column;

justify-content: center;
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem!important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
.profilecamera_upload{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
}
.camera_section{
    text-align: center;
}
 /* .profilecompletion_edit_dailog.MuiDialog-paperWidthSm{
    width: 750px !important;
    max-width: none !important;
  } */

/* .WithStyles\(ForwardRef\(DialogContent\)\)-root-4  {
    padding: 42px 42px 0px 42px!important;
}
.WithStyles\(ForwardRef\(DialogActions\)\)-root-8 {
    margin: 0;
    padding: 33px 42px 42px 42px!important;
} */
.MuiDialog-root
.dailog_w80{
    width: 100%;
  }
  /* .email_hidden{
    display: inline-block;
    width: 90%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  } */
@media only screen and (max-width: 768px) {
    /* .email_hidden{
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      } */
    .MuiDialog-root

   .MuiDialogActions-root.responsive-dialog{
        
        padding: 24px 24px 24px 42px !important;
        
    }
    .responsive-dialog_body_profilepic{
        padding: 0px 24px 0px 24px!important;
    }

    .MuiDialog-root

    .responsive-body-for-profile-edit{
        padding: 0px 24px 0px 24px!important;
    margin-top: 70px
    }
    .MuiDialog-root

    .responsive-body-primaryskill{
        padding: 0px 24px 0px 24px;
    margin-top: 70px
    }

    .responsive-body-profilecompletion{
        padding: 0px 24px 0px 24px;
    margin-top: 70px
    }

    .MuiDialog-root
    .removemodal_responsive{
        padding: 30px 24px 0px 24px!important;
    }
    .MuiDialog-root
    .responsive-profilepic_title{
        padding: 16px 24px!important;
    }
    .MuiDialog-root
    .dailog_w80{
      width: 80%;
    }
    .MuiDialog-root
    .responsive-profilepic_body{
        padding: 0px 24px;
    }
    .MuiDialog-root
   .MuiDialogActions-root.responsiveRemove_Modal_button{
        padding: 22px!important;
    }
    
    .crop-container {
        position: relative;
        width: 100%;
        height: 250px;
        background: #333;
      }
    
    .profiletopedit_icon{
        margin-left: 142px;
    }
    .editprofilemodal_image {
    background: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 86%;
    position: relative;
    padding: 0px 20px 0px 20px;
    
}
.editprofilemodal {
    background: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 86%;
    position: relative;
    margin-left: 2px;
    }
    
    .editprofilemodal_availabilty {
        background: rgb(255, 255, 255) !important;
        border-radius: 4px !important;
        outline: none !important;	
        width: 86% ;
        position: relative;
        padding: 0px 20px 0px 20px;
    }
    .editprofilemodal_completion {
        background: rgb(255, 255, 255) !important;
        border-radius: 4px !important;
        outline: none !important;	
        width: 86% ;
        position: relative;
        padding: 0px 20px 0px 20px;
        margin-top: 0%;
        margin-left: 4px;
        height: 570px;
    }
    .profileleft_popup {
    width: 100%;
    margin: 30px auto;
    }
    .modal_popup_close {
        position: absolute;
        top: 5px!important;
        right: 24px;
    }
    .zipgap{
        margin-top: 12px;
    }
    
    
    .dropdown_width {
        width: 100%!important;
        }
        .dropdown_width_outer {
            display: block!important;
            justify-content: space-between;
        }
        .dropdown_width_profile {
            width: 100% !important;
        }
        .fs-35 {
            font-size: 27px;
        }
        .camera_icon {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 96px;
    top: 0;
}
}
.profile-completion__availability-edit-but {
    position: absolute;
    right: 35px;
    top: 20px;
    widows: 100%;
    /* margin-top: 10px; */
}
.profile-completion__availability-card {
    position: relative;
}
.profile_card .MuiSkeleton-circle {
    margin: 0 auto;
  }



  @media (min-width:769px) and (max-width:1366px) {
  .MuiDialog-root .responsive-body-primaryskill {
    padding: 0px 24px 0px 42px;
    margin-top: 70px;
}
.MuiDialog-root .responsive-body-profilecompletion {
    padding: 0px 42px 0px 42px;
    margin-top: 70px;
}
.MuiDialog-root
    .responsive-profilepic_body{
        padding: 0px 24px 0px 42px;
    }
  }

  @media (min-width:1367px) and (max-width:1920px) {
    .MuiDialog-root .responsive-body-primaryskill {
      padding: 0px 42px 0px 42px;
      margin-top: 70px;
  }
  .MuiDialog-root .responsive-body-profilecompletion {
      padding: 0px 42px 0px 42px;
      margin-top: 70px;
  }
  .MuiDialog-root
    .responsive-profilepic_body{
        padding: 0px 42px;
    }
    }
.phoneError_ml{
    margin-left: 10px;
}
.card_top {
    box-shadow: rgba(0, 0, 0, 0.24) -1px -5px 5px 0px;
    transition: all 0.2s ease-in-out;
    padding: 0px 10px 0px 10px;
    border-radius: 4px 4px 0px 0px;
    background-color: #ffffff;    
  }
@media (max-width:768px){
    .phoneError_ml{
        margin-left: 0;
    }
}
.pb_5{
    padding-bottom: 5px;
}
.resume_name{
    margin-top: 30px;
    margin-left: 10px;

}