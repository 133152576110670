.grid__container .MuiFormControl-root {
  width: 100%;
}

.grid__container .MuiFormControl-root input {
  padding: 20px 40px !important;
}

.recruiter_invite_heading {
  font-size: 18px;
  font-family: "Manrope-Semibold";
  color: #1a8fff;
}
.dropdown_width_skill_recruiter_invite_skill {
  display: flex;
}

.dropdown_width_skill_recruiter_invite_skill .MuiFormControl-fullWidth {
  width: 100%;
  border: 1px solid;
  border-radius: 4px;
  outline: none;
}

.recruiter_invite_divider {
  background-color: #a3bcd5;
  height: 1px;
  margin: 1rem 44rem 1rem 0rem;
}

.recruiter_invite_divider_two {
  margin: 1rem 20rem 1rem 0rem;

  background-color: #a3bcd5;

  height: 1px;
}
.bulk_upload_button{
  margin-left:20rem ;
}

.recruiter_invite_label {
  font-size: 20px;
  color: #04093f;
  font-family: "Segoe-Semibold";
}
.recruiter_invite_wrapper {
  padding: 6% 5.6% 1%;
  background-color: #fff;
}

@media (min-width: 1921px) and (max-width: 2560px) {
  .recruiter_invite_divider {
    background-color: #a3bcd5;
    height: 1px;
    margin: 1rem 66rem 1rem 0rem;
  }

  .recruiter_invite_divider_two {
    margin: 1rem 37rem 1rem 0rem;
    background-color: #a3bcd5;
    height: 1px;
  }
  .bulk_upload_button{
    margin-left:37rem ;
  }

  .recruiter_invite_divider_two {
    margin: 1rem 37rem 1rem 0rem;
    background-color: #a3bcd5;
    height: 1px;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .recruiter_invite_divider {
    background-color: #a3bcd5;
    height: 1px;
    margin: 1rem 58.5rem 1rem 0rem;
  }
  .recruiter_invite_divider_two {
    margin: 1rem 31rem 1rem 0rem;
    background-color: #a3bcd5;
    height: 1px;
  }
  .bulk_upload_button{
    margin-left:31rem ;
  }
  .recruiter_invite_wrapper {
    padding: 6% 5.6% 1%;
    background-color: #fff;
  }
}

.invite_candidate_buttom_wrapper {
  margin-top: 2rem;
}
.invite_candidate_add_icon_wrap .MuiSvgIcon-colorPrimary {
  color: #1a8fff;
  width: 1.4em;
  height: 1.4em;
}
.invite_candidate_form_wrapper {
  /* display: flex;
  justify-content: space-between; */
  width: 100%;
  /* padding: 0px 90px 0px 0px; */
  /* height: 450px;
    overflow: scroll; */
}

.scrollbar {
  height: 360px;
  padding: 0px 90px 0px 0px;
  width: 80%;
  overflow-y: auto;
  margin-bottom: 25px;
}

#style-2::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px; */
  background-color: #fff;
}

#style-2::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 0px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #1a8fff;
}

.recruiter_invite_label_form {
  font-size: 20px;
  color: #04093f;
  font-family: "Segoe-Semibold";
}

.dropdown_width_recruiter_invite {
  width: 32.5%;
}

.invite_candidate_form_wrapper_main {
  /* border-right: 2px solid #1A8FFF;
  display: flex; */
  width: 100%;
  margin-top: 10px;
}
.invite_candidate_form {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}
.invite_candidate_add_icon_wrap {
  float: right;
  margin-top: 20px;
  margin-right: -3px;
}

.btn-invite_submit {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  /* border: 1px solid transparent; */
  padding: 9px 35px 11px 35px;
  font-size: 18px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn-invite_submit_blue {
  color: #fff;
  background-color: #1a8fff;
  border: 1px solid #1a8fff;
  margin-top: 10px;
}

@media (min-width: 768px) and (max-width: 1366px) {
  .recruiter_invite_wrapper {
    padding: 6% 5.6% 1%;
    background-color: #fff;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .recruiter_invite_wrapper {
    padding: 6% 5.6% 1%;
    background-color: #fff;
  }
}

/* width */
.invite_candidates_scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.invite_candidates_scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.invite_candidates_scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.invite_candidates_scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.invite_candidate_skill_dropdown_wrapper_skilltype {
  width: 25%;
}
.invite_candidate_skill_dropdown_wrapper_skill {
  width: 25%;
  margin-left: 25px;
}
.closeIcon {
  position: absolute;
  right: -35px;
  top: 0px;
  /* bottom: -50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 69px;
}
.closeIcons {
  position: absolute;
  right: -80px;
  top: -3px;
}

.invite_action-icon {
  position: absolute;
  margin-top: 0px;
}
.invite_action-icon.check-circle {
  color: green;
  position: absolute;
  right: -35px;
  top: 0px;
  /* bottom: -50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 116px;
}
.invite_action-icon.circle-round {
  color: red;
  position: absolute;
  right: -35px;
  top: 0px;
  /* bottom: -50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 116px;
}
.invite__success {
  color: green;
  font-family: "Manrope-Semibold";
}
.invite__error {
  color: red;
  font-family: "Manrope-Semibold";
}

.goBack {
  font-size: 20px;
  color: #04093f;
  font-family: "Segoe-Semibold";
  text-decoration: none;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 21vw;
}
.invite_candidate_label_wrapper {
  width: 27%;
}

.client_info {
  padding-top: 10px;
  font-family: "Segoe-Regular";
  position: absolute;
}
.authorize
{
  margin-top: 30px;
}
.bulk_upload{
  font-size: 16px;
  font-family: "Segoe-Semibold";
}
.sample_file{
  margin-left: 2vh;
  align-items: center;
  font-size: 16px;
  color: #1a8fff;
  font-family: "Segoe-Semibold";
}
.bulk_drop_menu{
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.button_mgn_top{
  margin-top: 3vh;

}
.bulk_drop_mexdxd{
  position: 'fixed'; 
}
.bulkUploadTemplateUpload{
  font-size: 18px;
  
  font-family: "Segoe-Semibold";
}
.bulkUploadTemplateButton{
  height: 48px;
}

.linkButton{
  height: 48px;
}
.submit_btn_ivt{
  position: absolute;
  right: 23.9%;

}
.text_box_cleartalent_form_recruiter_highlighter {
  height: 48px;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 2px solid red;
  border-radius: 4px;
}
.btn_styl{
  width: 130px;
}
#btn_border{
  border: 2px solid red ;

}

#center_Aling{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
#downTemp{
  margin-top: 10px;
  margin-right: 3px;
}
