.recruiterSignUp .mainBody {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.recruiterSignUp .mainDiv {
    margin-top: 120px;
}

.recruiterSignUp .footerSection {
    margin-top: auto;
}

.recruiterSignUp .mainHeading {
    font-size: 35px;
    font-family: 'Manrope-semibold';
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 130px;
    margin-bottom: 35px;
}

.recruiterSignUp .leftContainer {
    padding: 0 11%;
    padding-top: 30px;
}

.recruiterSignUp .inputLabel {
    font-size: 13px;
    font-family: 'Manrope-Regular';
    margin-bottom: 8px;
    margin-top: 5px;
}

.recruiterSignUp .subLabel {
    font-size: 11px;
}

.recruiterSignUp .uploadPictureText {
    text-align: center;
    font-size: 14px;
    font-family: 'Manrope-Semibold';
    margin-bottom: 14px;
}

.recruiterSignUp .termsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.recruiterSignUp .termsLink {
    color: #2699FB;
    cursor: pointer;
    text-decoration: underline;
}

.recruiterSignUp .continue_container {
    display: flex;
    justify-content: center;
}

.recruiterSignUp .continue_button {
    width: 300px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
    padding: 15px;
    margin-top: 10px;
}

.recruiterSignUp .continue_button:hover {
    opacity: 0.8;
}

.recruiterSignUp .note {
    font-size: 13px;
    font-family: 'Segoe UI';
    font-weight: 500;
    font-style: italic;
    display: flex;
    justify-content: center;
}

.recruiterSignUp .rightContent {
    border-left: 1px solid #A3BCD5;
    height: 100%;
}

.recruiterSignUp .topContainer {
    padding-bottom: 50px;
}

.recruiterSignUp .otpInput {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.recruiterSignUp .resendBtn {
    width: 140px;
    font-family: 'Manrope-Bold';
    font-size: 17px;
    color: #fff;
    background-color: #1a8fff;
    border: none;
    border-radius: 8px;
    padding: 10px 10px;
    cursor: pointer;
}

.recruiterSignUp .resendBtn:hover {
    opacity: 0.8;
}

.recruiterSignUp .resendCont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.recruiterSignUp .timer {
    font-family: 'Manrope-Bold';
    font-size: 17px;
    margin-left: 10px;
}

.recruiterSignUp .resendBtn_disabled {
    width: 140px;
    font-family: 'Manrope-Bold';
    font-size: 17px;
    color: #fff;
    background-color: #1a8fff;
    border: none;
    border-radius: 8px;
    padding: 10px 10px;
    cursor: default;
    opacity: 0.4;
}

.recruiterSignUp .verifyContainer {
    display: flex;
    justify-content: center;
}

.recruiterSignUp .verifyBtn {
    width: 260px;
    font-family: 'Manrope-Bold';
    font-size: 18px;
    color: #fff;
    background-color: #1a8fff;
    border: none;
    border-radius: 8px;
    padding: 20px 10px;
    cursor: pointer;
    margin-top: 70px;
    margin-bottom: 35px;
}

.recruiterSignUp .verifyBtn:hover {
    opacity: 0.8;
}

.recruiterSignUp .recievedEmail {
    font-family: 'Manrope-Semibold';
    font-size: 16px;
    text-align: center;
}

.recruiterSignUp .line1 {
    font-size: 40px;
    font-family: 'Manrope-Semibold';

}

.recruiterSignUp .line2 {
    font-size: 38px;
    font-family: 'Manrope-Semibold';
    color: #1a8fff;
}

.recruiterSignUp .line3 {
    font-family: 'Manrope-Semibold';
    font-size: 20px;
    color: #04093F;
    padding-top: 16px;
}

.recruiterSignUp .thanksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
    margin-left: 11%;
    margin-right: 11%;
}

.recruiterSignUp .successImage {
    width: 100px;
    height: 100px;
    margin-right: 30px;
}

.textbox_phone_input {
    height: 47px;
    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}

.textbox_phone_input:hover {
    border: 1px solid #000;
}

.textbox_phone_input:active {
    border: 1px solid #3f51b5;
}


@media (max-width: 768px) {
    .recruiterSignUp .thanksContainer {
        flex-direction: column;
        margin-top: 25%;
    }

    .recruiterSignUp .mainHeading {
        margin-top: 100px;
        margin-bottom: 0;
    }

    .recruiterSignUp .rightContent .f_size_35 {
        font-size: 20px;
    }

    .recruiterSignUp .rightContent .tick_mark_adjust {
        width: 22px;
    }

    .recruiterSignUp .rightContent {
        padding: 0 11%;
    }

    .recruiterSignUp .form_text_container_other {
        padding: 0;
    }

    .recruiterSignUp .mainHeading {
        font-size: 28px;
    }
}

.specific_screen {
    margin-bottom: 0px !important;
}

@media (max-width: 1279px) { 
    .specific_screen1 {
        display: none;
     }
}

@media (min-width: 1367px) and (max-width: 1920px) { 
    .specific_screen1 {
       display: none;
    }

}
.profileImage_recruiterSignUp{
    height: 93px;
    width: 93px;
    border-radius: 50%;
}
