.pending_resume_container {
  margin: 120px 90px 100px;
  background-color: #ffffff;
}

.pendingResumeArrow {
  font-size: 20px;
  color: #04093f;
  font-family: "Segoe-Semibold";
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 34vw;
  margin-bottom: 15px;
}

.pending_resume_container__title {
  font-size: 30px;
  color: #04093f;
  font-family: "Segoe-Semibold";
  text-decoration: none;
  margin: 10px 0px 20px;
  display: flex;
  align-items: center;
  width: max-content;
  color: #2896ff;
  border-bottom: 1px solid #2896ff;
  padding-bottom: 5px;
}


.pending_resume_container__table {
  height: 80%;
  overflow-y: auto;
}

.scrollable {
  scrollbar-width: thin;
  scrollbar-color: #1A8FFF #dddddd;
  scrollbar-track-color: #dddddd;
  scrollbar-face-color: #1A8FFF
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #1A8FFF;
  height: 5px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #dddddd;
}

.resume_table_body {
  background-color: #e4f0f5;
}


.resume_table {
  border-collapse: collapse;
  border: 1px solid rgb(200, 200, 200);
  letter-spacing: 1px;
  font-family: sans-serif;
  font-size: 0.8rem;
  width: 98%;
}

.resume_table_td,
.resume_table_th {
  padding: 5px 0px 5px 15px;
  height: 37px;
  text-align: left;
}

.error .resume_table_td span {
  color: rgba(178, 32, 32, 1);
}

.success .resume_table_td {
  font-family: "Segoe-Regular";
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgb(190, 190, 190);
}

.pending-validations-actions {
  margin-top: 40px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.pending-validations-actions__cancel {
  color: black !important;
  width: 135px; 
}

.pending-validations-actions__submit {
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff ; 
    padding: 5px 30px ;
    width: 135px;    
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
}


.pending-validations-actions__submit:hover{
  opacity: 0.9;
}
.pending-validations-actions__submit_disable{
  /* height: 40px; */
  display: flex ;
  background-color: #1885e6 !important;  
  color: #ffffff ; 
  padding: 5px 30px ;
  width: 135px;    
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  border: none;
  font-family: Arial;
  font-size: 15px;
  border-radius: 4px;
  gap: 10px;
}
.pending-validations-actions .MuiCircularProgress-root{
  color: #FFFFFF !important;
  height: 13px !important;
  width: 13px !important;
}


.status_icons .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.status_icons .success {
  color: rgba(75, 255, 34, 1);
}

.status_icons .error {
  color: rgba(178, 32, 32, 1);
}



.status_icons {
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.custom_kendo_grid .k-table-thead,
.k-table-header {
  background-color: #1A8FFF !important;
  height: 48px !important;
  color: #ffffff !important;
  font-family: "Segoe-Semibold" !important;
  font-weight: bold !important;
  font-size: 18px !important;
  letter-spacing: 0px !important;
}

.custom_kendo_grid .k-pager-numbers .k-button .k-button-text {
  color: black !important;
}

.kendoStylePendingResume {
 min-height: 567px;
}

.pending-validations-actions__viewResume {
  color: white !important;
  background-color: #31B3F8 !important;
}

.pending-validations-actions__viewResume_blank{
  color: white !important;
  background-color: #31B3F8 !important;
  opacity: 0.5;
}

.footer_container_pending_resume{
  margin-top: auto;
}