.btn-text {
    text-align: center;
    justify-content: center;
}

.editBtn {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
    border-radius: 4px;
    border: none;
}
 
.editBtn:hover {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
.editBtn-client {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
 
.editBtn-client:hover {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}

.editIcon {
    width: 10px;
    height: 10px;
    top: 357px;
    left: 1476px;
    padding: 0.24px 0px 0.24px 0px;
}
.tableGrid {
    grid-template-columns: 80% 20%;
    grid-gap: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;
}