.spaceOut{
    margin-top: 15px;
}
span.spaceOut{
    display: inline-block;
    margin-bottom: 8px!important;
} 
/* .spaceOut span {
    
} */
.actionFieldheight{
    height: 40px;
    border:0.5px solid black;
}

.spaceOut .resumeLinkContainer{
    display: flex;
    align-items: center;
    margin: 5px;
    gap: 15px;
}

.ActivityDynamicFields .react-datepicker__input-container .react-datepicker__calendar-icon {
    margin-top: 6px;
    margin-left: 95%;
}
