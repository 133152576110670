@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');

.error404-container {
    position: relative;
    height: calc(100vh - 80px);
    /* padding-top: 80px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
}

.error404_wrapper_main {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0px 0px 20px;

}

.errorLogoWrapper {
    margin-top: 10vh;
    margin-bottom: -7vh;
}

.error404Logo {
    height: 220px;
    width: auto;
}

.error4text {
    font-size: 200px;
    color: #A3BCD5;
    font-family: 'Manrope', sans-serif;
    font-weight: lighter;
}

.errorTextHeading {
    font-size: 33px;
    font-weight: semi-bold;
    font-family: 'Manrope', sans-serif;
}

.errorTextParagrapgh {
    font-family: 'Manrope', sans-serif;

}

.error404ActionBtn {
    background-color: #1885E6 !important;
    color: white !important;
    padding: 10px 60px !important;
    font-family: 'Manrope', sans-serif !important;
    font-weight: bold !important;
}

.errorTextHeading {
    font-family: 'Manrope', sans-serif;
}

.error404_wrapper_main #errorTextHeadingID {
    margin-top: 20px;

}



@media only screen and (max-width: 600px) {

    /*Big smartphones [426px -> 600px]*/
    .errorLogoWrapper {
        margin-top: unset;
    }

    .error4text {
        font-size: 70px;

    }

    .error404Logo {
        height: 90px;
    }

    .errorTextHeading {
        font-size: 29px;
        margin-top: 35px;
    }

    .errorTextParagrapgh {
        padding: 0px 20px;
        font-size: 11px;
    }

}