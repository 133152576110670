.mainContainer_advanceSearch {
    display: flex;
    min-height: 100vh;
    /* width: 100vw; */
    flex-direction: column;
    background-color: #ffffff;
}

.footerContainer_advanceSearch {
    margin-top: auto;
}

.mainDiv_advanceSearch {
    margin-top: 115px;
    margin-left: 5%;
    margin-right: 5%;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titlesofCommunity {
    font-size: 30px;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin: 10px 0px 0px;
    display: flex;
    align-items: center;
    width: max-content;
    color: #2896ff;
    border-bottom: 1px solid #2896ff;
    padding-bottom: 5px;
}

.goBackToRecruiterDashboard{
    font-size: 20px;
    color: #7F7F7F;
    font-family: "Segoe-Semibold";
    /* text-decoration: none; */
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 34vw;
    margin-bottom: 15px;
}
.goBackToRecruiterDashboard:hover{
    color:#04093f;
}

.searchTextfield {
    width: 450px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
}

.titleContainer input[type="text"],
select {
    padding-left: 0px !important;
}

.searchIcon_advSearch {
    opacity: 0.6;
}

.mainContentContainer_advanceSearch {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: flex-start;
}

.filterContainer_advanceSearch_poweresume {
    width: 400px;
    min-height: 760px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}

.filterContainer_advanceSearch_candidate {
    width: 400px;
    min-height: 1100px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}

.topSection_searchFilter {
    padding: 15px;
    padding-left: 10px;
    background-color: #D1E8FF;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.searchButton {
    margin: 0px 0px !important;
    color: #fff !important;
    background-color: #1885e6 !important;
    width: 80px;
}

.showMyCandidateContainer {
    margin-top: 10px;
}

.showMyCandidateText {
    font-size: 11px;
    font-family: "Segoe UI";
    font-weight: 500;
    color: #04093f;
    margin-left: -5px;
}

.showMyCandidateCheckboxContainer {
    margin-left: -10px;
}

.showingRF {
    font-family: 'Segoe UI';
    font-size: 16px;
    color: #04093F;
}

.filterSection_advanceSearch {
    padding: 15px;
    padding-top: 0px;
}

.skillTitleContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.booleanText {
    font-size: 14px;
    font-family: "Segoe UI";
    opacity: 0.7;
    color: #43425D;
}

.filterItemTitle {
    font-size: 16px;
    font-family: "Segoe UI";
    color: #43425D;
}

.inputFieldWidthFix {
    width: 100%;
}

.divider_input {
    margin-top: 15px;
    width: 100%;
    border-top: 1px solid #2699FB;
    opacity: 0.4;
}

.inputItemsContainer {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}
.CurrencyContainer {
   margin-top: 13px;
   display: flex;
   flex-direction: column;
}

.skillContainer_advanceSearch {
    margin-top: 13px;
}

.sliderInputFeild {
    padding-top: 10px;
    width: 94% !important;
}

.sliderContainer .MuiSlider-track {
    height: 10px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: #2699fb;
    border-radius: 30px;
}

.sliderContainer .MuiSlider-root {
    width: 100% !important;
}

.sliderContainer .MuiSlider-rail {
    width: 100%;
    height: 10px;
    display: block;
    opacity: 0.38;
    position: absolute;
    border-radius: 1px;
    background-color: #d5dde3;
    border-radius: 30px;
}

.sliderContainer .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: -4px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -6px;
    border-radius: 50%;
    justify-content: center;
    background-color: #2699fb;
    border: 2px solid white;
}

.mytalent_range_slider .MuiSlider-root {
    color: #2699fb;
    border: 2px solid white;
    width: 100% !important;
}

.sliderContainer .MuiSlider-track {
    height: 10px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: #2699fb;
    border-radius: 30px;
    /* left: -1% !important */
}

.sliderContainer .MuiSlider-mark {
    width: 5px;
    height: 5px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: 2px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -2px;
    border-radius: 50%;
    justify-content: center;
    background-color: #2699fb;
    /* border: 2px solid white; */
    opacity: 50%;
}

.sliderContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sliderValue {
    font-family: Arial;
    font-size: 13px;
    color: #2699FB;
    margin-top: 10px;
}

.locationContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.locationAutoComplete {
    width: 120px;
}

.locationItemTitle {
    font-size: 13px;
    font-family: 'Segoe UI';
    margin-bottom: 5px;
    color: #04093F;
}

.locationAutoComplete .MuiAutocomplete-input {
    font-size: 12px !important;
    font-family: 'Segoe UI' !important;

}

.employementAutocomplete .MuiAutocomplete-input {
    font-size: 13px !important;
    font-family: 'Segoe UI' !important;
}

.locationContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.othersContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.employmentItems .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.yearsOfExperienceAutoComplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.skillSelectAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.keywordSearchContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
{
    /* padding: 5px 0px !important; */
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 18px !important;
}

.CurrencyContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.othersContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.employmentItems .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.yearsOfExperienceAutoComplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.skillSelectAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.keywordSearchContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
{
    /* padding: 5px 0px !important; */
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 18px !important;
}
.skillSelectAutocomplete {
    margin-top: 10px;
}
.keywordSelectAutocomplete{
    margin-top: 10px;
}

.locationContainer .MuiOutlinedInput-root,
.othersContainer .MuiOutlinedInput-root,
.employmentItems .MuiOutlinedInput-root,
.skillContainer_advanceSearch .MuiOutlinedInput-root,
.yearsOfExperienceAutoComplete .MuiOutlinedInput-root,
.educationAutocomplete .MuiOutlinedInput-root,
.yoeMinMaxField .MuiOutlinedInput-root,
.additionalSkillSelectContainer .MuiOutlinedInput-root,
.keywordSearchContainer .MuiOutlinedInput-root

{
    border: 1px solid #2699FB;
}


.CurrencyContainer .MuiOutlinedInput-root,
.othersContainer .MuiOutlinedInput-root,
.employmentItems .MuiOutlinedInput-root,
.skillContainer_advanceSearch .MuiOutlinedInput-root,
.yearsOfExperienceAutoComplete .MuiOutlinedInput-root,
.educationAutocomplete .MuiOutlinedInput-root,
.yoeMinMaxField .MuiOutlinedInput-root,
.additionalSkillSelectContainer .MuiOutlinedInput-root,
.keywordSearchContainer .MuiOutlinedInput-root

{
    border: 1px solid #2699FB;
}

.employementContainer {
    display: flex;
    width: 100%;
    gap: 10px;
}

.employmentItems {
    margin-top: 15px;
    width: 100%;
}

.educationCheckboxContainer {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin-left: -10px;
}

.educationCheckboxContainer .checkboxItems {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.educationCheckboxContainer .checkboxLabel {
    font-family: 'Segoe UI';
    color: #04093F;
    font-size: 12px;
    margin-left: -6px;
}

.educationCheckboxContainer .MuiCheckbox-root,
.mustHaveKeywordContainer .MuiCheckbox-root,
.showMyCandidateContainer .MuiCheckbox-root {
    color: #9bc1ff !important;
}

.educationCheckboxContainer .MuiCheckbox-colorSecondary.Mui-checked,
.mustHaveKeywordContainer .MuiCheckbox-colorSecondary.Mui-checked,
.showMyCandidateContainer .MuiCheckbox-colorSecondary.Mui-checked {
    color: #2896ff !important;
}

.KendoContainer_poweResumeSearch {
    width: calc(100% - 440px);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    margin-bottom: 17px;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 750px;
}
.KendoContainer_CandidateSearch {
    width: calc(100% - 440px);
    margin-bottom: 17px;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 1090px;
}
.KendoContainer_RecommendedCandidate {
    width: 100% ;
    margin-bottom: 17px;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 1090px;
}

.kendo_contains {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
}

.kendoTabsContainer {
    display: flex;
    align-items: center;
}

.kendoTab {
    background-color: #D1E8FF;
    width: 250px;
    font-family: 'Segoe-Semibold';
    font-size: 17px;
    text-align: center;
    padding: 10px 0px;
    opacity: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-right: 3px;
    cursor: pointer;
}

.kendoTabOpen {
    background-color: #1885e6;
    color: #FFFFFF;
}

.mainDiv_advanceSearch .k-grid-content {
    overflow-y: hidden;
}

.searchresultsText {
    font-family: 'Segoe UI';
    font-size: 18px;
    font-weight: 600;
    /* margin-bottom: 10px; */
}

.kendo_poweResumeSearch {
    min-height: 720px;
    margin-top: 10px;
}
.kendo_candidateSearch {
    min-height: 1195px;
    margin-top: 10px;
}

.mustHaveKeywordContainer {
    margin-left: -10px;
    display: flex;
    align-items: center;
}

.mustHaveText {
    font-family: 'Segoe UI';
    font-size: 13px;
    font-weight: 400;
    margin-left: -5px;
}

.skillContainer_advanceSearch .MuiChip-root,
.additionalSkillSelectContainer .MuiChip-root,
.educationAutocomplete .MuiChip-root, 
.keywordSearchContainer .MuiChip-root 
{
    background-color: #D1E8FF !important;
}

.skillContainer_advanceSearch .MuiChip-deleteIcon,
.additionalSkillSelectContainer .MuiChip-deleteIcon,
.educationAutocomplete .MuiChip-deleteIcon,
.keywordSearchContainer .MuiChip-deleteIcon

{
    color: dimgray !important;
}

.employementAutocomplete {
    width: 100%;
}

.otherCompanyNameContainer {
    margin-top: 10px;
    width: 100%;
}

.otherCompanyNameTextField {
    width: 100%;
}

.otherCompanyNameTextField input[type=text],
select {
    border: 2px solid #2698fb87;
    font-size: 13px;
    opacity: 0.9;
}

.yearsOfExperienceAutoComplete {
    margin-top: 10px;
}

.yearsOfExperienceAutoComplete .MuiAutocomplete-input,
.daysAutoComplete .MuiAutocomplete-input {
    font-size: 15px !important;
    font-family: 'Segoe UI';
}

.daysAutoComplete {
    width: 200px;
}

.educationAutocomplete {
    margin-top: 10px;
}

.educationAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.minMaxDiv {
    width: 80px;
}

.minMaxContainer {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.searchtoggleContainer .MuiSwitch-colorPrimary.Mui-checked {
    color: #08A4F9 !important
}

.searchtoggleContainer .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
    background-color: #81C8EF !important;
}

.searchtoggleContainer .MuiSwitch-track {
    background-color: #b9dcff !important;
}

.searchtoggleContainer .MuiSwitch-switchBase {
    color: #81C8EF !important;
}

.titleContainer .MuiOutlinedInput-notchedOutline {
    border: none;
}

.titleContainer .MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
}

.additionalSkillContainer_primarySearch {
    margin-top: 15px;
}

.yearsOfExpMinMaxContainer {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.yoeMinMaxField .MuiOutlinedInput-input {
    padding: 10px;
    padding-left: 10px;
}

.yoeto {
    font-size: 15px;
    font-family: 'Segoe UI';
}

.additionalSkillContainer_primarySearch .MuiFormControl-root {
    width: 100%;
}

.selectedKeywords_container {
    display: flex;
    flex-wrap: wrap;
}

.keywords_items {
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: #D1E8FF;
    padding: 0px 7px;
    border-radius: 16px;
    padding-left: 10px;
    height: 32px;
    margin: 3px;
    font-size: 14px;
    font-family: "Segoe UI";
    color: #43425D;
}

.cancelIcon_keywords {
    height: 22px !important;
    width: 22px !important;
    color: dimgray !important;
    cursor: pointer;
}

.keywordSearchContainer .MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
    padding: 10px 0px;
    border-bottom: 1px solid #2699FB;
}
.expandtext_kendo{
    color: blue;
    cursor: pointer;
    font-size: 13px;
    font-family: sans-serif;
}
.expandtext_kendo:hover{
    color:red
}
.keywordSearchTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.searchtoggleContainer {
    display: flex;
    align-items: center;
}

.btn_activity_cancel_community{
    width: 107px;
    height: 39px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #ffff;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  }
  .btn_activity_save_community{
    width: 107px;
    height: 39px;
    border: none;
    border-radius: 8px;  
    font-size: 16px;
    font-weight: 500;
    color: #ffff;
    background-color:#31B3F8;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

    }
    .topSection_advanceSearch{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
    }

    
