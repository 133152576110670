.mainDiv_reg {
    margin-top: 120px;
    background-color: #FFFFFF;
}
.mainContainer_reg{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    background-color: #FFFFFF;
}

.rightHeading {
    font-size: 35px;
    font-family: 'Manrope-semibold';
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 35px;
}

.leftContainer {
    padding: 0px 11%;
    padding-top: 30px;
}

.leftHeading {
    font-size: 32px;
    font-family: 'Manrope-Regular';
    margin-bottom: 30px;
}

.left_points {
    font-size: 17px;
    font-family: 'Manrope-Regular';
    margin-bottom: 20px;
}

.leftBullets {
    font-size: 18px;
    font-family: 'Manrope-Bold';
    color: #707070;
}

.blueTexts {
    font-size: 17px;
    font-family: 'Manrope-Semibold';
    color: #2699FB;
}

.rightContainer {
    border-left: 1px solid #A3BCD5;
    height: 100%;
    padding-left: 20px;
    padding-right: 11%;
    padding-top: 30px;
}
.inputLabel_right{
    font-size: 14px;
    font-family: 'Manrope-Regular';
    margin-bottom: 8px;
    margin-top: 5px;
}
.inputLabel_right_email{
    font-size: 14px;
    font-family: 'Manrope-Regular';
    margin-top: 5px;
}
.sendOtp{
    width: 130px;
    padding: 12px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
}
.sendOtp:hover{
    opacity: 0.8;
}
.sendOtp_disabled{
    width: 130px;
    padding: 12px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    opacity: 0.4;
    cursor:default;
}
.changeEmail{
    width: 120px;
    white-space: nowrap;
    padding: 12px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 15px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
    margin-right: 5px;
}
.changeEmail:hover{
    opacity: 0.8;
}
.resendOtp{
    width: 120px;
    white-space: nowrap;
    padding: 12px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 15px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
    margin-right: 5px;
}
.resendOtp:hover{
    opacity: 0.8;
}
.resendOtpDiv{
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
.note{
    font-size: 14px;
    font-family: 'Segoe UI';
    font-weight: 600;
    font-style: italic;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.alreadyAccount{
    font-size: 16px;
    font-family: 'Segoe UI';
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.loginLink{
    color: #2699FB;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: underline;
}
.continue_container{
    display: flex;
    justify-content: center;
}
.continue_button{
    width: 300px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
    padding: 15px;
    margin-top: 10px;
}
.continue_button:hover{
    opacity: 0.8;
}
.continue_button_disabled{
    width: 300px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    cursor:default;
    padding: 15px;
    opacity: 0.4;
    margin-top: 10px;
}
.termsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.termsLink{
    color: #2699FB;
    cursor: pointer;
    text-decoration: underline;
}
.rightHeading_inviteTest {
    font-size: 35px;
    font-family: 'Manrope-semibold';
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 35px;
    text-align: center;
}
@media (max-width: 600px){
    .rightContainer {
        padding-left: 11%;
    }
    .rightHeading_inviteTest{
        font-size: 25px;
    }
}
.topContainer{
    padding-bottom: 50px;
    background-color: #FFFFFF;
}

.headerListStyleNone{
    list-style: none;
}
.hamburgerIsClicked{
    display: block;
}
.hamburgerIsNotClicked{
    display: none;
}
.main_landing_container{
    margin-top: 50px;
    padding-bottom: 50px;
}
.contact_us{
    font-family: 'Manrope-Medium';
}