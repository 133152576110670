/*--------------- RATING CSS -------------*/

.rating {
	border: none;
	float: right;
}

.rating>input {
	display: none;
}

.rating>label:before {
	margin-right: 7px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
}

.rating>label:first-child:before {
	margin-right: 0px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
}

.rating>.half:before {
	content: "\f089";
	position: absolute;
}

.rating>label {
	color: #fff;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	float: right;
}


/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,

/* show gold star when clicked */

.rating:not(:checked)>label:hover,

/* hover current star */

.rating:not(:checked)>label:hover~label {
	
}
.rating_labelactive
{
    color: #1A8FFF !important;
    -webkit-text-stroke-width: 1px !important;
    -webkit-text-stroke-color: #D1E8FF !important;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,

/* hover current star when changing rating */

.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,

/* lighten current selection */

.rating>input:checked~label:hover~label {
	
}


/*--------------- RATING CSS -------------*/
.ReactModal__Overlay {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    overflow-y: scroll !important;
}

.adddaytime_modal {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 750px !important;
    margin: 30px auto !important;
    position: absolute !important;
}

.modal_popup_close {
    position: absolute;
    top: 24px;
    right: 24px;
}
/*---------------  -------------*/
.rating1 {
	border: none;
	float: right;
	}
	
	.rating1>input {
	display: none;
	}
	
	.rating1>label:before {
	margin-right: 7px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
	}
	
	.rating1>label:first-child:before {
	margin-right: 0px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
	}
	
	.rating1>.half:before {
	content: "\f089";
	position: absolute;
	}
	
	.rating1>label {
	color: #fff;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	float: right;
	}
	
	
	.rating1>input:checked~label {
	color: #1A8FFF;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	}


	.rating1 {
	border: none;
	float: right;
	}
	
	.rating1>input {
	display: none;
	}
	
	.rating1>label:before {
	margin-right: 7px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
	}
	
	.rating1>label:first-child:before {
	margin-right: 0px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
	}
	
	.rating1>.half:before {
	content: "\f089";
	position: absolute;
	}
	
	.rating1>label {
	color: #fff;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	float: right;
	}
	
	
	.rating1>input:checked~label {
	color: #1A8FFF;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	}
	/*---------------  -------------*/
	.rating2 {
		border: none;
		float: right;
		}
		
		.rating2>input {
		display: none;
		}
		
		.rating2>label:before {
		margin-right: 7px;
		font-size: 1.55em;
		font-family: FontAwesome;
		display: inline-block;
		content: "\f005";
		}
		
		.rating2>label:first-child:before {
		margin-right: 0px;
		font-size: 1.55em;
		font-family: FontAwesome;
		display: inline-block;
		content: "\f005";
		}
		
		.rating2>.half:before {
		content: "\f089";
		position: absolute;
		}
		
		.rating2>label {
		color: #fff;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #D1E8FF;
		float: right;
		}
		
		
		.rating2>input:checked~label {
		color: #1A8FFF;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #D1E8FF;
		}
	
	
		.rating2 {
		border: none;
		float: right;
		}
		
		.rating2>input {
		display: none;
		}
		
		.rating2>label:before {
		margin-right: 7px;
		font-size: 1.55em;
		font-family: FontAwesome;
		display: inline-block;
		content: "\f005";
		}
		
		.rating2>label:first-child:before {
		margin-right: 0px;
		font-size: 1.55em;
		font-family: FontAwesome;
		display: inline-block;
		content: "\f005";
		}
		
		.rating2>.half:before {
		content: "\f089";
		position: absolute;
		}
		
		.rating2>label {
		color: #fff;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #D1E8FF;
		float: right;
		}
		
		
		.rating2>input:checked~label {
		color: #1A8FFF;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #D1E8FF;
		}
		
.Dropdown-control{
    border:solid 2px #A3BCD5!important;
    font-size: 22px;
    font-family: 'Segoe-Regular';
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    padding: 15px 30px 15px 10px!important;
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
.Dropdown-arrow{
    top: 28px!important;
    border-color: #444 transparent transparent!important;
    
}
/* .input_dropdown{
    padding: 13px 10px;
    font-size: 22px;
    font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
} */

.Dropdown-menu {
    transition: all 200ms ease-out !important;
    top: 100% !important;
    left: 0 !important;
    min-width: 100% !important;
    margin-top: 0px !important;
    background: #fff !important;
    height: 240px !important;
    overflow-y: scroll !important;
    border: 2px solid #2896FF !important;
    border-radius: 3px !important;
    z-index: 999 !important;


}

.Dropdown-option {
    padding: 13px 10px!important;
    font-size: 22px !important;
    font-family: 'Segoe-Semibold'!important;
    font-style: normal!important;
    font-weight: 600!important;
    color: #01313C!important;

}
.badge_skill {
	box-sizing: border-box;
	display: inline-block;
	background-color: #D1E8FF;
	color: #01313C;
	border-radius: 3rem;
	text-align: center;
	font-size: 1.6rem;
	font-weight: 400;
	padding: 0.05rem 0.8rem 0.1rem;
	line-height: inherit;
	font-family: 'Segoe-Regular';
	font-style: normal;
	margin-top: 5px;
	}
	
.badge_skill--info {
	background-color: #D1E8FF;
	}
	
.badge_skill--smaller {
	font-size: 13px;
	padding: 3px 10px ;
	}
.totalexp{
	width: 60%;
}
@media only screen and (max-width: 768px) {

.edit_left{
	margin-left: 12px!important;
}
.totalexp{
	width: 100%;
}
.badge_skill {
	
	margin-top: 5px;
	}
}
.rating_skill img {
	margin-right: 5px;
	cursor: pointer;
}

.subskill-badge {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #D1E8FF; 
	/* background-color: #e0e0e0; */
	transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-size: 13px;
	padding: 3px 0px;
	/* color: #01313C; */
	color: rgba(0, 0, 0, 0.87);
	border-radius: 3rem;
	text-align: center;
	font-weight: 400;
	line-height: inherit;
	font-family: 'Segoe-Regular';
	font-style: normal;
	margin-right: 10px;
	margin-bottom: 0.5rem;
}
.subskill-badge--list {
	display: flex;
	flex-wrap: wrap;
}
.subskill-badge > span {
	padding: 0px 12px;
	white-space: nowrap;
}
.subskill-badge > svg {
	cursor: pointer;
}

.subskill-badge > svg.MuiChip-deleteIcon:hover {
	color: rgba(0, 0, 0, 0.4) !important;
}

.subskill-badge > svg.MuiChip-deleteIcon.MuiChip-deleteIcon {
	color: rgba(0, 0, 0, 0.26);
	width: 22px;
	cursor: pointer;
	height: 22px;
	margin: 0 5px 0 -6px;
	-webkit-tap-highlight-color: transparent;
}

.add-new-btn {
	background: transparent;
	border: none;
	cursor: pointer,
}
.mn_normal{
	font-family: 'Manrope-Regular';
}