.name_date_column {
    margin-bottom: 12px;
}

.report_details_container {
    width: 70%;
    height: 100%;
}

.report_details_tab {
    width: 19%
}

/* details summary::before {
    content: "▶"; 
    color:  rgb(0, 158, 253);
    padding-right: 8px;
    font-size: 1rem;
}

details[open] summary::before {
    content: "▼"; 
} */

/* .requisiton_report_details[open]>summary,
details[open]>p {
    border-bottom: 2px solid #009efd;
} */

details[open] .show_all_date_name {
    display: none;
}

details summary::after,.requisition_report_details_close {
    content: "...show more ";
    font-style: italic;
    font-weight: 600;
    text-align: end;
    width: 100%;
    float: right;
    font-size: 0.8rem;
    color: rgb(0, 158, 253);
    margin-top: -2%;
    /* background-color: rgb(0, 158, 253);
    color:white; */
    cursor: pointer;
}

details[open] summary::after {
    content: "";
}

.requisiton_report_details>summary {
    list-style: none;
}

.req_details_evenRow{

    background: #F6F6F6 !important
}
.req_details_oddRow{
    background: #ffffff !important
}