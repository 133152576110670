.btnGreen {
    color: '#fff'!important;
    background-color: '#2AD156'!important;
    border: 'none'!important;
    padding: '7px 24px 9px 23px'!important;
    float: 'right'!important;
    top:"20px"!important
}
.admin_subskill_topsection{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.admin_subskill_leftsection{
    width: 30%;
    margin-left: 10px;
}
.admin_subskill_rightsection{
    margin-bottom: 10px;
    margin-right: 10px;
}
.kw
{
    width: 100%;
}
.marginHeading
{
    margin-bottom: 0px;
    padding-bottom: 0px;
}
@media only screen and (max-width: 768px){

    .btnGreen {
        color: '#fff'!important;
        background-color: '#2AD156'!important;
        border: 'none'!important;
        padding: '7px 24px 9px 23px'!important;
        float: 'right'!important;
        
    }
    .admin_subskill_topsection{
        width: 100%;
        display: block;
        justify-content: space-between;
    }
    .admin_subskill_leftsection{
        width: 95%;
        margin-left: 10px;
    }

}

.actionContainer {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1280px)
  {
    .alignment
    {
        max-width: none !important;
        margin-top: 20px;
    }
  }

.mt_110{
    margin-top: 110px;
}