.demoDialog .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding-left: 25px !important;
}

.demoName {
    font-family: 'Manrope-Medium';
    font-size: 16px;
    color: #666666;
}

.demoNameTitle {
    font-family: 'Manrope-Medium';
    font-size: 16px;
    color: #666666;
    margin-bottom: 13px;
}
.bb{
    outline: none;
    border: 1px solid #c6c0c0;
    border-radius: 4px;
    padding: 10px;
}

.demoDialog .datePicker .MuiInputBase-root{
    padding-bottom: 10px;
}

.datePicker{
    width: 100%;
}

.MuiPickersToolbar-toolbar{
    background-color: #1885E6 !important;
}

.MuiPickersDay-daySelected{
    background-color: #1885E6 !important;
}

.demoTextBox{
    height: 47px;
    width: 100%;
}

.textbox_phone_input_demo .PhoneInputInput{
    border: none; 
    outline: none;
    opacity: 0.6;
    font-size: 16px;
}


.textbox_phone_input_demo .PhoneInputInput:focus{
    outline: none;
}

.textbox_phone_input_demo {
    height: 47px;
    width: 93%;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}


