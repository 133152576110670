.reqDetailsContainer {
    margin-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
}

.reqDetailsGrid {
    display: flex;
    gap: 20px;
    height: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;

}


 .reqDetailsLeftCardGrid{
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    display: flex;
    padding-left: 20px;
    padding-top: 20px;
    align-content: baseline;
}
.reqDetailsLeftFormContainer{
    width: calc(100% - 320px);
}
.reqDetailsRightCard{
    display: flex;
    flex-direction: column;
    width: 300px;
}

.reqDetailsBasicInfo{
    display: flex;
    flex-direction: column;
    width: 250px;
}
.reqDetailsBasicInfo_location{
    display: flex;
    flex-direction: column;
    width: 520px;
}
.reqDetailsBasicInfo_jobDesCription{
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 97%;
}

.reqDetails_headings{
    font-size: 17px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-bottom: 10px;
    color: #3B3B3B;
    
}

.textAreaJD{
    margin-left: 10px;

    }
.reqDetailsBasicInfo_ActionBtnsContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 8px;
    margin-left: 10px;
    margin-bottom: 15px;
}
.reqDetails-referenceId{
    background-color: #fff;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    justify-content: space-between;
    cursor: pointer;
}
.reqDetailsBasicInfo .reqDetailsDatePicker{
   padding: 12.5px ;
   border: 1px solid lightgrey;
   font-family:"Roboto", "Helvetica", "Arial", sans-serif;
   font-size: 1rem;
   font-style: normal;
   font-weight: 400;


}

.reqDetailsBasicInfo .reqDetailsDatePicker::placeholder{
    color: #BABABA;
}

.reqDetailsBasicInfo .MuiSelect-selectMenu{
    padding-left: 10px;
}


.reqDetailsBasicInfo .react-datepicker__input-container .react-datepicker__calendar-icon {
    margin-top: 6px;
    margin-left: 85%;
}

.reqDetailsBasicInfo input:focus-visible{
    outline: none;
}

.reqDetailsBasicInfo .MuiSelect-select.MuiSelect-select em{
    font-family:"Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    color:#BABABA;
}

.reqDetailsBasicInfo .MuiInputBase-root{
    font-family:"Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.reqDetailsBasicInfo_ActionBtnsContainer .RequisitionDetails_CancelBtn{
    background-color: white;
    color: black;
}
.loader_requisitionDetails{
    height: 50vh;
    width: calc(100% - 320px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.viewDocumentButton_viewRequisition{
    padding: 10px !important;
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    width: 140px !important;
}

.viewButton_container_viewRequisition{
    position: absolute;
    right: 30%;
}



.cancelButton_viewRequisition{
    background-color: #ffffff !important ;
    color: black !important;
    width: 110px !important;
    padding: 10px !important;
    border-radius: 5px;
}
.cloneRequisitionButton_viewRequisition{
    background-color: #4DD164 !important ;
    color: #ffffff !important;
    padding: 10px ;
    cursor: pointer;
}

.cloneRequisitionButton_viewRequisition:hover{
   box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

.cloneRequisition_createRequisition_dialogBtn{
    width: 100%;
    padding: 10px;
    background-color: #31B3F8 !important;

}
.cloneRequisitionBtnContainer{
    display: flex;
    gap:20px;
}
.maindivButton_requisitionBody{
    display: flex;
    flex-direction: row;
}

.button_AssignRecruiter{
    background-color: #2ECA75 !important;
    margin-bottom: 10px !important;
    color: #ffffff !important;
    padding: 10px !important;
}

.button_candidateSearch{
    background-color: #4A97C1 !important;
    margin-bottom: 10px !important;
    color: #ffffff !important;
    padding: 10px !important;
}

.button_uploadResume{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    padding: 10px !important;
}

.iconDivision_viewRequisition{
    padding-right: 5px !important;
}

.recentActivities_BtnGroup{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-right: 12px;
}

.dialogBox_assignRequisition .input-text-1 {
    width: 120px;
    height: 46px;
    border-radius: 8px;
    background-color: white;
}

.dialogBox_assignRequisition .input-text-2{
    width: 300px;
    height: 46px;
    border-radius: 8px;
    background-color: white;
    padding: 0px;
}

.dialogBox_assignRequisition .input-text-3{
    width: 350px;
    height: 46px !important;
    border-radius: 8px;
    background-color: white !important;
}
.header-text-popup{
    font-family: "Segoe-Semibold" !important;
    font-weight: 400px !important;
    font-size: 15px !important;
    line-height: 21.09px;
    color: #3B3B3B !important;
    padding: 10px 12px !important;
}

.header-text-popup-1 {
    font-family: "Segoe-Semibold" !important;
    font-weight: 400px !important;
    font-size: 15px !important;
    line-height: 21.09px;
    color: #3B3B3B;
    padding: 10px 12px ;
    max-width: 200px ;
}

.header-text-popup-2 {
    font-family: "Segoe-Semibold" !important;
    font-weight: 400px !important;
    font-size: 15px !important;
    line-height: 21.09px;
    color: #3B3B3B !important;
    padding: 10px 12px !important;
    width: 300px !important;
}

.alignment_ActionButton_recruitmentLeader{
    padding-right: 10px ;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    padding: 15px;
}
.alignment_ActionButton_recruiter{
    padding-right: 63px !important;
    padding-bottom: 30px !important;
}

.buttonContainer_viewRequisition_outside{
    display: flex;
    gap: 10px;
}



.dialogBox_assignRequisition .MuiDialogContent-root{
    padding: 15px;
    max-height: 440px;
    overflow: auto;
    padding-bottom: 0px !important;
}


.rd_UploadResumeActionsContainer .MuiDialogActions-root{
    justify-content: space-between !important;
}
.assignedRecruiterContainer{
    display: flex !important;
    justify-content: flex-end;
}
.assignedRecruiterContainer .alignment_ActionButton_recruiter{
    padding-right: 35px !important;
}
.close_icon_right{
    cursor: pointer;
}

.mainContainer_requisitionDetails{
   
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 100vh;
}

.topContainer_requisitionDetails{
    margin-top: -10px;
}

.saveButton_viewRequisition{
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff !important;
    width: 110px !important;
    padding: 10px !important;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center

}

.saveButton_viewRequisition_disable
{
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff !important;
    width: 110px !important;
    padding: 10px !important;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
    gap:10px;

}


.saveButton_viewRequisition:hover{
  opacity: 0.9;
}

.buttonContainer_viewRequisition_outside .MuiCircularProgress-root{
  color: #FFFFFF !important;
  height: 13px !important;
  width: 13px !important;
}

.assignRequisitionContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top:25px !important;
    padding-left: 30px !important;
    padding-bottom: 25px !important;
}
.reassignRecruiterContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top:25px !important;
    padding-left: 30px !important;
    padding-bottom: 25px !important;
}
.assignRequistionRowItems{
    display: flex;
    flex-direction: row;
    gap:15px;
}

.dialog-actionbutton-assignTeamLeader{
    padding: 15px 20px !important;
}
.updateButton-assignTeamLeader{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}
.updateButton-assignTeamLeader:hover{
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}
.updateButton-assignTeamLeader_disabled{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.cancel-button-assignTeamLeader{
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.cancel-button-assignTeamLeader:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.reassign-button-recruiter{
    font-size: 15px;
    border-radius: 3px;
    width: 160px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.reassign-button-recruiter:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.noOfPosition_teamLeaderAssignment .MuiOutlinedInput-input{
    height: 10px !important;
    width: 150px !important;
}
.addIcon_teamLeaderAssignment{
    float: right;
    cursor: pointer;
    color: #1885E6 !important;
    font-size: 35px !important;
}
.addIcon_teamLeaderAssignment:hover{
    opacity: 0.7;
}
.assignRequisitionContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
    margin-right: 12px !important;
}
.dialog-actionbutton-assignTeamLeader .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
}
.addButtonGrid_addTeamLeader{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.openings_TeamLeaderRequisitionContainer{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 5px !important;
}
.openings_RecruiterRequisitionContainer{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 5px !important;
}
.cancelIcon_teamLeaderReq{
    cursor: pointer;
}
.cancelIcon_teamLeaderReq:hover{
    opacity: 0.7;
}
.assignRequisitionContainer .MuiTableCell-body {
    border:none !important;
}

.recruiterAssignment_teamLeader .MuiOutlinedInput-input{
    height: 10px !important;
    width: 150px !important;
    margin-right: 10px !important;
}
.recruiterName_teamLeader .MuiOutlinedInput-input{
    height: 46px !important;
    width: 250px !important;
}
.recruiterName_teamLeader .MuiInputBase-input.Mui-disabled{
    padding: 18.5px 14px !important;
} 
.positionsTag_assignRecruiter{
    font-family: 'Segoe-Semibold';
    font-size: 15px;
    padding-left: 15px;
}

.recruiterAssignmentButton{
    font-size: 15px;
    border-radius: 3px;
    width: 200px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.recruiterAssignmentButton:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.selectedTeamLeaderOnBehalfContainer{
    padding: 20px !important;
    padding-left: 32px !important;
    height: 100px !important;
}
.teamLeaderHeading_behalf{
    font-family: 'Segoe-Semibold';
    font-size: 15px;
    margin-bottom: 15px;
}

.dialogBox_reassignRecruiter .input-text-3{
    height: 46px !important;
    border-radius: 8px;
    background-color: white !important;
}

.label_recruiterReassignment{
    font-family: 'Segoe-Semibold' !important;
    font-size: 16px !important;
    margin-bottom: 0px !important;
}
.note_recruiterReassignment{
    font-family: 'Segoe UI' !important;
    font-size: 14px !important;
    margin-bottom: 0px !important;
    font-style: italic;
}

.loader_requisitionDetails{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}