.uploadFuctionContainer {
    text-align: center;
    margin-top: 5vh;
}

.uploadFuctionContainer_conditional{
    text-align: center;
    margin-top: 100px;
}

.fileUploadText {
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    color: #4d4f5c;
}

.mb_10 {
    margin-bottom: 10px;
}

.cdProfileHeading_newProfile {
    color: #1885E6;
    font-family: "Segoe UI";
    font-size: 29px;
    font-weight: 300;
    font-style: normal;
}

.uploadBtn {
    width: 92px;
    height: 21px;
    border: 0px;
    background: #05a6f4;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    color: #ffffff;
    border-radius: 4px;
}

.newCandidateProfile_top_align {
    margin-top: 20vh;
    /* text-align: left; */
}

.drag_drop_margin {
    margin-top: 20px;
    margin-left: 30px; 
    /* margin-left: 62px */
}

.drag_drop_file {
    font-size: 11px;
    font-family: SegoeUI, "Segoe UI"
}

.color_4d4f5c {
    color: #a9aab3;
}

.image_in_center {
    display: block;
    /* margin-left: auto;
    margin-right: auto; */
}

.font_button_css {
    background-color: #1885E6;
    width: 150px;
    height: 45px;
    color: #FFFFFF;
    border: none;
    outline: none;
    font-size:9px;
    font-family:SegoeUI, "Segoe UI";
    border-radius: 4px;
}


.chooseFile{
    margin-top: 6px;
    margin-left: 50px;
}

.font_300_29_Semilight{
    font-size:29px;
    font-weight:300;
    font-family: SegoeUI-Semilight, "Segoe UI";
    color:#1885e6
}

@media only screen and (max-width: 768px) {
    .newCandidateProfile_top_align {
        margin-top: 11vh;
        /* text-align: left; */
    }
   
}

/* .text_in_center{
    display: block;
    margin-left: auto;
    margin-right: auto;   
} */

.new_candidate_icon{
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
}

.candidate_filepicker_button{
    background-color: #05A6F4 !important;
    color: white !important;
}

.candidate_filepicker_button_disable{
    background-color: #05A6F4 !important;
    color: white !important;
    opacity: 50%;
}

.uploadFuctionCon{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
}

.candidate_resume_logo{
    padding-left: 20px;
    padding-top: 11px;
}

.new_candidate_icon_download{
    width: 20px;
    height: 20px;
}

.close_icon_newCandidate{
    position: relative;
    top: -10px;
    left: 38px;
    cursor: pointer;
}

.candidateFileExtention{
    position: relative;
    top: 4px;
    left: -14px;
}
.fileUploadText{
    font-family: 'Segoe-semibold';
    font-size: 12px;
}