.question-report-view-resolved.MuiTableCell-root{
   display: flex;
}
.answer-set-container-question-report{
   
      display: flex;
      flex-direction: column;
      padding: 1rem 0rem;
   
}
.radiobtn-questionreport .MuiRadio-colorSecondary.Mui-checked {
      color: #1a8fff;
}
.answer-set-list-questionreport{
   display: flex;
    align-items: center;
    justify-content: space-between;
}
.radiobtn-questionreport .MuiCheckbox-colorSecondary.Mui-checked{
   color: #1a8fff;
}
.link-colour{
   color: #3f51b5 !important;
}
.textareaComment{
   width: 90%;
   outline: none;
   height:45px;
   border: 1px solid rgba(0, 0, 0, 0.23);
   padding: 14px 20px;
   border-radius: 4px;
   font: inherit;
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;

}
.btnAction{
   padding-bottom: 0px;
}

@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
