.hK {
  height: 75vh;
}

@media (min-width: 1280px) {
  .pageWidth {
    max-width: none;
    margin-top: 20px;
    padding: 0px !important;
  }
}

.revokeBtn {
  margin-left: 10px !important;
}

.options {
  margin-bottom: 15px;
}

.options:last-child {
  margin-bottom: 0px;
}

.mt_5 {
  margin-top: 5px;
}

.authorize {
  margin-top: 30px;
}

.tab_button {
  padding: 1rem 2rem;
  cursor: pointer;
  border: 1px solid #3f51b5;
  font-family: "Segoe-Semibold";
  transition: background ease 0.3s;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
}

.tab_button_active {
  background: #3f51b5;
  color: white;
  font-size: 18px;
  font-family: "Segoe-Semibold";
}

.updatedCard .k-grid {
  height: 64vh;
}

.titleContainer_approvalrecruiter {
  display: flex;
  justify-content: start;
  font-family: "Segoe-Semibold";
  /* background-color: #05A6F4;
  color: #FFFFFF; */
  font-size: 24px;
  padding: 5px 3px;
  color: #2896ff;
  border-bottom: 1px solid #2896ff;
  width: 220px;
  margin-bottom: 10px;
}

.tab_button_approval {
  margin-right: 3px;
  margin-bottom: 3px;
}



.tabs_requisitionRepor {
  font-family: 'Segoe-Semibold';
  width: 10%;
  font-size: 15px;
  text-align: center;
  padding: 10px 8px;
  cursor: pointer;
  background: #B0DAF18A;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}



.tabs_requisitionReports_select {
  background-color: #009EFD;
  width: 10%;
  font-size: 15px;
  color: #FFFFFF;
  font-family: 'Segoe-Semibold';
  text-align: center;
  padding: 10px 8px;
  opacity: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}



.approveRecruiterModal .admindailog_title_modal {
  padding: 13px 18px !important;
}

.approveRecruiterModal .admindailog_title_modal {
  display: flex;
  align-items: center;
  height: 4.2vh;
  padding: 10px;
  background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
  color: white;
  border-radius: 4px 4px 0px 0px;
  font-family: 'poppins', sans-serif;
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  align-items: end;
}



.approveRecruiterModal .dialog-title-text {
  color: #ffff;
  font-weight: 600;
  font-size: 20px;
  font-family: 'poppins', sans-serif;
}



.approveRecruiterModal .removemodal_responsive {
  padding: 20px 20px;
}

.approveRecruiterModal .iconStyle {
  width: 35px;
  height: unset;
  color: #FFFFFF;
}



.approveRecruiterModal .remove_modal {
  justify-content: end;
  margin-bottom: 5px;
  padding: 0px 10px;
}



.approveRecruiterModal .btn_primary {
  color: #FFFFFF;
  width: 120px;
  height: 35px;
  font-size: 15px;
  border-radius: 3px;
  background-color: #1885E6 !important;
  font-family: 'Segoe-regular';
  cursor: pointer;
  display: flex;
  justify-content: center;
}



.approveRecruiterModal .btn_primary:hover {
  background-color: #1885E6 !important;
  opacity: 0.8;
}



.approveRecruiterModal .btn_remove {
  font-size: 15px;
  border-radius: 3px;
  width: 120px;
  font-family: "Segoe-Semibold";
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 5px;

}

.unrevokedButton {
  background-color: #31B3F8 !important;
  color: #ffffff !important;
}

.tab_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dw {
  width: 100%;
}

.ExportButton_Container {
  padding-bottom: 5px;
}



.mainContainer_recruiter {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  align-items: baseline;
}

.leftSideMainContainer_recruiter {
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.rightSideMainContainer_recruiter {
  width: calc(100% - 410px);
}

.leftSideTopSection {
  background-color: #D1E8FF;
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
}

.TopSectionLeftSide {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.button_search_recruiter {
  background-color: #31B3F8 !important;
  outline: none !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 10% !important;
  color: white !important;
  width: 100% !important;
  font-size: 1em !important;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.buttonContainer_search_recruiter {
  width: 31%;
}

.leftSide_buttomSection{
  padding: 10px 15px 10px 15px;
}

.leftside_contains_recruiter{
  margin-bottom: 10px;
}

.topography_front_recruiter{
  font-size: 16px;
    font-family: "Segoe UI";
    color: #43425D;
}

.outline_textbox_recruiter{
  height: 47px;
  background-color: #ffffff !important;
}