.inputLabel_right {
    font-size: 14px;
    font-family: 'Manrope-Regular';
    margin-bottom: 8px;
    margin-top: 5px;
}

.pending-validations-actions__upload {
    background-color: #31B3F8 !important;
    margin-left: 15px !important;
    color: white !important;
    padding: 10px !important;
}

.textbox_phone_input {
    max-width: 100%;
    max-height: 45px !important;
}

.textbox_phone_input .PhoneInputInput {
    padding: 10px;
    border: none;
    font-size: 16px;
}

.upload_field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.upload_field .MuiFormControl-root {
    width: 100% !important;
}

.upload_field .upload_resume_textbox .input[type="text"] {
    padding: 24px !important;
}

.form-error {
    color: red;
    font-size: 13px;
    margin-top: 8px;
}

.autocomplete-container .MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-inputRoot {
    padding-top: 5px;
    align-items: center;
    justify-content: center;
}

.add-button {
    background-color: #788189;

    color: #000000;
    cursor: pointer;
    border: none;
    padding: 6px 12px;
    margin-left: 8px;
    border-radius: 4px;
    font-size: 14px;
}

.add-button:hover {
    background-color: #0056b3;
}


.phoneNumber_pendingResume .PhoneInputInput {
    border: none;
    outline: none;

}

.textbox_phone_input_pendingResume {
    height: 46px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}

.admin_textbox_error{
    height: 46px;
    border: 2px solid red;
}

.admin_textbox_primarySkill_error{
    height: 43px;
    border: 2px solid red;
}

.dialogbox_addidtional_skill_candidate_resume .MuiAutocomplete-endAdornment{
    display: none !important;
}

.admin_textbox_currency{
    height: 46px;
    width: 140px;
}

@media screen and (max-width: 1800px) {
    .admin_textbox_currency{
        width: 100px;
    }
}

@media screen and (max-width: 1345px) {
    .admin_textbox_currency{
        width: 80px;
    }
}

@media screen and (max-width: 820px) {
    .admin_textbox_currency{
        width: 50px;
        
    }
}