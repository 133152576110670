.dropdown {
  position: relative;
  display: inline-block;
  /* z-index: 10; */
}

.dropdown-content {
  /* display: none; */
  position: absolute;
  /* background-color: #04093f; */
  background-color: white;
  min-width: 207px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 150px;
  left: 0px;
}
.recruiter_nav {
 
  padding: 5px 0px 0px 38px;
 
}


.dropdown-content li {
  /* color: white; */
  padding: 14px 16px 14px 37px;
  text-decoration: none;
  display: block;
  /* border-bottom: 1px solid #2f3575f0; */
  border-bottom: 1px solid #1A8FFF;
  /* font-family: 'Segoe-Regular'; */
  font-family: 'Manrope-Regular';
  font-size: 16px;
}
/* .responsive_li_adminpages{
    display: none;
} */
.hamberger_nav{
  display: none;
}
.LinkForHeader_admin{
  float: left;
  padding: 3px 0px 0px 10px;
  /* color: #d1e8ff; */
  text-decoration: none;
  font-size: 17px;
  /* font-family: "Segoe-Regular"; */
  font-family: 'Manrope-Regular';
}
#menu-list-grow::-webkit-scrollbar-thumb
{
  /* background-color: rgb(195, 195, 240); */
  /* color: red; */
  background-color: #1A8FFF;
}
#menu-list-grow
{
  padding-bottom: 30px;
  padding-top: 0px;
}
@media only screen and (max-width: 768px){
  .responsive_li_adminpages{
      margin-left: 24px;
      width: 70% !important;
    }
    .dropdown-content li {
      
      padding: 14px 16px 14px 37px !important;
      
  }
  .dropdown-content li:last-child {
      
      padding: 14px 16px 14px 37px !important;
      
  }
  .dropdown-content {
      /* display: none; */
      width: 100%;
    }
    .hamberger_nav {
      display: block;
  }
    
} 


/* .show {display: block;} */
.navbar .LinkForHeader_admin:first-child {
  margin-top: 2px;
}



/* Drop down css admin menu */
.menuBorder
{
  border-bottom:1px solid #1A8FFF;
}
.navbar.admin-header-navbar {
  display: flex;
}
.admin-header-navbar .MuiButton-label {
  /* color: #d1e8ff; */
  font-size: 18px;
  /* font-family: 'Segoe-Regular'; */
  font-family: 'Manrope-Regular';
  padding: 0;
}
.admin-header-navbar .MuiList-root {
  /* background-color: #04093f; */
  /* background-color: white; */
  /* color: #d1e8ff; */
  min-width: 220px;
  height: 300px;
  overflow-y: scroll;
}

.admin-header-navbar .MuiList-root::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5; */
  
}

.admin-header-navbar .MuiList-root::-webkit-scrollbar {
  width: 6px;
	background-color: transparent;
}

.admin-header-navbar .MuiList-root::-webkit-scrollbar-thumb {
  background-color: white;
	border-radius: 10px;

}




#style-4 .MuiList-root::-webkit-scrollbar
{
	
}

#style-4 .MuiList-root::-webkit-scrollbar-thumb
{

}
.admin-header-navbar .MuiList-root li.MuiListItem-root {
  /* border-bottom: 1px solid #2f3575f0; */
  border-bottom: 1px solid #1A8FFF;
  /* font-family: 'Segoe-Regular'; */
  font-family: 'Manrope-Regular';
  font-size: 18px;
  padding: 14px 16px 14px 37px;
}
.admin-header-navbar .MuiButton-text {
  padding: 2px 8px;
}
.admin-header-navbar .MuiPaper-root {
  top: 43px;
  position: relative;
  right: -25px;
  transition: none !important;
}
.listStyle
{
  list-style-type: none;
}
.itemlist
{
  display: block !important;
}
