.home-contact-us .dropdown_width_outer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.mt_12 {
  margin-top: 12px;
}
.home-contact-us .dropdown_width {
  width: 48.5%;
}

input[type=text], select {
  width: 100%;
  /* padding: 14px 20px!important; */
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
}
.home-contact-us .text_box_cleartalent {
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}


.home-contact-us .btn-green, .home-contact-us .btn-green:hover {
  color: #fff;
  background-color: #2ad156;
  font-family:'Manrope-Bold';
  border: none;
}
.home-contact-us .btn-big {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 9px 35px 11px;
  font-size: 18px;
  border-radius: 4px;
  font-family:'Manrope-Bold';
}
.cp {
  cursor: pointer;
}
.contact_header, .MuiTypography-h6 {
  font-family: 'Manrope-Light' !important;
  color: #01313c!important;
  font-size: 28px !important;
}

.home-contact-us .text_area_cleartalent_support_home {
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Manrope-Regular' !important;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid #a5a4a4;
  border-radius: 4px;
}
.home-contact-us .MuiDialog-paperWidthSm {
  max-width: 750px !important;
}
.home-contact-us legend {
  font-family: 'Manrope-bold' !important;
  font-size: 16px;
  margin-bottom: 17px;
  font-style: normal;
  font-weight: 600;
  color: #01313c;
}
.home-contact-us .MuiTypography-h6 {
  font-family: 'Manrope-Light' !important;
}
.text_area_cleartalent_support_home {
  width: 100%;
}

.support_dialog_title {
  position: relative;
}

.support_grid_message {
  margin-top: 20px !important;
}

.support_message {
  height: 160px;
  width: 100%;
  border: 1px solid #707070;
  opacity: 0.6;
}

.support_line {
  border-bottom: 1px solid #6398F1;
  width: 268px;
  font-family: 'Manrope-Medium';
  font-size: 36px;
  color: #1885E6;
  height: 58px;
}

.blue {
  position: absolute;
  top: 0;
  right: 4px;
}

.support_con {
  padding-top: 0px !important;
  padding-right: 28px !important;
}

.support_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
}

.support_grid {
  padding-right: 20px;
}

.support_form_leb {
  font-family: 'Manrope-Medium';
  font-size: 16px;
  color: #666666;
}

.Support_textbox{
  height: 40px;
  width: 100%;
  padding-left: 0px !important;
}

.support_grid_message{
  margin-top: 20px !important;
  padding-bottom: 0px;
}

#mes_lable{
  font-size: 16px;
  font-family: 'Manrope-Medium';
  width: 100%;
 height: 15px;
 color:#666666;
}

.support_message{
  height: 140px;
  width: 100%;
  border: 1.4px solid #707070;
  opacity: 0.6;
  border-radius: 3px;
}

.support_mail{
  padding-Right: 0px;
}