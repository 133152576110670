@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}