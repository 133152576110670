.cdProfileHeading {
    color: #1885E6;
    font-family: "Segoe UI";
    font-size: 29px;
    font-weight: 300;
    font-style: normal;
}

.updateStyle {
    font-family: "Segoe UI";
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    color: #01313c;
}

.profileHeading {
    margin-bottom: 10px;
}

.firstBlueContainer {
    display: flex;
    flex-direction: row;
    background: #d5e7fd;
    padding: 20px 0px;
    /* height: 21vh; */
    border-radius: 8px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
}

.resumeContainer_noresume{
    height: 60px;
    width: 83px;
}

.imgAndCVContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #1885E6;
    padding: 10px 12px;
}
.imgAndCVContainer_skeleton{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    border-right: 1px solid #1885E6;
    padding: 10px 12px; 
}

.only_candidate_profileImage_admin {
    width: 69px;
    height: 69px;
    border-radius: 50%;
}

.imageContainer {
    position: relative;
}

.camera_icon_profile_powerResume_admin {
    position: absolute;
    width: 25px;
    bottom: 0;
    right: 0px;
    padding-left: 10px;
}

.resumeContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 65px;
    margin-top: 15px;
}
.resumeContainer_Skeleton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 65px;
}

.viewresume_container_profilecomplete{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:5px;
    cursor: pointer;
    font-family: 'Segoe-Semibold';
    font-size: 14px;
}

.fileImg {
    width: 22px;
}
.fileImg_resume{
    width: 30px;
    height: 30px;
    margin-left: 4px;
}

.detailsContainer {
    margin: 0px 8px;
}

.candidateName {
    /* font-family: "Segoe UI";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    color: #04093f; */
    font-size: 15px;
    font-weight: 700;
    font-family: SegoeUI-Bold, "Segoe UI";
    color: #04093f;
    line-height: 1.5;
}

.candidateDetails {
    
    font-size: 15px;
    font-weight: 500;
    font-family:  "Segoe-regular";
    padding-bottom: 5px;
    color: #04093f;
}

.viewText{
    font-size: 14px;
    font-family: "Segoe-semibold";
    padding-bottom: 5px;
    color: #04093f;
}

.candidate_data_value {
    font-family: "Segoe-regular";
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.candidate_data_value_header {
    font-family: "Manrope-light";
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.partitionContainer {
    /* margin-bottom: 10px; */
}
.partitionContainer_skeleton{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skillsCardHeading {
    font-family: "Manrope";
    font-size: 23px;
    font-weight: 300;
    font-style: normal;
    color: #01313c;
    margin-bottom: 15px;
}

.cardsContainer {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    background-color: white;
}

.cardContent {
    /* margin-bottom: 25px; */
}



.skillsCardSubHeading {
    /* font-family: "Segoe UI";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    color: #01313c; */
    font-family: SegoeUI-Semibold, "Segoe UI";
    color: #01313c;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.0;
}

.skillCardCaptions {
    /* font-family: "Segoe UI";
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    color: #01313c; */
    font-family: "Segoe-semibold";
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #01313c;
}

.chipContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.chipStyle {
    /* border-radius: 30px;
    background: #d1e8ff;
    font-family: "Segoe UI";
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    color: #01313c;
    width: 70px;
    height: 20px;
    text-align: center;
    margin-right: 8px; */
    border-radius: 10px;
    background-color: #d1e8ff;
    transform: translate(-0.26px, -0.265px);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-top: 6px;
    text-align: center;
}

.chipstyle_skill_font{
    font-family: SegoeUI-Semibold, "Segoe UI";
    color: #01313c;
    font-size: 10px;
    font-weight: 600;
}

.cardStyle {
    background: white;
    margin: 20px;
    padding: 5px 10px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    border-radius: 4px;
}

.cardStyle_primarySkill{
    background: white;
    margin: 20px;
    padding: 5px 10px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    border-radius: 4px;
}

/* ::-webkit-scrollbar {
    width: 16px;
}

::-webkit-scrollbar-thumb {
    background-color : #1885E6
}

::-webkit-scrollbar-track {
    background-color: #ece2e2;
}

::-webkit-scrollbar-track:hover {
    background-color: #d1c8c8;
} */

/* @media (max-width: 1872px) {
    .candidateDetails {
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        color: #04093f;
        word-break: break-word;
    }
} */
/* 
@media (max-width: 1532px) {
    .candidateDetails {
        font-family: "Segoe UI";
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        color: #04093f;
        word-break: break-word;
    }

    .updateStyle {
        font-family: "Segoe UI";
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        color: #01313c;
    }
} */

/* @media (max-width: 1158px) {
    .candidateDetails {
        font-family: "Segoe UI";
        font-size: 9px;
        font-weight: normal;
        font-style: normal;
        color: #04093f;
        word-break: break-word;
    }    

    .candidateName {
        font-family: "Segoe UI";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        color: #04093f;
    }
} */



/* Previous css */

.candidate_specific_extra_padding {
    /* padding: 14px 8px 20px 8px; */
    padding-top: 20px;
}

.candidate_specific_card {
  
    background-color: #D5E7FD;
}
.candidate_specific_card_wd{
    /* width:100% */
    width: 100%;
    height: 246px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.candiate_profile_first_container {
    display: flex;
    align-items: flex-start;
}

.candiate_profile_first_container .candidate_profile_image {
    margin-right: 10px;
    /* Adjust spacing between image and text */
}

.candiate_profile_first_container::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: black;
    /* Change the color of the vertical line */
    margin-right: 10px;
    /* Adjust spacing between line and text */
}

.candidate_specific_content {
    /* font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 300;
    font-size: 12px; */
   
    display: flex;
    flex-direction: column;
    word-break: break-word;
    margin-left: 4px;
}
.text_font{
   font-family: "Segoe-Semibold";
    color:#04093F
}
.candidate_specific_20_bold{
    font-size: 20px;
    font-weight: 700;
}
.candidate_specific_15_semibold{
    font-size: 15px;
    font-weight: 600;
}
.candidate_specific_14_semibold{
    font-size: 14px;
    font-weight: 600;
}

.candidate_specific_vr_line{
    width: 1px;
    height: 228px;
    background-color: #1885E6;
    margin: 0 2px; /* Adjust spacing around the line */
}
.candidate_specific_br_word{
    word-break: break-word;
}

.mt_25{
    margin-top: 25px;
}
.mt_10{
    margin-top: 10px;
}
.padding_icon_txt{
    padding: 13px 0px 0px 20px;
}
.icon_style{
    width: 24;
    height: 24;
    margin-left: 19px;
}
@media (max-width: 767px) {
 
}
.morelesscount{
    font-family: 'Segoe-semibold';
    font-size: 12px;
    color: #1885E6;
    cursor: pointer;
    margin-top: 10px;
}
.morelesscount:hover{
    color: red;
}

.pencilIcon_profilecompletion{
    cursor: pointer;
    margin-left: 25px;
    margin-top: 10px;
}

.closeIconProfileComplete{
    position: absolute;
    right: 11px;
    top: 14px;
    color: grey;
    cursor: pointer;
}

.saveButtonProfileComplete{
    height: 40px;
    display: flex;
    background-color: #1885e6 !important;
    color: #ffffff;
    padding: 5px 30px;
    width: 135px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
}

.saveButtonProfileComplete:hover{
    opacity: 0.9;
}

.saveButtonProfileComplete_disabled{
    height: 40px;
    display: flex;
    background-color: #1885e6 !important;
    color: #ffffff;
    padding: 5px 30px;
    width: 135px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    gap: 10px;
}

.cancelButton_profileComplete{ 
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* border: 1px solid transparent; */
    height: 40px;
    width: 135px;
    font-size: 18px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    cursor: pointer;
}

.cancelButton_profileComplete:hover {
    background-color: #f5f5f5;
}
.saveButtonProfileComplete_disabled .MuiCircularProgress-root {
    color: #FFFFFF !important;
    height: 18px !important;
    width: 18px !important;
    margin-top: 2px;
}

.candidateProfilecontainer_pencil{
    display: flex;
    flex-direction: row;
}

.dialogContainer{
    width: 100%;
}

.addressContainer{
    display: flex;
    flex-direction: row;
}

.inputLabel_right {
    font-size: 14px;
    font-family: 'Manrope-Regular';
}

.itemContainer{
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}

.compensationcontainer{
    width: 100%;
    display: flex;
}
.admin_textbox_currency_profileComplete{
    height: 46px;
    width: 160px;
}

.currency_CompensationContainer{
    display: flex;
    flex-direction: row;
}

.currncy_compensationstyle1{
    height: 46px;
    width: 160px !important;
}

.currncy_compensationstyle2{
    height: 46px;
    width: 160px !important;
    margin-left: 5px;
}

.locationitemcontainer_profilecomplete{
    width: 220px;
}

.locationItemsContainer{
    display: flex;
    flex-direction: column;
}

.locationContainer_profilecomplete{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dialogContainer_phone .PhoneInputInput {
    border: none; 
    outline: none;
}

.dialogContainer_name .input[type="text"]{
    padding: 0px !important;
}

.dialogContainer_email .input[type="text"]{
    padding: 0px !important;
}





.mt-15{
    margin-top: 15px;
}

.uploadcontainer_profile_complete{
    display: flex;
    flex-direction: column;
}

.uploadFuctionContainer_conditional_profileComplete{
    text-align: center;
    margin-top: 20px;
}

.candidate_filepicker_button_profileComplete{
    height: 37px;
    background-color: #05A6F4 !important;
    color: white !important;
}

.new_candidate_icon_profilecomplete{
    width: 40px;
    height: 40px;
}

.uploadFuctionCon_profileComplete{
    display: flex;
    justify-content: center;
}

.uploadResume_profileComplete{
    font-family: 'Segoe-semibold';
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
}

.candidate_filepicker_button_profileCompleted_noresume{
    background-color: #05A6F4 !important;
    color: white !important;
    padding: 5px 2px !important;
    font-size: 13px !important;
    width: 88px !important;
    margin-top: 26px !important;
}

.candidate_filepicker_button_profileComplete{
    background-color: #05A6F4 !important;
    color: white !important;
    padding: 10px 5px !important;
    font-size: 13px !important;
    width: 88px !important;
    height: 50px;
    margin-top: 14px !important;
}
.candidateHistoryDiv{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 15px;
}

.candidateHistoryDiv .MuiButton-containedPrimary{
    background-color: #05A6F4;
    width: 95%;
}

.candidateHistoryDiv .MuiButton-containedPrimary:hover{
    background-color: #0598e1;
}

.candidateHistoryDiv .MuiButton-label{
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 15px;
}