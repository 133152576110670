/* .container {
    padding: 3rem;
} */
.card-container{
    padding:1rem;
    box-shadow:0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.header-client-manager {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    background-color: white;
}

.header-image-manage-client {
    display: flex;
    align-items: center;
    gap: 14px;
}
.image{
    width: 41px;
    height: 41px;
    top: 132px;
    left: 100px;
    padding: 0px 1.34px 0px 1.34px;

}

.manage-client-main {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    /* height: 75vh; */

}


.manage-client-main .manage-client-main-head {
    font-size: 30px;
    color: #31B3F8;
    font-weight: 600;
    font-family: "Segoe-Semibold";
    border-bottom: 1px solid #31B3F8;
}
.manage-client-main .add-button-manageclient {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
.manage-client-main .add-button-manageclient:hover {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
.header-client-manager .add-button-manageclient .MuiButton-label{
    display: flex;
    flex-direction: row;
}
.add-image {
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: row;
}

.table-head {
    background-color: #31B3F8;
}

.card-container .edit-button{
    background-color: #31B3F8;
    color: #ffff;
}
.card-container .edit-button .MuiButton-label{
    display: flex;
    flex-direction: row;
}

.edit-button {
    background-color: #31B3F8;
    color: #ffff;
}

.dialog-title-manage-client {
    background-color: #31B3F8;
    color: #ffff;
    display: flex;
    gap:0.5rem
}
.dialog-title-text{
    color: #ffff;
    font-weight: 600;
    font-size: 20px;
}
.dialog-image {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    vertical-align: middle;
}

.dialog-content {
    padding: 20px;
}
.dialog-button{
    display: flex;
    gap: 0.5rem;
}

.dialog-button .dialog-submit-button{
    background-color: #31B3F8;
    color: #ffff;
    border-radius: 6px;
    padding: 6px 20px;
}

.dialog-button .dialog-submit-button:hover{
    background-color: #31B3F8;
    color: #ffff;
    border-radius: 6px;
    padding: 6px 20px;
}
.dialog-button .dialog-cancel-button{
    background-color: white; 
    color: black;
    border-radius: 6px;
    padding: 0px 20px;
    border: 1px solid black;
    width: 116px;
    height: 40px;
    margin-top: 10px;
}

.dialog-button .dialog-cancel-button:hover{
    background-color: white; 
    color: black;
    border-radius: 6px;
    padding: 0px 20px;
    border: 1px solid black;
    width: 116px;
    height: 40px;
    margin-top: 10px;
    opacity: 0.9;
}

.iconStyle {
    width: 47px;
    height: 47px;
    margin-right: 8px;
    vertical-align: middle;
}


.manage-client-modal .MuiDialogTitle-root {
    padding: 8px 18px !important;
}


.manage-client-modal .MuiSelect-selectMenu {
    padding: 9.5px 14px;
}

.manage-client-modal .MuiDialogActions-root {

    padding: 8px 24px 15px;

}
.multi-select {
    width: 100%;
    height: 47px;
}

.dialog-content .MuiOutlinedInput-input {
    padding: 24.5px 14px;
}

.add-division .dialog-title {
    background-color: #31B3F8;
    padding: 16px 24px !important;
}


.add-division .iconStyle {
    width: 39px;
    height: unset;
}

.add-division .dialog-title .dialog-title-text {
    font-weight: 600;
    font-size: 25px;
    color: #ffff;
}

.add-division .dialog-button {
    padding: 16px 18px !important;

}

.active-field {
    height: 49px;
}

.MuiOutlinedInput-multiline {
    padding: 0px !important;
}

.manangeclientContainerDivisionLink{
    width: 100%;
    margin-bottom: 6px;
}

.manangeclientContainerDivisionLinks{
    width: 98%;
    margin-bottom: 6px;
}

.gridContainer_heading_manageClient{
    margin-left: 2px !important;
}

.gridContainerRecruitmentLeader{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.recruitmentLeader_contains_client{
    width: 100%;
}

.closeContainer_client{
    margin-top: 9px;
}

.updateButton_OrgAdmin{
    height: 40px;
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff ; 
    padding: 5px 30px ;
    margin-top: 10px ;
    width: 116px;    
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
}
.updateButton_OrgAdmin:hover{
    opacity: 0.9;
}
.updateButton_OrgAdmin_disabled{
    height: 40px;
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff ; 
    padding: 5px 30px ;
    margin-top: 10px ;
    width: 116px;     
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    gap: 10px;
}

.dialog-actionbutton-client .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
}

.client-dialog-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}
.client-dialog-content{
    padding-top: 20px !important;
}
.input-field-client-dialog input[type="text"]{
    height: 45px;
}
.field-title-client-dialog{
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}
.updateButton-client{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}
.updateButton-client:hover{
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}
.updateButton-client_disabled{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.cancel-button-client{
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.cancel-button-client:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.dialog-actionbutton-client{
    padding: 15px 20px !important;
}
.iconStyle_client{
    width: 35px;
    height:35px;
    margin-right: 8px;
    vertical-align: middle;
}
.client-dialog-content .MuiOutlinedInput-inputMultiline{
    padding: 18.5px 14px !important;
}