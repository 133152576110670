.requ_header {
    /* border: 1px solid black; */
    height: 5vh;
    width: 89vw;
    /* background-color: #8dc4fc; */
    color: white;

}

.searchrec_subheader {
    height: 46px;
    /* margin-bottom: 0px; */
    padding: 15px;
    padding-left: 10px;
    background-color: #D1E8FF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


}

/* style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: '' }} */
.searchrec_color {
    color: #ffff;
}

.searchreq_leftmaincon {
    background-color: #ffff;
    /* width: 100%; */
    height: 60vh;
    /* box-shadow: 0px 0px 10px 0px #00000040; */
    /* margin-top: -4px; */
    /* padding-top: 1vh; */
    padding-left: 2vh;

}

.searchreq_autocom {
    width: 95%;

    /* height: 1em !important; */
    /* padding: ; */
}

.ListIco {
    height: 40px !important;
    width: 40px !important;
}

.searchreq_autoco {
    width: 23vh;
}

.searchreq_calender {
    padding-top: 10px;
}

.seareq_button {
    background-color: #009EFD;
    width: 172px;
    height: 39px;
    border-radius: 8px;
    color: #ffff;
    font-size: 16px;
    font-family: "Segoe-Semibold";
    box-shadow: none;
    border: none;

}

.seareq_image {
    height: 18px;
    width: 18px;
    /* display: flex; */
    justify-content: center;
    text-align: center;
    align-items: center;
}

.searec_leftcont_requisitionSearch {
    width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}


.searec_employementContainer {
    display: flex;
    width: 95%;
    gap: 10px;
}

.searec_employmentItems {
    margin-top: 15px;
    width: 100%;
}

.seareq-date-picker .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 10px;
    top: 8px;
}

.divider_input_searchreq {
    margin-top: 15px;
    border-top: 1px solid #2699FB;
    opacity: 0.4;
}

.seareq_date_border {
    /* border:solid 2px #7a6bdc; */
    border: 2px solid #177bd3ce !important;
    padding: 12px 10px !important;
    font-family: "Segoe UI" !important;
    font-size: 14px !important;
}

.sea_top_contain {
    display: flex;
    justify-content: space-between;


}

.addReq_button {
    display: flex;
    justify-content: center;
    align-items: center;

}

.searec_main_con {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */

}

.datePicker_SearchReequisition .input[type="text"] {
    border: 1px solid #2699FB !important;
}

.seareq-date-picker {
    display: flex;
    flex-direction: row;
    width: 95%;
    gap: 5px;
    align-items: center;
}

.seareq_datePicker {
    margin: 3px;
}

.seareq_card_border {
    margin-left: 20px;
    height: 800px;
    width: 80%;
}