.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*----------- Scroll -----------*/

/* ::-webkit-scrollbar {
	width: 8px;
  } */

/* Track */
/* ::-webkit-scrollbar-track {
   
	border-radius: 30px;
  }
    */
/* Handle */
/* ::-webkit-scrollbar-thumb {
	background: #B0D0EE; 
	border-radius: 0px;
  }
   */
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
	background: #2896FF; 
  }
   */
/*----------- Scroll -----------*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Segoe-Light";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Segoe-Regular"),
    url("resources/fonts/SegoePro-Light.ttf") format("woff");
}

@font-face {
  font-family: "Segoe-semi-Light";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Segoe-Regular"),
    url("resources/fonts/SegoePro-Semilight.ttf") format("woff");
}

@font-face {
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Segoe-Regular"),
    url("resources/fonts/SegoePro-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Segoe-Semibold";
  font-style: normal;
  font-weight: 600;
  src: local("fonts/SegoePro-Bold"),
    url("resources/fonts/SegoePro-Semibold.ttf") format("woff");
}

@font-face {
  font-family: "Segoe-Bold";
  font-style: normal;
  font-weight: 600;
  src: local("fonts/SegoePro-Bold"),
    url("resources/fonts/SegoePro-Bold.ttf") format("woff");
}

@font-face {
  font-family: "Segoe-Bold-Italic";
  font-style: normal;
  font-weight: 600;
  src: local("fonts/SegoePro-Bold-Italic"),
    url("resources/fonts/SegoePro-Bold-Italic.ttf") format("woff");
}

@font-face {
  font-family: "Noway-Regular";
  src: local("fonts/Noway-Regular"),
    url("resources/fonts/Noway-Regular.otf") format("woff");
}

@font-face {
  font-family: "Noway_Regular_Italic";
  src: local("fonts/Noway_Regular_Italic"),
    url("resources/fonts/Noway_Regular_Italic.otf") format("woff");
}


/*    #################################    COMMON CSS    ##################################      */

body {
  margin: 0px;
  padding: 0px;
  /* background-color: #eeeeee; */
}

p {
  margin: 0px;
}

/*------------- BUTTON CSS COMPONENT ---------------*/
.btn-big {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 9px 35px 11px 35px;
  font-size: 22px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn-small {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 5px 20px 7px 20px;
  font-size: 18px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn_remove {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 7px 30px 9px 30px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn2 {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 7px 30px 9px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
  border: none;
}

.btn3 {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 7px 30px 9px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
  width: 95px;
  border: none;
}

.btn4 {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 7px 30px 9px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
  width: 85px;
  border: none;
}

.btn5 {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  /* border: 1px solid transparent; */
  border: none;
  padding: 9px 35px 11px;
  font-size: 20px;
  border-radius: 4px;
  width: 135px;
  font-family: "Segoe-Semibold";
}

.btn_remove-green {
  color: #fff;
  padding: 7px 30px 9px 30px;
  background-color: #2ad156;
}

.btn_remove-green:hover {
  color: #fff;
  background-color: #2ad156;
}

.btn_remove-green:focus {
  outline: none;
}

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 7px 24px 9px 23px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn-green {
  color: #fff;
  background-color: #2ad156;
}

.btn-green:hover {
  color: #fff;
  background-color: #2ad156;
}

.btn_primary {
  background-color: #31B3F8 !important;
  color: #ffffff !important;
}

.btn_primary:hover {
  background-color: #31B3F8 !important;
  opacity: 0.8;
  color: #ffffff !important;
}

.btn-green:focus {
  outline: none;
}

.btn-resume {
  color: #01313c;
  width: 100%;
  background-color: #d1e8ff;
}

.btn-resume:hover {
  color: #01313c;
  background-color: #d1e8ff;
}

.btn-resume:focus {
  outline: none;
}

.btn-resume-testimonial {
  color: #01313c;
  width: 100%;
  background-color: #d1e8ff;
  padding: 7px 9px 9px 9px;
}

.btn-resume-testimonial:hover {
  color: #01313c;
  background-color: #d1e8ff;
}

.btn-resume-testimonial:focus {
  outline: none;
}

.change_profile_btn {
  color: #01313c;
  background-color: #d1e8ff;
  padding: 7px 24px 9px 23px;
}

.change_profile_btn:hover {
  color: #01313c;
  background-color: #d1e8ff;
}

.change_profile_btn:focus {
  outline: none;
}

.btn-skill {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
}

.btn-skill:hover {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
}

.btn-skill:focus {
  outline: none;
}

.btn-addanothertestimonial {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
  padding: 7px 10px 9px 9px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Segoe-Semibold";
  width: 100%;
}

.btn-addanothertestimonial:hover {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
}

.btn-addanothertestimonial:focus {
  outline: none;
}

.btn-date {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
  padding: 7px 8px 9px 8px;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  font-family: "Segoe-Semibold";
  white-space: break-spaces;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn-date:hover {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
}

.btn-date:focus {
  outline: none;
}

.btn-record {
  color: #01313c;
  background-color: #d1e8ff;
  border: 1px solid #a3bcd5;
  font-weight: 600;
  padding: 7px 24px 9px 23px;
  font-size: 16px;
  font-family: "Segoe-Semibold";
}

.btn-record:hover {
  color: #01313c;
  background-color: #d1e8ff;
  border: 1px solid #a3bcd5;
}

.btn-record:focus {
  outline: none;
}

.preference-btn {
  color: #01313c;
  background-color: #d1e8ff;
  width: 100%;
  font-weight: 600;
  padding: 7px 9px 9px 9px;
  font-size: 12px;
  text-align: center;
  font-family: "Segoe-Semibold";
}

.preference-btn:focus {
  outline: none;
}

.add_another_btn_adjust {
  text-align: right;
  margin-top: 20px;
}

/*------------- BUTTON CSS COMPONENT---------------*/

/*-------------------------------------------------------- Hints------------------------------------------------------------
mr= margin-right, ml= margin-left, mt = margin-top, mb= margin-bottom, pt= padding-top, pb= padding-bottom, pl= padding-left
pr=padding-right, fs= font-size, mw=min-width, fsi=font-style, pr=position-relative
-------------------------------------------------------- Hints------------------------------------------------------------*/

.row {
  width: 100%;
}

.fl {
  float: left;
}

.card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease-in-out;
  padding: 14px 20px 20px 20px;
  border-radius: 4px;
  background-color: #ffffff;
}

.closeicon_set {
  width: 24px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.text-uppercase {
  text-transform: uppercase;
}

.f-color {
  color: #01313c;
}

.fs-semi-bold {
  font-weight: 600;
  font-family: "Segoe-Semibold";
}

.fs-regular {
  font-weight: normal;
  font-family: "Segoe-Regular";
}

.fs-light {
  font-weight: normal;
  font-family: "Segoe-Light";
}

.fs-semi-light {
  font-weight: normal;
  font-family: "Segoe-semi-Light";
}

.fs-Bold-Italic {
  font-weight: normal;
  font-family: "Segoe-Bold-Italic";
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-28 {
  font-size: 28px;
}

.fs-18 {
  font-size: 18px;
}

.pt_13 {
  padding-top: 13px;
}

.fs-16 {
  font-size: 16px;
}

.fs-24 {
  font-size: 24px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-25 {
  font-size: 25px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-35 {
  font-size: 35px;
}

.fs-44 {
  font-size: 44px;
}

.fsi {
  font-style: italic;
}

.w100 {
  width: 100%;
}

.fs-12 {
  font-size: 12px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_14 {
  margin-right: 14px;
}

.mr_31 {
  margin-right: 31px;
}

.mr_26 {
  margin-right: 26px;
}

.mr_18 {
  margin-right: 18px;
}

.mr_23 {
  margin-right: 23px;
}

.mr_20 {
  margin-right: 20px;
}

.pr {
  position: relative;
}

.pr_31 {
  padding-right: 31px;
}

.mt_19 {
  margin-top: 19px;
}

.mt_24 {
  margin-top: 24px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_0 {
  margin-top: 0px;
}

.mt_100 {

  margin-top: 100px;
}

.mt_110 {
  margin-top: 110px;
}

.mt_34 {
  margin-top: 34px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_23 {
  margin-top: 23px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_22 {
  margin-top: 22px;
}

.m_0 {
  margin: 0px;
}

.text-center {
  text-align: center;
}

.mt_9 {
  margin-top: 9px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_12 {
  margin-top: 12px;
}

.mb_100 {
  margin-bottom: 100px;
}

.mb_12 {
  margin-bottom: 12px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_14 {
  margin-bottom: 14px;
}

.mb_16 {
  margin-bottom: 16px;
}

.mb_30 {
  margin-bottom: 30px;
}

.green_text {
  color: #2ad156;
}

.mt_15 {
  margin-top: 15px;
}

.mt_17 {
  margin-top: 17px;
}

.mt_18 {
  margin-top: 18px;
}

.ml_6 {
  margin-left: 5px;
}

.mt_41 {
  margin-top: 41px;
}

.mt_6 {
  margin-top: 6px;
}

hr.new1 {
  border-top: 1px solid #a3bcd5;
}

.df {
  display: flex;
}

.ml_25 {
  margin-left: 25px;
}

.ml_20 {
  margin-left: 20px;
}

.mt_16 {
  margin-top: 16px;
}

.mt_29 {
  margin-top: 29px;
}

.mt_60 {
  margin-top: 60px;
}

.ml_57 {
  margin-left: 57px;
}

.ml_41 {
  margin-left: 41px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_21 {
  margin-left: 21px;
}

.pr_23 {
  padding-right: 23px;
}

.pr_18 {
  padding-right: 18px;
}

.mt_70 {
  margin-top: 70px;
}

.pr_26 {
  padding-right: 26px;
}

.fs-26 {
  font-size: 26px;
}

.powerresumetop_adjust {
  margin-top: 80px;
}

.ml_12 {
  margin-left: 12px;
}

.ml_22 {
  margin-left: 22px;
}

.ml_36 {
  margin-left: 36px;
}

.pb_22 {
  padding-bottom: 22px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pb_40 {
  padding-bottom: 40px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_17 {
  margin-left: 17px;
}

.ml_15 {
  margin-left: 15px;
}

.mw {
  width: 50%;
}

.mt_40 {
  margin-top: 40px;
}

.mw_36 {
  width: 36%;
}

.mw_50 {
  width: 50%;
}

.primary_background {
  background-color: #f5f9fc;
}

.profile_icon {
  vertical-align: middle;
}

.pt_6 {
  padding-top: 6px;
}

.pt_23 {
  padding-top: 23px;
}

.pt_3 {
  padding-top: 3px;
}

.pt_4 {
  padding-top: 4px;
}

.pt_2 {
  padding-top: 2px;
}

.pt_1 {
  padding-top: 1px;
}

.pl_8 {
  padding-left: 8px;
}

.pl_10 {
  padding-left: 10px;
}

.pl_3 {
  padding-left: 3px;
}

.pt-24 {
  padding-top: 24px;
}

.mb_3 {
  margin-bottom: 3px;
}

.mb_32 {
  margin-bottom: 32px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_0 {
  margin-bottom: 0px;
}

.w1004 {
  max-width: 1004px;
}

.w95 {
  max-width: 95%;
}

.nowrap-white {
  white-space: nowrap;
}

.main-left {
  max-width: 25%;
  width: 25%;
}

.main-right {
  width: 73%;
  margin-left: 2%;
}

.full_body {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 0px;
}

.pull-right {
  float: right;
}

.popup {
  background-color: #fff;
  border-radius: 4px;
  padding: 0px !important;
}

.text-right {
  text-align: right;
}

.pt_4 {
  padding-top: 4px;
}

.pl_6 {
  padding-left: 6px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_32 {
  margin-top: 32px;
}

.mt_47 {
  margin-top: 47px;
}

hr.new2 {
  border-top: 2px solid #1a8fff;
}

hr.new3 {
  border-top: 1px solid #707070;
}

.cp {
  cursor: pointer;
}

.text-upper {
  text-transform: uppercase;
}

.custom-file-container__custom-file {
  display: flex !important;
  flex-wrap: wrap;
  margin-top: 32px !important;
}

.custom-file-container__image-preview {
  background-color: transparent !important;
  background-image: none !important;
  height: auto !important;
}

.custom-file-container__custom-file__custom-file-control {
  width: 20%;
}

.custom-file-container__image-multi-preview {
  width: 21% !important;
  height: 190px !important;
}

.custom-file-container__custom-file__custom-file-input {
  position: relative !important;
}

.custom-file-container__image-clear {
  border: 1px solid;
  width: 25px;
  height: 25px;
  margin-top: 5px;
  text-align: center;
  border-radius: 50%;
}

.signOut-btn {
  background-color: #2ad156;
  padding: 12px 22px;
  color: #fff;
  font-size: 18px;
  font-family: "Segoe UI";
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  top: 2%;
  right: 10%;
  position: absolute;
}

.pl-0 {
  padding-left: 0px !important;
}

.br_word {
  word-break: break-word;
}

/* .flex-wrap-container {
			display: flex;
			flex-wrap: wrap;
		} */
.mw_60 {
  width: 60%;
}

.divider_adjust {
  padding: 0px 42px;
}

.MuiDialogActions-spacing> :not(:first-child) {
  margin-left: 10px !important;
}

.pt_0 {
  padding-top: 0px !important;
}

.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 16px 42px;
}

.float_none {
  float: unset !important;
}

.text_area_cleartalent {
  width: 94%;
  /* height: 45px; */
  /* padding: 0px 0px; */
  /* font-size: 14px; */
  /* line-height: 1.42857143; */
  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}

.__react_component_tooltip {
  display: inline-block;
  font-size: 13px;
  left: -999em;
  opacity: 0;
  padding: 6px 13px !important;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  top: -999em;
  visibility: hidden;
  z-index: 999;
  border-radius: 6px !important;
  left: 45px !important;
}

.crossFormodal {
  float: right;
  position: absolute;
  right: 41px;
  top: 30px;
}

.record-intro-title .crossFormodal {
  top: 13px;
}

.email_hidden {
  display: inline-block;
  width: 90%;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

/*    #################################    COMMON CSS    ##################################      */
/* responsive    */

@media only screen and (max-width: 768px) {
  .email_hidden {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .add_another_btn_adjust {
    text-align: left;
    margin-top: 20px;
  }

  .closeicon_set {
    width: 15px;
  }

  legend {
    font-size: 18px !important;
    margin-bottom: 17px;
    font-family: "Segoe-Semibold";
    font-style: normal;
    font-weight: 600;
    color: #01313c;
  }

  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 18px !important;
  }

  .text_area_cleartalent {
    width: 79%;
    /* height: 45px; */
    /* padding: 0px 0px; */
    /* font-size: 14px; */
    /* line-height: 1.42857143; */
    padding: 0px 30px;
    font-size: 16px;
    font-family: "Segoe-Regular";
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
  }

  .crossFormodal {
    float: right;
    position: absolute;
    right: 41px;
    top: 30px;
  }

  .record-intro-title .crossFormodal {
    top: 13px;
  }

  .btn-big {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;

    padding: 9px 9px 11px 9px !important;
    font-size: 22px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
  }

  /* .MuiDialogContent-root {
		flex: 1 1 auto;
		padding: 8px 24px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding: 12px 18px 0px 18px!important;
		margin-top: 70px;
} */
  /* .MuiDialogActions-root {
		flex: 0 0 auto;
		display: flex;
		padding: 8px;
		align-items: center;
		justify-content: flex-end;
		padding: 20px 18px 20px 18px!important;
} */

  .full_body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
  }

  .main-left {
    min-width: 100%;
  }

  .main-right {
    width: 100%;
    margin-left: 0%;
  }

  .mw_50 {
    width: 100%;
  }

  .ml_12 {
    margin-left: 0px;
  }

  .df {
    display: flex;
    flex-wrap: wrap;
  }

  .myskill-right {
    text-align: left !important;
    margin-top: 10px;
  }

  .btn-addanothertestimonial {
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    padding: 7px 10px 9px 9px;
    font-weight: 600;
    font-size: 16px;
    font-family: "Segoe-Semibold";
    width: 100%;
  }

  .btn-addanothertestimonial:hover {
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
  }

  .btn-addanothertestimonial:focus {
    outline: none;
  }

  .btn-resume-testimonial {
    color: #01313c;
    width: 100%;
    background-color: #d1e8ff;
    padding: 13px 10px 16px 9px !important;
  }

  .btn-resume-testimonial:hover {
    color: #01313c;
    background-color: #d1e8ff;
  }

  .btn-resume-testimonial:focus {
    outline: none;
  }

  .divider_adjust {
    padding: 0px 24px;
  }

  .btn2 {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 7px 30px 9px;
    font-size: 16px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    margin-bottom: 17px;
    border: none;
  }

  .btn3 {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 7px 30px 9px;
    font-size: 16px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    margin-bottom: 17px;
    width: 89px;
    border: none;
  }

  .btn_remove {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 7px 30px 9px 30px;
    font-size: 16px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    margin-bottom: 17px;
  }
}

.signoutbtn {
  position: absolute;
  right: 26%;
  top: 14px;
}

.signoutbtn {
  position: absolute;
  right: 7% !important;
  top: 11px;
}

@media screen and (max-width: 1366px) {
  .closeicon_set {
    width: 15px;
  }

  .btn-big {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* border: 1px solid transparent; */
    padding: 9px 35px 11px 35px;
    font-size: 18px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
  }

  legend {
    font-size: 18px !important;
    margin-bottom: 17px;
    font-family: "Segoe-Semibold";
    font-style: normal;
    font-weight: 600;
    color: #01313c;
  }
}

/* @media screen and (max-width: 1920px) { */

@media (min-width: 1367px) and (max-width: 1920px) {
  .closeicon_set {
    width: 24px;
  }

  .btn-big {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* border: 1px solid transparent; */
    padding: 9px 35px 11px 35px;
    font-size: 22px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
  }

  legend {
    font-size: 22px;
    margin-bottom: 17px;
    font-family: "Segoe-Semibold";
    font-style: normal;
    font-weight: 600;
    color: #01313c;
  }
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-to-footer {
  flex: 1;
  width: 100%;
}

@media (max-width: 767px) {
  .flex-to-footer {
    width: auto;
  }

  .section-wrapper {
    display: block;
    flex-direction: column;
    min-height: 100vh;
  }
}

.homepage-carousel .thumbs-wrapper {
  display: none;
}

.banner-container {
  position: relative;
}

.carousel-text-wrapper {
  position: absolute;
  top: 0rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.homepage-carousel {
  height: 100vh;
  overflow: hidden;
}

.banner-text {
  font-size: 2rem;
  /* padding: 0em 2em; */
  padding-right: 2em;
  font-family: "Noway-Regular";
  color: #fff;
  font-weight: bold;
  width: 40%;
  text-align: left;
}

.homepage-carousel .carousel-status {
  visibility: hidden;
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.homepage-carousel .carousel-slider {
  height: 100vh;
}

.homepage-carousel .carousel .control-dots .dot.selected {
  background: #2ad156;
}

.homepage-carousel .carousel .control-dots .dot {
  width: 12px;
  height: 12px;
  box-shadow: none;
  border: 1px solid #2ad156;
}

.homepage-carousel .carousel .control-next.control-arrow:before {
  transform: rotate(135deg);
  border-right: 8px solid transparent;
  margin-right: 20px;
}

.homepage-carousel .carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.homepage-carousel .carousel .control-prev.control-arrow:before {
  transform: rotate(-45deg);
  border-right: 8px solid transparent;
  margin-left: 20px;
}

.homepage-carousel .carousel .control-arrow:before,
.homepage-carousel .carousel.carousel-slider .control-arrow:before {
  width: 25px;
  height: 25px;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
}

.homepage-carousel .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

@media (min-width: 767px) and (max-width: 1366px) {

  .homepage-carousel .carousel .control-arrow:before,
  .homepage-carousel .carousel.carousel-slider .control-arrow:before {
    width: 20px;
    height: 20px;
    border-top: 4px solid #fff;
    border-left: 4px solid #fff;
  }
}

@media (max-width: 767px) {
  .homepage-carousel .carousel-slider {
    height: auto;
  }

  .carousel-text-wrapper {
    height: auto;
    bottom: 3rem;
  }

  .carousel.carousel-slider .control-arrow {
    height: auto;
  }

  .homepage-carousel {
    height: auto;
  }

  .level_sec {
    display: flex;
    flex-direction: column;
  }

  .level_sec_single {
    width: auto !important;
    margin: 1rem !important;
  }

  .banner-text {
    font-size: 14px;
  }
}

.formone-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.form-one-complete-later {
  order: 1;
}

.formone-button-wrapper .MuiCircularProgress-root {
  margin-right: 3rem;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 14.5px 12px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-left: 0px !important;
}

input[type="text"],
select {
  padding-left: 14px !important;
}

.MuiButton-root {
  text-transform: capitalize !important;
}

.MuiTableContainer-root {
  margin: 0px 12px;
}

.question-list-section-wrapper .MuiTableContainer-root {
  margin: 0;
}

.email-configuration-container .MuiTableContainer-root {
  margin: 0;
}

.configaration_input {
  width: 100%;
}

.recruiter_private_table th {
  padding: 12px 16px;
  text-align: left;
}

.Recruiter_Private_Skill_Modal .admindailog_title_modal.MuiDialogTitle-root {
  padding: 16px 24px !important;
}

.Recruiter_Private_Skill {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 2px 16px 2px 16px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Regular";
}

.btn-Recruiter_Private_Skill {
  color: #fff;
  background-color: #ff4141;
}

.btn-Recruiter_Private_Skill:hover {
  color: #fff;
  background-color: #ff4141 !important;
}

.Recruiter_Private_Skill {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 2px 16px 2px 16px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Regular";
}

.talent_community_inner .MuiTableContainer-root {
  margin: 0px 0px;
}

.btn-blue_adjust {
  height: 43px;
}

.invite_taketest .form-one-left-upper {
  padding: 0px 31px;
}

.invite_taketest .form-one-left-upper .form-one-left-logo {
  padding: 2rem 0rem 2rem 0rem;
}

.invite_taketest_right .form_text_container_other {

  margin-top: 1.2em;

}


@media (min-width: 1367px) and (max-width: 1920px) {
  .invite_taketest .form-one-left-upper {
    padding: 14px 46px;
  }

}

@media (min-width: 1921px) and (max-width: 2560px) {
  .invite_taketest .form-one-left-upper {
    padding: 14px 54px;
  }

}


.k-grid th,
.k-grid td {

  font-family: Roboto, sans-serif;
}

.k-column-title {
  font-weight: bold;
}

.k-pager-numbers .k-link.k-state-selected {
  color: #ffffff;
  background-color: rgb(63 81 181 / 68%);
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
  color: #ffffff;
  background-color: rgb(63 81 181 / 68%);
}

.k-pager-input,
.k-pager-sizes,
.k-pager-info {
  font-family: Roboto, sans-serif;
}

.recruiter_badge {
  z-index: 0;
}


.Skill_serch_right_wrapper {
  position: unset !important;
  width: 100%;
  display: flex !important;
  justify-content: right;
  flex-direction: row;
}

.Skill_serch_right {

  width: 259px !important;

}

/* input:focus, textarea:focus, select:focus{
  outline: none !important;
  background-color: yellow;
}
.Mui-focused {
   outline: none !important; 
  
} */
/* .bar{
  height: 100%;
  border: 1px solid #009EFD;
  width: 10px;
  margin-left: 10px;
}
.pid{
  width: 10px;
  height: calc(10% - 10px);;
  
  margin: 5px;
} */
.powres_card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  transition: all 0.2s ease-in-out;
  padding: 14px 20px 20px 20px;
  border-radius: 4px;
  background-color: #ffffff;
}

.btn_powres {
  color: #01313c;
  background-color: #d1e8ff;
}

.btn_powres:hover {
  /* color: #0f8aa6; */
  opacity: 0.7;
  background-color: #d1e8ff;
}

.btn_powres:focus {
  outline: none;
}

.fs-semi-ui-class {
  font-family: "Segoe UI"
}