.ats-dash-container {
    margin-top: 130px;
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.topBar-Ats-Feed {
    display: flex;
    height: 50px;
    margin-bottom: 20px;
}

.info-cards-topbar-ats-feed {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    gap: 15px;
}

.ats-feed-orgLogoArea {
    display: flex;
    justify-content: end;
    align-items: top;
    height: auto;
    width: 100%;
}

.Ats-DashFeed-OrgLogo {
    max-width: 200px;
    max-height: 45px;
}

.ats-dashFeed-card {
    padding: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    font-family: 'poppins', sans-serif;
    /* gap: 20px; */
    width: 240px;
    justify-content: space-between;
}

.ats-dashFeed-cardInfo {
    align-items: end;
    display: flex;
    flex-direction: column;
}

.communityStatsText {
    font-size: 18px;
    color: #2896fe;
    font-weight: 500;
}

.ats-dashFeed-MainGrid {
    display: grid;
    /* grid-template-columns: 3.5fr 2.5fr 1.5fr; */
    grid-template-columns: 4fr 1fr;
    gap: 20px;
}


.wasi-lineChart .main-svg {
    box-shadow:rgb(100 100 111 / 6%) 0px 0px 4px 2px;
}

.wasi-lineChart .js-plotly-plot .plotly .main-svg {
    height: 100% !important;
    width: 100%;
}

.ats-dashFeed-RightBar-Title {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 8px 8px 0px 0px;
    font-family: "Segoe-semibold";
    font-size: 19px;
}

.subtile_recLeadeFeed {
    font-family: 'poppins', sans-serif;
    font-size: 10px;
    margin-left: 2px;
    margin-top: 6px;
    color: #ffffff;
}

.ats-UploadResumeModal-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
}


.choose_file_dialog {
    margin-top: 20px;
}

.ats-dashFeed-RightBar {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 75vh;
    border-radius: 8px;

}

.ats-dash-rightBar-infoContainer {
    overflow-y: auto;
    height: 90%;
}

.ats-dash-rightBar-infoContainer::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.ats-dash-rightBar-infoContainer::-webkit-scrollbar-thumb {
    background-color: #1F729F;
    /* Set the color of the scrollbar thumb */
}

.ats-dash-rightBar-infoContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the color of the scrollbar track */
}


.ats-dashFeed-RightBar-InfoCard {
    border: 1px solid #31B3F8;
    display: flex;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7px;
    padding: 10px;
    gap: 10px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    cursor: pointer;
}

.ats-dashFeed-RightBar-InfoCard:hover {
    opacity: 0.8;
    background-color: #f1f1f1;
}


.ats-dash-rightBar-loader {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.ats-dashFeedWrapper {
    display: grid;
    grid-template-columns: 2.35fr 3fr;
    gap: 20px;
    margin-bottom: 3vh;
}

/* For 110% Screen Resolution */
@media only screen and (max-width: 1750px) {}

/* For 125% Screen Resolution */
@media only screen and (max-width: 1543px) {
    .ats-dashFeed-MainGrid {
        grid-template-columns: 1fr;
    }

    .ats-dashFeed-RightBar {
        width: 43%;
    }

    .ats-dash-body {
        margin-left: 20px !important;
    }
}


/* For 140% Screen Resolution */
@media only screen and (max-width: 1380px) {

}


/* For 150% Screen Resolution */
@media only screen and (max-width: 1380px) {}

.resume_source {
    font: 600 18px/24px Segoe UI;
    padding-left: 10px;
}

.source_div {
    margin-right: 20px;
    margin-bottom: 3px;
}
.recruiter_logo{
    max-width: 200px;
    max-height: 45px;
}