/*------------- VIDEO INTERVIEW QUESTION ---------------*/

.videosection {
  width: 100%;
}

.vl_question {
  border-left: 1px solid #a3bcd5;
  /* / height: 273px; / */
  margin-left: 20px;
  margin-top: 0px;
}

/*------------- VIDEO INTERVIEW QUESTION ---------------*/

/*------------- STEP CSS ---------------*/

.bs-stepper .step-trigger {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.bs-stepper .step-trigger:focus {
  outline: none;
}

.bs-stepper-label {
  display: inline-block;
  margin: 0.25rem;
}

.bs-stepper-header {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bs-stepper-line,
.bs-stepper .line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: 9px;
  background-color: #a3bcd5;
}

.success_line {
  background-color: #2ad156;
}

.bs-stepper-circle {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  color: #adb5bd;
  border: 1px solid #a3bcd5;
  border-radius: 100%;
  line-height: 37px;
  font-size: 16px;
  background-color: #fff;
  font-weight: normal;
  font-family: "Segoe-Regular";
  outline: none;
}

.active .bs-stepper-circle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.success .bs-stepper-circle {
  color: #fff;
  background-color: #2ad156;
  border-color: #2ad156;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.bs-stepper-content {
  padding: 0 20px 20px;
}

.bs-stepper.vertical {
  display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
  display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden;
}

/* .bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
  display: none;
} */

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
  display: block;
  visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
  display: block;
}

/* .bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
  display: none;
} */

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
  visibility: hidden;
}

/*------------- STEP CSS ---------------*/

/*------------- RADIO BUTTON ---------------*/

.questionoption [type="radio"]:checked,
.questionoption [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.questionoption [type="radio"]:checked + label,
.questionoption [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 43px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  color: #01313c;
  margin-top: 25px;
}

.questionoption [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #1a8fff;
  border-radius: 100%;
  background: #fff;
}

.questionoption [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #01313c;
  border-radius: 100%;
  background: #fff;
}

.questionoption [type="radio"]:checked + label:after,
.questionoption [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: #1a8fff;
  position: absolute;
  border: 1px solid #1a8fff;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.questionoption [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.questionoption [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------- RADIO BUTTON ---------------*/



.interview-question-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.interview-question-scroll::-webkit-scrollbar-thumb {
  background-color: #a5a4a4;
  border-radius: 30px;
}
/* .viewtestresults-questionside::-webkit-scrollbar {
  width: 5px;
  height: 5px;
} */
/* .viewtestresults-questionside::-webkit-scrollbar-thumb {
  background-color: #a5a4a4;
  border-radius: 30px;
} */
.flex-video-top {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.next-button {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.stepcontainer {
  display: flex;
  align-items: center;
  flex: 1 0 32px;
}

.text-success {
  color: #2ad156;
}
.interview-text {
  font-family: "Segoe UI";
  font-size: 14px;
}
#video-element {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); 
  -moz-transform: rotateY(180deg); 
  /* width: 462px; */
  /* max-height: 274px; */
  object-fit: cover;
}
.react-timer {
  padding: 0 !important;
  display: inline-block !important;
  width: fit-content !important;
}
.react-timer > h3{
  font-size: 100% !important;
}
.react-timer button {
  display: none;
}
.timer-label-static {
  font-size: 100% !important;
  font-weight: 200;
  line-height: 1.5;
  margin: 0px;
  color: rgb(102, 102, 102);
}
.fs-regular.fs-14.f-color.mr_14.mt_23.text-success.interview-text {
  color:#2ad156 !important;
}

.question-card-container > p {
  text-align: left;
}
.start_test_text_top {
  text-align: left;
  margin-top: 10px;
}
.start_test_text_bottom  {
  text-align: center;
}

.ongoing-video-next-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 1rem;
  padding-top: 1.2rem;

}
.time-label-limit {
  display: flex;
}

.time-label-limit span {
  color: #01313C;
  font-weight: 400;
  font-family: 'Segoe-Semibold';
}
.react-timer h3 {
  color: #01313C !important;
}

.cardheight {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardheight.cardheight_endtest{
  height: auto;
  margin-top: 1em;
  padding: 2rem 0rem;
}
.start_test_text_top.after_endtest_submit{
  padding: 4rem 0rem;
}
@media(max-width: 767px) {
  .end-test-container {
  padding: 0rem 1rem;
  }


  .nav_logo_home {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 70%
}
  
}

.react-timer {
  display: none !important;
}
.end-test-comment-wrapper {
  width: 100%;
}
.end-test-comment-wrapper legend{
  text-align: left;
}
.end-test-comment-wrapper .text_area_cleartalent {
  width: 94%;
  padding: 10px 12px;
}
.end-test-comment-wrapper-btn {
  text-align: right;
  margin-top: 12px;
}
.end-test-star img {
  width: 35px;
  margin-right: 5px;
}

.questionoption.questionoption-alignment_card [type="radio"]:checked + label, .questionoption.questionoption-alignment_card [type="radio"]:not(:checked) + label {
  padding-left: 0px;
  display: flex;
  align-items: center;
  }
  .questionoption.questionoption-alignment_card [type="radio"]:not(:checked) + label:before {
  position: initial ;
  margin-right: 1rem;
  flex-shrink: 0;
  
  
  }
  
  .questionoption.questionoption-alignment_card [type="radio"]:checked + label:before {
  position: initial;
  margin-right: 1rem;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment_card [type="radio"]:checked + label:after {
  top: initial;
  left: 7px;
  }
  .questionoption.questionoption-alignment_card [type="radio"]:checked + label:before {
  margin-right: 1rem;
  position: inherit;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment_card [type="radio"]:checked + label:after {
  left: 7px;
  top: inherit;
  }
  .questionoption-alignment_card .interview-chkbox input:checked + label:after {
  
  display: flex;
  align-items: center;
  top: inherit;
  }
  
  
  .questionoption-alignment_card .interview-chkbox label {
  
  display: flex;
  align-items: center;
  }
  
  .questionoption.questionoption-alignment_card  [type="radio"]:checked + label:after, .questionoption.questionoption-alignment_card  [type="radio"]:not(:checked) + label:after {
  top: inherit !important;
  }
  .string-whitespace-pre {
    white-space: pre;
  }
  .block-answer {
    white-space: pre;
  }

@media (min-width: 768px) and (max-width: 1366px) {
  .block-answer-wrapper {
    font-size: 16px;
  }
  .card.flex-video-bottom {
    padding: 14px 20px 23px 20px;
  }
  .register-test-container {
    max-width: 1200px;
  }
  .question-title {
    font-size: 16px;
  }
  .questionoption-alignment_card .interview-chkbox label {
    font-size: 16px;
  }
  .scrollHeight {
    /* max-height: 47vh; */
    overflow-y: scroll;
    height: 310px;
  }
  .vl_question {
    height: auto;
  }
  .bs-stepper-circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  /* .video-information-wrapper .mw {
    width: 50%;
  } */
  #video-element {
    width: 100%;
    max-height: 305px;
  }
  .skillname_fontsize{
    font-size: 23px;
  }
  .flex-video-bottom {
    height: 100%; 
    /* position: relative; */
  }
}
@media (min-width: 1367px) {
  .scrollHeight {
    /* max-height: 55vh; */
    overflow-y: scroll;
    height: 470px;
  }
  /* .video-information-wrapper {
    height: 60vh
  } */
  .vl_question {
    height: 52vh
  }
  .questioncardwidth{
    max-width: 1200px;
  }
  .register-test-container {
    max-width: 1200px;
  }
  
}
@media(min-width: 1367px) {
  .flex-video-bottom {
    /* height: calc(100vh - 132px); */
    position: relative;
  }
  #video-element {
    width: 100%;
    max-height: 100%;
    height: 82%;
  
   
  }
  .skillname_fontsize{
    font-size: 32px;
  }
  .question-title{
    font-size: 18px;
  }
  .block-answer-wrapper{
    font-size: 16px;
  }
  .card.flex-video-bottom {
    padding: 14px 20px 23px 20px;
  }
  .nav_bg_left {
    width: 21%;
}

}
@media(max-width: 1367px) and (min-width: 768px) {
  
}
 .right-pane-qa-video {
   width: 50%;
 }
 .interview-question-scroll {
   width: 96%;
 }
 .end_test_Loading_wraper{
  padding: 9em 0em;
 }
 .card.card-video-height {
  padding: 10px 20px 23px 20px;
 }

 /* p.string-whitespace-pre:empty {
   background: red;
 }
 p.without-line-break {
   background-color: red;
 } */
 p#index0 {
   display: none;
 }


 .registerstartTestRefferal{
  color: #1a8fff;
  font-size: 23px;
  font-family: "segoe-regular";
  margin-top: 63px;
  font-weight: 600;
 }

 .registerstartTestcolor{
  cursor: pointer;
  color: red;
 }

 .registerstartTestcolor:hover{
  color:blue;
 }

 #testQuestionDescp h2 {
  margin-block-start: 0; /* or margin-block-start: initial; */
}
#testQuestionDescp h1 {
  margin-block-start: 0; /* or margin-block-start: initial; */
}
