/*--------------- Header CSS -------------*/
.nav_bg {
  width: 100%;
  background-color:  #FFFFFF !important;
}
.header_fixed {
  position: fixed;
  top: 0px;
  z-index: 999;
}

.nav_bg_left {
  width: 42%;
  /* background-color: #04093f; */
}

.nav_bg_right {
  /* width: 58%; */
  background-color: #04093f;
}
.hamberger_icon {
  display: none;
}

.nav_logo {
  margin-top: 22px;
  margin-bottom: 16px;
  width: 100%;
}

.navbar {
  width: 100%;
  overflow: auto;
  padding-inline-start: 0px;
}

.navbar li {
  list-style-type: none;
}

.navbar li img {
  vertical-align: middle;
}

.LinkForHeader {
  float: left;
  padding: 7px 0px 0px 38px;
  color: #d1e8ff;
  text-decoration: none;
  font-size: 16px;
  font-family: "Segoe-Regular";
}

.nav_menu_icon {
  padding-right: 5px;
}

.notify-badge {
  position: absolute;
  top: 5px;
  margin-left: 9px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  background: #2ad156;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: 8px;
}
/* .contact_header{
  font-weight: normal!important;
  font-family: 'Segoe-Light'!important;
  font-size: 68px!important;
  color: #01313C!important;
} */
.MuiTypography-h6 {
  font-family: "Segoe-Light" !important;
  font-size: 35px !important;
  color: #01313c !important;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 6px 24px;
}
.contact_details_heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Segoe-Semibold" !important;
  color: #01313c !important;
}
.nav_menu_adjust {
  display: flex;
  justify-content: space-between;
}
.mobilemenuleft_adjust {
  margin-left: 0px;
}
.fadeInHmbMenu {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
  position: absolute;
  width: 100%;
  left: 0px;
  height: 350px;
  z-index: 99;
  background-color: #04093f;
  top: 61px;
  /* display: block; */
}
.fadeOutHmbMenu {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  width: 0;
  height: 0;
  /* display: none; */
}
.support_textbackgroundone {
  background-color: #d1e8ff;
  width: 44.5%;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
}
.support_textbackgroundtwo {
  background-color: #d1e8ff;
  width: 42%;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
}
.support_section {
  display: flex;
  justify-content: space-between;
}
.text_area_cleartalent_support {
  width: 94%;
  /* height: 45px; */
  /* padding: 0px 0px; */
  /* font-size: 14px; */
  /* line-height: 1.42857143; */
  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}
.add_email_box {
  width: 100%;
  position: relative;
  display: flex;
}
.add_email_box .search-input {
  width: 100%;
  padding: 10px;
  height: 45px;
  border: 1px solid #a5a4a4;
  border-radius: 4px 0 0 4px;
  border-right: none;
  outline: none;
  font-size: 16px;
  color: #000;
  background: none;
  font-family: "Segoe-Regular";
}

.add_email_box .search-input:focus {
  box-shadow: none;
  border: 1px solid #a5a4a4;
}

.add_email_box .search-input::placeholder {
  font-size: 16px;
  color: #01313c;

  font-family: "Segoe-Regular";
}

.text_area_cleartalent_support {
  width: 94%;

  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid #a5a4a4;
  border-radius: 4px;
}

.text_area_cleartalent_support::placeholder {
  font-size: 16px;
  color: #01313c;

  font-family: "Segoe-Regular";
}

.add_email_box .search-button {
  text-align: center;
  height: 45px;
  padding: 0px 20px;
  outline: none;
  cursor: pointer;
  border: 1px solid #2ad156;
  border-radius: 0 4px 4px 0;
  border-left: none;
  background: none;
  font-size: 16px;
  border-left: 1px solid #a5a4a4;
  font-family: "Segoe-Semibold";
  color: #fff;
  background-color: #2ad156;
}
.mail_bg {
  background-color: #d1e8ff;
}

.mail_text {
  font-size: 18px;
  font-family: "Segoe-Regular";
  color: #01313c;
}

.mail_close_icon {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .text_area_cleartalent_support {
    width: 77%;
    /* height: 45px; */
    /* padding: 0px 0px; */
    /* font-size: 14px; */
    /* line-height: 1.42857143; */
    padding: 0px 30px;
    font-size: 16px;
    font-family: "Segoe-Regular";
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
  }
  .responsive_Support {
    padding: 0px 18px !important ;
  }
  .support_textbackgroundone {
    background-color: #d1e8ff;
    width: 94%;
    padding: 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .support_textbackgroundtwo {
    background-color: #d1e8ff;
    width: 94%;
    padding: 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .support_section {
    display: block;
    justify-content: unset;
  }

  .MuiTypography-h6 {
    font-family: "Segoe-Light" !important;
    font-size: 30px !important;
    color: #01313c !important;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  .hamberger_icon {
    float: right;
    display: contents;
  }
  .nav_logo {
    margin-top: 22px;
    margin-bottom: 16px;

    width: 80%;
  }

  .nav_bg_left {
    width: 100% !important;
    /* background-color: #04093f; */
  }

  .nav_bg_right {
    width: 100% !important;
    background-color: #04093f;
    display: none !important;
  }

  .LinkForHeader {
    float: left;
    padding: 7px 4px 0px 4px !important;
    color: #d1e8ff;
    text-decoration: none;
    font-size: 16px;
    font-family: "Segoe-Regular";
  }
  .nav_menu_adjust {
    display: flex;
    padding-bottom: 0px;
    padding-right: 20px;
  }
  .hamberger_nav {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 350px;
    z-index: 99;
    background-color: #04093f;
    top: 61px;
  }
  .fadeInHmbMenu {
    opacity: 1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    position: absolute;
    width: 100%;
    left: 0px;
    height: 350px;
    z-index: 99;
    background-color: #04093f;
    top: 61px;
    /* display: block; */
  }
  .fadeOutHmbMenu {
    opacity: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    width: 0;
    height: 0;
    /* display: none; */
  }
  .navbar_mobile {
    width: 100%;
    overflow: auto;
    padding-inline-start: 0px;
  }
  .mobilemenuleft_adjust {
    margin-left: 24px;
  }

  .navbar_mobile li {
    list-style-type: none;

    /* display: block; */
    width: 100%;
    /* margin: 10px 0px; */
    border-bottom: 1px solid #192288;
    padding: 20px 0px !important;
  }
  .navbar_mobile li:last-child {
    list-style-type: none;

    /* display: block; */
    width: 100%;
    /* margin: 10px 0px; */
    border-bottom: 0px solid #192288;

    padding: 20px 0px !important;
  }

  .navbar_mobile li img {
    vertical-align: middle;
  }
}

.support-mailbody-textarea {
  width: 100%;
  height: 100px !important;
}
.mail-validation-error {
  color: red;
}
.remove-snackbar-alert .MuiAlert-message {
  font-family: "Segoe-Regular";
}
.text_area_send_mail {
  width: 100%;
  padding-right: 0px;
}
.fle{
  display: flex;
}
.mail_more{
  display: inline;
}