/* .popup ~ .video-recorder__Wrapper-sc-7k20rv-0.kHmnKJ {
  min-height: 600px !important;
} */
.dloCfB {
  display: none;
}
.hvEHqT {
  display: none;
}
.gNmWLX {
  top: 0;
}
button {
  cursor: pointer;
}
.btn.btn-danger {
  color: #d4d4d4;
}
.video-skill-test {
  width: 258px !important;
  height: 158px !important;
}
.ml-25 {
  margin-left: 25px;
}
.mt-10 {
  margin-top: 10px;
}
.kHmnKJ {
  min-height: 450px!important;
}
.skillvideo_modal_videointro {
  background: rgb(255, 255, 255) !important;
  overflow: auto !important;
  border-radius: 4px !important;
  outline: none !important;
  width: 800px !important;
  /* margin: 1px auto !important; */
  position: relative!important;
}
.icaKGS {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 283px;
}

@media only screen and (max-width: 768px) {
  .ml-25 {
    margin-left: 0px;
  }
  .video-skill-test {
    width: 100% !important;
    height: 158px !important;
  }
  .kHmnKJ {
    min-height: 250px!important;
  }
  .skill_video_popup.pr {
    width: 100%;
    margin-top: 20px;
  }
  .ReactModal__Content.ReactModal__Content--after-open.skillvideo_modal {
    margin-top: 0px;
  }
  .skillvideo_modal_videointro {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 94% !important;
    /* margin: 30px auto !important; */
    position: relative!important;
  }
  .popup {
    background-color: #fff;
    border-radius: 4px;
    padding: 15px 10px 25px 10px;
}
.icaKGS {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 164px;
}
}

.my-video-intro {
  width: auto;
  height: 180px;
  object-fit: cover;
}
.recorded-video-intro {
  width: 100%;
}
.closeicon_set {
  width: 15px;
}

.recorded-video-container .MuiDialogTitle-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 42px;
}

/* Recorded video intro responsiveness */
@media (max-width: 500px) {
  .my-video-intro {
    width: 100%;
  }
  .recorded-video-container .MuiDialogTitle-root {
    padding: 16px;
  }
  .recorded-video-container .MuiDialog-paper {
    margin: 14px;
  }
}
.video-intro-heading.MuiDialogTitle-root {
  padding: 16px 27px !important;
}