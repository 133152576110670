.thirdCard {
    width: 300px;
}

.cardBlock {
    padding: 20px 0px;
    border-radius: 10px;
    box-shadow: 0px 3px 8px #0000003d;
}

.t_card_heading {
    display: flex;
    justify-content: left;
    align-items: center;
}

.colorBox {
    background-color: #3ca4e5;
    width: 10px;
    height: 25px;
    margin-right: 10px;
}

.thirdCardHeading {
    /* font-family: 'Segoe-Semibold';
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #075482; */
    font-family: "Segoe-bold";
    font-weight: bold;
    color: #4D4F5C;
    font-size: 22px;
}

.t_card_content {
    padding: 20px 10px 10px 20px;
    width: 60%;
    font-weight: bold;
}

.third_col_styles {
    padding: 10px 0px;
    color: #393939;
}

.tableCell {
    padding: 5px 0px;
    font-family: 'Segoe-Regular';
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
}
.requisitionType{
    cursor: pointer;
}
.requisitionType:hover{
    text-decoration: underline;
    opacity: 0.9;
    color: #075482;
}

.cellQuantity {
    font-family: 'Segoe-Regular';
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    color: #393939;
}