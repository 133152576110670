.padding_80
{
    padding: 80px 0px;
}
.whiteAdust {
    height: 78vh
  }
  .circular-progress-center {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }