.talentCommunityMainContainer {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}

.talentCommunityMainGrid {
    padding: 0 5%;
    padding-top: 117px;
}

.talentCommunitySearchBarContainer {
    border-bottom: 1px solid #E9E9F0;
    padding-bottom: 15px;
    margin-bottom: 15px !important;
}

.searchbar {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* justify-content: space-between; */
    align-items: center;
    gap: 10px
}

.searchbarcontainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.recruiterLogoContainerMain {
    max-width: 200px;
    max-height: 45px;
    display: flex;
    justify-content: flex-end;
}

.recruiterLogoContainer {
    max-height: 100%;
    max-width: 100%;
}

.searchbarfeild {
    width: 40vw;
    border-radius: 8px;
    box-shadow: 0px 3px 8px #0000003d;
}

.searchbar .MuiOutlinedInput-notchedOutline {
    border: none;
}

.talentCommunityAdvancedSearchButton {
    padding-left: 15px;
}

.searchButton {
    color: #fff !important;
    background-color: #31B3F8 !important;
    height: 35px;
    margin-left: 10px !important;
}

.talenCommunityActivityContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* align-items: center; */
    margin-top: 10px;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}

.activityCount {
    display: flex;
    gap: 25px;
}

.talentCommunitySizecontainer {
    width: 300px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 8px #0000003d;
    align-items: center;
    height: 40px;
}

.talentCommunitySizeleftside {
    display: flex;
}

.talentCommunitySizeInfoContainer {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.talentCommunitySizeCount {
    padding-bottom: 5px;
    margin-top: -6px;
    color: #03A2F9;
    font-family: "Segoe-semibold";
    font-size: 18px;
}

.talentCommunitySizeTile {
    font-family: "Segoe-semibold";
    color: #4D4F5C;
    font-size: 13px;
}

.talentCommunityaddedcontainer {
    width: 370px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 8px #0000003d;
    align-items: center;
    margin-left: 40px;
}

.talentCommunityGraphActionmainContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 25px;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: flex-start;
}

.talentCommunityLeftSideMainContainer {
    width: 340px;
    box-shadow: 0px 4px 12px #0000001a;
}

.talentCommunityRightSideMainContainer {
    /* box-shadow: 0px 4px 12px #0000001a; */
    width: calc(100% - 360px);
    display: flex;
    /* justify-content: center; */
    margin-top: -20px;
    flex-direction: column;

}
.tableContainer1_recruiterDashboard{
    display: flex;
}

.leftsidetopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 20px;

}

.talentCommunityLeftSideActionTitle {
    font-family: "Segoe-bold";
    font-weight: bold;
    color: #4D4F5C;
    font-size: 22px;
    margin-bottom: 25px;
    width: 100%;
    border-left: 10px solid #31B3F8;
    padding-left: 20px;
    margin-top: 15px;
}

.leftsidetopContainerTitle {
    font-family: "Segoe-semibold";
    font-size: 17px;
    text-align: center;
}


.leftsidebottomContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.leftsidetopContainercontent {
    width: 150px;
    height: 125px;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.leftsidetopContainercontent_admin {
    width: 150px;
    height: 125px;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
}

@media screen and (max-width: 900px) {

    .talentCommunityGraphActionmainContainer {
        flex-direction: column;
    }

    .talentCommunityaddedcontainer {
        margin-left: 0px;
        width: 600px;
    }

    /* .talentCommunitySizecontainer {
        width: 600px;
    } */

    /* .talentCommunityLeftSideMainContainer {
        width: 640px;
    } */

    /* .talentCommunityRightSideMainContainer {
        width: 640px;
        margin-top: 10px;
    } */
}

@media screen and (min-width:900px) and (max-width: 1280px) {
    /* .talentCommunitySizecontainer {
        width: 310px;
    }

    .talentCommunityaddedcontainer {
        width: 310px;
    } */

    /* .talentCommunityLeftSideMainContainer {
        width: 353px;
    }

    .talentCommunityRightSideMainContainer {
        width: calc(100% - 400px);
    } */
}

@media screen and (max-width: 680px) {
    /* .talentCommunityLeftSideMainContainer {
        width: 360px;
    } */

}

.plotyGraph {
    width: 90%;
    height: 100% !important;
}

.modebar {
    display: none;
}

.js-plotly-plot .plotly .main-svg {
    height: 100% !important;
}

.js-plotly-plot .plotly .user-select-none {
    height: 500px !important;
}

.advanceSearchButton {
    color: #fff !important;
    background-color: #31B3F8 !important;
    height: 40px;
    margin-left: 10px !important;
}

.MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
}

.actioncontainer_maindiv {
    display: flex;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    flex-wrap: wrap;
    align-items: center;
}

.action_imageheight {
    height: 60px;
}

.communityStatsImg {
    height: 38px;
}

.searchIcon_action {
    width: 45px !important;
    height: 45px !important;
    color: #31B3F8;
}

.searchIconContainer {
    border: 1px solid #31B3F8;
    border-radius: 50%;
    width: 50px;
    /* padding: 6px; */
    padding-left: 7px;
    padding-top: 8px;
    padding-bottom: 2px;
    opacity: 0.8;
    margin-bottom: 5px;
}

.item_actionContainer {
    width: 150px;
    height: 120px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
    cursor: pointer;
}

.item_actionContainer:hover,
.item_actionContainer_single:hover {
    opacity: 0.8;
    color: #31B3F8;
}

.item_actionContainer_single {
    width: 150px;
    height: 120px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
    cursor: pointer;
}

.item_actionContainer_admin {
    width: 150px;
    height: 120px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
    opacity: 0.6;
}

.actioncontainer_title {
    font-family: "Segoe-semibold";
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width: 1400px) {
    .activityCount {
        gap: 10px;
    }

    .talentCommunitySizecontainer {
        width: 280px;
    }
}

@media screen and (max-width: 1270px) {
    .activityCount {
        flex-wrap: wrap;
    }

    .talenCommunityActivityContainer {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-end;
    }
}

.upload_resume_dialog_modal .dialog_modal_title .MuiTypography-root {
    /* position: ; */
    font: 600 28px/37px Segoe UI;
    letter-spacing: 0px;
    color: #01313C;
    opacity: 1;
}

.MuiTypography-root .modal_close_icon_button {
    position: absolute;
    right: 11px;
    top: 12px;
}

.talentCommunityMainContainer .MuiDialog-container .MuiDialog-paper {
    margin: 32px;
    position: relative;
    overflow-y: unset;
    max-width: 727px !important;
    min-width: 450px;
}

.choose_file_dialog_content {
    max-height: 80vh;
    max-width: 900px;
    overflow-y: unset !important;
    font-size: 14px;
    box-sizing: border-box;
}


.choose_file_dialog_content .MuiInputBase-root {
    border: 1px solid #1A8FFF;
    border-radius: 4px;
    /* width: 189px;
    height: 48px; */
    opacity: 1;
}

.caution_text {
    font: 600 11px/15px Segoe UI;
    letter-spacing: 0px;
    color: #AFAFAF;
    text-align: center;
    /* padding-bottom: 20px; */
}

.grid-container {
    /*margin-top: 10px; */
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
    max-height: 240px;
    padding: 20px 10px;
    overflow-y: auto;
}

.grid-item {
    position: relative;
    width: 19.5%;
    text-align: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    padding: 20px 10px;
}

.file-info {
    margin-right: 8px;
}


.file-desc {
    font: 600 10px/14px Segoe UI;
    letter-spacing: 0px;
    color: #01313C;
    word-break: break-word;
}

.close-icon {
    position: absolute !important;
    top: -23px;
    right: -23px;
}

.close-icon .MuiSvgIcon-root {}

.custom-icon {
    width: 48px;
    /* Set the desired width */
    height: 48px;
    /* Set the desired height */
}

.choose_file_dialog {
    /* position: relative; */
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
    /* display: inline-block; */
    flex-direction: column;
    align-items: center;
}

.choose_file_btn {
    background-color: white;
    padding: 8px 20px;
    text-align: center;
    font: 600 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #3F3F3F;
    width: 189px;
    height: 48px;
    border: 1px solid #1A8FFF;
    border-radius: 4px;
    cursor: pointer;
}

.choose_file_dialog input[type=file] {
    position: relative;
    top: -48px;
    height: 45px;
    width: 189px;
    height: 48px;
    opacity: 0;
    cursor: pointer;
}


.action_buttons {
    justify-content: center !important;
}

.action_buttons .upload_button {
    width: 140px;
    height: 47px;
    background: #05A6F4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.action_buttons .upload_button .MuiButton-label {
    width: 58px;
    height: 24px;
    text-align: center;
    font: 600 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.action_buttons .cancel_button {
    width: 126px;
    height: 47px;
    border: 1px solid #A3BCD5;
    border-radius: 4px;
    opacity: 1
}

.action_buttons .cancel_button:hover {
    /* background-color: #01313C;
    color: #FFFFFF; */
}

.action_buttons .cancel_button .MuiButton-label {
    width: 56px;
    height: 26px;
    text-align: center;
    font: 600 19px/26px Segoe UI;
    letter-spacing: 0px;
    color: #01313C;
    opacity: 1;
}

.circularProgressContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 200px;
    font-family: "Segoe-semibold";
    font-size: 16px;
}

.plsWaitText {
    padding-left: 12px;
}

.mytalentBottomContainer {
    margin-top: auto;
}

.Unauthorized_container {
    background-color: #ffffff;
}

.UnAuthorized_contain {
    min-height: 60vh;
}

.excelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e7145;
    color: #ffffff;
    gap: 6px;
    padding: 4px 8px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: 'Segoe-semibold';
    font-size: 14px;
}

.excelButton:hover {
    opacity: 0.9;
}

.excelImg_advanceSearch {
    height: 30px;
}

.searchresultsText_div {
    font-family: 'Segoe UI';
    font-size: 18px;
    font-weight: 600;
    /* margin-bottom: 10px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.circularprogress_mytalent {
    height: 400px !important;
    width: 400px !important;
    color: #05A6F4 !important;
}

.upload_resume_mod {
    text-align: center;
}

.requisitionCountTable_recruiterDashboard {
    padding: 0px 20px;
    width: 50%;
    margin-bottom: 20px;
    padding-left: 0px;
}

.candidateInterviewTable_recuiterDashboard {
    width: 50%;
}
.upcomingStartsTable_recuiterDashboard{
    width: 49.5%;
}

@media only screen and (max-width: 1540px) {
    .talentCommunityRightSideMainContainer{
        flex-direction: column;
    }
    .tableContainer1_recruiterDashboard{
        flex-direction: column;
    }
    .upcomingStartsTable_recuiterDashboard{
        width: 100%;
    }
    .requisitionCountTable_recruiterDashboard {
        width: 100%;
    }

    .candidateInterviewTable_recuiterDashboard {
        width: 100%;
    }
}
@media only screen and (max-width: 900px) {
    .talentCommunityRightSideMainContainer{
        width: 100%;
    }
}
