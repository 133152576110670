.last-update-text {
  /* background-color:rgba(209, 232, 255, 0.4); */
  /* width: 430px; */
  /* padding: 10px 10px 0px 10px; */
  /* margin: 0px auto; */
  /* border-top-left-radius: 6px;
  border-top-right-radius: 6px; */
  font-size: 15px;
}
.pb_12 {
  padding-bottom: 12px;
}
.PowerResume_Copy{
  font-size: 26px;

}
.powerResume_section{
  display: flex;
  justify-content:end;
  /* margin-top: 13vh; */

}
.profile_footer{
margin-top: 20px;
}


.footerflex{
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.card_top_box {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease-in-out;
  padding: 14px 20px 20px 20px;
  /* border-radius: 4px; */
  background-color: #ffffff;
  
}

@media only screen and (max-width: 768px) {
  .last-update-text {
    /* background-color:rgba(209, 232, 255, 0.4); */
    /* width: 100%; */
    padding: 10px;
    margin: 0px auto;
    /* border-top-left-radius: 6px;
    border-top-right-radius: 6px; */
  }
  
}

.mn_medium{
  font-family: 'Manrope-Medium';

}
.mn_bold{
  font-family: 'Manrope-Bold';

}
.mn_semibold{
  font-family: 'Manrope-semibold';

}
.powres_contain{
  display: flex;
  justify-content: space-between;

}
.pl_55{
  padding-left: 55px;
}
.powres_maincon{
  background-color: #ffff;
}

.powres_main{
  display: flex;
  flex-direction: row-reverse;
  /* margin-top: 14vh; */
  justify-content: space-between;
}

.pt_20
{
  padding-top: 20px;
}

.powres_maintop{
  max-width: 1200px;
  margin-top: 3vh;
}

.powres_fs_color{
  color:#1885E6 ;
}
.powres_fs{
  font-size: 37px;
}
.powres_inter_box{
  margin-left:20px ;
  max-width: 280px;

}
.powres_other
{
  height: 190px;
}
.powres_prim
{
  height: 130px;
}


