.heading {
    width: 100%;
    text-align: left !important;
    margin-bottom: 10px;
}

.header_name {
    font-family: 'Malayalam Sangam MN', sans-serif;
    font-size: 23px;
    font-weight: bold;
}

.flexContainer {
    display: flex;
    flex-direction: column;
}

.underline {
    border-bottom: 1px solid #6A91E9;
    opacity: 0.5;
}

.leftCard {
    text-align: center;
    border-radius: 10px;
    height: 100%;
}

.imgDiv {
    padding: 30px 0px;
    background-color: #D1E8FF
}

.menuDiv {
    background-color: #1885e6;
    padding: 20px 0px;
    height: 100%;
}

.rightCard {
    margin: 0px !important;
    padding: 0px !important;
    margin-left: 20px !important;
    padding: 0px 10px !important;
    height: 100% !important;
}

.firstRow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 150px;
    margin-top: 50px;
}

.secondRow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 18%;
}

.cells {
    width: 160px
}

.btnStyle {
    margin-top: 10px;
    text-align: right;
    margin-right: 55px;
}

.editBtn {
    text-align: center;
    margin-top: 10px;
}

.editContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.display_name {
    font-family: "Segoe UI";
    font-size: 20px;
    margin-top: 7px;
}

.updateButton {
    margin: 10px 0px !important;
    color: #fff !important;
    background-color: #1885E6 !important;
    margin-top: 9.5px !important;
    width: 139px;
}

.editButton {
    color: #fff;
    background-color: #1885E6;
    width: 50px;
}

.underline_btn {
    border-bottom: 1px solid #6A91E9;
    opacity: 0.5;
    margin-right: 55px;
}

.section-wrapper {
    min-height: auto !important;
}

.btn-error {
    background-color: red;
    color: #FFFFFF;
}


.edit_header_logo {
    height: 40px;
    width: 200px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.left_main_div {
    height: 100%;
}

.upper_left_div {
    height: 60%;
    background-color: #D5E7FD;
}

.lower_left_div {
    height: 40%;
    background-color: #4C94F7;
}

.card_left {
    height: 100%;
}

.profile_grid {
    margin-top: 20px !important;
}

.edit_grid {
    margin-top: 5px !important;
}

.rec_grid {
    margin-top: 10px !important;
}

.logo_grid {
    margin-top: 36px !important;
}

.mainContainer_acc {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.footerContainer {
    margin-top: auto;
}

.bodyContainer {
    margin-top: 120px;
    padding: 0 6%;
    padding-bottom: 20px;
}

.topcontainer {
    background-color: #FFFFFF;
}

.topHeading {
    font-family: 'Malayalam Sangam MN', sans-serif;
    font-size: 23px;
    font-weight: bold;
}

.underline_Heading {
    border-bottom: 1px solid #6A91E9;
    opacity: 0.5;
    padding-top: 10px;
    margin-bottom: 15px;
}


.rightsideDiv {
    height: 600px;
    padding-left: 12px;
}

@media (max-width: 600px) {
    .rightsideDiv {
        padding-left: 0;
        padding-top: 12px;
    }

    .rightBottomSection {
        border-top: none !important;
        justify-content: flex-start !important;
    }

    .user_profile {
        height: 25px;
        width: 25px;
        border: 3px solid #2896FF;
        border-radius: 50%;
        padding-top: 0px !important;
    }

    .nav_bg_right {
        top: 0;
        right: 0;
    }

    .responsive_profile {
        position: absolute;
        top: 8px;
        right: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .user_name {
        text-align: center;
    }
}

.rightBody {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}


.rightBottomSection {
    margin-top: auto;
    border-top: 1px solid hsl(222, 74%, 66%, 0.5);
    height: 75px;
    margin-left: 20px;
    margin-right: 80px;
    display: flex;
    justify-content: flex-end;
}

.updateButton_Right {
    height: 40px;
    color: #fff;
    background-color: #5E92F0;
    border: none;
    font-family: Arial;
    font-size: 18px;
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px 30px;
    cursor: pointer;
}

.updateButton_Right:hover {
    opacity: 0.9;
}

.rightTopSection {
    padding: 40px 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.cellBody {
    display: flex;
    flex-direction: column;
    width: 135px;
    margin-bottom: 100px;
}

.static_name {
    font-family: 'Segoe UI';
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    color: #5E92F0;
    width: 20px !important;
    cursor: pointer;
    margin-left: 5px;
}

.textbox {
    height: 46px;
    margin-top: 10px;
}

.cellBody_phn {}

@media (max-width: 960px) {

    .cellBody,
    .cellBody_phn {
        margin-bottom: 15px;
    }

    .rightTopSection {
        padding-left: 10px;
    }
}

.upperLeftSection {
    height: 360px;
    width: 100%;
}

.bottomLeftSection {
    background-color: #4C94F7;
    height: 240px;
}

.upper_left_img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.edit_profiles {
    width: 105px;
    height: 105px;
    border-radius: 50%;
}

.editContainer_left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 7px;
}

.Edit_name {
    font-size: 16px;
    font-family: "Segoe UI";
    color: #1885E6;
    cursor: pointer;
}

.icon1 {
    color: #5E92F0;
    width: 20px !important;
    cursor: pointer;
    margin-left: 5px;
}

.display_rec_name {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: "Segoe-Regular";
    font-size: 20px;
    margin-top: 8px;
}

.logo_main_div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.edit_logo_div {
    height: 54px;
    width: 205px;
    display: flex;
    justify-content: center;
}

.edit_logos {
    max-width: 100%;
    max-height: 100%;
}

.lower_left_acc {
    width: 100%;
    text-align: center;
}

.account_setting {
    margin-top: 40px;
    padding: 10px 0px;
    font-family: 'Segoe-Semibold';
    font-size: 22px;
    color: #05093C;
    background-color: #FFFFFF;
    width: 100%;
}

.menu-icon {
    height: 17px;
    width: 17px;
}

.account-text {
    margin-left: 4px;
}

.profile_dropdown {
    position: relative;
}

.account_profile_pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.account_nav {
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    width: max-content;
}

.user_menu {
    width: 80%;
}

.profile_name {
    width: 80%;
}

.profileImag {
    width: 38px;
    ;
    height: 38px;
    border-radius: 50%;
    border: 3px solid #2896FF;
    margin-top: 22px;
}

.profile_drop {
    width: 1%;
}

.profile_drop_menu {
    position: absolute;
    top: 90px !important;
    right: 0px;
}

.nav_bg_home_sectio {
    justify-content: space-between;
}

.resume_head {
    display: flex;
    align-items: center;
}

.textbox_phone_input_accountSettings {
    height: 47px;
    width: 230px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}

.textbox_phone_input_accountSettings:hover {
    border: 1px solid #000;
}

.textbox_phone_input_accountSettings:active {
    border: 1px solid #3f51b5;
}

.userDetailFieldContainer_phoneNumber .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: 0px;
    height: 30px;
    font-size: 16px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    width: 80%;
}

.userDetailFieldContainer_phoneNumber .PhoneInputInput:focus {
    flex: 1 1;
    min-width: 0;
    border: 0px;
    outline: none;
    height: 30px;
    font-size: 16px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    width: 80%;
}

@media only screen and (max-width: 600px) {
    .profile_name {
        width: 80%;
        font-size: 0.85rem !important;
    }
}

.userDetailFieldContainer {
    display: flex;
    flex-direction: column;
    width: 250px;
}

.userDetailFieldContainer_email {
    display: flex;
    flex-direction: column;
    width: 250px
}

.userDetailFieldContainer_phoneNumber {
    display: flex;
    flex-direction: column;
    width: 250px
}

.userDetails_headings {
    font-size: 17px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-bottom: 10px;
    color: #3B3B3B;
}

.userDetails_textbox_outline {
    height: 47px;
    background-color: #ffffff !important;
}

.updateButton_AccountSettings {
    height: 40px;
    display: flex;
    background-color: #1885e6 !important;
    color: #ffffff;
    padding: 5px 30px;
    margin-top: 10px;
    width: 135px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
}

.updateButton_AccountSettings:hover {
    opacity: 0.9;
}

.updateButton_AccountSettings_disabled {
    height: 40px;
    display: flex;
    background-color: #1885e6 !important;
    color: #ffffff;
    padding: 5px 30px;
    margin-top: 10px;
    width: 135px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    gap: 10px;
}

.profilePictureDialog .MuiCircularProgress-root {
    color: #FFFFFF !important;
    height: 18px !important;
    width: 18px !important;
    margin-top: 2px;
}

.rightBottomSection .MuiCircularProgress-root {
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
}

.removeButton_accountSettings_disabled .MuiCircularProgress-root {
    color: #01313c !important;
    height: 18px !important;
    width: 18px !important;
}

.removeButton_accountSettings_disabled{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 5px !important;
    margin-top: 2px !important;
    opacity: 0.7;
    cursor: default !important;
}


.cancelButton_AccountSettings {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* border: 1px solid transparent; */
    height: 50px;
    width: 135px;
    font-size: 18px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    cursor: pointer;
}

.cancelButton_AccountSettings:hover {
    background-color: #f5f5f5;
}

.profilePictureSave_accountSettings {
    height: 50px !important;
    margin-top: 0px !important;
    font-size: 18px !important;
    border-radius: 4px !important;
    font-family: "Segoe-Semibold" !important;
}