.trackCandidateContainer {
    margin-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
}

.trackCand_UpperCard{
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 5px 0px;
    border:1px solid #1a8fff;
}

.trackCand_searchFieldsContainer{
    display: flex;
    padding: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    gap: 20px;

}

.trackCandidate_searchHeading{
    margin-bottom: 10px;
    font-size: 18px;
}

.trackCandidate_searchInput{
    width: 20vh;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    padding: 5px;
}

.trackCand_searchFieldsContainer p{
    color: #8d8d8d;
}

.trackCand_SearchBtn{
    display: flex;
    flex-direction: row;
    margin-right: 2%;
    height: 5.5vh;
}


.trackCand_LowerCard{
   
    height: 50vh;
    margin-bottom: 1% ; 
}



.trackCand_TrackFlowTitle{
    font-size: 20px;
    margin-top: 20px;
    background-color: rgb(40, 150, 255);
    padding: 15px;
    justify-content: center;
    display: flex;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: white;
    border-radius: 5px 5px 0px 0px;
    gap: 10px;
}

.trackCand_TrackFlowDiagram{
    height: 50vh;
    margin-bottom: 1%;
}
.trackCand_TrackFlowDiagram .bi.bi-diagram{
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 0px solid #d3d3d3;
    height: 200vh;
}

.mainContainer_trackCandidate{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.container_trackCandidate{
    margin-top: -10px;
}
