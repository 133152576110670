.user_pop_button .MuiDialogActions-root {
    padding: 20px 24px 22px 24px;
    align-items: end;
}

.user_pop_button.content-scrollable .MuiDialogActions-root {
    padding: 20px 42px 22px 24px;
}

.user_table td {
    padding: 0px 16px;
}

.user_table th {
    padding: 0px 16px;

}

.MuiTable-root thead tr th {
    font-weight: bold;

}

.edit_user_table .MuiSvgIcon-root {
    width: .8em;
    height: .8em;
}

.table_outer_wrapper {
    padding-bottom: 20px;
}

.user_table_dropdown_section {
    padding: 10px 0px !important;
}

.userlist_dailog_content {
    padding: 0px;
}

.adduser-password-label {
    display: flex;
    flex-direction: column;
}

.adduser-password-label span:last-child {
    font-size: 12px;
}

@media(min-width: 1367px) {
    .user_pop_button.content-scrollable .MuiDialogActions-root {
        padding: 20px 24px 22px 24px;
    }
}

.admin_subskill_topsection.manage-user-table-wrapper {
    margin-bottom: 1.5rem;
}


.users_top_section_wrapper {
    display: flex;
    justify-content: space-between;
}

@media (min-width: 1280px) {
    .allignmentFix {
        max-width: none;
        margin-top: 20px;
    }
}

.mt-110 {
    margin-top: 110px;
}

.userlist_mainContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    height: 85vh;
}

.userlist_heading {
    font-size: 30px;
    color: #01313c;
    font-weight: 600;
    font-family: "Segoe-Semibold";
    font-size: 24px;
    padding: 5px 3px;
    color: #2896ff;
    border-bottom: 1px solid #2896ff;
    width: 72px;
    margin-bottom: 10px;
}

/* .userlist_content_container {
    display: flex;
    flex-direction: row;
} */

.userlist_content_container{
    display: flex;
    width: 100%;
}

.userlist_leftside_mainContainer {
    width: 400px;
    /* min-height: 1100px; */
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}

.userlist_rightside_mainContainer{
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    /* border-radius: 20px; */
    width: calc(100% - 410px);
    /* width: 1300px; */
    margin-left: 25px;
}

.userlist_topSection{
    padding: 15px;
    padding-left: 10px;
    background-color: #D1E8FF;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.userlist_showingRF {
    font-family: 'Segoe UI';
    font-size: 16px;
    color: #04093F;
}

.userlist_showMyCandidateContainer {
    margin-top: 10px;
}

.userlist_booleanText {
    font-size: 14px;
    font-family: "Segoe UI";
    opacity: 0.7;
    color: #43425D;
}

.userlist_bottomSection {
    padding: 15px;
    padding-top: 0px;
}

.inputItemsContainer {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}

.userlist_leftside_content {
    margin-bottom: 20px;
}

.userlist_title {

    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.userlist_kendostyle{
    height: 65vh;
    width: calc(100% - 20px);
}

.userlist_kendogridContainer {
    padding: 0px 10px 10px 10px;
    width: 100%
}
/* 
.phone_input_userlist {
    max-width: 94.5% !important;
    max-height: 45px !important;
} */

.phone_input_userlist .PhoneInputInput {
    padding: 10px;
    border: none;
    font-size: 16px;
}

.textbox_phone_input_userlist {
    height: 46px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}

.phone_input_userlist .PhoneInputInput {
    border: none;
    outline: none;

}

.leftSideMainContainer_user{
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 502px;
}

.rightSideMainContainer_user{
    width: calc(100% - 410px);
}