.footer_bg {
  width: 100%;
  background-color: #04093F;
  margin-top: 20px;
  }
  
  .footer_bg_sec {
  width: 25%;
  }
  
  .footer_links {
  margin-bottom: 0px;
  padding-left: 0px;
  margin-top: 34px;
  
  }
  
  .footer_links li {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 40px;
  }
  
  .footer_links li:last-child {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px 0px 40px;
  }
  
  .footer_links li a {
  color: #D1E8FF;
  text-decoration: none;
  font-size: 16px;
  
  font-family: 'Segoe-Regular';
  }
  
  
  
  .footer_links li a:hover::after {
  opacity: 1;
  }
  
  .footer-text-color {
  color: #D1E8FF;
  }
  
  .footer-copyright {
  opacity: .4;
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
  }

.Colorcss {
  color: #d1e8ff;
  text-decoration: none;
  font-size: 16px;
  font-family: "Segoe-Regular";
}
.footerlogo_adjust{
  margin-left: 0px;
}
.footer_adjust {
  display: flex;
  justify-content: space-between;
  }
  
  
  .new4 {
  border-top: 1px solid #d1e8ff2b;
  }
  .footerlogo{
    width: 100%;
  }
  .footerlogo_section{
    width: 25%;
  }
  .poweresume-footer {
    font-size: 1.0625em;
    color: #D1E8FF;
    font-family:'Segoe-Regular';
    text-decoration: underline;
  }
  .poweresume-footer-copyright {
    font-size: 1.0625em;
    font-family:'Segoe-Regular';
    color: #D1E8FF;
  }
/*------------- COMMON CSS COMPONENT ---------------*/

@media only screen and (max-width: 768px) {
  .footerlogo_section{
    width: 100%;
    margin-left: 0rem;
  }
  .footer_bg_sec {
    width: 100%;
    padding-left: 20px;
  }

  .footer_bg {
    width: 100%;
    background-color: #04093f;
    margin-top: 20px;
    /* padding: 20px; */

  }
  .footer_links {
    margin-bottom: 0px;
    padding-left: 0px;
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
  }
  .footer-copyright {
    opacity: 0.4;
    padding-top: 15px;
    padding-bottom: 15px;
    
  }
  .footerlogo_adjust{
    margin-left: 20px;
  }
  .footer_links li {
    list-style-type: none;
    line-height: 40px;
    display: inline;
    padding: 0px;
    

    }
    
    .footer_links li:last-child {
    list-style-type: none;
    line-height: 40px;
    display: inline;
    padding: 0px;
    }
    .footer_adjust {
      display: contents;
      justify-content: unset;
  }
}
