.addBtnDivStyle {
    width: 100%;
    text-align: end;
  }
  
  .addBtnStyle {
    color: "#fff" !important;
    background-color: "#2AD156" !important;
    border: "none" !important;
    padding: "7px 23px 7px 23px" !important;
  }

  .optionsStyle{
    margin-bottom: 10px;
  }

  .skillLocation{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .skilLocationContainer{
    padding: 0px 20px 0px 0px;
  }

  .locationCss{
    margin-left: 10px;
  }

  .addButton{
    text-align: end;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .closeBtnStyle{
    position: absolute;
    right: -27px;
    cursor: pointer;
  }

  .locationCloseBtnStyle{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
  }

  .locationField{
    width: 100%;
  }

  .addButton .MuiSvgIcon-root.addBtn{
    color: #1a8fff;
    width: 1.4em !important;
    height: 1.4em !important;
  }