.firstCard {
    width: 125px;
}

.cardBlock {
    /* border: 1px solid black; */
    padding: 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 3px 8px #0000003d;
}

.f_card_heading {
    display: flex;
    justify-content: left;
    align-items: center;
}

.colorBox {
    background-color: #3ca4e5;
    width: 10px;
    height: 25px;
    margin-right: 10px;
}

.actionsCardHeading {
    font-family: "Segoe-bold";
    font-weight: bold;
    color: #4D4F5C;
    font-size: 22px;
}

.f_card_content {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
}

.f_card_content_inner_div {
    text-align: center;
    width: 130px;
    cursor: pointer;
    margin: 25px 5px;
}

.f_card_content_inner_div:hover {
    opacity: 0.6;
}
.iconLegendStyle {
    font-family: 'Segoe-Semibold';
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #075482;
    text-align: center;
    margin-top: 10px;

}
.candidate-history-icon{
    font-size: 45px !important;
    color: #3ca4e5;
}

@media screen and (min-width: 1290px) {
    .firstCard {
        width: 300px
    }
}