.actionbutton{
    display:flex;
    gap:0.5rem;
}
.tableGrid {
    grid-template-columns: 80% 20%;
    grid-gap: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;
}
