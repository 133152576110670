.skeleton_container {
    margin: 80px 0px;
    height: 70vh;
}

.skeleton_items_first {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.skeleton_items_second {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 5px;
}

.skeleton_items_1 {
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skeleton_items_2 {
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skeleton_width {
    width: 500px; 
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .skeleton_container {
        margin: 50px 0px 80px;
        height: 100vh;
    }

    .skeleton_width {
        width: 400px; 
    }

    .skeleton_items_second {
        margin-top: 30px; 
    }

    .skeleton_items_2 {
        margin-top: 20px;
    }
}

/* Medium devices (landscape tablets, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 900px) {
    .skeleton_container {
        margin: 100px 0px 300px;
        height: 70vh;
    }

    .skeleton_items_2 {   
        margin-top: 20px;  
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Large devices (laptops/desktops, 900px and up) */
@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .skeleton_container {
        margin: 100px 0px 80px;
        height: 70vh;
    }  

    .skeleton_items_second {
        margin-top: 50px;   
        flex-wrap: nowrap;
        gap: 35px;
    }

    .skeleton_items_2 {
        gap: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .skeleton_width {
        width: 400px; 
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .skeleton_container {
        margin: 120px 0px 80px; 
        height: 70vh;
    }
  
    .skeleton_items_second {
        margin-top: 80px;
        flex-wrap: nowrap;      
    }
    .skeleton_width{
        width: 600px;
    }
}