@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.ats-dash-nav-container{
    background: rgb(24,90,153);
    background: linear-gradient(360deg, rgba(24,90,153,1) 0%, rgba(32,118,201,1) 25%, rgb(40, 150, 254) 100%);
    height:81% ;
    position:fixed;
    width: 12.5%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    overflow: auto;
}

.ats-dash-nav-container .MuiListItem-button:hover{
    background-color: transparent;
}

/* Custom scrollbar */
.ats-dash-nav-container::-webkit-scrollbar {
    width: 10px;
}

.ats-dash-nav-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2); /* Light track for visibility */
    border-radius: 10px;
}

.ats-dash-nav-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.8); /* Light thumb for contrast */
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.ats-dash-nav-container::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 1); /* Brighter on hover */
}

.ats-dash-nav-container::-webkit-scrollbar-corner {
    background: transparent;
}

.ats-dash-navTitle{
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.3;
}

.ats-dash-navItem{
    color: white;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.3;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    padding: 7px 10px;

}

.ats-dash-navItem-selected{
    background-color: white;
    color: black;
    border-radius: 5px;
}


@media only screen and (max-width: 1550px){
    .ats-dash-nav-container{
        height: 75%;
    }
    .ats-dash-navItem{
        font-size: 12px;
        padding: 2px 10px;
    }
    .ats-dash-navTitle{
        margin-bottom: 5px;
    }
}


@media only screen and (max-width: 1380px){
    .ats-dash-nav-container{
        height: 73%;
    }
    .ats-dash-navItem{
        font-size: 10px;
        padding: 2px 10px;
        margin-bottom: 12px;
    }
    .ats-dash-navTitle{
        margin-bottom: 5px;
        font-size: 17px;
    }
}


@media only screen and (max-width: 1280px){
    .ats-dash-nav-container{
        height: 72%;
    }
    .ats-dash-navItem{
        font-size: 9px;
        padding: 2px 10px;
        margin-bottom: 8px;
    }
    .ats-dash-navTitle{
        margin-bottom: 5px;
        font-size: 15px;
    }
}