.admin_skill_topsection{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.admin_skill_leftsection{
    width: 30%;
    margin-left: 10px;
}
.admin_skill_rightsection{
    margin-bottom: 10px;
    margin-right: 10px;
}
.btnGreen {
    color: '#fff'!important;
    background-color: '#2AD156'!important;
    border: 'none'!important;
    padding: '7px 24px 9px 23px'!important;
    float: 'right'!important;
    top:"20px"!important
}

.tabs{
    display: 'block'
}