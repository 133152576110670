.footerContainer_inc{
    margin-top: auto;
}
.browserLinks{
    font-size: 15px;
    font-family: 'Manrope-SemiBold';
    color: #4998EF;
    text-decoration: none;
    cursor: pointer;
    margin-top: 3px;
}
.browserLinks:hover{
    opacity: 0.8;
    text-decoration: underline;
}
.MainContainer_INC{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #ffffff;
}
.containerTop_INC{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.mainDiv_top{
    margin: 0 11%;
    margin-top: 20vh;
    margin-bottom: 70px;
}
.topHeading_inc{
    font-size: 2rem;
    font-family: 'Manrope-Semibold'
}
.browserContainer_INC{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-top: 20px;
}
@media (max-width:600px){
    .topHeading_inc{
        font-size: 24px;
    }
}