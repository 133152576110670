.termsofuse {
    text-align: center !important;
}

.Prohibited_ul li {
    list-style-type: none;
    font-size: 22px;
    font-family: "Segoe-Regular";
    padding: 5px 0px;
}

.goToclrTalent {
    color: #1A8FFF;
    text-decoration: underline;
}

.container.terms-of-use-container {
    padding-top: 6em;
}

.text-left {
    text-align: left;
}