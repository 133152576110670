.searchrec_subheader {
    height: 46px;
    /* margin-bottom: 0px; */
    padding: 15px;
    padding-left: 10px;
    background-color: #D1E8FF;
}

.searchrec_color {
    color: #ffff;


}

.activity_autocom {
    width: 96%;
    padding:2%;
}

.ListIco {
    height: 40px !important;
    width: 40px !important;
}

.searchreq_autoco {
    width: 23vh;
}

.searchreq_calender {
    padding-top: 10px;
}

/* .talentCommunityMainContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 2px 2px !important;
} */

.seareq_button {
    background-color: #009EFD;
    width: 172px;
    height: 39px;
    border-radius: 8px;
    color: #ffff;
    font-size: 16px;
    font-family: "Segoe-Semibold";
    box-shadow: none;
    border: none;

}

.seareq_image {
    height: 18px;
    width: 18px;
    /* display: flex; */
    justify-content: center;
    text-align: center;
    align-items: center;
}

.searec_leftcont {
    width: 400px;
    height: 800px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}


.searec_employementContainer {
    display: flex;
    width: 95%;
    gap: 10px;
}

.searec_employmentItems {
    margin-top: 15px;
    width: 100%;
}


.activity_date .react-datepicker__input-container .react-datepicker__calendar-icon {
    margin-top: 12px;
    margin-left: 90%;
}

.activity_date {
    width: 95%;
    /* height: 6vh; */
}

.activity_date_border {
    /* border:solid .5px; */
    border: 1px solid rgb(192, 192, 192);
    /* width: 10vh; */
    height: 40px;
    /* box-shadow: none; */
}

.mainDiv_advanceSea {
    /* margin-top: 120px; */
    margin-left: 5%;
    margin-right: 5%;
}

.interview_mainCont {
    box-shadow: 0px 0px 10px 0px #00000040;
    border-radius: 8px;

}

.activity_dialog {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    font-family: "Segoe-Semibold";
    color: #ffff;


}


.activity_date .react-datepicker-wrapper {
    display: inline;
}


.activity_topCon {
    display: flex;
    gap: 10px;
    padding-left: 4vh;
    padding-top: 10px;
    padding-bottom: 20px;
    /* padding-right: ; */
}

.activity_topCon .pt_20 {
    padding-top: 20px;
}

.activity_rightCon {
    margin-left: 20px;
    width: 300px;
}

.activity_footer {
    margin-top: 4vh;
}

.activity_support_line {
    border-bottom: 1px solid #6398F1;
    width: 200px;
    font-family: 'Manrope-Medium';
    font-size: 36px;
    color: #1885E6;
    height: 58px;
}

.btn_activity_cancel {
    width: 107px;
    height: 39px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #ffff;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.btn_activity_delete {
    width: 107px;
    height: 39px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #da1e28;
    border: 1px solid #da1e28;
    background-color: #ffff;
    /* background-color: #da1e28; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /* margin-right: 40px; */

}

.btn_activity_save {
    width: 107px;
    height: 39px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #ffff;
    background-color: #31B3F8;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

}

.activity_textarea {
    font-size: 14px;
    font-family: Segoe UI;
}

.activit_dialog_header {
    display: flex;
    align-items: center;

}

.pd_top_10 {
    padding-top: 10px;
}

.KendoContainer_Activity {
    width: calc(100% - 320px);
        /* min-height: 500px; */
}

.kendo_Activity {
    min-height: 695px;
    /* max-height: 695px ; */
    /* height:695px */
}

.KendoContainer_Activity .k-grid-container {
    overflow-y: scroll;
}

.activity_na {
    text-align: center;
}
.editButtonAcitivity{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 107px;
    height: 39px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    gap:5%;
    color: #ffff;
    background-color: #31B3F8;
}
.export_recSelect{
   display: flex;
   gap:29%;
   margin-left: -120px;
}
.selectrec{
    background-color: #31B3F8;
    padding: 12px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Segoe-semibold';
    font-size: 14px;
    width: 170px;
    font-weight:bolder;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}