.recentActivities_Container{
    margin-top: 20px;
    padding: 8px 10px;
    background-color: #B0DAF130;
    border-radius: 5px;
}
.activities_cont
{  
    /* max-width: 86%; */
    width: 96%;
}

.recentActivities_heading{
    font-size: 20px;
    font-family: 'Segoe-Semibold', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0px 0px 5px 0px;
    color: #009EFD;
}

.recentActivities_status{
    display: flex;
    background: #c8e9fd;
    padding: 12px;
    border-radius: 10px;
    font-size: 15px;
    font-family: 'Segoe-Semibold', Tahoma, Geneva, Verdana, sans-serif;
    justify-content: space-between;
    margin-bottom: 7px;
    align-items: center;
    cursor: pointer;
}

.recentActivities_status:hover{
    background: #a6d3eb;
    box-shadow: 0px 0px 3px 0px #009EFD;

}

.recentActivities_status_bottom{
    display: flex;
    flex-direction: row-reverse;
}

.recentActivities_ViewAllCandidates{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color: #009EFD;
    text-decoration: underline;
    font-family: 'Segoe-Semibold', Tahoma, Geneva, Verdana, sans-serif;
    cursor: pointer;
}

.recentActivities_statusValue{
    font-size: 22px;
}

.recentActivities_BtnGroup{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.header {
    /* width: 947px; */
    height: 33px;
   
    border-radius: 8px, 8px, 0px, 0px;
    color: #FFFFFF !important;
    background: linear-gradient(90deg, rgba(49,179,248,1) 0%, rgba(29,105,146,1) 100%);
    padding: 17px 33px !important;
    display: flex;
    align-items: center;

}
.add_btn_assign {
    margin-right: 10px;
    margin-top: 36px;
}


.pad-adjust {
    padding: 10px 12px !important;
}

.input-text-3 .MuiInputBase-input {
    height: 13px;
}

.input-text input[type=text] {
    height: 50px;
}

.input-text-other input[type=text] {
    height: 50px;
}

.input-text .MuiInputBase-input {
    height: 11px;
}


.MuiDialog-paperWidthSm {
    max-width: 814px!important;
}

.input-text {
    width: 105px;
}



.modal-width {
    max-width: 800px !important;
}


/* .input-text {
    width: 150px;
    height: 50px !important;
    border-radius: 8px;
    border: 1px solid #999999;
    background-color: white !important;
} */

.save-btn {
    width: 107px;
    height: 39px;
  
    border-radius: 8px;
    background: #009EFD;
    color: #FFFFFF;
    border: none;
    font-family: "Segoe-Semibold";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;

}

.cancel-btn {
    width: 107px;
    height: 39px;
  
    border-radius: 8px;
    background: #5E5E5F;
    color: #FFFFFF;
    border: none;
    font-family: "Segoe-Semibold";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.header_top {
    display: flex;
    align-items: center;
    color: white;
    font-size: 22px;
    font-family: "Segoe-Semibold";

}

.header_top img {
    width: 40px;
    padding-right: 10px;
}

.close_icon_right {
    margin-top: 30px;
}