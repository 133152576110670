.mainContainer_videoSkillTest {
    min-height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.testNameHeader {
    font-family: 'Manrope-Semibold';
    font-size: 35px;
    color: #04093F,
}

.mainDiv_videoSkillTest {
    margin: 0 11%;
    margin-top: 130px;
}

.testInfoContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.timeLeft {
    font-family: 'Manrope-Bold';
    font-size: 14px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.timeleftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressBarContainer {
    width: 260px;
}

.progressBarContainer .MuiLinearProgress-barColorPrimary {
    background-color: #1885E6 !important;
    border-radius: 10px;
}

.progressLabel_allignment_center {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progressLabel_allignment_start {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.progressLabel_allignment_end {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.questionContainer {
    display: flex;
    margin-left: 30px;
    margin-top: 30px;
}

.line_question {
    height: 76px;
    border-left: 5px solid #1885E6;
}

.questionNoContainer {
    height: 76px;
    border-left: 5px solid #1885E6;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.questionNo {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: #1885E6;
    color: #ffffff;
    font-family: 'Manrope-Semibold';
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionName {
    font-family: 'Manrope-Regular';
    font-size: 17px;
    color: #04093F;

}

.questionNameContainer {
    height: 100%;
    width: 100%;
    padding-right: 2px;
    padding-top: 10px;
}

@media (max-width: 600px) {
    .questionNameContainer {
        margin-left: 12px;
    }

    .testNameHeader {
        font-size: 18px;
    }

}

@media (max-width: 1100px) {
    .testInfoContainer {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .testNameHeader {
        font-size: 25px;
    }

    .referral {
        font-size: 18px !important;
        cursor: pointer;
    }
}


.answerHeading {
    margin-top: 17px;
    height: 76px;
    display: flex;
    align-items: center;
    font-family: 'Manrope-bold';
    font-size: 17px;
    color: #04093F;
}

.video_Container_Grid {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 280px;
}

.video_camera {
    height: 160px;
    width: 220px;
    background-color: #707070;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    position: absolute;
    z-index: 10000;
    cursor: move;
    margin-top: -160px;
    margin-right: 20px;
}

.topDiv_video {
    z-index: 10000;
    cursor: move;
}

.bottomDiv_video {
    background-color: #D1E8FF;
    height: 33px;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.micIcon {
    margin-left: 5px;
}

.bottomContainer_quesCard {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.bottomContainer_quesCard:hover {
    opacity: 0.8;
}

.cardContainer_review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

.text_area_skillTest {
    font-size: 16px;
    font-family: "Segoe-Regular";
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
    width: 98%;
}

.SubmitButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;
}

#backProgress .MuiLinearProgress-barColorPrimary {
    background-color: #1885E6 !important;
    opacity: 0.25 !important;
    border-radius: 10px;
}

.tleft {
    margin-right: 2px;
}

.container_skillTest {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    padding-top: 4px;
    cursor: pointer;
    font-size: 17px;
    font-family: 'Source sans pro', sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container_skillTest input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.container_skillTest .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #D7E7FF;
    border-radius: 5px;
    opacity: 0.8;
}

.container_skillTest:hover input~.checkmark {
    opacity: 1;
    border: 2px solid #1885E6;
}

.container_skillTest input:checked~.checkmark {
    background-color: #2196F3;
    border: none;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.container_skillTest input:checked~.checkmark:after {
    display: block;
}

.container_skillTest .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container_radio_skillTest {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    padding-top: 4px;
    cursor: pointer;
    font-size: 17px;
    font-family: 'Source sans pro', sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container_radio_skillTest input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.container_radio_skillTest .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #D7E7FF;
    border-radius: 5px;
    border-radius: 50%;
    opacity: 0.8;
}

.container_radio_skillTest:hover input~.checkmark {
    opacity: 1;
    border: 2px solid #1885E6;
}

.container_radio_skillTest input:checked~.checkmark {
    background-color: #2196F3;
    border: none;
}

.container_radio_skillTest:after {
    content: "";
    position: absolute;
    display: none;
}

.container_radio_skillTest input:checked~.checkmark:after {
    display: block;
}

.container_radio_skillTest .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.timeCount {
    font-family: 'Manrope-Semibold';
    font-size: 35px;
    margin-top: -7px;
}

.referral {
    margin-top: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    font-size: 22px;
    letter-spacing: 1px;
    animation-name: referal_link;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    margin: 40px 0px;
    text-align: center;
    text-shadow: 1px 1px 0 hsl(164deg 18% 20%);
    font-family: 'Manrope-Semibold';
    cursor: pointer;
}

@keyframes referal_link {
    from {
        color: orangered;
    }

    to {
        color: blue;
    }
}

.registered_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.successImagess {
    height: 100px;
    width: 100px;
    margin-bottom: 50px;
}

.containerfor_circularPrg {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
}

.containerfor_circul {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
}

.mainContainer_Test{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}
.testHeader_Test{
    margin: 0 11%;
    margin-top: 120px;
}
.testBody_test{
    margin: 0 11%;
    margin-top: 10px;
    height: 66vh;
    margin-bottom: 10px;
}
.mainTestContainer{
    height: 88%;
    overflow:auto;
}
.nextButtonContainer{
    height: 12%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    margin-left: 20px;
    border-top: 1px solid #DCF4FF;
}
.videoScreen_main{
    height: 16vh;
    cursor: move;
    position: fixed;
    background-color: #707070;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    left:58vw;
    top:0;
    z-index: 10000;
}
.bottomDiv_video {
    background-color: #D1E8FF;
    height: 3vh;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottomDiv_video .MuiSvgIcon-root{
    height: 3vh;
}
@media (max-width: 850px) {
    .videoScreen_main{
        height: 100px;
        width: 111px;
        left: 70vw;
    }
    .bottomDiv_video{
        height: 20px;
    }
}