.secondCard {
  width: calc(100% - 450px);
  margin-bottom: 10px;
}
@media screen and (min-width: 1290px) {
  .secondCard {
    width: calc(100% - 630px);
    margin-bottom: 10px;
  }
}
.secondCard_searchRequisition {
  width: calc(100% - 420px);
  margin-bottom: 10px;
  height: 790px;
}

.cardBlock {
  padding: 10px 0px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #0000003d;
}

.secCard_header {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.sec_req_cards {
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  font-family: 'Segoe-Semibold';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #075482;
}


.secCard_header_components {
  margin-right: 10px;
  margin-top: 5px;
}

.searchBG {
  background-color: #e6e6e6 !important;
  border-radius: 10px !important;
}

.filterNav {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.filterBtnStyle_selected {
  box-shadow: 0px 3px 8px #0000003d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #009EFD;
  color: white;
  border-radius: 8px;
  border: 0px;
  padding: 8px 1px;
  width: 160px;
  cursor: pointer;
}

.filterBtnStyle {
  box-shadow: 0px 3px 8px #0000003d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #74c1f1;
  color: white;
  border-radius: 8px;
  border: 0px;
  padding: 8px 1px;
  width: 160px;
  cursor: pointer;
}

.filterBtnStyle:hover {
  background-color: #31B3F8;
  color: white;
  cursor: pointer;
}

.filterTexts {
  font-family: 'Segoe-Regular';
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin-left: 20px;
  letter-spacing: 0.05em;
}

.filterIcon {
  margin-right: 10px;
}

.sec_card_content {
  /* padding: 0px 20px 20px 20px; */
  padding-left: 20px;
}
.sec_card_content_searchRequisition{
  padding-left: 20px;
  height: 780px;
}

.sec_card_req_container {
  height: 66vh;
  overflow-y: auto;
}
.sec_card_req_container_searchRequisition {
  height: 780px;
  overflow-y: auto;
}

.sec_card_req_container::-webkit-scrollbar {
  width: 10px;
}

.sec_card_req_container::-webkit-scrollbar-thumb {
  background-color: #4ab6fa;
  ;
  border: 1px solid #c8c8c8;
  border-radius: 20px;
  box-shadow: 0 0 6px #c8c8c8;
}

.sec_card_req_container::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #c8c8c8 !important;
}
.sec_card_req_container_searchRequisition::-webkit-scrollbar {
  width: 10px;
}

.sec_card_req_container_searchRequisition::-webkit-scrollbar-thumb {
  background-color: #4ab6fa;
  ;
  border: 1px solid #c8c8c8;
  border-radius: 20px;
  box-shadow: 0 0 6px #c8c8c8;
}

.sec_card_req_container_searchRequisition::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #c8c8c8 !important;
}


.req_card {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.req_card:hover {
  background-color: #e9e6e6;
  cursor: pointer;
}

.greenCard {
  border: 2px solid #11BB55;
}

.redCard {
  border: 2px solid #F13A3A;
}

.blueCard {
  border: 2px solid #009EFD;
}

.reqCardContainer {
  /* width: 35%; */
}

.card_left_text {
  margin-bottom: 5px;
  display: flex;
}

.secCard_label {
  font-family: 'Segoe-Semibold';
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  min-width: 120px;

}

.valuesFields {
  font-family: 'Segoe-semibold';
  font-size: 15px;
  text-align: left;
  color: #6E6D6D;
  word-break: break-word;
}

.card_right_part {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.priorityDiv {
  border-radius: 8px;
  padding: 8px 8px;
  color: white;
  width: 120px;
  font-family: 'Segoe-Regular';
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.greenPriorityDiv {
  background-color: #00bf63;
}

.redPriorityDiv {
  background-color: #F13A3A;
}

.bluePriorityDiv {
  background-color: #009EFD;
}

.personName {
  display: flex;
  font-family: 'Segoe-Regular';
  font-size: 15px;
  text-align: left;
}

.contact_label {
  font-family: 'Segoe-Semibold';
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  min-width: 60px;
}

.searchbar_requisition {
  width: 300px;
}

.secCard_header .MuiInputBase-root {
  background-color: #ECEEEF !important;
  font-family: 'Segoe-Regular' !important;
  opacity: 0.6;
  border: 1px solid #ECEEEF !important;
  outline-offset: 0ch;
}

.secCard_header .MuiOutlinedInput-adornedEnd {
  padding-right: 10px !important;
}

.secCard_header .Mui-focused {
  opacity: 1 !important;
}

.secCard_header .searchIcon_searchBar {
  color: #075482 !important;
}

.skeletonStyle {
  margin-bottom: 15px;
  border-radius: 8px;
}

.loadingDiv {
  text-align: center;
}

.noRecStyle {
  text-align: center;
  padding: 25px 0px;
  font-family: 'Segoe-Regular';
  font-size: 15px;
  font-weight: 600;
}

.noDataFound {
  font-family: 'Segoe-Regular';
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #6E6D6D;
  height: 55vh;
}