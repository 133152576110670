html {
    height: 100%;
}

body {
    margin: 0px;
    padding: 0px;
    /* background-color: #fff; */
    /* height: 100%; */
}

#root {
    /* height: 100%; */
}

.mainbg_form {
    background-color: #fff;
    min-height: 720px;
    /* height: auto; */
    height: 100%;
}

.mainbg_form_recruiter {
    background-color: #fff;
    /* min-height: 720px; */
    /* height: auto; */
    height: 100%;
}

.formlogo_adjust {
    width: 250px;
}

.PowerResume_Copy_form {
    vertical-align: super;
    font-size: 22px;
}

.leftSection {
    width: 50%;
    background-color: #04093F;
    position: relative;

}

.white_section_recruiter {
    background-color: white;
    height: 40px;
    width: 100%;
}

.rightSection {
    width: 50%;
    background-color: #fff;
    height: 100%;
}

.logo {
    width: 100%;
    text-align: center;
    margin-top: 100px;
}

.bgsec {
    width: 100%;
    text-align: center;

}

.dropdown_width_outer.formone-otpbtn {
    justify-content: initial;
}

.image_add {
    /* width: 225px; */
    margin-top: 10px;
    height: 100%;
    /* position: absolute;
    bottom: 0px;
    right: 19%;
    margin-left: -250px; */
}

.form_text_container {
    padding: 200px 50px 0px 200px;
}

.form_text_container_other {
    /* padding: 10px 100px 0px 100px; */
    padding: 0px;

}

.cp {
    cursor: pointer;
}

.profile_image {
    width: 93px;
    border-radius: 50%;
    height: 93px;
}

.image_addjust_inner {
    width: 93px;
    margin: 0px auto;
}

.text_box_cleartalent_form {
    width: 94%;
    /* height: 54px; */
    padding: 0px 0px;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 8px 12px;
    font-size: 16px;
    font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid #A3BCD5;
    border-radius: 4px;
}

input[type=text],
select {
    width: 100%;
    /* padding: 14px 20px !important; */

    display: inline-block;

    border-radius: 4px;
    box-sizing: border-box;
}





.MuiOutlinedInput-notchedOutline {
    border: 1px solid #292929;
}

.text_box_cleartalent_form1 {
    height: 48px;
    width: 94%;

    font-size: 14px;
    padding: 0 20px;
    font-size: 16px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;

}

input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.field_name {
    font-size: 16px;
    color: #04093F;
    font-family: 'Segoe-Regular';
    font-style: normal;

}

.text-right {
    float: right;
}

.progress_adjust {
    padding: 25px 0px 0px 0px;
    /* width: 597px; */
    margin: 0px auto;
    justify-content: center;
}

.margin-form-right {
    margin-top: 30px;
}

.hide-logo-formone-right {
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
    color: #000 !important;
    background-color: #EAEAEA !important;
    border-radius: 30px;
    width: 418px;
    height: 14px;
    margin-left: 20px;
    margin-top: 13px;
}

.w3-green,
.w3-hover-green:hover {
    color: #fff !important;
    background-color: #00CCBE !important;
    border-radius: 30px;
}


.w3-badge,
.w3-tag {
    background-color: #000;
    color: #04093F;
    display: inline-block;
    font-family: 'Segoe-Regular';
    padding: 8px 7px 8px 7px;
    text-align: center
}

.w3-green1,
.w3-hover-green1:hover {
    color: #04093F !important;
    background-color: #FFD9D9 !important;

}

.w3-badge {
    border-radius: 6px;
}

.camera_icon_form {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 0px;
    top: 0;
}

p {
    margin: 0px;
}

@font-face {
    font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('fonts/Segoe-Regular'), url('../../resources/fonts/SegoePro-Regular.ttf') format('woff');
}

@font-face {
    font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    src: local('fonts/SegoePro-Bold'), url('../../resources/fonts/SegoePro-Semibold.ttf') format('woff');
}

@font-face {
    font-family: 'Segoe-Bold';
    font-style: normal;
    font-weight: 600;
    src: local('fonts/SegoePro-Bold'), url('../../resources/fonts/SegoePro-Bold.ttf') format('woff');
}





/*------------- BUTTON CSS COMPONENT ---------------*/



.btn-green_form {
    color: #fff;
    background-color: #00D0AD;
}

.btn-green_form:hover {
    color: #fff;
    background-color: #00D0AD;
}

.btn-green_form:focus {
    outline: none;
}


.btn-form {
    color: #01313C;

    background-color: #D1E8FF;
}

.btn-form:hover {
    color: #01313C;
    background-color: #D1E8FF;
}

.btn-form:focus {
    outline: none;
}

hr.new1 {
    border-top: 1px solid #A3BCD5;
}

/*------------- BUTTON CSS COMPONENT---------------*/


/*-------------------------------------------------------- Hints------------------------------------------------------------
mr= margin-right, ml= margin-left, mt = margin-top, mb= margin-bottom, pt= padding-top, pb= padding-bottom, pl= padding-left
pr=padding-right, fs= font-size, mw=min-width, fsi=font-style, pr=position-relative
-------------------------------------------------------- Hints------------------------------------------------------------*/


/*------------- COMMON CSS COMPONENT ---------------*/

.row {
    width: 100%;
}

.fl {
    float: left;
}

/* .card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    transition: all .2s ease-in-out;
    padding: 24px 20px 23px 20px;
    border-radius: 4px;
    background-color: #FFFFFF;
} */

.text-uppercase {
    text-transform: uppercase;
}

/* .f-color {
    color: #04093F;
} */

.fs-semi-bold {
    font-weight: 600;
    font-family: 'Segoe-Semibold';
}

.fs-bold {
    font-weight: 600;
    font-family: 'Segoe-Bold';
}

.fs-regular {
    font-weight: normal;
    font-family: 'Segoe-Regular';
}

.fs-22 {
    font-size: 22px;
}

.fs-20 {
    font-size: 20px;
}

.fs-18 {
    font-size: 18px;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.fs-12 {
    font-size: 12px;
}

.fs-25 {
    font-size: 25px;
}

.fs-16 {
    font-size: 16px;
}

.fs-40 {
    font-size: 40px;
}

.fsi {
    font-style: italic;
}

.w100 {
    width: 100%;
}

.fs-12 {
    font-size: 12px;
}

.mr_14 {
    margin-right: 14px;
}

.pr {
    position: relative;
}

.mt_19 {
    margin-top: 19px;
}

.mt_40 {
    margin-top: 40px;
}

.mt_23 {
    margin-top: 23px;
}

.mt_22 {
    margin-top: 22px;
}

.mt_41 {
    margin-top: 41px;
}

.mt_9 {
    margin-top: 9px;
}

.mt_8 {
    margin-top: 8px;
}

.df {
    display: flex;
}

.ml_25 {
    margin-left: 25px;
}

.ml_10 {
    margin-left: 10px;
}

.ml_22 {
    margin-left: 22px;
}

.mw {
    width: 50%;
}

.mt_40 {
    margin-top: 40px;
}

.mw_36 {
    width: 36%;
}

.pt_6 {
    padding-top: 6px;
}

.pt_23 {
    padding-top: 23px;
}

.pl_8 {
    padding-left: 8px;
}

.pt-24 {
    padding-top: 24px;
}

.mb_3 {
    margin-bottom: 3px;
}

.mb_20 {
    margin-bottom: 20px;
}

.w1004 {
    max-width: 1004px;
}


/*------------- COMMON CSS COMPONENT ---------------*/


/*------------- VIDEO INTERVIEW TOP ---------------*/

.playButton {
    width: 65px;
    height: 65px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.flex-video-top {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
}

.containerfor_circularprogress {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}


/*------------- VIDEO INTERVIEW TOP ---------------*/


/*------------- VIDEO INTERVIEW QUESTION ---------------*/

.flex-video-bottom {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
}

.videosection {
    width: 100%;
}

.vl {
    border-left: 1px solid #A3BCD5;
    margin-left: 20px;
    margin-top: 0px;
}


/*------------- VIDEO INTERVIEW QUESTION ---------------*/


/*------------- STEP CSS ---------------*/

.bs-stepper .step-trigger {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: none;
    border-radius: .25rem;
    transition: background-color .15s ease-out, color .15s ease-out;
}

.bs-stepper .step-trigger:focus {
    outline: none;
}

.bs-stepper-label {
    display: inline-block;
    margin: .25rem;
}

.bs-stepper-header {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.bs-stepper-line,
.bs-stepper .line {
    flex: 1 0 32px;
    min-width: 1px;
    min-height: 1px;
    margin: 9px;
    background-color: #A3BCD5;
}

.success_line {
    background-color: #2AD156;
}

.bs-stepper-circle {
    display: inline-flex;
    align-content: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    color: #adb5bd;
    border: 1px solid #A3BCD5;
    border-radius: 100%;
    line-height: 37px;
    font-size: 16px;
    background-color: #fff;
    font-weight: normal;
    font-family: 'Segoe-Regular';
    outline: none;
}

.active .bs-stepper-circle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    font-family: 'Segoe-Bold';
    font-size: 16px;
}

.success .bs-stepper-circle {
    color: #fff;
    background-color: #2AD156;
    border-color: #2AD156;
    font-family: 'Segoe-Bold';
    font-size: 16px;
}

.bs-stepper-content {
    padding: 0 20px 20px;
}

.bs-stepper.vertical {
    display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
    flex-direction: column;
    align-items: stretch;
    margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
    display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
    display: block;
    visibility: hidden;
}

.bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
    display: none;
}

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
    visibility: hidden;
    transition-duration: .3s;
    transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
    visibility: visible;
    opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
    display: block;
    visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
    display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
    display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
    visibility: hidden;
}


/*------------- STEP CSS ---------------*/


/*------------- RADIO BUTTON ---------------*/

.clear [type="radio"]:checked,
.clear [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.clear [type="radio"]:checked+label,
.clear [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 43px;
    cursor: pointer;
    line-height: 30px;
    display: inline-block;
    color: #01313C;
    margin-top: 25px;
}

.clear [type="radio"]:checked+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #1A8FFF;
    border-radius: 100%;
    background: #fff;
}

.clear [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #01313C;
    border-radius: 100%;
    background: #fff;
}

.clear [type="radio"]:checked+label:after,
.clear [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 16px;
    height: 16px;
    background: #1A8FFF;
    position: absolute;
    border: 1px solid #1A8FFF;
    top: 8px;
    left: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.clear [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.clear [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


/*------------- RADIO BUTTON ---------------*/


/*------------- CHECKBOX ---------------*/

.interview-chkbox {
    display: block;
    margin-bottom: 23px;
    margin-top: 23px;
}

.interview-chkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}


.interview-chkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #01313C;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 13px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 7px;
}

.interview-chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 11px;
    width: 7px;
    height: 12px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.interview-chkbox :checked+label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #1A8FFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 13px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 7px;
}

.link_wraper {
    display: flex;
    align-items: center;

}

.recruiter_signup_note {
    font-family: 'Segoe-Semibold';
    font-size: 11px;
    font-style: italic;
    color: #04093F;

    padding-bottom: 14px;
}


.recruiter_signup_form_adjust {
    padding-left: 0px !important;
}

.upload_text_recruiter {
    font-family: 'Segoe-Semibold';
    font-size: 14px;
    text-align: center;
    color: #01313C;

    padding-bottom: 14px;
}

.link_wraper_recruiter {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 17px;
}

.basicinfo_chkbox {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.formone_terms {
    text-decoration: underline;
    color: #2896ff;

}

/*------------- CHECKBOX ---------------*/


.interview-question-scroll::-webkit-scrollbar {
    width: 5px;
}

.interview-question-scroll::-webkit-scrollbar-track {
    /*background: #c6c6c6;
  width: 2px*/
}

.interview-question-scroll::-webkit-scrollbar-thumb {
    background-color: #A5A4A4;
    border-radius: 30px;
}




.interview-question-scroll1::-webkit-scrollbar {
    width: 5px;
}

.interview-question-scroll1::-webkit-scrollbar-track {
    /*background: #c6c6c6;
  width: 2px*/
}

.interview-question-scroll1::-webkit-scrollbar-thumb {
    background-color: #A5A4A4;
    border-radius: 30px;
}

/* 
.viewtestresults-questionside::-webkit-scrollbar {
    width: 5px;
}

.viewtestresults-questionside::-webkit-scrollbar-thumb {
    background-color: #A5A4A4;
    border-radius: 30px;
} */
.viewtestresults-questionside {
    width: 50%;
}

.next-button {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.individual-skill {
    /* border: 1px solid #D1E8FF; */
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 20px;
    margin-top: 10px;
}

.individual-skills {
    /* border: 1px solid #D1E8FF; */
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    /* padding: 10px; */
    margin-top: 10px;
}

.myskill-right {
    text-align: right;
}

.other_formpage {
    margin: 0px auto;
    width: 90%;

}

.notify-badge {
    position: absolute;
    /*right:-20px;*/
    top: 5px;
    margin-left: 9px;
    width: 12px;
    height: 12px;
    line-height: 12px;
    background: #2AD156;
    text-align: center;
    border-radius: 50%;
    color: white;
    font-size: 8px;
}

.mr_10 {
    margin-right: 10px;
}

.df_from1 {
    display: flex;

}

.pr_5 {
    padding-right: 5px;
}


@media only screen and (max-width: 768px) {

    .form_text_container {
        padding: 30px 50px 0px 20px;
    }

    /* .leftSection {
    width:100%;
    background-color: #04093F;
    position: relative;
    
}

.rightSection {
    width: 100%;
    background-color: #fff;
    height: 100%;
} */


    .df {
        display: flex;
        flex-wrap: wrap;
    }

    .progress_adjust {
        padding: 20px 20px 0px 20px;
        width: unset;
    }

    .w3-light-grey,
    .w3-hover-light-grey:hover,
    .w3-light-gray,
    .w3-hover-light-gray:hover {
        color: #000 !important;
        background-color: #EAEAEA !important;
        border-radius: 30px;
        width: 145px;
        height: 14px;
        margin-left: 10px;
        /* margin-top: 25px; */
    }

    .form_text_container_other>p {
        font-size: 25px;
    }

    .form_text_container_other {}

    .image_add {
        width: 251px;
        position: unset;
        bottom: 0px;
        right: 19%;
    }

    .logo {
        width: 100%;
        text-align: center;
        margin-top: 40px;
    }

    .camera_icon {
        position: absolute;
        width: 30px;
        height: 30px;
        bottom: 0;
        right: 108px;
        top: 0;
    }


    .bubble-1 {
        width: 89px;
        position: absolute;
        top: 50%;
        left: 0px;
        transition: top 2s;
        display: none;
    }

    .bubble-2 {
        width: 138px;
        position: absolute;
        top: 31%;
        right: -46px;
        transition: top 2s;
        display: none;
    }

    .bubble-3 {
        width: 89px;
        position: absolute;
        top: 400px;
        left: -38px;
        transition: top 2s;
        display: none;
    }

    .bubble-4 {
        width: 110px;
        position: absolute;
        top: 561px;
        right: -2rem;
        z-index: 99;
        transition: top 2s;
        display: none;
    }

    .bubble-5 {
        width: 138px;
        position: absolute;
        top: 45%;
        right: 207px;
        transition: left 2s;
        display: none;
    }

    .btn {
        display: inline-block;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;

        padding: 7px 10px 9px 10px;
        font-size: 16px;
        border-radius: 4px;
        font-family: 'Segoe-Semibold';
    }

    .ab {
        height: 923px;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding-top: 20px;
        margin: 10px;
        /* margin-left: 10px; */
    }

}

.ab {
    height: 881px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding-top: 20px;
    margin-bottom: 20px;
}

.bubble-1 {
    width: 89px;
    position: absolute;
    top: 50%;
    left: 0px;
    transition: left 2s;

}

.bubble-2 {
    width: 138px;
    position: absolute;
    top: 31%;
    right: -46px;
    transition: left 2s;
}

.bubble-3 {
    width: 89px;
    position: absolute;
    top: 400px;
    left: -38px;
    transition: top 2s;
}

.bubble-4 {
    width: 110px;
    position: absolute;
    top: 561px;
    /* right: 146px; */
    right: -2rem;
    z-index: 99;
    transition: top 2s;
}

.bubble-5 {
    width: 138px;
    position: absolute;
    top: 45%;
    right: 207px;
    transition: left 2s;
}

label#experience-label+div.MuiInputBase-root.MuiOutlinedInput-root.mt_8.MuiInputBase-formControl>div.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 12px 25px 12px 30px;
}

label#assignment-date+div.MuiFormControl-root.MuiTextField-root>div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl>input.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 12px 25px 12px 30px;
}


.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.flex-column {
    flex-direction: column;
}

.form-one-left {
    background: #000080;
}


.other_formpage_recruiter {
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.f-white {
    color: #fff;
}

.f-red {
    color: red;
}

.f-blue {
    color: #04093F;
}

.user_register_image_camera {
    position: absolute;
    top: 10px;
    right: 2px;
    width: 24px;
}


.user_register_image_setup {
    display: flex;
    justify-content: center;
}

.f_size_35 {
    font-size: 30px;
    font-family: 'Manrope-Bold';

}

.mt_35 {
    margin-top: 35px;
}

.signup_recruiter_dropdown .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.9);
}

button.verify-otp-btn_recruiter_signup:disabled {
    background: #1A8FFF;
    opacity: 0.5;
}

.text_box_cleartalent_form_recruiter {
    height: 48px;
    width: 100%;
    font-size: 14px;
    padding: 0 20px;
    font-size: 16px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
    border: 1px solid#292929;
    border-radius: 4px;
}



.term_text {
    font-family: 'Manrope-Bold' !important;
}

.vl_recruiter {
    border-right: 1px solid #A3BCD5;
}

.tick_mark_adjust {
    width: 30px;

    padding-top: 13px;
    padding-right: 10px;
}

.logo_start_color {
    color: #1A8FFF;
}

.btn_sign_up_recruiter {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 18px 112px 18px 110px;
    font-size: 18px;
    border-radius: 8px;
    font-family: 'Manrope-Bold';
}

.recruiter_signin .btn_sign_up_recruiter {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 18px 112px 18px 110px;
    font-size: 18px;
    border-radius: 8px;
    font-family: 'Manrope-Bold';
    box-shadow: none;
}

button:disabled,
button[disabled] {
    border: 1px solid #1a8fff6b;
    ;
    background-color: #1a8fff6b;
    opacity: 0.8;
    color: #fff;
}

.main-right button:disabled,
button[disabled] {
    border: 1px solid #1a8fff6b;
    border: none;
    background-color: #1a8fff6b;
    opacity: 0.8;
    color: #fff;
}

.btn_loader {
    display: flex;
}

.recruiter_signin .btn-blue_sign_up_recruiter {
    color: #fff;
    background-color: #1A8FFF;
}

.recruiter_profile_image_contaioner {
    position: relative;
    width: fit-content;
}

.recruiter_profile_image_contain {
    position: relative;
    width: fit-content;
    align-items: center;
}

.recruiter_profile_wrapper {
    display: flex;
    justify-content: center;
}

.camera_icon_recruiter {
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 0;
    right: 0px;
    top: 0;
}

.camera_icon_profile {
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 0;
    right: 0px;
    top: 35px;
    padding-left: 10px;
}

.btn_sign_up_recruiter1 {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 11px 9px 13px 9px;
    font-size: 18px;
    border-radius: 4px;
    font-family: 'Manrope-Bold';
}

.btn-blue_sign_up_recruiter {
    color: #fff;
    background-color: #1A8FFF;
}

.user_register_image {
    position: relative;
}


@media (min-width: 768px) and (max-width: 1366px) {
    .recruiter_sign_thankyou_wrapper_adjust {
        display: flex;
        justify-content: center;
        padding: 180px 0px 140px 0px;

    }

    .field_name_reccruiter {
        font-size: 12px;
        color: #04093F;
        font-family: 'Manrope-Regular';
        font-style: normal;
    }

    .header_container_width {
        max-width: 94%;
    }

    .form-one-left_recruiter {
        background: #fff;
        padding: 0px 11%;
    }

    .footer_recruiter_width {
        max-width: 93%;
    }

    .recruiter_signup_header {
        font-family: 'Manrope-Bold';
        font-size: 34px;
        color: #04093F;
        text-align: center;
        padding-bottom: 38px;
        padding-top: 108px;
    }

    .form_text_container_other .form-one-left-text {
        padding: 0px 11%;
    }

    .btn_sign_up_recruiter {
        display: inline-block;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        border: 1px solid transparent;
        padding: 18px 86px 18px 86px;
        font-size: 18px;
        border-radius: 8px;
        font-family: 'Manrope-Bold';
    }


}

.btn_sign_up_verify {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 18px 94px 18px 95px;
    font-size: 18px;
    border-radius: 8px;
    font-family: 'Manrope-Bold';
}

.resend_otp_section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.btn_resendotp {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 10px 26px 10px 26px;
    font-size: 18px;
    border-radius: 8px;
    font-family: 'Manrope-Bold';
}


@media (min-width: 1921px) and (max-width: 2560px) {

    .form_text_container_other .form-one-left-text {
        padding: 0px 10%;
    }

    .form-one-left_recruiter {
        background: #fff;
        padding: 0px 10%;
    }

    .recruiter_sign_thankyou_wrapper_adjust {
        display: flex;
        justify-content: center;
        padding: 355px 0px 280px 0px;
    }

}

@media (min-width: 1367px) and (max-width: 1920px) {
    .recruiter_sign_thankyou_wrapper_adjust {
        display: flex;
        justify-content: center;
        padding: 344px 0px 280px 0px;
    }

    .field_name_reccruiter {
        font-size: 14px;
        color: #04093F;
        font-family: 'Manrope-Regular';
        font-style: normal;
    }

    .form_text_container_other .form-one-left-text {
        padding: 0px 10%;
    }

    .form-one-left_recruiter {
        background: #fff;
        padding: 0px 10%;
    }

    .header_container_width {
        max-width: 95%;
    }

    .recruiter_signup_header {
        font-family: 'Manrope-Bold';
        font-size: 34px;
        color: #04093F;
        text-align: center;
        padding-bottom: 38px;
        padding-top: 108px;
    }

    .footer_recruiter_width {
        max-width: 95%;
    }

}


@media (min-width: 1921px) and (max-width: 2560px) {

    .recruiter_signup_header {
        font-family: 'Manrope-Bold';
        font-size: 34px;
        color: #04093F;
        text-align: center;
        padding-bottom: 38px;
        padding-top: 132px;
    }



}

@media (min-width: 2561px) {
    .recruiter_signup_header {
        font-family: 'Manrope-Bold';
        font-size: 34px;
        color: #04093F;
        text-align: center;
        padding-bottom: 38px;
        padding-top: 190px;
    }
}

@media (min-width: 981px) and (max-width: 1150px) {

    .w3-light-grey,
    .w3-hover-light-grey:hover,
    .w3-light-gray,
    .w3-hover-light-gray:hover {
        width: 300px;
    }

    /* .form_text_container_other {
    padding: 10px 30px 0px 30px;
} */
    .progress_adjust {
        padding: 20px 0px 0px 0px;
    }
}

@media (min-width: 769px) and (max-width: 980px) {

    .w3-light-grey,
    .w3-hover-light-grey:hover,
    .w3-light-gray,
    .w3-hover-light-gray:hover {
        width: 220px;
    }

    .form_text_container_other {}

    .progress_adjust {
        padding: 20px 0px 0px 10px;
    }
}

.form-one-footnote {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-style: italic;
    color: #01313C;
}

.form-one-wrapper .MuiGrid-item {
    position: relative;
}

@media(max-width: 599px) {
    .form-one-left-lower {
        position: inherit;
    }

    .form-one-left-lower img {
        width: 100%;
    }

    .form-one-footnote {
        font-size: 14px;
    }

    .other_formpage .customized-select {
        margin-bottom: 20px;
    }

    .other_formpage .dropdown_width_outer {
        margin-bottom: 0px;
    }

    .w3-light-grey,
    .w3-hover-light-grey:hover,
    .w3-light-gray,
    .w3-hover-light-gray:hover {
        width: 100%;
        margin-left: 0;
    }
}

.form-one-left-punch>p {
    font-size: 40px;
}

.form-one-left-text>p {
    font-size: 28px;
    padding-right: 2rem;
}

/* .form-one-left-punch, .form-one-left-text {
    padding-left: 3rem;
} */
.form-one-left-logo {
    padding: 2rem 0rem 2rem 3rem;
}

@media(max-width: 767px) {

    .form-one-left-punch>p {
        font-size: 30px;
    }

    .form-one-left-text>p {
        font-size: 25px;
        padding-right: 1rem;
    }

    .other_formpage {
        padding-bottom: 2rem;
    }

    .form-one-left-logo {
        padding: 1rem;
        width: auto;
    }

    .form-one-btn {
        margin-top: 0px;
        padding: 12px 12px;
    }
}

.form-one-left-lower img {
    width: 100%;
}

@media (min-width: 767px) and (max-width: 1400px) {
    .form-one-left-punch>p {
        font-size: 30px;
    }

    .form-one-left-text>p {
        font-size: 22px;
        padding-right: 2rem;
    }

    .form-one-left-section {
        /* height: calc(100vh - 6rem); */
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        margin-top: 0px !important;
    }

    .margin-form-right {
        margin-top: 0px;
    }

    .form-one-footnote {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .form_text_container_other>p {
        font-size: 30px;
    }

    .progress_adjust {
        padding: 20px 0px 0px 0px;
    }
}

.form_text_container_other input {
    font-size: 16px;
}


.bubble-4-two {
    width: 110px;
    position: absolute;
    top: 561px;
    right: 0;
    z-index: 99;
    transition: top 2s;
}

.bubble-3-two {
    width: 89px;
    position: absolute;
    top: 400px;
    left: 0;
    transition: top 2s;
}

.form-two-wrapper-header {
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;
}

/* .form-two-main-seciton form {
    padding: 0rem 2rem;
} */

/* @media (max-width: 980px) and (min-width: 600px) {
    .form-one-left {
        height: 100vh;
    }
    .form-one-left-lower {
        position: absolute;
        bottom: 0;
    }
} */
@media (min-width: 600px) {

    .form-two-main-seciton .MuiGrid-grid-sm-6 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-two-side-border {
        border-right: 1px solid #A3BCD5;
    }
}

.form_container-two {
    width: 100%;
}

.form-two-testimonial-btn {
    display: flex;
}

.form-two-all-button {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 1rem 0rem;
}

@media(min-width: 900px) {
    .form-two-main-seciton .MuiGrid-grid-sm-6 {
        padding: 0rem 3rem;
    }
}

.form-two-main-seciton {
    background: #fff;
}

@media(max-width: 899px) {
    .form-two-main-seciton .MuiGrid-grid-sm-6 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        padding: 0rem 2rem;
    }

    .form-two-side-border {
        border-right: none;
    }

    .form-two-all-button {
        justify-content: flex-start;
        padding: 0rem 2rem;
    }
}

.form-two-resume-btn {
    display: flex;
}

.form-one-wrapper_recruiter {
    /* margin-top: 32px; */
    margin-bottom: 30px;
}

.footer_links_recruiter {
    margin-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px !important;
}

.footer_bg_candidates_recruters {
    width: 100%;
    background-color: #C2C2C2;
    margin-bottom: 40px;
    margin-top: 40px;

}

.recruiter_header_logo_adjustment {
    width: 227px;
    padding: 20px 0px;
}

.nav_bg_left_recruiter {
    width: 25%;
    background-color: #fff;
}

/* @media(min-width: 981px) {
    .form-one-wrapper .MuiGrid-item {
        height: auto;
    }
    .form-one-left {
        height: 100%;
    }
} */
@media (min-width: 600px) {
    .form-one-left {
        height: 100%;
        min-height: 100vh;
    }

    .form-one-left-lower {
        display: flex;
        align-items: flex-end;
    }
}

.form-one-left {
    display: flex;
    flex-direction: column;
}

.form-one-left-upper,
.form-one-left-lower {
    flex: 1
}

@media (min-width: 1500px) {

    .form-one-wrapper label,
    .form-two-main-seciton label {
        font-size: 20px;
    }
}

.form-two-testimonial-delete .MuiDialogActions-root,
.form-two-resume-delete .MuiDialogActions-root {
    justify-content: center;
}

.remove-snackbar-alert .MuiAlert-message {
    font-family: 'Segoe-Regular';
}

.form-one-left-section {
    /* height: calc(100vh - 6rem); */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-top: 30px;
}

@media (max-width: 599px) {
    .form-one-left-section {
        height: auto;
        padding-bottom: 2rem;
    }
}

button.verify-otp-btn:disabled {
    background: #00d0ad;
    opacity: 0.5;
}

.resendotp-timer {
    display: flex;
    align-items: center;
    color: #000080;
    white-space: nowrap;
}

.form-one-btn:disabled {
    opacity: 0.5;
    padding: 12px 12px;
}

.form-error {
    color: red;
    font-size: 13px;
}

button.verify-otp-btn {
    padding: 12px 12px !important;
}

.formone-otpbtnswitch {
    display: flex;
}

.otp-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.otp-wrapper input {
    flex-shrink: 0;
    margin: 0px 26px 0px 0px;
    width: 100%;
}

.formone-otpbtnswitch button {
    margin: 0;
}

.otp-wrapper .dropdown_width button {
    margin: 0;
}

@media (max-width: 767px) {
    .otp-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .formone-otpbtnswitch {
        margin-top: 1rem;
        margin-bottom: 1rem;

    }

    .form_text_container_other {
        margin-top: 0em !important;
        padding: 0px 15px;
    }

    .formone-button-wrapper button {
        margin-top: 1rem;
    }



}

@media(min-width:1367px) {
    .form_text_container_other {
        /* margin-top: 3.6em; */
    }
}

@media only screen and (max-width:1366px) and (min-width: 768px) {
    .form_text_container_other {
        /* margin-top: 1.5em; */
    }

    .imagelogo_formone {
        width: 250px;
    }

    .otp-wrapper input {
        flex-shrink: 0;
        margin: 0px 20px 0px 0px;
        width: 100%;
    }
}

.formone-button-wrapper button {
    padding: 12px 12px;
}

.formone-button-wrapper_recriter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: row-reverse; */
}

@media(min-width: 769px) and (max-width: 1200px) {
    .other_formpage {
        padding: 0px 25px;
    }
}

.text-cyan {
    color: #2896ff;
}

.text_box_cleartalent_form1.phone-number-form-one {
    width: 100%;
}

.form_text_container_other .block-device-color {
    color: red;
    font-size: 16px;
}

.mr_5 {
    margin-right: 5px;
}

.otp-text {
    white-space: nowrap;
    font-size: 16px;
    margin-left: 5px;
    font-style: italic;
    color: #01313C;
}

.email-label-form-one {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-family: 'Manrope-Semibold';
    font-size: 16px;
}

.formone-button-wrapper .form-one-next-btn {
    padding: 12px 24px;
}

.formone-button-wrapper .form-one-next-btn:disabled {
    opacity: 0.5;
}

.progress_adjust>p {
    white-space: nowrap;
}

.p-err {
    margin-top: 5px;
    font-style: italic;
    color: #01313C;
}


.recruiter_signup_otp_left_title {
    font-family: 'Manrope-Bold';
    font-size: 34px;
    color: #04093F;
    text-align: center;
    padding-bottom: 38px;
    padding-top: 18px;
}

.recruiter_signup_otp_left_title_drescription {
    font-family: 'Manrope-Semibold';
    font-size: 13px;
    color: #01313C;
    text-align: center;

    padding-top: 25px;
}

.mt_45 {
    margin-top: 45px;
}

.recruiter_otp_section_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.recruiter_resend_text {
    font-family: 'Manrope-Semibold';
    font-size: 13px;
    color: #1A8FFF;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;

}

.recruiter_otp_section #otp-content2 {
    display: flex;
    justify-content: inherit;
    padding-left: 8px;
    width: 11ch;
}

.recruiter_otp_section #otp-input {
    padding: 0px;
    padding-left: 4px;
    letter-spacing: 24px;
    border: 0;
    background-image: linear-gradient(to left, #167ffc 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 33px 1px;
    background-repeat: repeat-x;
    background-position-x: 19px;
    width: 220px;
    min-width: 220px;
    margin-bottom: 0px;
}

.recruiter_otp_section #otp-content {
    left: 0;
    position: sticky;
}

.recruiter_otp_section #otp-holder {
    width: 190px;
    overflow: hidden
}

.recruiter_otp_section #otp-in {
    border: none;
    width: 12ch;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1.14ch, transparent 0, transparent 2.034ch) 0 100%/100% 2px no-repeat;
    color: dimgrey;
    font: 4.5ch consolas, monospace;
    letter-spacing: 20px;
    color: #000;

}

.recruiter_otp_section #otp-in:focus {
    outline: none;
    color: dimgrey;
}

.recruiter_sign_thankyou_wrapper {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recruiter_sign_thankyou_icon {}

.recruiter_sign_thankyou_icon_adjust {
    width: 108px;
}

.recruiter_sign_thankyou_text_head {

    font-family: 'Manrope-Semibold';
    font-size: 40px;
    color: #121212;
    padding-left: 64px;


}

.recruiter_sign_thankyou_text_head_dres {

    font-family: 'Manrope-Semibold';
    font-size: 20px;
    color: #04093F;
    padding-top: 16px;


}

.recruiter_sign_thankyou_text_head_other_color {
    color: #1A8FFF;
}


.recruiter_signin_header {
    font-family: 'Manrope-Bold';
    font-size: 34px;
    color: #04093F;


}

@media (min-width: 768px) and (max-width: 1435px) {
    .country_label {
        padding-top: 10px;
    }
}

.contactUsFont {
    font-family: 'Manrope-Regular';
    color: #000000;
    font-size: 1.0625em;
}