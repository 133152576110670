.signOut {
  display: grid;
  place-items: center;
}

.signoutButton {
  font-size: 20px;
  background-color: white;
  border: 0px;
  cursor: pointer;
  font-size: 1.0625em;
  font-family: "Manrope-Regular";
}

.main_card{
  height: 438px !important;
  width: 265px !important;
  border-radius: 10px !important;
}

.nav_bg_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_profile {
  height: 38px;
  width: 38px;
  border: 3px solid #2896FF;
  border-radius: 50%;
  padding-top: 0px !important;
}

.user_menu {
  display: flex;
  align-items: center;
}

.user_name{
  font-family: "Manrope-Regular" !important;
  font-size: 2vh !important;
  text-align: center;
}

.all_profile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #D1E8FF !important;
  padding: 15px 30px 50px 30px !important;
  height: 252px;
}

.edit_profile{
  height: 82.25px;
  width: 82.25px;
  border-radius: 50%;
  align-items: center;

}

.recruiter_name{
  font-family: "Arial" !important;
  font-size: 14px bold !important;
  text-align: center;
  padding-top: 19px !important;
}

.user_roll{
  font-family: "Arial" !important;
  font-size: 10px !important;
  font-weight: bold !important;
  text-align: center;
  padding-top: 10px !important;
}

.edit_logo{
  height: 38px;
  width: 146px;
  align-items: center;
  padding-top: 17px !important;
}

.cardAction{
  width:100%;
  padding:0 !important;
  margin: 0 !important;
  background-color: #1885e6;
  color: white;
  height: 186px;
  margin-top: -40px !important;
}

.card_text{
  font-size: 12px !important;
  font-family: 'Segoe UI'!important;
  width: 100%;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  color: #ffffff;
}

.dialog{
  position: absolute;
  left: 10;
  top:50;
  border: 5px solid black;
}
.menulist{
  height: 100%;
  width: 100%;
}

.arrow{
  cursor: pointer;
}

.menu_title{
  font-size: 12px ;
  font-family: 'Segoe UI';
 
  color: #ffffff;
}


.navlink{
  text-decoration: none;
}

.person{
  color: #ffffff;
}

.logo_main_div{
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit_logo_div{
  height: 40px;
}

.edit_logos {
  max-width: 100%;
  max-height: 100%;
}

.menu-icon{
  height: 17px;
  width: 17px;
}

.account-text{
  margin-left: 4px;
}

.profile_dropdown{
  position: relative;
}

.header_profile_pic{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 }

 .header_nav{
  margin-top: 17px;
  padding-top: 0px;
  display: flex;
  width: max-content;
  margin-right: 20px;
 }

 .user_menu{
  width: 80%;
}

.profile_name{
  width: 80%;
  flex-grow: 1;
}

.profileImg{
  width: 38px;;
  height:38px;
  border-radius: 50%;
  border: 3px solid #2896FF;
  margin-top: 8px;
}

.profile_drop{
  width: 1%;
}

.profile_drop_menu_headers{
  position: absolute;
  top: 66px !important;
  right: 0px;
  }

  .notification_profile_mainContainer_header{
    display: flex;
    flex-direction: row;
  }

  .notification_container_header{
    margin-top: 16px;
    margin-right: 45px;
    display: flex;
    flex-direction: column;
  }

  .bellIcon_Img{
    height: 30px;
    width: 29px;
  }

  .notification_contain_header{
    position: absolute;
    top: 83px;
    right: 300px;
    width: 328px;
    box-shadow: 0px 3px 8px #0000003d;
    background-color: #ffffff;
  }

  .notification_contain_header_recruiter{
    position: absolute;
    top: 75px;
    right: 241px;
    width: 328px;
    box-shadow: 0px 3px 8px #0000003d;
    background-color: #ffffff;
  }

  .notification_titleContainer_header{
    background-color: #31B3F8;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .notification_text_header{
    color: #ffffff;
    font-family: "Segoe-regular";
    font-size: 25px;
    font-weight: 400;
  }

  .markAllRead_container_header{
    padding-top: 5px;
  }

  .markAllRead_text_header{
    color: #ffffff;
    font-family: "Segoe-regular";
    font-size: 15px;
    font-weight: 400;
    border-bottom: 1px solid #ffffff;
  }
 

  .notification_sms_container_header{
    background-color: #ffffff;
    height: 217px;
    overflow: auto;
    padding-top: 14px;
  }



  .notification_mainDiv_header{
    border: 1px solid #31B3F8;
    display: flex;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7px;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    cursor: pointer;
  }
  .notification_mainDiv_header_Unread{
    border: 1px solid #31B3F8;
    background-color: aliceblue;
    display: flex;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7px;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    cursor: pointer;
  }

  .iconColor_header{
    color: rgb(49, 179, 248) !important;
  }

  .requitionNumber_notificationcontainer_header{
    display: flex;
    flex-direction: column;
  }

  .viewallContainer_notifacation_header{
    display: flex;
    justify-content: flex-end;
    padding: 13px 10px 13px 10px;
  }

  .viewAllText_header{
    color: #31B3F8;
    font-family: "Segoe-regular";
    font-size: 17px;
    border-bottom: 1px solid #31B3F8;
    margin-right: 7px;
    cursor: pointer;
  }

  .viewAllText_header:hover{
    opacity: 0.5;
  }

  .requitionno_text_header{
    font-family: "Segoe-Semibold";
    font-size: 13px;
    color: #393939;
    padding-bottom: 2px;
  }

  .submittedBy_notification_header{
    font-family: "Segoe-regular";
    font-size: 14px;
    color:  #9B9B9B;
  }

  .notificationIcon_header{
    cursor: pointer;
  }

  .NoRecentText{
    font-family: "Segoe-Semibold";
    font-size: 13px;
    color: #393939;
    text-align: center;
  }

  .headerUserTitle{
    max-width: 200px ;
  }

 