.add-team .dialog-title {
    background-color: #31B3F8;
    padding: 16px 24px !important;
}

.add-team .iconStyle {
    width: 35px;
    height: unset;
}

.add-team .dialog-title .dialog-title-text {
    font-weight: 600;
    font-size: 25px;
    color: #ffff;
}

.add-team .dialog-button {
    padding: 16px 18px !important;

}

.team-dialog-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}

.team-dialog-content {
    padding-top: 20px !important;
}

.field-title-team-dialog {
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}

.input-field-team-dialog input[type="text"] {
    height: 45px;
}

.dialog-actionbutton-team {
    padding: 15px 20px !important;
}

.cancel-button-team {
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}

.cancel-button-team:hover {
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}

.close_button_team{
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
    margin-right: 14px;
}

.close_button_team:hover {
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}

.updateButton-team {
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}

.updateButton-team:hover {
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}

.updateButton_team_disabled {
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    gap: 10px;

}

.dialog-actionbutton-team .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
}

.team_dialog .MuiDialogContent-root{
    margin-top: 31px !important;
}

.tableGrid_team{
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tableGrid_team .k-grid{
    min-height: 70vh !important;
}
.field-title-recruiter-dialog {
    font-size: 26px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}
.dialog-actionbutton-Recruiter{
    padding: 10px 10px !important;
    /* margin-top: 10vh; */
}


.field-title-recruiter-reassign {
    font-size:16px;
    font-family: 'Segoe-regular';
    text-align: center;
}



  

 