
.resume [type="radio"]:checked,
.resume [type="radio"]:not(:checked) {
	position: absolute;
	visibility: hidden;
}

.resume [type="radio"]:checked+label,
.resume [type="radio"]:not(:checked)+label {
	position: relative;
	padding-left: 32px;
	cursor: pointer;
	line-height: 25px;
	display: inline-block;
	font-family: 'Segoe-Semibold';
	font-style: normal;
	font-weight: 600;
	color: #01313C;
	font-size: 14px;
	margin-top: 20px;
}

.resume [type="radio"]:checked+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 25px;
	height: 25px;
	border: 1px solid #1A8FFF;
	border-radius: 100%;
	background: #fff;
}
.ml_26{
	margin-left: 26px;
}

.resume [type="radio"]:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 25px;
	height: 25px;
	border: 1px solid #A3BCD5;
	border-radius: 100%;
	background: #fff;
}

.resume [type="radio"]:checked+label:after,
.resume [type="radio"]:not(:checked)+label:after {
	content: '';
	width: 11px;
	height: 11px;
	background: #1A8FFF;
	position: absolute;
	border: 1px solid #1A8FFF;
	top: 7px;
	left: 7px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.resume [type="radio"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.resume [type="radio"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
.upload_text {
	color: #1A8FFF;
	text-decoration: underline;
	padding-top: 10px;
	padding-left: 10px;
	cursor: pointer;
	}
	.remove_modal{
		width: 100%;
		display: flex;
		justify-content: center;
	}
/*.react-pdf__message
{
	display: none;
}*/
.remove-btn-design
{
	color: #01313C;
	text-decoration: underline;
	font-family: 'Segoe-Semibold';
	font-size: 14px;
}
.ResumeDelete 
{
	background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;
	width: 350px;
	position: relative;
}
.resumebg{
	padding: 24px 20px 20px 20px!important;
}
.linkedin-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.linkedin-wrapper img:first-child{
	position: relative;
	top: -8px;
}
.linkedin-wrapper img:last-child{
	position: relative;
	top: -8px;
}
.linkedin-modal-dialog .MuiDialog-paper {
	width: 80%;
}
.linkedin-modal-url-container {
	width: 100%;
}
.linkedin-modal-url-container .remove_modal {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.wordbreak {
	word-break: break-word;
}
.fs_manrope_12_btn{
	font-size: 12px;
	font-family: 'Manrope-Bold';
	display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 7px 24px 9px 23px;
  /* font-size: 16px; */
  border-radius: 4px;

}
.powres_box{
	height:170px;
	width: 238px;
}