.referralmainContainer {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}

.referralMainGrid {
    padding: 0 5%;
    padding-top: 110px;
    display: flex;
    flex-direction: column;
}

.referralContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 20px;
}

.referralTitle {
    font-size: 30px;
    color: #04093f;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 21vw;
    border-bottom: 1px solid #2896ff;
    padding-bottom: 5px;
    color: #2896ff;
}

.referralArrow {
    font-size: 20px;
    color: #04093f;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 21vw;
}

.referralContent_leftSide {
    width: 245px;
    box-shadow: 0 5px 10px 0 rgba(26, 143, 255, .24);
    border-bottom-left-radius: 15px;
}

.referralContent_topContainer {
    padding: 10px;
    background-color: #d1e8ff;
    display: flex;
    justify-content: space-between;
}

.referralContent_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.referralsearchButton {
    margin: 10px 0px !important;
    color: #fff !important;
    background-color: #1885e6 !important;
}

.referralContent_buttomContainer {
    padding: 10px;
}

.mytalent_sidebar .mytalentsidemutiselect .MuiChip-root {
    color: #2699fb;
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    white-space: nowrap;
    border-radius: 4px;
    vertical-align: middle;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;

    background-color: #fffdfd;
    border: 1px solid #bce0fd;
}

.mytalent_sidebar .mytalentsidemutiselect .MuiChip-deleteIcon {
    color: rgb(38 153 251);
    width: 22px;
    cursor: pointer;
    height: 22px;
    margin: 0 5px 0 -6px;
    -webkit-tap-highlight-color: transparent;
}

.mytalent_sidebar .mytalentsidemutiselect .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px;
}

.mytalent_sidebar .mytalentsidemutiselect .MuiAutocomplete-endAdornment {
    position: unset;
}

.mytalent_sidebar .MuiInput-underline:before {
    border-bottom: 1px solid rgba(38, 153, 251, 0.4);
}

.mytalent_sidebar .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #2699fb;
}

.mytalent_sidebar .MuiSlider-rail {
    width: 100%;
    height: 10px;
    display: block;
    opacity: 0.38;
    position: absolute;
    border-radius: 1px;
    background-color: #d5dde3;
    border-radius: 30px;
}

.mytalent_sidebar .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: -4px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -6px;
    border-radius: 50%;
    justify-content: center;
    background-color: #2699fb;
    border: 2px solid white;
}

.mytalent_sidebar .MuiSlider-root {
    width: 100% !important;
}

.mytalent_sidebar .MuiSvgIcon-root {
    fill: rgba(38, 153, 251, 0.9) !important;
}

.mytalent_sidebar .MuiSlider-track {
    height: 10px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: #2699fb;
    border-radius: 30px;
}

.skills input[type="text"],
select {
    padding: 14px 0px !important;
    font-size: 16px;
    font-family: "Segoe UI";
    font-weight: normal;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #2896ff !important;
}

.referralContent_rightSide {
    width: calc(100% - 265px);
    height: 72vh;
}

.moreButton {
    cursor: pointer;
}

.referralBottomContainer {
    margin-top: auto;
}

.header-text {
    font-size: 17px;
    color: #04093f;
    font-family: "Segoe UI";
    font-weight: normal;
}

.activityInviteBtnAllignment {
    display: flex;
    justify-content: space-evenly;
}

.referralCancelButton {
    margin-left: 5px !important;
    color: #fff !important;
    background-color: #1885e6 !important;
}

.referralInviteButton {
    color: #fff !important;
    background-color: #1885e6 !important;
}

.activityInviteBtnAllignment .MuiButton-containedPrimary {
    color: #fff;
    background-color: #1885e6;
}

.activityInviteBtnAllignment .MuiButton-containedPrimary:hover {
    color: #fff;
    background-color: #1885e6;
    opacity: 0.8;
}

.activityInviteBtnAllignment .k-button-solid-base{
    color: #fff;
    background-color: #1885e6;
}

.inviteActionBtnFront{
    font-family: 'Source sans pro', sans-serif;
    font-weight: "bold";
    font-size: 17px;
}