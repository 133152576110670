.email-configuration-wrapper {
  background-color: #fff;
  padding: 1.5rem;
}
.email-configuration-btn {
  text-align: right;
}

.email-configuration-wrapper .MuiTableCell-head {
  font-weight: bold;
}
.email-configuration-container .flex-to-footer {
  width: auto !important;
}
.distribution-email-count-wrapper {
  display: flex;
  align-items: center;
}
.distribution-email-count-wrapper input {
  width: 80%
}
.distribution-email-count-wrapper span {
  width: 20%;
  cursor: pointer;
  /* text-align: center; */
  margin-left: 2rem;
}
.email-configuration-wrapper tr td{
  width:50%;
}
.email-configuration-wrapper tr td:first-child {
  width: 30%;
}
/* .distrubution-list tr td:nth-of-type(3) {
  width: 26%;
} */
.distrubution-list tr td{
width: 25%;
}