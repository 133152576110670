@font-face {
  font-family: "Manrope-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: local("fonts/Manrope-SemiBold"),
    url("../../resources/fonts/Manrope-SemiBold.ttf") format("woff");
}

@font-face {
  font-family: "Manrope-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Manrope-Medium"),
    url("../../resources/fonts/Manrope-Medium.ttf") format("woff");
}

@font-face {
  font-family: "Manrope-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Manrope-Regular"),
    url("../../resources/fonts/Manrope-Regular.ttf") format("woff");
}

.mb_8{
  margin-bottom: 8px;
}

.mainDiv {
  min-height: 100vh;
  margin: 0px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.orText{
  color: #1a8fff;
}

.mandatoryText{
  text-align: center;
  margin-bottom: 16px;
  font-family: "Manrope-Regular";
  font-size: 2ch;
}

.mainHeader {
  min-height: 50px;
}

.mainFooter {
  min-height: 50px;
}

.relativeBody {
  position: relative;
}

.banner_section_referral {
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 85px;
  background-color: white;
  padding: 35px 0px;
  margin-bottom: 86px;
  padding-bottom: 0px;
}

.why_refer_text {
  margin-bottom: 20px;
  font-size: 21px;
  display: flex;
  font-family: "Manrope-SemiBold";
}

.smallTickStyle{
  margin-right: 10px;
  color: #1a8fff;
}

.blueReferralText{
  color: black;
}

.emailInputTextPadding {
  padding-left: 0px !important
}

.tickStyle{
  height: 3vh;
}

.text_body {
  margin-left: 45px;
}

.file_button {
  padding: 0px !important;
}

.text_body_heading {
  font-family: "Manrope-SemiBold";
  font-size: 20px;
  margin-bottom: 10px;
}

.why_refer_body_desc {
  font-family: "Manrope-Medium";
  font-size: 17px;
}

.bubble_blue {
  position: absolute;
  margin-left: -65px;
  margin-top: 33px;
}

.rightSide_border {
  border-right: 2px solid rgb(216, 210, 210);
}

.style {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 40px;
}

.companies {
  text-align: center;
  font-size: 24px;
  font-family: "Manrope-SemiBold";
  font-size: 27px;
}

.body_text {
  text-align: center;
  font-size: large;
  font-size: 22px;
  font-family: "Manrope-SemiBold";
}

.blue {
  color: #1a8fff;
}

.recruitmentPartners {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  /* border-bottom: 2px solid rgb(216, 210, 210); */
}

.underline1 {
  color: rgb(216, 210, 210);
}

.underline_box {
  text-align: right;
}

.underline_boxnew {
  text-align: center;
}

.cancelText {
  font-family: "Manrope-SemiBold" !important;
  color: #1a8fff !important;
  text-decoration: underline !important;
  text-align: center !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  font-size: 12px;
}

.resendOTP {
  font-family: "Manrope-Regular" !important;
  color: #1a8fff !important;
  font-size: 12px;
  background-color: #fff !important;
  border: none;
}

.resendOTP:disabled {
  color: #98a0a1 !important;
}

.cancelText:hover {
  color: blue !important;
}

.resend_otp_section_referral {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
}

.dialogHeading {
  padding: 20px !important;
}

.dialog1 {
  padding: 20px 0px !important;
}

.phoneDetails {
  font-size: 12px;
}

.dialogBody {
  text-align: center;
  font-family: "Segoe-Regular";
  font-size: 17px;
  margin-bottom: 10%;
  margin-top: 10%;
}

.dialogBody1 {
  text-align: center;
  font-family: "Segoe-Regular";
  font-size: 17px;
  margin-bottom: 13%;
  margin-top: 11%;
}

.inputTagMargin {
  margin-top: 2%;
}

.underline2 {
  color: rgb(216, 210, 210);
  width: 72%;
  border-bottom: 2px solid rgb(216, 210, 210);
}

.underline3 {
  width: 28%;
}

/* .logos {
  margin-right: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  height: 100px;
} */

.labels {
  width: 27%;
  font-family: "Segoe-Regular";
  font-size: 16px;
  margin-bottom: 12px;
}

.upload_btn {
  flex-shrink: 2.5;
  margin-top: 34px;
}

.docbutton {
  padding: 0px 8px !important;
}

.upload-btn-resume-testimonial {
  color: #01313c;
  width: 100%;
  background-color: white;
  padding: 1px 6px !important;
}

.upload_resume_button_text {
  color: #1a8fff;
  font-size: 10px;
  font-family: "Manrope-SemiBold";
}

.upload_resume_button_text_highlighted {
  color: red;
  font-size: 10px;
  font-family: "Manrope-SemiBold";
}

.dotted_border{
  border: 2px dotted red;
}

.scrollbox {
  /* height: 41vh; */
  padding: 0px 50px 0px 0px;
  width: 100%;
  overflow-y: scroll;
}

.referralSidebarFixed {
  width: 13vw;
}


@media only screen and (max-width: 600px) {
  .referralSidebarFixed {
    display: flex;
    position: unset;
    width: 100%;
  }

  .referralSidebarFixed .bubble_blue {
    margin-top: 0;
  }

  .referralSidebarFixed .style {
    margin-top: 0px;
    margin-bottom: 0px;
  }

}

.upload_btn_style {
  margin-top: 25px;
}

.fileType_icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload_textFile {
  font-size: 10px;
  width: max-content;
  padding: 0px;
  color: #1a8fff;
}

.MuiButton-label {
  flex-direction: column;
}

.replace_remove {
  border: 0px;
  background-color: white;
  font-size: 10px;
  font-family: "Manrope-SemiBold";
}

.f_menu.MuiMenuItem-root {
  border-bottom: 1px solid black;
  padding-left: 40px;
  padding-right: 40px;
}

.s_menu.MuiMenuItem-root {
  padding-left: 40px;
  padding-right: 40px;
}

.button_box {
  width: 100%;
  margin-right: 10px;
}

.button_boxnew {
  width: 100%;
}

.emailInputNew {
  width: 70% !important;
}

.btn-invite_next {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 9px 35px 11px 35px;
  font-size: 18px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn-invite_next_blue {
  color: #fff;
  background-color: #1a8fff;
  border: 1px solid #1a8fff;
  margin-top: 13px;
}

.invite_referral_label_wrapper {
  margin-right: 10px;
  width: 100%;
}

.btn_style {
  padding: 20px 150px;
  border-radius: 5px;
}

.add_btn_text {
  margin-bottom: 4px;
  font-family: "Manrope-Medium";
  margin-right: 10px;
  font-size: 18px;
}

.add_btn {
  margin-right: 10px;
}

.add_btn_style {
  align-items: center;
  justify-content: space-evenly;
  margin-top: -25px;
}

.autocomplete_border {
  border: 1px solid black;
  border-radius: 5px;
  height: 47px;
}

.autocomplete_border_highlighted{
  border: 2px solid red;
  border-radius: 5px;
  height: 47px;
}

.text_box_cleartalent_form_referral {
  height: 47px;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid#292929;
  border-radius: 4px;
}

.highlightStyle{
  height: 47px;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 2px solid red;
  border-radius: 4px;
}

#phoneNumber::placeholder{
  opacity: 0.6 ;
}

.mtb_24 {
  margin: 24px 0px;
}

.input_rows {
  margin-bottom: 28px;
}

.invite_candidate_add_refer_icon_wrap {
  float: right;
  margin-top: 0px;
  margin-right: -3px;
}

.invite_candidate_add_refer_icon_wrap .MuiSvgIcon-colorPrimary {
  color: #1a8fff;
  width: 1.8em;
  height: 1.8em;
}

.input_row_each {
  display: flex;
  flex-direction: row;
}

.flexInputs {
  display: flex;
  flex-direction: row;
}

.pdFix {
  padding: 0px !important;
}

.emailVerifyCode {
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 35vh;
}

.emailVerifyInput {
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 20vh;
}

.dialogAction {
  display: flex;
  flex-direction: column;
}

.dialogWidth {
  width: 600px;
}

.emptyDiv {
  height: 79px;
}

.otpSection {
  margin-right: 8%;
}

.emailInput {
  width: 25% !important;
}

.numericContainer .PhoneInput {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.numericContainer .PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: 0px;
  height: 30px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  width: 80%;
}

.numericContainer .PhoneInputInput:focus {
  flex: 1 1;
  min-width: 0;
  border: 0px;
  outline: none;
  height: 30px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  width: 80%;
}

.numericContainer .PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 160%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
  margin-left: -20px;
}

.numericContainer option {
  font-size: 15px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
}

.validationText {
  color: red;
  font-family: "Manrope-Semibold";
}

.alertStyle {
  position: absolute;
  width: 78%;
  top: -2.3vh;
  align-items: center;
}

.existedUser {
  cursor: pointer;
  text-decoration: underline;
}

.failedDataDiv {
  text-align: center;
  padding: 35px;
}

.tickImage {
  width: 15vh;
}

.successData {
  padding: 15px 0px;
  font-size: 18px;
  font-family: "Segoe-Regular";
}

.failedList {
  margin-top: 5px;
  margin-bottom: 20px;
  background-color: #c6e2fa;
  padding: 0px 40px;
}

.failedListItem {
  padding: 2.5px 0px;
  color: #3B71CA;
  font-family: "Segoe-Regular";
}

@media only screen and (max-width: 389px) {
  .rightSide_border {
    border-right: 0;
    padding-bottom: 20px !important;
    margin-bottom: 20px !important;
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .underline2 {
    width: 100%;
    color: rgb(216, 210, 210);
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .button_box {
    width: 100%;
    margin-right: 10px;
    text-align: center;
  }

  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 390px) and (max-width: 599px) {
  .banner_section_referral {
    width: 100%;
    position: relative;
    overflow: hidden;
    top: 70px;
  }

  .rightSide_border {
    border-right: 0;
    padding-bottom: 20px !important;
    margin-bottom: 20px !important;
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .input_rows {
    margin-bottom: 25px;
  }

  .input_row_each {
    display: flex;
    flex-direction: column;
  }

  .labels {
    width: 27%;
    font-family: "Segoe-Regular";
    font-size: 16px;
    margin-bottom: 0px;
  }

  .invite_referral_label_wrapper {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 15px;
  }

  .underline2 {
    width: 100%;
    color: rgb(216, 210, 210);
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .button_box {
    width: 100%;
    margin-right: 10px;
    text-align: center;
  }

  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .banner_section_referral {
    width: 100%;
    position: relative;
    overflow: hidden;
    top: 70px;
  }

  .rightSide_border {
    border-right: 2px solid rgb(216, 210, 210);
  }

  .input_rows {
    margin-bottom: 25px;
  }

  .input_row_each {
    display: flex;
    flex-direction: column;
  }

  .labels {
    width: 27%;
    font-family: "Segoe-Regular";
    font-size: 16px;
    margin-bottom: 0px;
  }

  .invite_referral_label_wrapper {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 15px;
  }

  .underline2 {
    width: 100%;
    color: rgb(216, 210, 210);
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .banner_section_referral {
    width: 100%;
    position: relative;
    overflow: hidden;
    top: 70px;
  }

  .rightSide_border {
    border-right: 2px solid rgb(216, 210, 210);
  }

  .input_rows {
    margin-bottom: 25px;
  }

  .input_row_each {
    display: flex;
    flex-direction: column;
  }

  .labels {
    width: 27%;
    font-family: "Segoe-Regular";
    font-size: 16px;
    margin-bottom: 0px;
  }

  .invite_referral_label_wrapper {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 15px;
  }

  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1024px) {
  .dfGrid {
    display: flex;
    flex-direction: column;
  }

  .flexInputs {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1111px) and (max-width: 1920px) {

  /* .phoneLabel {
    margin: -5px !important;
  } */
  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}