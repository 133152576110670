.candidate-signin-wrapper {
  /* position: relative; */
  /* height: calc(100vh - 80px); */
  /* padding-top: 80px; */
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* height: 100vh; */
  background-color: #fff;
}

.candidate-signin-container {
  width: 550px;
  margin: 0 auto;
  /* border-radius: 5px; */
  background: #fff;
  /* box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.54); */
  /* border: solid 1px; */
  /* padding: 10px; */
}

.candidate-signin-btn button:disabled {
  opacity: 0.5;
}

.candidate-signin-btn-fp button:disabled {
  opacity: 0.5;
}

.candidate-signin-btn, .candidate-signin-btn-fp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
}

.candidate-signin-btn .fplink {
  color: #04093F;
  text-decoration: underline;
}

.fp-email-wrapper {
  display: flex;
  /* justify-content: flex-start; */
  align-items: flex-start;
}

.fp-email-with-err {
  /* width: 78%; */
  width: 69%;
  position: relative;
  /* flex-shrink: 0;
  flex: 1; */
  margin-right: 10px;
}

.fp-email-with-err p {
  /* position: absolute; */
  bottom: -6px;
}

/* .fp-email-wrapper .otp-input {
  width: 70%;
} */

.btn-reset-pwd {
  padding: 12px 12px;
}

.btn-cancel-pwd {
  padding: 12px 24px;
}

.email-label-notice {
  font-size: 15px;
  margin-left: 5px;
  font-style: italic;
  color: #01313C;
  position: relative;
  bottom: 1px;
}

.full-width {
  width: 100%;
}

.signInBtnAfterReset{
  background-color: #009EFD;
  height: 50px;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 18px;
}

.candidate-signin-title {
  padding: 20px 0;
  background-color: #fff;
  color: #000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.fs-small {
  font-size: small;
}

.candidate-signin-main-content {
  padding: 25px;
}

.btn-candidate-signin {
  background-color: #1A8FFF;
  color: #fff;
}

.candidate-password-textbox {
  width: 100%;
  padding: 14px 20px!important;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c6c0c0;
}

.candidate-signin-btn-fp-screen {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 18px;
}

.btn.btn-fp-cancel {
  border: 1px solid #01313C;
  background: #fff;
}

.btn.btn-sendotp {
  padding: 12px 15px;
}

.btn.btn-timer {
  padding: 12px 0px;
  width: 100px;
}

.btn.btn-resendotp {
  padding: 12px 6px;
}

.btn-reset-pwd:disabled {
  opacity: 0.5;
}

/*Start: Sign in page css*/

.login_control input[type=text], input[type=password] {
  width: 100%;
  padding: 8px 20px !important;
  margin: 8px 0;
  display: inline-block;
  /* border: 1px solid #707070; */
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Manrope-SemiBold';
}

/* Set a style for all buttons */

.login_control button {
  background-color: #009EFD;
  border: 1px solid #009EFD;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Manrope-Regular';
  -webkit-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
}

.full-width {
  width: 100%;
}

.login_control button:hover {
  opacity: 0.8;
}

.login_control label {
  font-family: 'Manrope-SemiBold';
  font-size: 16px;
  color: #000;
}

.clear_login_remember_sec {
  display: flex;
  justify-content: space-between;
}

.mt_34 {
  margin-top: 34px;
}

.login_container {
  width: 450px;
  margin: 1rem 0;
}

.nav_bg_login {
  width: 100%;
  background-color: #fff;
  /* position: fixed; */
  z-index: 99;
  -webkit-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
}

/* .candidate-signin-wrapper {
  position: relative;
} */

.footer_links {
  margin-bottom: 0px;
  padding-left: 0px;
  margin-top: 34px;
}

.footer_bg_login {
  width: 100%;
  background-color: #C2C2C2;
  margin-bottom: 40px;
}

.footer_adjust {
  padding-bottom: 70px;
}

.form-group1 input[type=checkbox] {
  display: none;
  cursor: pointer;
}

.form-group1 input[type=checkbox]+label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #0076B5;
  border-radius: 0px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group1 input[type=checkbox]:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 4px;
  height: 10px;
  border: 1px solid #00a7ff;
  border-width: 0 2px 2px 0;
  transform: rotate( 45deg);
}

.chklabel {
  position: relative;
  color: #000000;
  font-family: 'Manrope-Medium';
  font-size: 12px;
}

.fgtext {
  color: #000000;
  font-family: 'Manrope-Semibold';
  font-size: 12px !important;
}
.resetPwdOTPText{
  font-family: "Manrope-Semibold";
  font-size: 16px;
}

/*End: Sign in page css*/

/* input[type=text] {
  padding: 8px 20px!important;
} */

.f_height {
  height: 20px;
}

.footer_links li {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px;
}

.footer_links li:last-child {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px 0px 0px;
}

.footer_links li a {
  color: #000;
  text-decoration: none;
  font-size: 1.0625em;
  font-family: 'Manrope-Medium';
}

.footer_links li a:hover::after {
  opacity: 1;
}

.footer-text-color {
  color: #D1E8FF;
}

.footer-copyright {
  opacity: .4;
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
}

.reset-password-container {
  /* width: 525px; */
  width: 430px;
  margin: 60px auto;
}

.reset-password-container button {
  background-color: #009EFD;
  border: 1px solid #009EFD;
  color: white;
  padding: 8px 20px;
  margin: 8px 0 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 108px;
  font-size: 14px;
  font-family: 'Manrope-Regular';
  -webkit-box-shadow: 2px 3px 5px 0px rgb(0 0 0 / 45%);
  -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 2px 3px 5px 0px rgb(0 0 0 / 45%);
}

#otp-input {
  padding: 0px;
  padding-left: 4px;
  letter-spacing: 24px;
  border: 0;
  background-image: linear-gradient(to left, #167ffc 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 33px 1px;
  background-repeat: repeat-x;
  background-position-x: 19px;
  width: 220px;
  min-width: 220px;
  margin-bottom: 0px;
}

#otp-content {
  left: 0;
  position: sticky;
}

#otp-holder {
  width: 190px;
  overflow: hidden
}

#otp-in {
  border: none;
    width: 12ch;
    background: repeating-linear-gradient(
      90deg , dimgrey 0, dimgrey 1.5ch, transparent 0, transparent 2.034ch) 0 100%/100% 2px no-repeat;
    color: dimgrey;
    font: 4.5ch consolas, monospace;
    letter-spacing: 1ch;
    color: #000;
}

#otp-in:focus {
  outline: none;
  color: dimgrey;
}

.otp_sec {
  display: flex;
  justify-content: space-between;
}

.w-70 {
  width: 70%;
}

.message-container {
  width: 350px;
  margin: 60px auto;
}
.login-box-border {
  border: 1px solid #d4d4d4;
  padding: 20px;
}
.login_wrapper_main {
  display: flex;
    width: 100%;
    justify-content: center;
}
.singin_box{
  padding-bottom: 12vh;
}
.error_message{
  margin-top: 5.5vh;
}

@media only screen and (max-width:1366px) and (min-width: 768px) {
  .login_container {
    width: 450px;
    /* margin-top: 1rem;
    margin-bottom: 2rem */
  }
/* .candidate-signin-wrapper {
  position: relative;
  height: unset;
} */
.login_wrapper_main{
  display: flex;
    width: 100%;
    justify-content: center;
    
}
 
}
.ht_47{
  height: 47px;
}

.emailStyle{
  font-size: 14px;
}