.admin-container {
  background: #fff;
  padding: 2em 0em;
  border-radius: 4px;
}
.admin-section-home-first,.admin-section-home-second {
  display: flex;
  /* justify-content: space-between; */
  padding: 0rem 0.5rem;
}
.page-box-element {
  border: 1px solid #1A8FFF;
  padding: 20px;
  width: 23%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-left: 14px;
  margin-right: 5px;
  text-align: center;
}

hr.admindivider {
  border-top: 1px dashed #b9b9b9;
    margin: 0px 22px;
}
.mt_12 {
  margin-top: 12px;
}

.page-box-element_last {
  padding: 20px;
  width: 23%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin-left: 14px;
  cursor: default;
  margin-right: 5px;
}
.admin-container h3 {
  padding: 0rem 0.5rem;
}
.page-box-element:hover {
  background:#1A8FFF;
}
.page-box-element:hover p{
  color:#fff;
}
.page-box-element:hover img{
  filter: brightness(0) invert(1);
}
.page-box-element img {
  width: 38px;
  height: 38px;
}
@media (max-width: 767px) {
  .admin-section-home-first,.admin-section-home-second {
    flex-direction: column;
    padding: 0rem 1.2rem;
  }
  .page-box-element {
    width: auto;
    margin-bottom: 1rem;
  }
  .admin-container .admin-section-home-first {
    margin-bottom: 0px;
  }
  .page-box-element_last{
    display: none;
  }
}
#reportedquestion {
  background: #eee;
  position: relative;
}
#reportedquestion:hover p {
  color: #01313c
}

#reportedquestion:hover img {
  filter: none;
}
#reportedquestion .coming-soon {
position: absolute;
bottom: 3px;
}