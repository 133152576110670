@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none !important;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
/* .margin_grid{
    margin: 10px ;
    margin-bottom: 20px;
    height: 70vh;
} */

.poppercolor{
    width: 100% !important;
    opacity: 1 !important;
    background-color: white !important;
    height: 50px !important;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: -5px;
    
}

.popperbutton{
    height: 30px !important;
    margin: 15px !important;
}

