.title-bar {
    width: 1376px;
    height: 50px !important;
    top: 297px;
    left: 121px;
    border-radius: 8px, 8px, 0px, 0px;
    background-color: #31B3F8 !important;
    font-family: Roboto !important;
    font-size: 24px !important;
    font-weight: 600;
    line-height: 28px !important;
    letter-spacing: 0em;
    text-align: left;
    color: white !important;
}

.action-btn {
    width: 81px;
    height: 29px;
    top: 400px;
    left: 158px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #31B3F8 !important;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}

.tableRow {
    top: 297px;
    /* left: 121px; */
    font-family: Roboto !important;
    font-size: 24px !important;
    font-weight: 600;
    line-height: 28px !important;
    letter-spacing: 0em;
    text-align: left;
    border: none;
}

.rec_interview_today {
    background: linear-gradient(90deg, rgba(242, 235, 189, 1) 0%, rgba(254, 191, 189, 1) 100%);
    cursor: pointer;
}

.rec_interview_today:hover {
    opacity: 0.8;
}

.rec_upcomingDate{
    cursor: pointer;
}
.rec_upcomingDate:hover{
    opacity: 0.8;
    background-color: #f1f1f1;
}


.rec_interview_coming {
    background-color: #fffd8d;
}

.candidateInterviewTable .MuiTableContainer-root {
    margin: 0px !important;
}

.candidateInterviewTable .MuiTableContainer-root::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.candidateInterviewTable .MuiTableContainer-root::-webkit-scrollbar-thumb {
    background-color: #1F729F;
    /* Set the color of the scrollbar thumb */
}

.candidateInterviewTable .MuiTableContainer-root::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the color of the scrollbar track */
}



.candidateInterviewTable .MuiTableContainer-root th {
    font-family: 'Segoe-semibold' !important;
}