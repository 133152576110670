.maincontainer_mis_operator_dashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #ffffff;
}

.maindiv_mis_operator_dashboard {
    margin: 0 6%;
    margin-top: 125px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    padding-bottom: 40px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.card_divisions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.iconMargin {
    margin-right: 5px;
}

.dateStyle {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.bottom_container_mis_operator_dashboard {
    margin-top: auto;
}