.font_29_300 {
    font-size: 29px;
    font-weight: 300;
    font-family: SegoeUI-Semilight, "Segoe UI";
}

.color_1885e6 {
    color: #1885e6;
}


.only_candidateProfileHeading_wd {
    width: 213px;
    height: 38px;
}

.cs_lastUpdate_font {
    color: #01313c;
    font-size: 15px;
    font-family: SegoeUI-Semibold, "Segoe UI";
    font-weight: 600;
}

.cs_lastUpdate_font_lastUpload{
    font-size: 15px;
    font-family: SegoeUI-Semibold, "Segoe UI";
}

.fiveDivContainer {
    display: flex;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    align-items: flex-start;
    margin-top: 10px;
}

.candidate_profile_boxes {
    padding-left: 13px;
    margin-bottom: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
    margin-right: 30px;

}

/* .icon_style{
    width: 15px;
    height: 15px;
} */

.only_candidate_profileImage {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.OnlyCandidateMainDiv_wd {
    /* width: 1069px; */
    width:100%;
    border-radius: 6px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.color_D5E7FD {
    background-color: #D5E7FD;
}

.color_04093f {
    color: #04093f;
}

.lh_1_pt_5 {
    line-height: 1.5;
}

.lh_2_pt_2 {
    line-height: 22px;
}

.lh_3_pt_5 {
    line-height: 3.5;
}

.font_20_700 {
    font-size: 20px;
    font-weight: 700;
    font-family: SegoeUI-Bold, "Segoe UI";
}

.font_15_600 {
    font-size: 15px;
    font-weight: 600;
    font-family: SegoeUI-Semibold, "Segoe UI";
}

.font_12_600 {
    font-size: 12px;
    font-weight: 600;
    font-family: SegoeUI-Semibold, "Segoe UI";
}

.font_17_600 {
    font-size: 17px;
    font-weight: 600;
    font-family: SegoeUI-Semibold, "Segoe UI";
}
.font_15_500{
    font-size: 15px;
    font-weight: 500;
    font-family: SegoeUI-Semibold, "Segoe UI";
}
.font_15_500_candidateName{
    font-size: 15px;
    font-weight: 500;
    font-family: SegoeUI-Semibold, "Segoe UI";
    padding-bottom: 5px;
}

.primary_secondary_skill_div {
    width: 96%;
    /* max-width: 1020px; */
    min-height: 430px;
    background-color: #fff;
    margin-top: 4vh;
    margin-left: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease-in-out;
    /* padding: 14px 20px 20px 20px; */
    /* border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; */
    padding-top: 22px;
    border-radius: 15px;
    padding-bottom: 20px;
    /* overflow-y: scroll; */
}

.only_candidate_specific_sub_card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease-in-out;
    /* padding: 14px 20px 20px 20px; */
    border-radius: 4px;
    background-color: #FFFFFF;
}

.only_candidate_specific_sub_wd {
    width: 100%;
    max-width: 780px;
    min-height: 202px;
    padding-bottom: 10px;
}
.only_candidate_specific_sub_wd_ps {
    width: 100%;
    max-width: 780px;
    /* min-height: 202px; */
    padding-bottom: 10px;
}

.only_candidate_specific_sub_card_margin {
    margin-left: 15px;
    min-height: 250px;
}
.only_candidate_specific_sub_card_margin_ps {
    margin-left: 15px;
    margin-bottom: 27px;
    /* min-height: 447px; */
}

.only_candidate_skill_type_exp_cmp_font {
    font-family: SegoeUI-Semibold, "Segoe UI";
    color: #01313c;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.0;
}

.only_candidate_rectangle-container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.only_candidate_rectangle_141 {
    border-radius: 10px;
    background-color: #d1e8ff;
    transform: translate(-0.26px, -0.265px);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-top: 6px;
    text-align: center;
}

.only_candidate_skill_font {
    font-family: SegoeUI-Semibold, "Segoe UI";
    color: #01313c;
    font-size: 10px;
    font-weight: 600;
}

/* .main-left_class {
    max-width: 25%;
    width: 25%;
}
.main-right_class {

    width: 73%;
} */
@media (max-width: 1200px) {
    .OnlyCandidateMainDiv_wd {
        width: 90%;
        /* Reduce the width to 90% for screens up to 1200px wide */
        padding-bottom: 10px;
        border-radius: 6px;
    }
}

@media (max-width: 768px) {
    .OnlyCandidateMainDiv_wd {
        width: 100%;
        /* Set the width to 100% for screens up to 768px wide */
        /* padding: 0 20px; */
        /* Add some padding to the container to avoid content overflowing */
        padding-bottom: 10px;
        border-radius: 6px;
    }
}

.primary_secondary_skill_div::-webkit-scrollbar {
    width: 13px;
}

.primary_secondary_skill_div::-webkit-scrollbar-thumb {
    background-color: #1885E6;
    border-radius: 4px;
}

.primary_secondary_skill_div::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.mainDiv_onlycandidateProfile {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFFFFF;
}

.maincontainer_onlycandidateprofile {
    display: flex;
    margin: 0 3%;
    margin-top: 120px;
    flex-direction: column;
}
.leftContainer_onlycandidateprofile{
    display: flex;
    flex-direction: column;
    width: 1000px;
}
.rightContainer_onlycandidateprofile{
    width: calc(100% - 1020px);
    padding-top: 60px;
    display: flex;
    justify-content: center;

}
.viewresume_box{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
}
.viewresume_container{
    display: flex;
    align-items: center;
    gap:5px;
    cursor: pointer;
    font-family: 'Segoe-Semibold';
    font-size: 14px;
}

.viewresume_container_value{
    display: flex;
    align-items: center;
    gap:5px;
    cursor: pointer;
    font-family: 'Segoe';
    font-size: 14px;
}
.inviteCandidateIconStyle {
    height: 80px;
    width: 80px;
}
.invitecandidate_text1{
    font-family: 'Segoe-semibold';
    font-size: 12px;
    color: #4D4F5C;
    opacity: 0.5;
    margin-top: 5px;
}
.invitecandidate_invitebutton{
    height: 25px;
    width: 85px;
    background-color: #05A6F4;
    color: #FFFFFF;
    padding: 0px !important;
    font-size: 14px !important;
    font-family: 'Segoe-semibold' !important;
    margin-top: 10px;
}
.invitecandidate_invitebutton:hover{
    opacity: 0.8;
}
.invitecandidate_text2{
    font-size: 22px;
    font-weight: 400;
    color: #05A6F4;
    font-family: 'Segoe UI';
    margin-top: 10px;
}
.invitecandidate_text3{
    font-size: 22px;
    font-weight: 400;
    color: #05A6F4;
    font-family: 'Segoe UI';
}
.mainDivcontainer_onlycandidateprofile{
    display: flex;
    width: 100%;
}
@media screen and (min-width: 1290px) {
    .maincontainer_onlycandidateprofile{
        margin: 0 5%;
        margin-top: 120px;
    }
}
@media screen and (max-width: 1270px) {
    .mainDivcontainer_onlycandidateprofile {
        flex-direction: column-reverse;
        justify-content: flex-start;
    }

    .rightContainer_onlycandidateprofile {
        width: 100%;
        justify-content: flex-end;
    }

    .leftContainer_onlycandidateprofile {
        width: 100%;
    }

    .fiveDivContainer {
        flex-wrap: wrap;
    }
}

.yearsofExp_only_candidateProfile {
    font-family: "Segoe-semibold";
    /* font-weight: 400; */
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #01313c;
}

.candidate_resume_fontstyle {
    font-family: "Segoe";
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.textCapitalize {
    text-transform: capitalize;
}

@media screen and (max-width: 600px) {
    .only_candidate_specific_sub_wd {
        width: 90%;
    }
}

.primaryskillcard_div {
    padding-top: 10px;
}

.primaryskillcard_div_extra {
    padding-top: 10px;
    margin-top: 20px;
}

.primaryskilldivcontainer {
    padding-left: 15px;
}

.primaryskilldivcontainer_olderProfile {
    padding-left: 15px;
    padding-bottom: 5px;
}

.skillHeadings_candidateProfile {
    font-family: "Segoe UI";
    color: #01313c;
    font-size: 25px;
    font-weight: 300
}

.skillHeadings_candidateProfile_mt5 {
    font-family: "Segoe UI";
    color: #01313c;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 15px;
}

.skillHeadings_candidateProfile_secondarySkill {
    font-family: "Segoe UI";
    color: #01313c;
    font-size: 25px;
    font-weight: 300;
}

.skillHeadings_candidateProfile_secondarySkill_container {
    padding-left: 22px;
    padding-top: 10px;
}

.multiProfileBox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.multiProfileBox_olderProfile {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
}

.skillHeadings_candidateProfile_olderProfile {
    font-family: "Segoe UI";
    color: #01313c;
    font-size: 25px;
    font-weight: 300;
    padding-bottom: 10px;

}

.candidate_resume_ph {
    font-size: 15px;
    /* font-weight: 500; */
    font-family: SegoeUI-Semibold, "Segoe UI"
}

.star_primaryskill {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.skillTestTimeInfoContainer_poweResumeRight_skeleton {
    display: flex;
    font-family: 'Segoe UI';
    font-size: 12px;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-bottom: 10px;
    /* border-bottom: 1px solid #A3BCD5; */
}

.invitetotaketestConrainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 48%;
    left: 51%;
}

.inviteCandidateIconStyle {
    height: 80px !important;
    width: 80px !important;
    color: #05A6F4;
    opacity: 0.8;
    text-shadow: 1px 4px 2px black !important;
    margin-left: -20px;
}

.invitecandidate_text1 {
    font-family: 'Segoe-semibold';
    font-size: 12px;
    opacity: 0.8;
    margin-top: 5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.041);
}

.invitecandidate_invitebutton {
    height: 25px;
    width: 85px;
    background-color: #05A6F4;
    color: #FFFFFF;
    padding: 0px !important;
    font-size: 14px !important;
    font-family: 'Segoe-semibold' !important;
    margin-top: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    letter-spacing: 1px;
    text-shadow: 0px 1px 0px black;
    padding-bottom: 2px !important;
}

.invitecandidate_invitebutton:hover {
    opacity: 0.8;
}

.invitecandidate_text2 {
    font-size: 22px;
    font-weight: 400;
    color: #05A6F4;
    font-family: 'Segoe UI';
    margin-top: 10px;
    text-shadow: 0px 1px 0px #013047b0;
}

.invitecandidate_text3 {
    font-size: 22px;
    font-weight: 400;
    color: #05A6F4;
    font-family: 'Segoe UI';
    /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.041); */
    text-shadow: 0px 1px 0px #013047b0;
}

.profileHeaderDiv_skeleton{
    width: 220px;
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) -1px -5px 5px 0px;
    /* border-bottom: 4px solid #1885E6; */
    display: flex;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    opacity: 0.5;
    background-color: rgba(128, 128, 128, 0.190);
}

.poweResumeInfoCard_poweResumeRight_skeleton {
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    border-radius: 5px;
    margin-left: 10px;
    border-top-left-radius: 0px;
    margin-top: -3px;
    margin-right: 3px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-top: 20px;
    opacity: 0.5;
    background-color: rgba(128, 128, 128, 0.190);
  }

  .resumeContainer_skeleton{
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }

  .fileImg_resume_skeleton{
    width: 30px;
    height: 30px;
    margin-left: 4px;
    margin-bottom: 10px;
  }

  .viewText_skeleton{
    font-size: 15px;
    font-weight: 500;
    font-family: SegoeUI-Semibold, "Segoe UI";
    /* padding-bottom: 5px; */
    color: #04093f;
  }
  
  @media screen and (max-width: 1020px) {
    .invitetotaketestConrainer{
        top: 123%;
        left: 52%;
    }
  }

  .poweResumeHeading_poweResumeRight_header {
    font-weight: normal;
    font-family: 'Segoe-Regular';
    font-size: 30px;
    color: #1885E6;
  }
  .resumeContainer_skeleton{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
  }
  .detailContainer_skeleton{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .primarySkillSkeletonContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .secondarySkill_skeleton{
    margin-top: 7px;
  }
  