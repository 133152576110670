/* #rectCrop  div{
    border-radius: 0 !important;
} */
#rectCrop{
    height: 40vh;
    width: 100%;
    background-color: rgb(92, 92, 92);
    border: 1px solid black;}

   #rectCrop .ReactCrop{
    max-width: 85%;
   } 
#rectCrop .reactEasyCrop_CropArea{
    /* width: 95% !important;
height: 80% !important; */
border-radius: 0 !important;
background-color: auto !important;

}

#editRecruiterLogoContainer{
    height: 40px !important;
}

#rectCrop .reactEasyCrop_Container{
    background-color: white; 
    height: 40vh !important;
    width: 100% !important;
    /* background-color: grey;    */
}
#rectCrop {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}
#freeCropper .ReactCrop__image{
    max-height: 40vh;
    width: 100%;
    background-color: white;
  }
.ReactCrop__child-wrapper{
   height: 250px;
}

  
#mainContainer .MuiDialogContent-root{
    overflow-y: hidden !important;
}

#mainContainer .reactEasyCrop_CropAreaGrid::before{
    border: 1px solid rgb(193, 193, 193);
}
#mainContainer .reactEasyCrop_CropAreaGrid::after{
    border: 1px solid rgb(193, 193, 193);
}
#orgLogoUploadContainer{
    /* background-color: red; */
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 3px;
}


#orglogoFileName{
    padding: 0px 16px !important;
}

#OrgLogoUploadButton{
    display: flex;
    gap: 12px;
    background-color: white;
    padding: 8px 10px 8px 10px;
    margin-left: 0;
    color: #303F9F;
    border: 1.5px solid #303F9F;
}

#OrgLogoUploadButton:hover{
    background-color: #303F9F;
    color: white;
}
/* .reactEasyCrop_Contain{ */
    /* transform: translateX(0%) !important */
/* } */