.skill_video_sec_left {
  width: 37%;
}

.video-interview-chkbox {
  display: block;
  margin-bottom: 23px;
  margin-top: 10px;
}

.skill_video_sec_right {
  width: 63%;
}

.video-interview-chkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.video-interview-chkbox label {
  position: relative;
  cursor: pointer;
  font-weight: 600;
  font-family: "Segoe-Semibold";
  font-size: 16px;
  color: #01313c;
}

.video-interview-chkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #2896ff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 11px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 4px; */
  margin-right: 7px;
}

.video-interview-chkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 5px;
  height: 12px;
  border: 2px solid #2896ff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.video-interview-chkbox :checked + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #1a8fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 11px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

.skill_video_next_text {
  color: #a3bcd5;
}
.fs-30{
  font-size: 30px;
}
.skill_video_next {
  display: flex;
  justify-content: flex-end;
}

.scrollHeight1 {
  overflow-y: scroll;
}

.interview-question-scroll::-webkit-scrollbar {
  width: 5px;
}

/* .interview-question-scroll::-webkit-scrollbar-track {
	background: #c6c6c6;
  width: 2px
} */

.interview-question-scroll::-webkit-scrollbar-thumb {
  background-color: #a5a4a4;
  border-radius: 30px;
}

.next-button {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.individual-skill {
  border: 1px solid #d1e8ff;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 14px 20px 14px 20px;
  margin-top: 10px;
}

.individual-skill-video {
  border: 1px solid #A3BCD5;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.myskill-right {
  text-align: right;
}

/*------------- CHECKBOX ---------------*/

.interview-chkbox {
  display: block;
  margin-bottom: 23px;
  margin-top: 23px;
}

.interview-chkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.interview-chkbox label {
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-family: "Segoe-regular";
  font-size: 18px;
  color: #01313c;
}

.interview-chkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #01313c;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

.interview-chkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #1a8fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.interview-chkbox :checked + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #1a8fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}







.interview-chkbox-testresult{
  display: block;
  margin-bottom: 23px;
  margin-top: 23px;
}

.interview-chkbox-testresult input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.interview-chkbox-testresult label {
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-family: "Segoe-regular";
  font-size: 18px;
  color: #01313c;
  display: flex;
}

.interview-chkbox-testresult label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #01313c;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  height: 0px;
  margin-top: 8px;
}

.interview-chkbox-testresult input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #1a8fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);

}

.interview-chkbox-testresult:checked + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #1a8fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

/*------------- CHECKBOX ---------------*/

/*------------- RADIO BUTTON ---------------*/

.clear [type="radio"]:checked,
.clear [type="radio"]:not(:checked) {
  position: absolute;
  visibility: hidden;
}

.clear [type="radio"]:checked + label,
.clear [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 43px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  color: #01313c;
  margin-top: 25px;
}

.clear [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #1a8fff;
  border-radius: 100%;
  background: #fff;
}

.clear [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #01313c;
  border-radius: 100%;
  background: #fff;
}

.clear [type="radio"]:checked + label:after,
.clear [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: #1a8fff;
  position: absolute;
  border: 1px solid #1a8fff;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.clear [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.clear [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------- RADIO BUTTON ---------------*/

/*------------- STEP CSS ---------------*/

.bs-stepper .step-trigger {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.bs-stepper .step-trigger:focus {
  outline: none;
}

.bs-stepper-label {
  display: inline-block;
  margin: 0.25rem;
}

.bs-stepper-header {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bs-stepper-line,
.bs-stepper .line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: 9px;
  background-color: #a3bcd5;
}

.success_line {
  background-color: #2ad156;
}

.bs-stepper-circle {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  color: #adb5bd;
  border: 1px solid #a3bcd5;
  border-radius: 100%;
  line-height: 37px;
  font-size: 16px;
  background-color: #fff;
  font-weight: normal;
  font-family: "Segoe-Regular";
  outline: none;
}

.active .bs-stepper-circle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.success .bs-stepper-circle {
  color: #fff;
  background-color: #2ad156;
  border-color: #2ad156;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.bs-stepper-content {
  padding: 0 20px 20px;
}

.bs-stepper.vertical {
  display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
  display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden;
}

.bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
  display: none;
}

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
  display: block;
  visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
  display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
  display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
  visibility: hidden;
}

.wb {
  word-break: break-word;
}

.link_color {
  color: #007bb5;
}

/*------------- STEP CSS ---------------*/

/*------------- VIDEO INTERVIEW QUESTION ---------------*/

.flex-video-bottom {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
}

.videosection {
  width: 100%;
}

.vl_video {
  border-left: 1px solid #a3bcd5;
  height: 267px;
  /* height: 328px; */
  margin-left: 12px;
  margin-top: 0px;
}

/*------------- VIDEO INTERVIEW QUESTION ---------------*/
.cross_size {
  width: 16px;
}
/*------------- VIDEO INTERVIEW TOP ---------------*/

.playButton {
  width: 45px;
  height: 45px;
}
.playButtonother {
  width: 45px;
  height: 45px;
}
.flex-video-top {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.cadidateinfo_clm_one{
  margin-right: 20px;
  text-align: left;
}
.cadidateinfo_clm_two{
  text-align: left;
}

/*------------- VIDEO INTERVIEW TOP ---------------*/

.mainbg {
  background-color: #eeeeee;
}

.skill_video_popup {
  width: 800px;
}

.video_top_text {
  justify-content: space-between;
}

.ReactModal__Overlay {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  overflow-y: scroll;
}

.skillvideo_modal {
  /* background: rgb(255, 255, 255) !important;
  overflow: auto !important;
  border-radius: 4px !important;
  outline: none !important;
  width: 800px !important;

  position: absolute !important; */
  background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;	
	width: 815px ;
    position: relative;
    padding: 0px 20px 0px 20px;
    overflow-y: scroll;
    height: 650px;
}

.time_icon {
  vertical-align: middle;
  padding-right: 8px;
}

/*------------- IMAGE ---------------*/

.btn-icon {
  vertical-align: baseline;
  padding-left: 6px;
}

.video_icon {
  background-image: url(./images/video-camera-B.png);
  background-repeat: no-repeat;
  width: 27px;
  height: 17px;
}

.video_icon:hover {
  background-image: url(./images/video-camera-w.png);
  background-repeat: no-repeat;
  width: 27px;
  height: 17px;
}

.arrow_icon {
  vertical-align: middle;
  margin-left: 10px;
}

.btnquestion {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 4px 10px 2px 10px;
  font-size: 22px;
  border-radius: 30px;
  font-family: "Segoe-Bold";
  width: 100%;
}

.btnquestion-questionselected {
  color: #fff;
  background-color: #1a8fff;
  outline: none;
}

.btnquestion-questionselected:hover {
  color: #fff;
  background-color: #1a8fff;
  outline: none;
}

.btnquestion-questionright {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #2ad156;
  outline: none;
}

.btnquestion-questionright:hover {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #2ad156;
  outline: none;
}

.btnquestion-questionwrong {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #ff4141;
  outline: none;
}

.btnquestion-questionwrong:hover {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #ff4141;
  outline: none;
}

.btnquestion-questiondisable {
  color: #a3bcd5;
  background-color: transparent;
  border: 1px solid #a3bcd5;
  outline: none;
}

.btnquestion-questiondisable:hover {
  color: #a3bcd5;
  background-color: transparent;
  border: 1px solid #a3bcd5;
  outline: none;
}

.modal_popup_close {
  position: absolute;
  top: 13px !important;
  right: 24px !important;
}
/* .alice-carousel__next-btn {
  position: absolute;
  top: -17px;
  right: 10px;
}
.alice-carousel__prev-btn {
  position: absolute;
  top: -17px;
  left: -5px;
}
.alice-carousel__next-btn-item {
  position: absolute;
  right: -16px;
  top: -2px;
}
.alice-carousel__prev-btn-item {
  position: absolute;
  left: -16px;
  top: -2px;
}
.alice-carousel__dots {
  display: none;
}

.alice-carousel__prev-btn [data-area]::after {
  position: relative;
  
  content: "\f104" !important;
  font-size: 34px !important;
  text-transform: capitalize;
  font-family: FontAwesome !important;
}

.alice-carousel__next-btn [data-area]::after {
  position: relative;
  
  content: "\f105" !important;
  font-size: 34px !important;
  text-transform: capitalize;
  font-family: FontAwesome !important;
} */
.skillvideocodingtest {
  margin-top: 0px;
}
.videobuttomquestion {
  display: flex;
}
.playbuttonsection {
  display: flex;
}
.scorecardsection {
  display: flex;
}

.text-left_special {
  margin-left: 25px;
}
.res_left {
  margin-left: 41px;
}
.popup {
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 40px 10px 40px;
}
.video-interview-test {
  object-fit: cover;
  width: 100% !important;
}
.skill_Pagination_adjust{
  height: 40px;
}

@media only screen and (max-width: 768px) {
  /* .alice-carousel__next-btn {
    display: none;
   }
   .alice-carousel__prev-btn {
     display: none;
   } */
  .res_left {
    margin-left: 24px;
  }
  .text-left_special {
    margin-left: 0px;
  }
  .skill_video_width {
    width: 100%;
    flex-wrap: wrap;
  }

  .skill_video_sec_left {
    width: 100%;
  }

  .skill_video_sec_right {
    width: 100%;
  }
  .skillvideocodingtest {
    margin-top: 11px;
  }
  .skillvideo_modal {
    background: transparent !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 100% !important;
    position: absolute !important;
    margin-top: 0px !important;
    padding: 0px;
  }
  .skill_video_popup {
    width: 100%;
    /* margin-top: 212px; */
  }
  .mw {
    width: 100%;
  }
  .vl {
    border-left: 1px solid #a3bcd5;
    height: 327px;
    margin-left: 12px;
    margin-top: 0px;
    display: none;
  }
  .videobuttomquestion {
    display: flex;
    flex-wrap: unset;
  }
  .playbuttonsection {
    display: flex;
    flex-wrap: unset;
  }
  .scorecardsection {
    display: initial;
    flex-wrap: unset;
  }
  .ml_41 {
    margin-left: 0px;
  }
}
.skill_video_sec_right {
  width: 100%;
}
.skill_video_sec_right.ml_22.skillvideocodingtest {
  margin-left: 0 !important;
}

.questionoption.wrong-question-option [type="radio"]:checked + label {
  color: #ff4141;
}
.questionoption.wrong-question-option [type="radio"]:checked + label:after {
  background: #ff4141;
  border: 1px solid #ff4141;
}
.questionoption.wrong-question-option [type="radio"]:checked + label:before {
  border: 2px solid #ff4141;
}
.questionoption.right-question-option [type="radio"]:checked + label:after {
  background: #2ad156;
  border: 2px solid #2ad156;
  top: 7px;
  left: 7px;
}
.questionoption.right-question-option [type="radio"]:checked + label:before {
  border: 2px solid #2ad156;
}
.questionoption.right-question-option [type="radio"]:checked + label {
  color: #2ad156;
}
.btnquestion {
  width: 95%;
}
/* .skillvideo_modal {
  overflow: initial !important;
} */
.videosection.mt_17 {
  object-fit: cover;
}
.video-interview-test>video {
  object-fit: cover;
  /* width: 360px !important;
  height: 268px !important; */
  width: 100% !important;
  height: 100% !important;
}
.right-answer {
  color: #2ad156;
}
.wrong-answer {
  color: #ff4141;
}
.interview-chkbox > input.right-checkbox[type="checkbox"]:checked + label:after {
  /* background: #2ad156;
  border: 2px solid #2ad156; */
  border: solid #2ad156;
  content: "";
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.interview-chkbox > input.wrong-checkbox[type="checkbox"]:checked + label:after {
  /* background:#ff4141;
  border: 2px solid #ff4141; */
  border: solid #ff4141;
  content: "";
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.interview-chkbox > input.right-checkbox[type="checkbox"]:checked + label {
  color: #2ad156;
}
.interview-chkbox > input.wrong-checkbox[type="checkbox"]:checked + label {
  color: #ff4141;
}
.interview-chkbox > input.right-checkbox[type="checkbox"]:checked + label:before {
  border: 2px solid #2ad156;
}
.interview-chkbox > input.wrong-checkbox[type="checkbox"]:checked + label:before {
  border: 2px solid#ff4141;
}
.interview-chkbox input.wrong-checkbox:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #ff4141;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.interview-chkbox input.right-checkbox:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #2ad156;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* .alice-carousel__prev-btn-item {
  top: 10px;
}
.alice-carousel__next-btn-item {
  top: 10px;
} */



/* test result css started  */

.test-result-wrapper {
background: #fff;
height: 100vh;
}
.test-result-container {
  max-width: 92%;
  margin: 0 auto;
  padding: 2em 0em;
}
.alice-carousel {
  position: relative;
}
.alice-carousel__stage {
  /* display: flex; */
  padding: 0rem 2rem;
}
.alice-carousel__stage li {
  list-style: none;
}
.test-result-heading {
  font:  40px Segoe-Light;
  color: #01313C;
  margin: 0 0 20px 0;
}



.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2px;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  display:flex
}

.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
  width: 100% !important;
}

/* .alice-carousel__next-btn-item, .alice-carousel__prev-btn-item {
  display: none;
} */
@media (max-width: 981px) {
  .test-result-container {
    margin: 0rem 2rem;
  }
}
.test-result-container .playbuttonsection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.test-result-card {
  margin: 30px 30px 30px 30px;
  min-height: 70vh;
  width: 88vw !important;
  margin-top: 12vh;

}
.mainbgcenter {
  display: flex;
  justify-content: center;
}
.questionoption.questionoption-alignment [type="radio"]:checked + label, .questionoption.questionoption-alignment [type="radio"]:not(:checked) + label {
  padding-left: 0;
  display: flex;
  align-items: center;
  }
  .questionoption.questionoption-alignment [type="radio"]:not(:checked) + label:before {
  position: initial ;
  margin-right: 1rem;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment.right-question-option [type="radio"]:checked + label:before {
  position: initial;
  margin-right: 1rem;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment.right-question-option [type="radio"]:checked + label:after {
  top: initial;
  left: 7px;
  }
  .questionoption.questionoption-alignment.wrong-question-option [type="radio"]:checked + label:before {
  margin-right: 1rem;
  position: inherit;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment.wrong-question-option [type="radio"]:checked + label:after {
  left: 8px;
  top: inherit;
  }
  
  .questionoption-alignment .interview-chkbox-testresult input.right-checkbox:checked + label:after {
  top: inherit;
  height: 15px;
  border: solid #2AD156;
  border-width: 0 2px 2px 0;
  }
  
  .questionoption-alignment .interview-chkbox-testresult input.right-checkbox:checked + label:before {
  top: inherit;
  height: inherit;
  border: 2px solid #2AD156;
  
  }
  .questionoption-alignment .interview-chkbox-testresult > input.right-checkbox[type="checkbox"]:checked + label {
  color: #2AD156;
  display: flex;
  align-items: center;
  }
  .questionoption-alignment .interview-chkbox-testresult label:before{
  margin-top: inherit;
  }
  .questionoption-alignment .interview-chkbox-testresult input.right-checkbox:checked + label:after {
  top: inherit;
  height: 15px;
  }
  
  .questionoption-alignment .interview-chkbox-testresult > input.wrong-checkbox[type="checkbox"]:checked + label {
  color: #FF4141;
  display: flex;
  align-items: center;
  }
  .questionoption-alignment .interview-chkbox-testresult input.wrong-checkbox:checked + label:after {
  top: inherit;
  height: 15px;
  border: solid #FF4141;
  border-width: 0 2px 2px 0;
  }
  
  .questionoption-alignment .interview-chkbox-testresult input.wrong-checkbox:checked + label:before {
  top: inherit;
  height: inherit;
  border: 2px solid #FF4141;
  
  }
  
  .questionoption-alignment .interview-chkbox-testresult label {
  display: flex;
  align-items: center;
  }
  .test-result-card .alice-carousel__next-btn {
    right: 0px;
  }
  .string-whitespace-pre {
    white-space: pre;
  }
  .block-answer {
    white-space: pre;
  }
  @media (min-width: 768px) and (max-width: 1366px) {
    .scrollHeight2 {
      /* max-height: 68vh; */
      overflow-y: hidden;
    }
  }
  @media (min-width: 1367px) {
    .scrollHeight2 {
      /* max-height: 68vh; */
      overflow-y: hidden;
    }
  }
    

/* Portrait */
@media only screen and (max-width: 425px){
  .test-result-heading{
    font-size: 30px;
  }
  #testSkill_Name{
    font-size: 30px !important;
  }
  .playbuttonsection p{
    font-size: 15px !important;
  }
  .test-result-container .playbuttonsection {
    flex-direction: column;

  }
  #customWidthTest{
    width: 100% !important;
  }
  .test-result-card{
    width: 95vw !important;
    padding: 0 !important;
  }
  .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item{
    margin-right: -5px !important;
    /* margin-left: -5px; */
  }
  .viewtestresults-questionside{
    margin-left: 0px !important;
    margin-top: 20px !important;
    width: 100% !important;
  }
  #testQuestionDescp{
    width: 70vw !important;
  }
}

#testQuestionDescp h2 {
  margin-block-start: 0; /* or margin-block-start: initial; */
  font-size: 1em
}
#testQuestionDescp h1 {
  margin-block-start: 0; /* or margin-block-start: initial; */
  font-size: 1em
}