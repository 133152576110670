.addIntervalButton{
    margin-bottom: 20px;
    margin-top: -25px;
}

.deleteBtn{
    margin-left: 10px !important;
}

@media only screen and (min-width:640px) {
    .addIntervalButton{
        text-align: end;
        margin-bottom: 20px;
        margin-top: -25px;
    }
}


.mt_11 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 14.5px 8px !important;
}

.field_name {
    margin-bottom: 10px;
}