.mainContainer_notification {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #ffffff;
}

.topContainer_notification {}

.bottomContainer_notification {
    margin-top: auto;
}

.mainDiv_notification {
    margin: 0 5%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.titlesofCommunity_notification {
    font-size: 30px;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin: 10px 0px 20px;
    display: flex;
    align-items: center;
    width: max-content;
    color: #31B3F8;
    border-bottom: 1px solid #31B3F8;
    padding-bottom: 5px;
}

.newNotification_notification {
    color: #7F7F7F;
    font-family: "Segoe-regular";
    font-size: 16px;
}

.notification_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px 10px 7px 10px;
    border-bottom: 1px solid #636363;
}

.newAll_notification {
    display: flex;
    flex-direction: row;
}


.newtext_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.alltext_notification {
    color: #2896FF;
    font-family: "Segoe-regular";
    font-size: 18px;
    border-bottom: 3px solid #31B3F8;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.markAsReadButton {
    background-color: #2896FF !important;
    color: #ffffff !important;
    width: 160px;
}

.markAsReadButton_allReaded {
    background-color: #2896FF !important;
    color: #ffffff !important;
    width: 160px;
    opacity: 0.5;
}

.interviewedButoon_notification {
    background-color: #ffffff !important;
    color: #585858!important;
    width: 105px;
    border: 2px solid #31B3F8 !important;
}

.cardContainer_notification {
    height: 58vh;
    overflow: auto;
    padding: 0px 10px;
    margin-left: -10px;
    padding-top: 10px;
}

.cardContains_container_notification {
    background-color: #ffffff;
    box-shadow: 0px 3px 8px #0000003d;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.cardContains_container_notification_unread{
    box-shadow: 0px 3px 8px #0000003d;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #F3F9FF;
    border-left: 3px solid #2896FF;
    cursor: pointer;
}

.leftSideContainer_cardContainer_notification {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileImg_notification {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 15px;
}

.candidateDetail_notification {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.candidateDetail_informationContainer_notification {
    display: flex;
    flex-direction: row;
    margin: 5px;
    margin-top: 5px;
}

.candidateDetail_informationContainer_recruiter_notification {}

.candidateName_notification {
    padding-right: 15px;
    color: #000000;
    font-size: 20px;
    font-family: "Segoe-regular";
}

.interviewText_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 15px;
}

.activityTimeContainer_notification {
    display: flex;
    flex-direction: row;
    margin: 3px;
    /* justify-content: space-between; */
}

.activityTimingText_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 14px;
    margin-right: 20px;
}

.assignTo_notification {
    display: flex;
    flex-direction: row;
    margin: 3px;
}

.userIcon_notification {
    padding-right: 11px;
}

.recruiterName_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 15px;
}

.interviewedDataContainer_notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 65px;
}

.interviewedDate_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 14px;
    margin-left: 5px;
}

.interviewedButtonContainer_notification {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.calanderIcon_notification {
    height: 20px !important;
}

.NoRecentText_notification{
    font-family: "Segoe-Semibold";
    font-size: 20px;
    color: #393939;
    text-align: center;
  }
