.activityCapsule{
    background: white;
    display:flex;
    height:35px;
    border:2px solid #31b3f8;
    padding: 12px;
    border-radius: 10px;
    font-size: 15px;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    font-family: 'Segoe-Semibold', Tahoma, Geneva, Verdana, sans-serif;
}

.activityCapsule:hover{
    background-color: #c8e9fd;
}
.activityTabsTitleAction{
    
    display: inline;
}
.activityActionsTabs{
    /* background: yellow; */
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    /* gap:8%; */
}
.activityActionsTabs span{
    
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    gap:10%;
    margin-right: 8%;
    align-items: center;
    cursor: pointer;
}

.activityActionContainer{
    width: 90%!important;
    margin:auto;
}
.deleteIconeActivityTabs{
    color: red;
}
.editIconeActivityTabs{
 color:#18aefe;
}