.question-list-section-wrapper .MuiTableCell-head {
  font-weight: bold;
}
.answer-set-container {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
}
.answer-set-list {
  display: flex;
  align-items: center;
}
.question-list-section-wrapper {
  background: #fff;
}
.subkill-filter-question .MuiFormControl-root {
  width: 100%;
}
.question-list-section-heading h2 {
  margin-top: 0;
  margin-bottom: 15px;
}
.question-list-section-wrapper {
  padding: 1rem;
}
.subkill-filter-question {
  margin-bottom: 0.5rem;
}
.answer-set-list .MuiSvgIcon-root {
  font-size: 2rem !important;
}
.answer-set-list .MuiFormControlLabel-root {
  margin-right: 0;
}
.questions-list-table .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px;
}
.go-to-question a {
  color: #000;
}
.subkill-filter-question-addquestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subkill-filter-question-wrapper{
  width: 100%;
  margin-right: 1rem;
}
.circular-progress-center-question {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 100px;
}
@media(max-width: 767px) {
  .subkill-filter-question-addquestion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .subkill-filter-question-addquestion .subkill-filter-question-wrapper:first-child {
    margin-bottom: 1rem;
  }
}
.correct-answer-layout {
  background: #2AD156;
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 4px;
}
.searchBox {
  display: flex;
  /* justify-content: flex-start; */
  height: 100%;
  margin-bottom: 20px;
}

.searchBox .MuiInputBase-root {
  height: 110%;
  margin-right: 30px;
 
  width:100%
}
.textfield{
  width:"39%";
 margin-right:30px;
}
.searchLabel{
  margin-bottom: 20px;
}

.k-grid-header-locked > table, .k-grid-header-wrap > table {
  margin-bottom: -1px;
  
  width: 100% !important;
}

.k-grid-content table, .k-grid-content-locked table {
  table-layout: fixed;
  width: 100% !important;
}

.btn_question {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 11px 24px 11px 23px;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'Segoe-Semibold';
}
.cursorChange{
  cursor: pointer;
}

.mt_9 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 13.8px 10px !important; 
}