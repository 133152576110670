/* .container {
    padding: 3rem;
} */
 
.card-container{
    padding: 2rem 1rem;
}
 
.header-spoc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
 
.header-image {
    display: flex;
    align-items: center;
    gap: 20px;
}
 
.header-spoc .add-button {
    background-color: #31B3F8;
    color: #ffff;
}
.header-spoc .add-button .MuiButton-label{
    display: flex;
    flex-direction: row;
}
.header-spoc .add-button:hover{
    background-color: #31B3F8;
}
 
.add-image {
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: row;
}
 
.table-head {
    background-color: #31B3F8;
}
 
.card-container .edit-button {
    background-color: #31B3F8;
    color: #ffff;
}
.card-container .edit-button .MuiButton-label{
    display: flex;
    flex-direction: row;
}
.card-container .edit-button:hover{
    background-color: #31B3F8;
}
 
.dialog-title {
    background-color: #31B3F8;
}
 
.dialog-title .dialog-title-text{
    font-weight: 600;
    font-size: 30px;
    color: #ffff;
}
 
.dialog-image {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    vertical-align: middle;
}
 
.dialog-content {
    padding: 20px;
}
 
.dialog-button{
    display: flex;
    gap: 10px;
}
 
.dialog-button .dialog-submit-button {
    background-color: #31B3F8;
    color: #ffff;
}
 
.dialog-button .dialog-cancel-button {
    background-color: white;
    color: black;
    border: 1px solid black;
}
.manage-spoc-main {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    height: 75vh;

}


.manage-spoc-main .manage-spoc-main-head {
    font-size: 30px;
    color: #31B3F8;
    font-weight: 600;
    font-family: "Segoe-Semibold";
    border-bottom: 1px solid #31B3F8;
}
.manage-spoc-main .add-button-managespoc {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
.manage-spoc-main .add-button-managespoc:hover {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}

.active-field {
    height: 49px;
}

.MuiOutlinedInput-multiline {
    padding: 0px !important;
}




.add-spoc .dialog-title {
    background-color: #31B3F8;
    padding: 16px 24px !important;
}

.add-spoc .iconStyle {
    width: 35px;
    height: unset;
}

.add-spoc .dialog-title .dialog-title-text {
    font-weight: 600;
    font-size: 25px;
    color: #ffff;
}

.add-spoc .dialog-button {
    padding: 16px 18px !important;

}

.spoc-dialog-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}

.spoc-dialog-content {
    padding-top: 20px !important;
}

.field-title-spoc-dialog {
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}

.input-field-spoc-dialog input[type="text"] {
    height: 45px;
}

.dialog-actionbutton-spoc {
    padding: 15px 20px !important;
}

.cancel-button-spoc {
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}

.cancel-button-spoc:hover {
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}

.updateButton-spoc {
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}

.updateButton-spoc:hover {
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}

.updateButton_spoc_disabled {
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    gap: 10px;

}

.dialog-actionbutton-spoc .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
  }

  .spoc-dialog-content .MuiOutlinedInput-inputMultiline{
    padding: 18.5px 14px !important;
}

