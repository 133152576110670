/*--------------- Header CSS -------------*/
  
.nav_bg_left {
  width: 25%;
  background-color: #ffffff;
}
.marb{
  margin-bottom: 14px;
}
/* .nav_bg_right {
  width: 58%;
  background-color: #04093f;
} */
.mail_txt{
  font-size: 16px;
  margin-left: 5px;
  margin-right: 4px;
  font-family: "Segoe-Regular";
  color: #01313c;
}
.mail_text {
  font-size: 18px;
  margin-left: 8px;
  font-family: "Segoe-Regular";
  color: #01313c;
}
.mail_close_icon {
  cursor: pointer;
}


.nav_logo {
  margin-top: 22px;
  margin-bottom: 16px;
  width: 100%;
}
.navbar {
  width: 100%;
  overflow: auto;
  padding-inline-start: 0px;
}

.navbar li {
  list-style-type: none;
}

.navbar li img {
  vertical-align: middle;
}

.link {
  float: left;
  padding: 7px 19px 0px 19px;
  /* color: #d1e8ff; */
  text-decoration: none;
  font-size: 16px;
  font-family: "Segoe-Regular";
}
.share_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.support_dialog_title{
  position: relative;
}

.support_dialog_title #para{
  font-size: 23px;
  font-family: 'Manrope-Regular';
  color: #666666;
}
.support_lin{
  border-bottom: 1px solid #6398F1;
  width: 368px;
  font-family: 'Manrope-Medium';
  font-size: 36px;
  color: #1885E6;
  height: 52px;
}
.support_con{
  padding-top: 0px !important;
  padding-right: 28px !important;
}
.support_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
}
.support_grid{
  padding-right: 20px;
}

.support_form_leb{
  font-family: 'Manrope-Medium';
  font-size: 16px;
  color:#666666;
}

.profile_support {
  margin-top: 5px;
}
.support_grid_message{
  margin-top: 20px !important;
  padding-bottom: 0px;
}

.support_message{
  height: 140px;
  width: 100%;
  border: 1.4px solid #707070;
  opacity: 0.6;
  border-radius: 3px;
}

.nav_menu_icon {
  padding-right: 5px;
}

.notify-badge {
  position: absolute;
  top: 5px;
  margin-left: 9px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  background: #2ad156;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: 8px;
}
.contact_header{
  font-weight: normal!important;
  font-family: 'Segoe-Light'!important;
  font-size: 68px!important;
  color: #01313C!important;
}
.MuiTypography-h6 {
  font-family: 'Segoe-Light'!important;
  font-size: 28px!important;
  color: #01313C!important;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 6px 24px;
}
.contact_details_heading{
  font-size: 20px!important;
  font-weight: 600!important;
  font-family: 'Segoe-Semibold'!important;
  color: #01313C!important;
}
/* @media only screen and (max-width: 768px) {
  .nav_logo {
    margin-top: 22px;
    margin-bottom: 16px;
    
    width: 80%;
  }

  .nav_bg_left {
    width: 100% !important;
    background-color: #FFFFFF;
  }
ma
  .nav_bg_right {
    width: 100% !important;
    background-color: #04093f;
  }

  .link {
    float: left;
    padding: 7px 4px 0px 4px !important;
    color: #d1e8ff;
    text-decoration: none;
    font-size: 16px;
    font-family: "Segoe-Regular";
  }
} */
.navbar-wrapper {
  display: flex;
  justify-content: flex-end;
}
.header-skeleton .MuiSkeleton-text {
  transform-origin: 0 0%;
}
.header-skeleton {
  height: 0;
}
.ml_3
{
  margin-left: 3px;
}

.menuContainer_header{
  /* margin-top: 20px;
    margin-right: 4px; */
    flex-grow: 0 !important;
}

.sharecontainer_header{
  display: flex;
  flex-direction: row;
}

.shareIcon_header{
  margin-right: 15px;
  color: #1885E6;
  margin-top: 7px;
}

.shareText_header{
  font-family: 'Segoe UI';
    font-size: 20px;
    color: #1885E6;
}

.menu_header{
  margin-top: 40px !important;
  margin-left: -1% !important;
 
}

.profile_drop_menu_header{
  position: absolute;
    top: 54px !important;
   right: 115px;
  
}

@media screen and (max-width: 1100px) {
  .profile_drop_menu_header{
    right: 4vw;
  }
}

.account_nav_header{
  margin-top: 20px;
}

.trackCandidate_header{
  height: 25px;
}

