.date_icon {
    vertical-align: middle;
    padding-right: 0px;
}

.ManageInterviewModalHeader{
    display: flex;
    padding: 20px 0px 0px 40px;
    box-shadow: rgb(50 50 93 / 25%) 0px 0px 12px -2px, rgb(0 0 0 / 30%) 0px 2px 7px -3px;
}
.ManageInterviewModalHeader legend{
    font-size: 30px !important;
}

.InterviewTimeContainer{
    font-size: 22px !important;
}
#btmModalContainer{
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px -3px 7px -3px !important;
}

/*----------- Add Day & Time  -------------*/

.addDay_time {
    width: 750px;
}


/*------------- CHECKBOX ---------------*/
.adddaychk {
    float:left;
    margin-right: 0.2em;
    }
    
    .adddaychkheight {
    
    /*height: 50px;*/
    display: flex;
    align-items: center;
    }
    #InterviewTimeCheckbox .MuiSvgIcon-root{
        font-size: 2rem !important;
    }
    #InterviewTimeCheckbox .MuiCheckbox-colorPrimary.Mui-checked{
        color:#1A8FFF !important;
    }
    #btmModalLine hr{
        border-top: 2px solid #1a8fff !important;
    }
.addday_chkbox  .addday_chkboxs{
    display: block;
    margin-bottom: 20px;
    /*margin-top: 23px;*/
}

/* .addday_chkbox  */
.addday_chkboxs input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

/* .addday_chkbox  */
.addday_chkboxs label {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Segoe-Semibold';
    font-size: 19px;
    color: #01313C;
}

.addday_chkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #01313C;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 16px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 9px;
    float: left;
}

/* .addday_chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 14px;
    width: 7px;
    height: 17px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */
.addday_chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: -29px;
    width: 6px;
    height: 14px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    }

.addday_chkbox :checked+label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #1A8FFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 16px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    /* margin-right: 7px; */
}


/*------------- CHECKBOX ---------------*/


/*----------- Add Day & Time  -------------*/

.ReactModal__Overlay {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    overflow-y: scroll !important;
}

.interviewtime_modal {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 750px !important;
    margin: 30px auto !important;
    position: absolute !important;
}

.modal_popup_close {
    position: absolute;
    top: 24px;
    right: 24px;
    
}
/* .MuiDialog-paperWidthSm {
    max-width: 750px!important;
} */
.calender_adjust{
    height: auto;
     padding: 10px 4px 10px 14px;
    width: 94%
}
.text_box_calender{
    width: 94%;
height: 45px;
padding: 0px 0px;
font-size: 14px;
/* line-height: 1.42857143; */
padding: 0px 20px;
font-size: 16px;
font-family: 'Segoe-Regular';
font-style: normal;
font-weight: normal;
background-color: #fff;
background-image: none;
border: 1px solid#c6c0c0;
border-radius: 4px;
}
/* .WithStyles\(ForwardRef\(DialogContent\)\)-root-1 {
    padding: 42px 42px 0px 42px!important;
}
.WithStyles\(ForwardRef\(DialogActions\)\)-root-2 {
    margin: 0;
    padding: 33px 42px 42px 42px!important;
} */

@media only screen and (max-width: 768px) {
    /* .WithStyles\(ForwardRef\(DialogContent\)\)-root-1 {
        padding: 42px 18px 0px 18px!important;
    }
    .WithStyles\(ForwardRef\(DialogActions\)\)-root-2 {
        margin: 0;
        padding: 33px 18px 42px 18px!important;
    } */
    .text_box_calender{
            /* width: 94%; */
    height: 45px;
    padding: 0px 0px;
    font-size: 14px;
    /* line-height: 1.42857143; */
    padding: 0px 20px;
    font-size: 16px;
    font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
    }
    .calender_adjust{
        height: auto;
         padding: 10px 4px 10px 14px;
        width: 93%
    }
    .interviewtime_modal {
        background: rgb(255, 255, 255) !important;
        overflow: auto !important;
        border-radius: 4px !important;
        outline: none !important;
        width: 86% !important;
        margin: 30px auto !important;
        position: absolute !important;
        margin-top: 0%!important;
        padding: 0px 20px 0px 20px !important;
        height: 615px;
        
    }
    .interviewtime {
        width: 100%;
    }
    .addday_chkbox label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #01313C;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 16px!important;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 9px;
        float: left;
        margin-top:9px;
        }
        
        .addday_chkbox input:checked+label:after {
            content: '';
            display: block;
            position: absolute;
            top: 14px;
            left: -29px;
            width: 7px;
            height: 17px;
            border: solid #1A8FFF;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .modal_popup_close {
            position: absolute;
            top: 24px;
            /* right: -64px; */
            left: 305px;
        }
        
        .adddaychk {
        float:none;
        }
        
        .adddaychkheight {
        
        height: 50px;
        }
        .addday_chkbox label {
            position: relative;
            cursor: pointer;
            font-weight: 600;
            font-family: 'Segoe-Semibold';
            font-size: 18px;
            color: #01313C;
        }
    }



    @media (min-width:769px) and (max-width:1366px) {
    .addday_chkbox {
        /* display: block; */
        display: flex;
        margin-bottom: 20px;
        /*margin-top: 23px;*/
    }
    
    .addday_chkbox input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }
    
    .addday_chkbox label {
        position: relative;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Segoe-Semibold';
        font-size: 18px;
        color: #01313C;
    }
    
    .addday_chkbox label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #01313C;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 12px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 9px;
        float: left;
    }
    
    .addday_chkbox input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 14px;
        width: 7px;
        height: 17px;
        border: solid #1A8FFF;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .addday_chkbox input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: -25px;
        width: 6px;
        height: 12px;
        border: solid #1A8FFF;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        }
    
    .addday_chkbox :checked+label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #1A8FFF;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 12px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        /* margin-right: 7px; */
    }
}

.resume [type="radio"]:checked+label.seeking-oportunity-radio-label, .resume [type="radio"]:not(:checked)+label.seeking-oportunity-radio-label {
    font-size: 18px;
}

@media only screen and (max-width: 600px) {
    .seeking-oportunity-radio-label{
        margin-right: 12px;
    }}

.powres_inter{
    display: flex;
    flex-direction: column;
    height: 954px;
    width: 225px;
    
}

.manageinterview_datetime{
    margin-bottom: 10px;
}

    