.profileContainer {
    display: flex;
    justify-content: center;
}

.candidate_specific_container {
    padding-right: 15px;
    padding-left: 15px;
    /* margin-left: 156px; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    /* margin-bottom: 20px; */
}

.candidate_specific_container_margin_bottom {
    margin-bottom: 20px;
}

.candidate_specific_w1004 {
    max-width: 1709px
}

.candidate_specific_full_body {
    display: flex;
    /* justify-content: space-between; */
    /* margin-top: 0px; */
    margin: 0 5vh;
}

.candidate_profile_section {
    /* font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 300;
    font-size: 29px; */
}

.candidate_profile_top_align {
    margin-top: 11vh;
    text-align: left;
}

.cs_lastUpdate_align {
    text-align: left;
}

.candidate_profile_text_color {
    color: #1885E6;
    font-size: 29px;
    font-weight: 300;
    font-family: SegoeUI-Semilight, "Segoe UI"
}

.main_candidate_specific_extra_padding {
    /* padding: 14px 8px 20px 8px; */
}

.main_candidate_specific_profile_card {
    position: relative;
}

.main_candidate_specific_card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease-in-out;
    /* padding: 14px 20px 20px 20px; */
    border-radius: 4px;
}

.main_candidate_specific_card_wd {
    /* width: 355px; */
    /* height: 647px; */
}

.main_powerresume_specific_card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease-in-out;
    /* padding: 14px 20px 20px 20px; */
    border-radius: 4px;
}

.main_powerresume_specific_card_wd {
    width: 1095px;
    /* height: 647px; */
}

.cs_lastUpdate_font {
    color: #01313c;
    font-size: 15px;
    font-family: SegoeUI-Semibold, "Segoe UI";
    font-weight: 600;
}

.candidate_specific_flex_to_footer {
    flex: 1;
    width: 100%;
}

.margin_main_right {
    margin-top: 12vh;
    margin-left: 20px;
}

.main-right_class {

    width: 73%;
}

.section-wrapper-candidateprofile {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


/* .section-wrapper-candidateprofile {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: auto !important;
    background-color: #ffffff;
} */

.bg_color_cadidate_profile {
    background-color: #ffffff;
}

.profile_footer_candidate {
    margin-top: auto
}

.candidate_footerflex {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #ffffff;
}

@media (max-width: 768px) {
    .candidate_specific_flex_to_footer {
        width: auto;
    }

    .candidate_specific_container {
        padding-right: 15px;
        padding-left: 15px;
        margin-left: auto;
        margin-right: auto;

    }

    .candidate_specific_container_margin_bottom {
        margin-bottom: 0px;
    }

    .candidate_profile_top_align {
        margin-top: 10vh;
        text-align: center;
    }

    .cs_lastUpdate_align {
        text-align: center;
    }

    .margin_main_right {
        margin-top: 3vh;
        margin-left: 2px;
    }

    .main-left_class {
        /* max-width: 25%; */
        width: 25%;
    }

}

@media only screen and (max-width: 768px) {
    .candidate_specific_full_body {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0px;
    }

    .candidate_specific_container_margin_bottom {
        margin-bottom: 0px;
    }

}

@media screen and (min-width: 820px) and (max-width: 912px) {
    .margin_main_right {
        margin-top: 8vh;
        margin-left: 1vh;
    }

    .candidate_profile_top_align {
        margin-top: 8vh;
        text-align: left;
    }

    .candidate_specific_container_margin_bottom {
        margin-bottom: 0px;
    }

}

@media (max-width: 767px) {

    .section-wrapper-candidateprofile {
        display: block;
        flex-direction: column;
        min-height: 100vh;
    }
}

/*new CSS*/

.mainContainer_candidateProfilePoweresume {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #ffffff;
}

.topContainer_candidateProfilePoweresume {}

.bottomContainer_candidateProfilePoweresume {
    margin-top: auto;
}

.mainDiv_candidateProfilePoweresume {
    margin: 0 5%;
    margin-top: 125px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
}
.mainDiv_candidateProfilePoweresume_shared{
    margin: 0 5%;
    margin-top: 125px;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.leftDiv_candidateProfilePoweresume {
    width: 360px;
    display: flex;
    flex-direction: column;
}

.rightDiv_candidateProfilePoweresume {
    min-width: calc(100% - 380px);
    min-height: 65vh;
}
.rightDiv_candidateProfilePoweresume{
    min-height: 65vh;
    width: calc(100% - 380px);
}

.leftDiv_candidateProfilePoweresumeDisplayNone{
    display: none;
}
.mainDiv_candidateProfileOnlyPoweResume {
    margin: 0 5%;
    margin-top: 125px;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

@media screen and (max-width: 1020px) {
    .mainDiv_candidateProfilePoweresume {
        display: flex;
        flex-direction: column;
    }
    .poweResumeInfoCard_poweResumeRight {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .leftDiv_candidateProfilePoweresume {
        width: 80vw;
    }
    .rightDiv_candidateProfilePoweresume {
        width: 85vw;
    }
}

.circularprogress_profile{
    height: 400px !important;
    width: 400px !important;
    color: #05A6F4 !important;
}