/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


body {
  margin: 0px;
  padding: 0px;
}

.mainbg {
  background-color: #fff;
}

p {
  margin: 0px;
}

 @font-face {
  font-family: 'Manrope-Light';
  font-style: normal;
  font-weight: normal;
  src: local('fonts/Manrope-Light'), url('../../resources/fonts/Manrope-Light.ttf') format('woff');
}

@font-face {
  font-family: 'Manrope-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('fonts/Manrope-Medium'), url('../../resources/fonts/Manrope-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('fonts/Manrope-Regular'), url('../../resources/fonts/Manrope-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Manrope-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('fonts/Manrope-SemiBold'), url('../../resources/fonts/Manrope-SemiBold.ttf') format('woff');
}

@font-face {
  font-family: 'Manrope-Bold';
  font-style: normal;
  font-weight: 600;
  src: local('fonts/Manrope-Bold'), url('../../resources/fonts/Manrope-Bold.ttf') format('woff');
}

/*------------- BUTTON CSS COMPONENT ---------------*/

.btn-home {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 10px 14px 10px 14px;
  font-size: 0.8125em;
  border-radius: 8px;
   cursor: pointer;
  font-family: 'Manrope-Regular';
}

.btn1-home {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 19px 10px 19px 10px;
  font-size: 0.875em;
  border-radius: 11px;
  cursor: pointer;
  font-family: 'Manrope-Medium';
}

.btn2-home {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 23px 32px 23px 32px;
  font-size: 1.75em;
  border-radius: 11px;
   cursor: pointer;
  font-family: 'Manrope-SemiBold';
}

.btn-start {
  color: #fff;
  background: #009EFD 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.btn-start:hover {
  color: #fff;
  background-color: #26EDA1;
}

.btn-start:focus {
  outline: none;
}


.btn-start_big {
  color: #fff;
  background: #009EFD 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  transition: all .2s ease-in-out; 
}

.btn-start_big:hover {
  color: #fff;
  background-color: #26EDA1;
  transform: scale(1.3) !important;
  
}

.btn-start_big:focus {
  outline: none;
}


.btn-date {
  color: #01313C;
  background-color: #fff;
  border: 1px solid #A3BCD5;
  padding: 7px 24px 9px 8px;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  font-family: 'Segoe-Semibold';
}

.btn-date:hover {
  color: #01313C;
  background-color: #fff;
  border: 1px solid #A3BCD5;
}

.btn-date:focus {
  outline: none;
}

.btn-record {
  color: #01313C;
  background-color: #D1E8FF;
  border: 1px solid #A3BCD5;
  font-weight: 600;
  padding: 7px 24px 9px 23px;
  font-size: 16px;
  font-family: 'Segoe-Semibold';
}

.btn-record:hover {
  color: #01313C;
  background-color: #D1E8FF;
  border: 1px solid #A3BCD5;
}

.btn-record:focus {
  outline: none;
}

.preference-btn {
  color: #01313C;
  background-color: #D1E8FF;
  width: 100%;
  font-weight: 600;
  padding: 7px 24px 9px 18px;
  font-size: 12px;
  text-align: center;
  font-family: 'Segoe-Semibold';
}

.preference-btn:focus {
  outline: none;
}


/*------------- BUTTON CSS COMPONENT---------------*/


/*-------------------------------------------------------- Hints------------------------------------------------------------
mr= margin-right, ml= margin-left, mt = margin-top, mb= margin-bottom, pt= padding-top, pb= padding-bottom, pl= padding-left
pr=padding-right, fs= font-size, mw=min-width, fsi=font-style, pr=position-relative
-------------------------------------------------------- Hints------------------------------------------------------------*/


/*------------- COMMON CSS COMPONENT ---------------*/

.row {
  width: 100%;
}

.fl {
  float: left;
}

.card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
  transition: all .2s ease-in-out;
  padding: 24px 20px 23px 20px;
  border-radius: 4px;
  background-color: #FFFFFF;
  /*float: right;*/
}

.text-uppercase {
  text-transform: uppercase;
}

.f-color {
  color: #01313C;
}

.fs-semi-bold {
  font-weight: 600;
  font-family: 'Segoe-Semibold';
}

.fs-bold-italic {
  font-weight: 600;
  font-family: 'Segoe-bold-italic';
}

.text_italic {
  font-style: italic;
}

.fs-regular {
  font-weight: normal;
  font-family: 'Segoe-Regular';
}

.fs-light {
  font-weight: normal;
  font-family: 'Segoe-Light';
}

.fs-semi-light {
  font-weight: normal;
  font-family: 'Segoe-semi-Light';
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.pt_13 {
  padding-top: 13px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-25 {
  font-size: 25px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-35 {
  font-size: 35px;
}

.fs-44 {
  font-size: 44px;
}

.fsi {
  font-style: italic;
}

.w100 {
  width: 100%;
}

.fs-12 {
  font-size: 12px;
}

.mr_14 {
  margin-right: 14px;
}

.pr {
  position: relative;
}

.mt_17 {
  margin-top: 17px;
}

.mt_18 {
  margin-top: 18px;
}

.mt_19 {
  margin-top: 19px;
}

.mt_23 {
  margin-top: 23px;
}

.mt_22 {
  margin-top: 22px;
}

.text-center {
  text-align: center;
}

.mt_9 {
  margin-top: 9px;
}

.mt_10 {
  margin-top: 10px;
}

.green_text {
  color: #2AD156;
}

.mt_15 {
  margin-top: 15px;
}

.mt_41 {
  margin-top: 41px;
}

.pull-right {
  float: right;
}

.mt_6 {
  margin-top: 6px;
}

hr.new1 {
  border-top: 1px solid #A3BCD5;
}

.df {
  display: flex;
}

.ml_25 {
  margin-left: 25px;
}

.ml_57 {
  margin-left: 57px;
}

.ml_41 {
  margin-left: 41px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_21 {
  margin-left: 21px;
}

.pr_23 {
  padding-right: 23px;
}

.pr_18 {
  padding-right: 18px;
}

.pr_26 {
  padding-right: 26px;
}

.ml_12 {
  margin-left: 12px;
}

.ml_22 {
  margin-left: 22px;
}

.ml_25 {
  margin-left: 25px;
}

.mw {
  width: 50%;
}

.skill_video_sec_left {
  width: 37%;
}

.skill_video_sec_right {
  width: 63%;
}

.skill_video_width {
  width: 750px;
}

.mt_40 {
  margin-top: 40px;
}

.mw_36 {
  width: 36%;
}

.mw_50 {
  width: 50%;
}

.primary_background {
  background-color: #F5F9FC
}

.date_icon {
  vertical-align: middle;
  padding-right: 12px;
}

.profile_icon {
  vertical-align: middle;
}

.pt_6 {
  padding-top: 6px;
}

.pt_23 {
  padding-top: 23px;
}

.pl_8 {
  padding-left: 8px;
}

.pt-24 {
  padding-top: 24px;
}

.mb_3 {
  margin-bottom: 3px;
}

.w1200 {
  max-width: 1200px;
}

.main-left {
  max-width: 25%;
}



/*------------- COMMON CSS COMPONENT ---------------*/


/*------------- VIDEO INTERVIEW TOP ---------------*/

.playButton {
  width: 65px;
  height: 65px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.flex-video-top {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}


/*------------- VIDEO INTERVIEW TOP ---------------*/


/*--------------- NAV CSS -------------*/

.nav_bg_home_section {
  display: flex;
  justify-content: space-between;
}

.home-specific-header.nav_bg_home {
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 99;
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.34);
}

.home-specific-header .nav_bg_left {
  width: 25%;
  background-color: transparent;
}

.home-specific-header .nav_bg_right {
  background-color: transparent;
}

.w-100 {
  width: 100%
}

.nav_logo_home {
  margin-top: 23px;
  margin-bottom: 25px;
  width: 100%
}

.navbar_home {
  width: 100%;
  /*overflow: auto;*/
}

.navbar_home li {
  list-style-type: none;
  display: inline;
}

.navbar_home li a {
  padding: 10px 0px 0px 0px;
  color: #000000;
  text-decoration: none;
  font-size: 1.0625em;
  font-family: 'Manrope-Regular';
  margin-left: 20px;
}

.navbar_home .special_hover a:hover {
  padding: 5px 0px 5px 0px;

  
  border-bottom:2px solid #474747;
}


/*.navbar_home li a:hover {
  
  padding: 7px 24px 9px 23px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Segoe-Regular';
  background-color: #03A2F8;
  border-radius:8px;
}*/

.banner_text_section {
  position: absolute;
  bottom: 30%;
  width: 100%;
}
.f_bold {
  font-weight: bold;
}
.banner_text_section1 {
  position: absolute;
  top: 60%;
  width: 100%;
}

.banner_button {}

.banner_text_top {
  font-family: 'Manrope-Bold';
  font-size: 3.25em;
  color: #EFEFEF;
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .banner_bottom_section-2 {
  width: 100%;
  position: absolute;
  top: 63%;
}
.banner_text_top {
  font-family: 'Manrope-Bold';
  font-size: 3.5em;
  color: #EFEFEF;
}

.banner_text_bottom {
  font-family: 'Manrope-Regular';
  font-size: 2.5625em;
  color: #fff;
}
.banner_text_section {
  position: absolute;
  top: 45%;
  width: 100%;
}


}
.banner_text_top_other_color {
  color: #009EFD
}

.banner_text_bottom {
  font-family: 'Manrope-Regular';
  font-size: 1.75em;
  color: #fff;
}

.banner_section {
  width: 100%;
  position: relative;
  overflow: hidden;
  /* top:60px; */

}
/* .banner_section-empoyer {
  background-image: url('../images/Banner-2.png');
  width: 100%;
  height: 100%;
  position: absolute;
  z-index:9;
} */

.banner_bottom_section {
  width: 100%;
  background-color: #fff;
}

.banner_bottom_section-1 {
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
  position: absolute;
  top: 40%;
}

.banner_bottom_section-2 {
  width: 100%;
  position: absolute;
  top: 63%;
}

.start_button_section {
  width: 100%;
  position: absolute;
  top: 60%;
}

.banner_bottom_section_employers_left {
  font-size: 1.5625em;
  color: #474747;
  padding: 20px 0px 0px 0px;
  font-family: 'Manrope-SemiBold';
  text-align: left;
  /* text-shadow: 0px 3px 6px #00000029; */
}


.banner_bottom_section_employers_right {
  font-size: 1.5625em;
  color: #474747;
  padding: 20px 0px 20px 0px;
  font-family: 'Manrope-SemiBold';
  float: right;
  text-align: right;
  /* text-shadow: 0px 3px 6px #00000029; */
}

.banner_bottom_section_employers_right_other_color {
 
  color: #009EFD;
 
  font-family: 'Manrope-Bold';
 
}

.banner_bottom_section_employers-1 {
  font-size: 1.875em;
  color: #474747;
  padding: 50px 0px 50px 0px;
  width:80%;
  font-family: 'Manrope-Medium';
  /* text-shadow: 0px 3px 6px #00000029; */
}

.banner_bottom_section_employers-1-other_color{
  color: #009EFD;
  font-family: 'Manrope-Bold';
  /* text-shadow: 0px 3px 6px #00000029; */
}

.banner_bottom_section_register {
  font-size: 1.3125em;
  color: #474747;
  padding: 50px 0px 50px 0px;
  width: 78%;
  font-family: 'Manrope-Medium';
  /* text-shadow: 0px 3px 6px #00000029; */
}

.banner_bottom_section_register_other_color {
  
  color: #009efd;
 
  font-family: 'Manrope-Bold';
  
}

.banner_bottom_section_text_left {
  font-size: 1.75em;
  color: #474747;
  padding: 35px 0px 70px 0px;
  font-family: 'Manrope-Medium';
  /* text-shadow: 0px 3px 5px #00000029; */
}

.employer_banner_bottom_section_text_left {
  font-size: 1.875em;
  color: #474747;
  padding: 61px 0px 0px 0px;
  font-family: 'Manrope-Bold';
  /* text-shadow: 0px 3px 5px #00000029; */

}

.employer_banner_bottom_section_text_left_bottom {
  font-size: 1.5625em;
  color: #474747;
  padding: 0px 0px 60px 0px;
  font-family: 'Segoe-Regular';
  /* text-shadow: 0px 3px 5px #00000029; */
}

.employer_banner_bottom_section_text_left_bottom_top {
  font-size: 1.8625em;
  color: #474747;
  padding: 50px 0px 50px 0px;
  font-family: 'Manrope-Medium';
  /* text-shadow: 0px 3px 5px #00000029; */
  line-height: 2.1;
}

.banner_left_text_section_employers {
  width: 100%;
}

.banner_left_text_section {
  width: 50%;
}


.banner_bottom_section_text_right {
  color: #474747;
  font-size: 1.875em;
  padding: 117px 0px 46px 0px;
  font-family: 'Manrope-SemiBold';
}

.banner_bottom_section_text_right_other_color {
  color: #009EFD;
  
  font-family: 'Manrope-Bold';
}

.banner_bottom_section_text_other_color {
  color: #009EFD;
  font-family: 'Manrope-SemiBold';
}

.banner_bottom_section_wrapper {
  display: flex;
  justify-content: space-between;
}

.home_step_section {
  width: 100%;
  position: absolute;
  top: 5%;
}

/* .full_body {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
} */

.proile-fwd-icon {
  vertical-align: super;
}

.profile-text {
  display: inline-block;
  width: 82px;
  margin-left: 5px;
}

.profile_card {
  position: relative;
}

/* .profile_top {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border-bottom: 1px solid #A3BCD5;
  padding: 8px 0px 8px 0px;
} */

.parallax {
  /* The image used */
  background-image: url("./images/Mask\ Group\ 2-768.png");
  /* Set a specific height */
  min-height: 1200px;
  background-attachment: fixed;
  background-position: 10% 66%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.parallax_employers {
  /* The image used */
  background-image: url("./images//Mask\ Group\ 22.jpg");
  /* Set a specific height */
  min-height: 1200px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: 10% -60%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.paralox_inner {
  position: absolute;
  top: 5%;
  left: 20px;
  height: 300px;
  border: 1px solid;
}


/*--------------- FOOTER CSS -------------*/

.footer_bg_employerpage {
  width: 100%;
  background-color: #C2C2C2;
  position: absolute;
  top: 80%
}

.footer_bg_candidates {
  width: 100%;
  background-color: #C2C2C2;
  position: absolute;
  top: 77%
}

.footer_adjust {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-top: 25px;
}

.footer_bg_sec {
  width: 25%;
}

.footer_bg_candidates .footer_links {
  margin-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
}
.footer_bg_candidates .f_height {
  height: initial;
}

.footer_bg_candidates .footer_links li {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px;
}

.footer_bg_candidates .footer_links li:last-child {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px 0px 0px;
}

.footer_bg_candidates .footer_links li a {
  color: #000;
  text-decoration: none;
  font-size: 1.0625em;
  font-family: 'Manrope-Medium';
}

.footer_bg_candidates .footer_links li a:hover::after {
  opacity: 1;
}

.footer-text-color {
  color: #D1E8FF;
}

.footer-copyright {
  opacity: .4;
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
}

.events li {
  display: flex;
  color: #000000;
  height:100px;
  font-size: 1.4375em;
  font-family: 'Manrope-SemiBold';
}

.events time {
  position: relative;
  padding: 0 .5em;
}

.events time::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  border-radius: 50%;
  background: #009EFD;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #009EFD;
  opacity: 1;
  width: 33px;
  height: 32px;
}

.events span {
  padding: 0px 34px;
  position: relative;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left:-1px;
  height: 100%;
  border-left: 3px #009EFD solid;
  top: 32px;
}

.last_border_none::before {
  border-left: none !important;
}

.events strong {
  display: block;
  font-weight: bolder;
}

.events {
  /*margin: 1em;*/
  padding-left: 0px;
  width: 100%;
}

.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  font-family: arial;
}

@keyframes zoominoutsinglefeatured {
  0% {
      transform: scale(1, 1);
      /* animation-duration: 200ms; */
  }
  50% {
      transform: scale(1.2, 1.2);
      /* animation-duration: 200ms; */
  }
  100% {
      transform: scale(1.2, 1.2);
      /* animation-duration: 200ms; */
  }
}

.cardcontainer img {
  animation: zoominoutsinglefeatured 20s;
}

.request_demo_sec {
  
  width: 100%
}

.request_button{
  width: 30%;
  display: flex;
  
  flex-direction: row;
  align-items: center;
}
/* .carousel-indicators [data-bs-target] {
  width: 11px;
  height: 9px;
  background: #fff 0% 0% no-repeat padding-box;

  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 50%;
} */
/* .carousel-indicators .active {
  background: #008FE4 0% 0% no-repeat padding-box;
}
.carousel-indicators {
  top: 200%;
} */
/* .btn1.btn-start_big.aos-init.aos-animate:hover {
  transform: scale(1.3) !important;
} */

.home-specific-header .nav_bg_right {
  display: flex;
  align-items: center;
}
.navbar_home {
  margin: 0;
}

.buller-wrapper {
  display: flex;
}
.circle-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bullet-circle {
  background: #009efd;
  width: 33px;
  height: 33px;
  border-radius: 50%;
}
.bullet-line {
  width: 3px;
  background: #009efd;
  height: 66px;
  /* transform-origin: 100% 0%; */
}
[data-aos="reveal-top"] {
  transform: scaleY(0);
  transform-origin: 100% 0%;
  transition-property: transform;
  transition-delay: 1500ms;
}
[data-aos="reveal-top"].aos-animate {
  transform: scaleY(1);
}

.bullet-text {
  font-size: 1.4375em;
  font-family: 'Manrope-SemiBold';
  color: #000;
  margin-left: 20px;
}
.buller-wrapper:last-child .bullet-line{
  display: none;
}
.request-demo {
 font-size: 1.7em;
 padding: 25px 20px;
}
/* .bullet-line {
  animation: mymove 5s;
} */
/* @keyframes mymove {
  from {height: 0px;}
  to {height: 66px;}
}
 */

 /* .cardcontainer img {
  animation: zoominoutsinglefeatured 5s infinite;
} */
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  transform: scale(1.3);
  transition: all .2s ease-in-out;
}

@media (min-width: 1200px) {
    /* .container {
      max-width: 90% !important;
    } */
}
/* .carousel-caption {
  position: initial;
  left: 0;
  bottom: 0;
  padding: 0;
  text-align: left;
} */
.carousel-indicators li {
 background: #fff;
  width: 10px;
  height: 10px;
  margin: 0rem 0.5rem;
  cursor: pointer;
  border-radius: 50%;
}
.carousel-indicators li.active {
  background: #009efd;
 }
 .carousel-control-prev, .carousel-control-next {
   display: none;
 }
@media(min-width: 1200px) {
  .carousel-indicators {
    top: 10em;
  }
}
.text_box_calender.homepage-text_box_calendar {
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}

.container-padding-left {
  padding-left: 17px;
}



[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
	transition-duration: 50ms
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
	transition-delay: 50ms
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
	transition-duration: .1s
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
	transition-delay: .1s
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
	transition-duration: .15s
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
	transition-delay: .15s
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
	transition-duration: .2s
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
	transition-delay: .2s
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
	transition-duration: .25s
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
	transition-delay: .25s
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
	transition-duration: .3s
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
	transition-delay: .3s
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
	transition-duration: .35s
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
	transition-delay: .35s
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
	transition-duration: .4s
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
	transition-delay: .4s
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
	transition-duration: .45s
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
	transition-delay: .45s
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
	transition-duration: .5s
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
	transition-delay: .5s
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
	transition-duration: .55s
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
	transition-delay: .55s
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
	transition-duration: .6s
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
	transition-delay: .6s
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
	transition-duration: .65s
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
	transition-delay: .65s
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
	transition-duration: .7s
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
	transition-delay: .7s
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
	transition-duration: .75s
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
	transition-delay: .75s
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
	transition-duration: .8s
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
	transition-delay: .8s
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
	transition-duration: .85s
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
	transition-delay: .85s
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
	transition-duration: .9s
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
	transition-delay: .9s
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
	transition-duration: .95s
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
	transition-delay: .95s
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
	transition-duration: 1s
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
	transition-delay: 1s
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
	transition-duration: 1.05s
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
	transition-delay: 1.05s
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
	transition-duration: 1.1s
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
	transition-delay: 1.1s
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
	transition-duration: 1.15s
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
	transition-delay: 1.15s
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
	transition-duration: 1.2s
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
	transition-delay: 1.2s
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
	transition-duration: 1.25s
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
	transition-delay: 1.25s
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
	transition-duration: 1.3s
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
	transition-delay: 1.3s
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
	transition-duration: 1.35s
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
	transition-delay: 1.35s
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
	transition-duration: 1.4s
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
	transition-delay: 1.4s
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
	transition-duration: 1.45s
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
	transition-delay: 1.45s
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
	transition-duration: 1.5s
}



[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
	transition-delay: 1.5s
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
	transition-duration: 1.55s
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
	transition-delay: 1.55s
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
	transition-duration: 1.6s
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
	transition-delay: 1.6s
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
	transition-duration: 1.65s
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
	transition-delay: 1.65s
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
	transition-duration: 1.7s
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
	transition-delay: 1.7s
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
	transition-duration: 1.75s
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
	transition-delay: 1.75s
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
	transition-duration: 1.8s
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
	transition-delay: 1.8s
}
[data-aos][data-aos][data-aos-delay="5000"].aos-animate,
body[data-aos-delay="5000"] [data-aos].aos-animate {
	transition-delay: 5s
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
	transition-duration: 1.85s
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
	transition-delay: 1.85s
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
	transition-duration: 1.9s
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
	transition-delay: 1.9s
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
	transition-duration: 1.95s
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
	transition-delay: 1.95s
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
	transition-duration: 2s
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
	transition-delay: 2s
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
	transition-duration: 2.05s
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
	transition-delay: 2.05s
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
	transition-duration: 2.1s
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
	transition-delay: 2.1s
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
	transition-duration: 2.15s
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
	transition-delay: 2.15s
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
	transition-duration: 2.2s
}

/* [data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
	transition-delay: 0
} */

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
	transition-delay: 2.2s
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
	transition-duration: 2.25s
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
	transition-delay: 2.25s
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
	transition-duration: 2.3s
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
	transition-delay: 2.3s
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
	transition-duration: 2.35s
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
	transition-delay: 2.35s
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
	transition-duration: 2.4s
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
	transition-delay: 2.4s
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
	transition-duration: 2.45s
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
	transition-delay: 2.45s
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
	transition-duration: 2.5s
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
	transition-delay: 2.5s
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
	transition-duration: 2.55s
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
	transition-delay: 2.55s
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
	transition-duration: 2.6s
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
	transition-delay: 2.6s
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
	transition-duration: 2.65s
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
	transition-delay: 2.65s
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
	transition-duration: 2.7s
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
	transition-delay: 2.7s
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
	transition-duration: 2.75s
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
	transition-delay: 2.75s
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
	transition-duration: 2.8s
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
	transition-delay: 2.8s
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
	transition-duration: 2.85s
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
	transition-delay: 2.85s
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
	transition-duration: 2.9s
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
	transition-delay: 2.9s
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
	transition-duration: 2.95s
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
	transition-delay: 2.95s
}
[data-aos][data-aos][data-aos-delay="3200"].aos-animate,
body[data-aos-delay="3200"] [data-aos].aos-animate {
	transition-delay: 3.2s
}
[data-aos][data-aos][data-aos-delay="4200"].aos-animate,
body[data-aos-delay="4200"] [data-aos].aos-animate {
	transition-delay: 4.2s
}
[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
	transition-delay: 2.2s
}
[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
	transition-duration: 3s
}
[data-aos][data-aos][data-aos-duration="3200"],
body[data-aos-duration="3200"] [data-aos] {
	transition-duration: 3.2s
}
[data-aos][data-aos][data-aos-duration="4200"],
body[data-aos-duration="4200"] [data-aos] {
	transition-duration: 4.2s
}
/* [data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
	transition-duration: 4.2s
} */

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
	transition-delay: 0
}
[data-aos][data-aos][data-aos-delay="3200"],
body[data-aos-delay="3200"] [data-aos] {
	transition-delay: 0
}
[data-aos][data-aos][data-aos-delay="4200"],
body[data-aos-delay="4200"] [data-aos] {
	transition-delay: 0
}
[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
	transition-delay: 3s
}

[data-aos][data-aos][data-aos-delay="3500"].aos-animate,
body[data-aos-delay="3500"] [data-aos].aos-animate {
	transition-delay: 3.5s
}

[data-aos][data-aos][data-aos-delay="4000"].aos-animate,
body[data-aos-delay="4000"] [data-aos].aos-animate {
	transition-delay: 4s
}

[data-aos][data-aos][data-aos-delay="4500"].aos-animate,
body[data-aos-delay="4500"] [data-aos].aos-animate {
	transition-delay: 4.5s
}

[data-aos][data-aos][data-aos-delay="5500"].aos-animate,
body[data-aos-delay="5500"] [data-aos].aos-animate {
	transition-delay: 5.5s
}

[data-aos][data-aos][data-aos-delay="6000"].aos-animate,
body[data-aos-delay="6000"] [data-aos].aos-animate {
	transition-delay: 6s
}

[data-aos][data-aos][data-aos-delay="6500"].aos-animate,
body[data-aos-delay="6500"] [data-aos].aos-animate {
	transition-delay: 6.5s
}

[data-aos][data-aos][data-aos-delay="7500"].aos-animate,
body[data-aos-delay="7500"] [data-aos].aos-animate {
	transition-delay: 7.5s
}



[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
	transition-timing-function: cubic-bezier(.25, .25, .75, .75)
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
	transition-timing-function: ease
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
	transition-timing-function: ease-in
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
	transition-timing-function: ease-out
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
	transition-timing-function: ease-in-out
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
	transition-timing-function: cubic-bezier(.6, -.28, .735, .045)
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
	transition-timing-function: cubic-bezier(.175, .885, .32, 1.275)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
	transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
	transition-timing-function: cubic-bezier(.47, 0, .745, .715)
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
	transition-timing-function: cubic-bezier(.39, .575, .565, 1)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
	transition-timing-function: cubic-bezier(.445, .05, .55, .95)
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
	transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
	transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
	transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
	transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
	transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
	transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
	transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
	transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
	transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos^=fade][data-aos^=fade] {
	opacity: 1;
	transition-property: opacity, transform
}

[data-aos^=fade][data-aos^=fade].aos-animate {
	 opacity: 1 !important; 
	transform: translateZ(0)
}


[data-aos=fade-up] {
	transform: translate3d(0, 100px, 0)
}

[data-aos=fade-down] {
	transform: translate3d(0, -100px, 0)
}

[data-aos=fade-right] {
	transform: translate3d(-100px, 0, 0)
}

[data-aos=fade-left] {
	transform: translate3d(100px, 0, 0)
}

[data-aos=fade-up-right] {
	transform: translate3d(-100px, 100px, 0)
}

[data-aos=fade-up-left] {
	transform: translate3d(100px, 100px, 0)
}

[data-aos=fade-down-right] {
	transform: translate3d(-100px, -100px, 0)
}

[data-aos=fade-down-left] {
	transform: translate3d(100px, -100px, 0)
}

[data-aos^=zoom][data-aos^=zoom] {
	opacity: 0;
	transition-property: opacity, transform
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
	opacity: 1;
	transform: translateZ(0) scale(1)
}

[data-aos=zoom-in] {
	transform: scale(.6)
}

[data-aos=zoom-in-up] {
	transform: translate3d(0, 100px, 0) scale(.6)
}

[data-aos=zoom-in-down] {
	transform: translate3d(0, -100px, 0) scale(.6)
}

[data-aos=zoom-in-right] {
	transform: translate3d(-100px, 0, 0) scale(.6)
}

[data-aos=zoom-in-left] {
	transform: translate3d(100px, 0, 0) scale(.6)
}

[data-aos=zoom-out] {
	transform: scale(1.2)
}

[data-aos=zoom-out-up] {
	transform: translate3d(0, 100px, 0) scale(1.2)
}

[data-aos=zoom-out-down] {
	transform: translate3d(0, -100px, 0) scale(1.2)
}

[data-aos=zoom-out-right] {
	transform: translate3d(-100px, 0, 0) scale(1.2)
}

[data-aos=zoom-out-left] {
	transform: translate3d(100px, 0, 0) scale(1.2)
}

[data-aos^=slide][data-aos^=slide] {
	transition-property: transform
}

[data-aos^=slide][data-aos^=slide].aos-animate {
	transform: translateZ(0)
}

[data-aos=slide-up] {
	transform: translate3d(0, 100%, 0)
}

[data-aos=slide-down] {
	transform: translate3d(0, -100%, 0)
}

[data-aos=slide-right] {
	transform: translate3d(-100%, 0, 0)
}

[data-aos=slide-left] {
	transform: translate3d(100%, 0, 0)
}

[data-aos^=flip][data-aos^=flip] {
	backface-visibility: hidden;
	transition-property: transform
}

[data-aos=flip-left] {
	transform: perspective(2500px) rotateY(-100deg)
}

[data-aos=flip-left].aos-animate {
	transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-right] {
	transform: perspective(2500px) rotateY(100deg)
}

[data-aos=flip-right].aos-animate {
	transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-up] {
	transform: perspective(2500px) rotateX(-100deg)
}

[data-aos=flip-up].aos-animate {
	transform: perspective(2500px) rotateX(0)
}

[data-aos=flip-down] {
	transform: perspective(2500px) rotateX(100deg)
}

[data-aos=flip-down].aos-animate {
	transform: perspective(2500px) rotateX(0)
}


@media only screen and (max-width: 768px) {
  .footer_bg_candidates .footer_links {
    display: initial;
  }
  .home-contact-us .MuiDialog-container {
    height: 75%;
    overflow: hidden;
    position: relative;
    top:6em;
  }
  .homepage-requestdemo .MuiDialog-container {
    height: 75%;
    overflow: hidden;
    position: relative;
    top:6em;
  }
  .homepage-requestdemo .MuiDialog-paperFullWidth {
    width: calc(100% - 10px);
    margin: 10px;
    }

  .home-contact-us .MuiDialog-paperFullWidth {
    width: calc(100% - 37px);
    margin: 20px;
  }
  .dropdown_width {
    width: 100% !important;
  }
  .dropdown_width_outer {
  display: inline !important;
  }
  .dropdown_width_outer .dropdown_width:last-child {
    margin-top: 1rem;
  }
  .responsive-margin {
    margin-top: 1rem;
  }
  .banner_bottom_section.banner_bottom-responsive-employer {
    margin-top: 50px;
  }
  .banner-section-image-responsive img {
    height: 100%;
    transform: translate(-11%, 0%);
    width: auto !important;
  }
  .banner-section-image-responsive {
    width: auto !important;
    height: 100vh;
  }
  .responsive-home-myVideo {
    transform: translate(-27%, -20%);
    height: 100%;
    width: auto !important;
  }
  .carousel-indicators {
    top: 9em;
  }
	.nav_logo_home {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 70%
}
	.nav_bg_home {
		display: none;
	}

	.nav_bg_home_section {
		display: block;
		justify-content: space-between;
	}

	.home-specific-header .nav_bg_left {
		width: 100%;
	}

	.navbar_home li a {
		padding: 10px 0px 0px 0px;
		color: #000000;
		text-decoration: none;
		font-size: 1.0625em;
		font-family: 'Manrope-Regular';
		margin-left: 6px;
	}

	ol, ul {
		padding-left: 0rem;
	}

	.btn-home {
		display: inline-block;
		font-weight: normal;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		border: 1px solid transparent;
		padding: 10px 8px;
		font-size: 1em;
		border-radius: 8px;
		cursor: pointer;
		font-family: 'Manrope-Regular';
	}

	.banner_section {
		width: 100%;
		position: relative;
		overflow: hidden;
		top: 70px;
	}

	.banner_text_top {
		font-family: 'Manrope-Bold';
		font-size: 1.66em;
		color: #EFEFEF;
	}

	.banner_text_bottom {
		font-family: 'Manrope-Regular';
		font-size: 1.2em;
		color: #fff;
	}

	.banner_text_section {
		position: absolute;
		top: 30em;
		width: 100%;
	}

	.banner_bottom_section_wrapper {
		display: block;
		justify-content: space-between;
	}
	.banner_left_text_section {
		width: 100%;
	}

	.banner_bottom_section {
		width: 100%;
		background-color: #fff;
		-webkit-box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		-moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
		box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		margin-top: -150px;
	}

	.banner_bottom_section_text_left {
		font-size: 1.75em;
		color: #474747;
		padding: 35px 0px 10px 0px;
		font-family: 'Manrope-Medium';
	}

	.banner_bottom_section_text_right {
		color: #474747;
		font-size: 1.875em;
		padding:10px 0px 46px 0px;
		font-family: 'Manrope-SemiBold';
	}

	.bullet-line {
		width: 3px;
		background: #009efd;
		/* height: 200px; */
	}
	.bullet-line.hr-first-l {
		height: 105px;
	}
	.bullet-line.hr-second-l {
		height:120px;
	}
	.bullet-line.hr-third-l {
		height:105px;
	}
	.bullet-line.er-first-l {
		height: 130px;
	}
	.bullet-line.er-second-l {
		height: 70px;
	}
	.bullet-line.er-third-l {
		height: 200px;
	}
	.banner_bottom_section-1 {
		width: 100%;
		background-color: #fff;
		-webkit-box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		-moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
		box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		position: absolute;
		top: 56%;
	}

	.parallax_employers .banner_bottom_section-1-employers {
		width: 100%;
		background-color: #fff;
		-webkit-box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		-moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
		box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		position: absolute;
		top: 52%;
	}

	.start_button_section {
		width: 100%;
		position: absolute;
		top: 70%;
	}
	.banner_bottom_section-2 {
		width: 100%;
		position: absolute;
		top: 78%;
	}
	.footer_bg_employerpage {
		width: 100%;
		background-color: #C2C2C2;
		position: absolute;
		top: 84% !important;
	}

	.banner_bottom_section_employers_right {
		font-size: 1.5625em;
		color: #474747;
		padding: 20px 0px 20px 0px;
		width: 100%;
		font-family: 'Manrope-SemiBold';
		float: right;
		text-align: right;
	}

	.request-demo {
		font-size: 1em;
		padding: 15px 20px;
	}
	.banner_bottom_section_employers_left {
		font-size: 1.5625em;
		color: #474747;
		padding: 20px 0px 0px 0px;
		width: 100%;
		font-family: 'Manrope-SemiBold';
		text-align: left;
	}
	
	.footer_bg_candidates {
		width: 100%;
		background-color: #C2C2C2;
		position: absolute;
		top: 77%;
	}
	.btn1-home {
		display: inline-block;
		font-weight: normal;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		border: 1px solid transparent;
		padding: 10px 10px 10px 10px;
		font-size: 0.875em;
		border-radius: 11px;
		cursor: pointer;
		font-family: 'Manrope-Medium';
	}
	.banner_bottom_section_register {
		font-size: 1.3125em;
		color: #474747;
		padding: 10px 0px 10px 0px;
		width: 100%;
		font-family: 'Manrope-Medium';
	}

	.footer_left_logo {
		width: 100% !important;
	}
	.footer_adjust {
		display: block;
		justify-content: space-between;
		padding-bottom: 70px;
		padding-top: 25px;
	}

	.parallax_employers {
		/* The image used */
		background-image: url("./images//Mask\ Group\ 22.jpg");
		/* Set a specific height */
		min-height: 1600px;
		/* Create the parallax scrolling effect */
		background-attachment: fixed;
		background-position: 51% 45%;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.parallax {
		/* The image used */
		background-image: url("./images//Mask\ Group 2-mobile.png");
		/* Set a specific height */
		min-height: 1200px;
		/* Create the parallax scrolling effect */
		background-attachment: fixed;
		background-position: 86% 53%;
		background-repeat: no-repeat;
		
		background-size: cover;
	   
		position: relative;
	
	}
	.employer_banner_bottom_section_text_left_bottom_top {
		font-size: 1.8625em;
		color: #474747;
		padding: 50px 0px 50px 0px;
		font-family: 'Manrope-Medium';
		line-height: normal;
	}

}

@media (min-width: 1367px) and (max-width: 1920px){

  .default_video {
    width: 437px !important;
  }
  .test_landing_main .form-one-left_recruiter {
    background: #fff;
    padding: 0px 26% !important;
}

.form-one-left-section {
  margin-top: 0px !important;
}

.test_landing_main .test_left_adjust {
  margin-top: 10% !important;
}
  .parallax {
    background-image: url("./images//Mask Group 2.png");
    min-height: 1200px;
    background-attachment: fixed;
    background-position: 10% 58%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


}

.bullet_text_break {
  margin-top:-1.25%;
}
.footer_left_logo {
  width: 25%;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  transform: scale(1.3);
  transition: all .2s ease-in-out;
}
#myBtn.show-button{
  display: block;
}

.home-top-nav {
	overflow: hidden;
	position: fixed;
	width: 100%;
	z-index: 9999;
	background: #fff;
	box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
}

.home-top-nav #myLinks {
  display: none;
}

.home-top-nav a {
  color: #009efd;
	padding: 22px 16px;
  text-decoration: none;
  font-size: 25px;
  display: block;
}

.home-top-nav a.icon {
 
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
#myLinks {
	background:#fff;
}
.navbar_home-responsive {
	padding: 14px 10px;
  margin-top: 0;
}
.navbar_home-responsive a {
	color: #000;
	font-size: 1.1em;
	padding: 7px 10px;
  font-family: 'Manrope-Medium';
}
@media (min-width: 769px) {
	.nav_bg_home_responsive {
		display: none;
	}
}
.banner_bottom_section-1-employers {
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
  position: absolute;
  top: 40%;

}
#myVideo.responsive-home-myVideo {
 width: 100%;
}
.banner-section-image-responsive img {
  width: 100%;
}
.footer_bg_candidates .footer_links li:last-child {
  padding-left: 3px;
}
.carousel {
  position: relative;
}

@media (min-width: 1200px) {
.carousel-indicators {
    top: 10em;
}
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
}
/* .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
} */
.carousel-caption {
  position: static;
  left: 0;
  bottom: 0;
  padding: 0;
  text-align: left;
  right: 15%;
  color: #fff;
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -4em;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

@media (min-width: 1367px) {
  .w1200 {
    max-width: 90%;
  }
  .bullet-text.bullet_text_break {
    margin-top:0%;
}
}
.homepage-requestdemo legend {
  font-family: 'Manrope-Bold' !important;
  font-size: 16px;
  margin-bottom: 17px;
  font-style: normal;
  font-weight: 600;
  color: #01313c;
}
.homepage-requestdemo .MuiTypography-h6 {
  font-family: 'Manrope-Light' !important;
}

.homepage-requestdemo .text_box_cleartalent {
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}
.homepage-requestdemo .btn-big {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 9px 35px 11px;
  font-size: 18px;
  border-radius: 4px;
  font-family:'Manrope-Bold';
}

.footer_bg_candidates .footer_links li:last-child a {
  text-decoration: underline;
  color: #1a8fff;
}

li.active-link {
  font-weight: bold;
}

@media (min-width: 768px) {
  .homepage-footer-left-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .homepage-footer-left-logo img {
    margin-top: 0px;
  }
  .footer_adjust.footer-adjust-home {
    padding-top: 46px;
    padding-bottom: 46px;
  }
 
}
.home-footer-terms {
  font-size: 1.0625em;
  font-family: 'Manrope-Medium';
  color: #2896ff;
  text-decoration: underline;
}
.home-footer-terms-concat {
  font-size: 1.0625em;
  font-family: 'Manrope-Medium';
  color: #2896ff;
}


.test_landing_header {
  text-align: center;
  background-color: white;
  padding: 102px 0px 15px;

}

.test_landing_header1 {
  text-align: center;
  background-color: white;
  padding: 52px 0px 15px;
}

.test_landing_header_text_1 {
  font-size: 25px;
  font-family: 'Manrope-Bold';
  color: #04093F;
}
.test_landing_header_text_2 {
  font-size: 22px;
  font-family: 'Manrope-Bold';
  color: #04093F;
  padding-top: 22px;
}
.test_landing_header_text_21 {
  font-size: 22px;
  font-family: 'Manrope-Bold';
  color: #04093F;
  padding-top: 10px;
}

.test_landing_header_text_3 {
  font-size: 14px;
  font-family: 'Manrope-Regular';
  color: #01313C;
  padding-top: 5px;
}
.test_landing_header_text_3_3 {
  color: #1A8FFF;
}

.test_dres_right_header {
  text-align: center;
  padding-bottom: 25px;
}
.test_dres_right_bottom {
  text-align: center;
  padding: 25px 0px;
  display: flex;
  justify-content: center;
}

.test_dres_right_bottom1 {
  text-align: center;
  padding: 0px 0px 25px;
}
.test_dres_right_header_text {
  font-size: 35px;
  font-family: 'Manrope-Bold';
  color: #04093F;
  
}

.cleartalent_sign_top_errormsg {
  margin-top: 120px;
    display: flex;
    justify-content: center;
}

.cleartalent_sign_top_errormsg_text {
font-family: 'Manrope-Regular';
    font-size: 16px;
    color: #ff0000;
    font-weight: 600;
    font-style: italic;
    padding-bottom: 10px;
  }

.test_dres_right_bottom_text {
  font-size: 14px;
  font-family: 'Manrope-Bold';
  color: #01313C;
  
}

.test_dres_right_bottom_text_1 {
  font-size: 14px;
  font-family: 'Manrope-Bold';
  color: #01313C;
  text-decoration: underline;
  width: auto;
  cursor:pointer;
}

.test_dres_right_bottom_text_2 {
  font-size: 14px;
  font-family: 'Manrope-Bold';
  color: #01313C;
  
  
}


.test_dres_right_header_text_1 {
  font-size: 17px !important;
  font-family: 'Manrope-Regular';
  color: #707070;
  padding-top: 24px;
  
}

.test_dres_right_header_text_1_1 {
  
  color: #2699FB;
 
  
}
.test_dres_right_header_text_1:first-child {
  padding-top: 0px;
}

.form_text_container_other .test_screen {
  padding: 0px 0% 0% 5%;
}


.btn-testlanding {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 15px 120px 16px 120px;
    font-size: 20px;
  border-radius: 8px;
   cursor: pointer;
  font-family: 'Manrope-Regular';
}


.test_landing_button_wrapper {
  display: flex;
  justify-content: center;
}
.btn-test-landing {
  color: #fff;
  background: #009EFD 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.btn-test-landing:hover {
  color: #fff;
  background-color: #26EDA1;
}

.btn-test-landing:focus {
  outline: none;
}
.test_landing {
  /* height: 272px !important; */
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}
.test_landing_left_bg {
  background-color: white;
  
}

.test_landing_video_bottom_section {
  height: 33px;
  background-color: #D1E8FF;
  border-radius: 0px 0px 8px 8px;
  display: flex;
    justify-content: center;
    align-items: center;
}

.test_landing_video_bottom_icon {
  justify-content: center;
  width: 20px;
  height: 20px;
  /* border: 1px solid red; */
  margin: 7px;
  display: flex;
  align-items: center;

}


.bubble_icon_section {
  position:absolute;
}

.test_landing_video_bottom_icon_adjust {
  width: 23px;
  cursor: pointer;
}



.test_recorder_bottom_text_section {
  text-align: center;
}
.test_recorder_bottom_text_section_text {
  font-size: 14px;
  font-family: 'Manrope-Regular';
  color: #01313C;
  padding-top: 26px;
}

.test_landing_main  .test_left_adjust {
  margin-top: 19%;
}
.default_video {
  width: 425px;
}




/* #root {
  
  background: white;
} */
.test_landing_main {
  margin-bottom: 20px;
  
}

.test_landing_main .form-one-left_recruiter {
  background: #fff;
  padding: 0px 17%;
}

.pid {
  /* height: 10%; */
    background: #2699FB;
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    border-radius: 30px;
    
}
.bar {
  width: 30px;
    background: #e4e4e4;
    margin-left: 10px;
    border-radius: 30px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.social_links{
  display: flex;
  justify-content: flex-end;
}
.iconColor{
  color: #000;
  margin-left: 5px;
}
@media (max-width: 768px){
  .social_links{
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}


