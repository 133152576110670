.pendingInvitemainContainer {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}

.pendingInviteMainGrid {
    padding: 0 5%;
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.kendoStyle {
    height: 100%;
    font-family: "Roboto", sans-serif;
}

.kendoGridDiv {
    width: 100%;
    height: 78vh;
}

.pendingInviteArrow {
    font-size: 20px;
    color: #04093f;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 21vw;
}

.pendingInviteTitle {
    font-size: 30px;
    color: #04093f;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 21vw;
    color: #2896ff;
    border-bottom: 1px solid #2896ff;
    padding-bottom: 5px;
}

.pendingInviteBottomContainer {
    margin-top: auto;
}


.pendingInvitemainContainer .k-table-tbody .k-table-row{
    height: 50px !important;
}

.pendingInviteMainGrid{
    padding-bottom: 10px;
}