.scroll_hide {
  overflow: unset !important;
}

.add_question_button {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
}

.mr_10 {
  margin-right: 10px !important;
}

.question_header {
  padding: 16px 0px !important;
}

.pb_15 {
  padding-bottom: 15px;
}
.height_adjust textarea {
  font-size: 16px;
  font-family: "Segoe-Regular";
  padding: 0.5rem;
  width:100%;
  margin-right: 20px;
}

.mySelect__value-container{
    height: 44px;
}

.choice_textarea textarea {
  width: 98% !important;
}

.choice_order_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.choice_order_section_width {
  width: 16%;
}
.choice_order_section_width_edit {
  width: 17%;
  margin-left: 30px;
}

.mt_30 {
  margin-top: 30px;
}

@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
.widthFix{
  width: 100%;
}
.ql-editor{
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}